import React from "react";
import {
  getContractTypeDescription,
  isBrokerCorpContract,
  isNotNullUndefinedOrEmpty,
} from "../../../../common/Functions";

const ContractTypeDescription = ({
  contracttypedesc = "",
  mmlis = "",
  hasFinancingAddon = "",
  agencynumber = "",
  corpmmid = "",
  corpcmid = "",
  mmid = "",
  cmid = "",
}) => {
  const buildContractLine = () => {
    let contractLine = getContractTypeDescription(
      contracttypedesc,
      mmlis,
      hasFinancingAddon,
      agencynumber
    );
    if (!isBrokerCorpContract(contracttypedesc)) {
      contractLine += isNotNullUndefinedOrEmpty(mmid) ? ` | MMID ${mmid}` : "";
      contractLine += isNotNullUndefinedOrEmpty(cmid) ? ` | CMID ${cmid}` : "";
    }
    return contractLine;
  };
  const buildLine = (line = "", mm = "", cm = "") => {
    let newLine = line;
    newLine += isNotNullUndefinedOrEmpty(mm) ? ` | MMID ${mm}` : "";
    newLine += isNotNullUndefinedOrEmpty(cm) ? ` | CMID ${cm}` : "";
    return newLine;
  };
  const showCorporationLine =
    isBrokerCorpContract(contracttypedesc) &&
    (isNotNullUndefinedOrEmpty(corpmmid) ||
      isNotNullUndefinedOrEmpty(corpcmid));
  const showMemberCorpLine =
    isBrokerCorpContract(contracttypedesc) &&
    (isNotNullUndefinedOrEmpty(mmid) || isNotNullUndefinedOrEmpty(cmid));
  return (
    <>
      <div id="confidential_contract type" className="eyebrow">
        {buildContractLine()}
        {showCorporationLine && (
          <div>
            <br />
            {buildLine("CORPORATION", corpmmid, corpcmid)}
          </div>
        )}
        {showMemberCorpLine && (
          <div>
            <br />
            {buildLine("MEMBER OF CORPORATION", mmid, cmid)}
          </div>
        )}
      </div>
    </>
  );
};

export default ContractTypeDescription;

import DRPSectionDivider from "../common/DRPSectionDivider";

const ReviewJudgmentLienDRP = ({
  drp: {
    comments,
    courtDetails: {
      courtActionType,
      courtActionTypeOtherText,
      courtLocation,
      courtName,
      docketOrCaseNumber,
      isPrivateNumber,
    },
    dispositionDetails: {
      isJudgmentLienOutstanding,
      matterResolvedOption,
      startDate,
      startDateExplanation,
      startDateOption,
    },
    judgmentLienDetails: {
      dateFiledWithCourt,
      dateFiledWithCourtExplanation,
      dateFiledWithCourtOption,
      dateLearned,
      dateLearnedExplanation,
      dateLearnedOption,
      judgmentLienAmount,
      judgmentLienHolder,
      judgmentLienType,
    },
  },
}) => {
  return (
    <div id="ReviewJudgmentLienDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">14M</div>
      </div>
      <DRPSectionDivider section={"JUDGMENT/LIEN DETAILS"} />

      <br />
      <div className="row">
        <div className="col-6">
          <strong>Judgment/Lien Type: *</strong>
        </div>
        <div className="col-6">{judgmentLienType}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Judgment/Lien Amount *</strong>
        </div>
        <div className="col-6">${judgmentLienAmount}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Judgment/Lien Holder:*</strong>
        </div>
        <div className="col-6">{judgmentLienHolder}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Date filed with Court: *</strong>
        </div>
        <div className="col-6">
          {dateFiledWithCourt}
          <br />
          {`${dateFiledWithCourtOption}${
            dateFiledWithCourtOption === "Explanation"
              ? `: ${dateFiledWithCourtExplanation}`
              : ""
          }`}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Date individual learned of the Judgment/Lien: *</strong>
        </div>
        <div className="col-6">
          {dateLearned}
          <br />
          {`${dateLearnedOption}${
            dateLearnedOption === "Explanation"
              ? `: ${dateLearnedExplanation}`
              : ""
          }`}
        </div>
      </div>
      <DRPSectionDivider section={"COURT DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Court action brought in: *</strong>
        </div>
        <div className="col-6">
          {courtActionType}
          {courtActionType === "Other" ? `: ${courtActionTypeOtherText}` : ""}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Name of court (Federal, State, Foreign or Other): *</strong>
        </div>
        <div className="col-6">{courtName}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>
            Location of court (City or County{" "}
            <span style={{ textDecoration: "underline" }}>and</span> State or
            Country): *
          </strong>
        </div>
        <div className="col-6">{courtLocation}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Docket/Case #: *</strong>
        </div>
        <div className="col-6">{docketOrCaseNumber}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>
            Check this box if the Docket/Case # is your SSN, a Bank Card number,
            or a Personal Identification Number.
          </strong>
        </div>
        <div className="col-6">{isPrivateNumber ? "Checked" : "-"}</div>
      </div>
      <DRPSectionDivider section={"DISPOSITION DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Is Judgment/Lien outstanding?*</strong>
        </div>
        <div className="col-6">{isJudgmentLienOutstanding}</div>
      </div>
      {isJudgmentLienOutstanding === "No" ? (
        <>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>How was the matter resolved? *</strong>
            </div>
            <div className="col-6">{matterResolvedOption}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Start Date: *</strong>
            </div>
            <div className="col-6">
              {startDate}
              <br />
              {`${startDateOption}${
                startDateOption === "Explanation"
                  ? `: ${startDateExplanation}`
                  : ""
              }`}
            </div>
          </div>
        </>
      ) : null}
      <DRPSectionDivider section={"COMMENTS"} />
      <div className="row">
        <div className="col-6">
          <strong>
            You may use this field to provide a brief summary of the
            circumstances leading to the action as well as the current status or
            final disposition. (Optional)
          </strong>
        </div>
        <div className="col-6">{comments === "" ? "-" : comments}</div>
      </div>
    </div>
  );
};
export default ReviewJudgmentLienDRP;

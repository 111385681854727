import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FORM_ID } from "../../../../../constants/Forms";
import DisclosureReportingPageLayout from "../common/DRPLayout";

import {
  CCB_FORM_ID,
  defTimelineSteps,
  defTimelineSteps2,
  headerText,
  initialState,
  orgDetails,
} from "./constants";
import { isDrpSummaryPage } from "./utils";
import { convertGroupKey } from "../helpers";

import CriminalDisclosureForm from "./CriminalDisclosureForm";
import CriminalDisclosureDRPSummary from "./CriminalDisclosureDRPSummary";
import AddDRPFormLayout from "../common/AddDRPFormLayout";

import CriminalDisclosureAddDRPOrganizationDetailsForm from "./AddDRPForms/CriminalDisclosureAddDRPOrganizationDetailsForm";
import CriminalDisclosureAddDRPCourtDetailsForm from "./AddDRPForms/CriminalDisclosureAddDRPCourtDetailsForm";
import CriminalDisclosureAddDRPEventStatusForm from "./AddDRPForms/CriminalDisclosureAddDRPEventStatusForm";
import CriminalDisclosureAddDRPEventDispDetailsForm from "./AddDRPForms/CriminalDisclosureAddDRPEventDispDetailsForm";
import CriminalDisclosureAddDRPCommentsForm from "./AddDRPForms/CriminalDisclosureAddDRPCommentsForm";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import {
  getCCBDRPIndOrgList,
  insertUpdateCCB14DRP,
  updateSingleCCB14DrpIndOrg,
} from "../../../../../api/CCB/DRP/criminalDisclosureDRP";
import { DRP_TYPES } from "../../../../../api/helpers/constants";

const drpListInitState = { ind: [], org: [] };

const CriminalDisclosure = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  candidateId,
  agencynumber,
  contracttypeid,
}) => {
  const { data: ccbData, isBack } = useSelector((state) => state.ccb14);

  const [customTimelineSteps, setCustomTimelineSteps] =
    useState(defTimelineSteps);
  const [customTimelineSteps2, setCustomTimelineSteps2] =
    useState(defTimelineSteps2);

  const [isLoading, setIsLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const [isRefetch, setIsDataRefetch] = useState();
  const [drpList, setDrpList] = useState({ ...drpListInitState });

  const [data, setData] = useState(initialState);
  const [sectionState, setSectionState] = useState(headerText.IND_CRIM_DISCL);
  const [values, setValues] = useState(null);

  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(false);

  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.CRIMINAL_DISCLOSURE
  );
  const { ind, org, uncheckedInd, uncheckedOrg } = isDrpSummaryPage(ccbData);

  const [isNewData, setIsNewData] = useState(false);
  const [dataIndex, setDataIndex] = useState(null);

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);

  const isMounted = useRef(false);

  const fetchCCB14DRP = async () => {
    try {
      setIsLoading(true);
      const indOrgList = await getCCBDRPIndOrgList({
        oktaSubId,
        drpTypeId: DRP_TYPES.Criminal,
      });

      setDrpList({ ind: indOrgList.ind, org: indOrgList.org });
      setIsLoading(false);

      isMounted.current = false;
      setIsDataRefetch(false);
    } catch (err) {
      setIsLoading(false);
      setIsDataRefetch(false);
      isMounted.current = false;
      if (err) {
        console.error(err);
      }
    }
  };

  useEffect(
    () => {
      isMounted.current = true;

      if (isMounted.current) {
        fetchCCB14DRP();
      }

      return () => {
        isMounted.current = false;
        window.onbeforeunload = null;
        setIsDataRefetch(false);
      };
    } /* eslint-disable-next-line */,
    []
  );

  useEffect(
    () => {
      if (isRefetch) {
        fetchCCB14DRP();
      }
    } /* eslint-disable-next-line */,
    [isRefetch]
  );

  useEffect(() => {
    if ((ind?.length || org?.length) && isBack) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }

    UtagTracking.sendPageNameView(
      "criminaldisclosureform",
      candidateId,
      agencynumber,
      contracttypeid
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const updateCCBForm = (formId, options = null) => {
    if (options?.section) {
      setSectionState(options?.section);
    }

    if (options?.values) {
      setValues(options?.values);
    }

    if (options?.isReset) {
      setIsDataRefetch(true);
      setIsNewData(true);
      setDataIndex(null);
      setData(initialState);
    }

    if (options?.isEdit) {
      if (options.data && options.section) {
        const { dataIndex: dI, isDraft, ...rest } = options.data;
        setDataIndex(dI);
        setIsDraft(isDraft === false ? false : null);
        setData({ [convertGroupKey(options.section)]: rest });
        setIsNewData(false);
      }
    }

    if (options?.isDelete) {
      if (typeof options.index === "number" && options?.index >= 0) {
        setDataIndex(options.index);
        setIsNewData(false);
      }
    }

    if (!isDRPCompleted && options?.isBackClick) {
      updateCCB14Step({ crimDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: false });
    }

    setCurrentFormId(formId);
  };

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case headerText.IND_CRIM_DISCL:
        s = "ind";
        break;
      case headerText.ORG_CRIM_DISCL:
        s = "org";
        break;
      default:
        break;
    }

    return s;
  };

  const getSubmitDataValues = (newData, name, completed = false) => {
    let groupKey = "";

    switch (name) {
      case headerText.ORG_CRIM_DISCL:
        groupKey = convertGroupKey(headerText.ORG_CRIM_DISCL);
        break;
      case headerText.IND_CRIM_DISCL:
        groupKey = convertGroupKey(headerText.IND_CRIM_DISCL);
        break;
      default:
        break;
    }

    const result = { ...data[groupKey], ...newData };

    const submitData = async () => {
      setIsLoading(true);

      try {
        await insertUpdateCCB14DRP({
          oktaSubId,
          drpTypeId: DRP_TYPES.Criminal,
          data: {
            groupKey,
            data: result,
            isNewData,
            isDraft: !completed,
          },
        });

        if (completed) {
          setIsLoading(true);
          setIsDataRefetch(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    const updateData = async () => {
      try {
        if (Object.values(newData)[0] === null) {
          setIsDataRefetch(true);
        } else {
          await updateSingleCCB14DrpIndOrg({
            oktaSubId,
            drpTypeId: DRP_TYPES.Criminal,
            data: {
              groupKey,
              data: result,
              isNewData,
              isDraft: isDraft === false ? isDraft : !completed,
            },
            section: sectionHandler(name),
            index: dataIndex,
          });
          if (completed) {
            setIsLoading(true);
            setIsDataRefetch(true);
          } else {
            setIsLoading(false);
          }
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    if (Object.keys(newData) && dataIndex === null && completed) {
      setIsLoading(true);
      submitData();
      setIsNewData(false);
    } else {
      if (typeof dataIndex === "number" && completed) {
        setIsLoading(true);
        updateData();
      }
    }

    setData((prevData) => ({
      ...prevData,
      [groupKey]: { ...prevData[groupKey], ...newData },
    }));
  };

  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.CRIMINAL_DISCLOSURE:
        return (
          <CriminalDisclosureForm
            header={headerText.HEADER}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            uncheckedInd={uncheckedInd}
            uncheckedOrg={uncheckedOrg}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            backFormId={FORM_ID.CCB_INTRO}
            setIsDataRefetch={setIsDataRefetch}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.HEADER}
            backFormId={CCB_FORM_ID.CRIMINAL_DISCLOSURE}
            nextFormId={FORM_ID.REGULATORY_ACTION_DISCLOSURE}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            ind="crimDisclosureCompleteInd"
            updateCCBForm={updateCCBForm}
            toggleForm={toggleForm}
            isNextDisabled={isDRPNextDisabled || isLoading}
            isLoading={isLoading}
          >
            <CriminalDisclosureDRPSummary
              oktaSubId={oktaSubId}
              updateCCB14Step={updateCCB14Step}
              updateCCB14CompletedInd={updateCCB14CompletedInd}
              updateCCBForm={updateCCBForm}
              setIsDRPNextDisabled={setIsDRPNextDisabled}
              setIsDRPCompleted={setIsDRPCompleted}
              data={drpList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsDataRefetch={setIsDataRefetch}
              ind={ind}
              org={org}
            />
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ORG_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={1}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Organization Details"
            description={orgDetails.DESCRIPTION}
          >
            <CriminalDisclosureAddDRPOrganizationDetailsForm
              data={
                data[convertGroupKey(headerText.ORG_CRIM_DISCL)]?.orgDetails
              }
              groupKey="orgDetails"
              values={values}
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.ADD_DRP_ORG_COURT_DETAILS}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.ORG_CRIM_DISCL}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ORG_COURT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={2}
            details="Court Details"
            setCustomTimelineSteps={setCustomTimelineSteps}
            steps={customTimelineSteps}
          >
            <CriminalDisclosureAddDRPCourtDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_ORG_DETAILS}
              nextFormId={CCB_FORM_ID.ADD_DRP_ORG_EVENT_STAT}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.ORG_CRIM_DISCL}
              groupKey="courtDetails"
              data={
                data[convertGroupKey(headerText.ORG_CRIM_DISCL)].courtDetails
              }
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ORG_EVENT_STAT:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={3}
            setCustomTimelineSteps={setCustomTimelineSteps}
            steps={customTimelineSteps}
            details="Event Status"
          >
            <CriminalDisclosureAddDRPEventStatusForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_ORG_COURT_DETAILS}
              nextFormId={CCB_FORM_ID.ADD_DRP_ORG_EVENT_DISP_DETAILS}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.ORG_CRIM_DISCL}
              groupKey="eventStatus"
              data={
                data[convertGroupKey(headerText.ORG_CRIM_DISCL)].eventStatus
              }
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ORG_EVENT_DISP_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={4}
            setCustomTimelineSteps={setCustomTimelineSteps}
            steps={customTimelineSteps}
            details="Event & Disposition Details"
          >
            <CriminalDisclosureAddDRPEventDispDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_ORG_EVENT_STAT}
              nextFormId={CCB_FORM_ID.ADD_DRP_ORG_COMMENTS}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.ORG_CRIM_DISCL}
              groupKey="eventDispDetails"
              data={
                data[convertGroupKey(headerText.ORG_CRIM_DISCL)]
                  .eventDispDetails
              }
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ORG_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={5}
            setCustomTimelineSteps={setCustomTimelineSteps}
            steps={customTimelineSteps}
            details="Comments"
          >
            <CriminalDisclosureAddDRPCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_ORG_EVENT_DISP_DETAILS}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.ORG_CRIM_DISCL}
              groupKey="comments"
              data={data[convertGroupKey(headerText.ORG_CRIM_DISCL)]?.comments}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_IND_COURT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={1}
            details="Court Details"
            setCustomTimelineSteps={setCustomTimelineSteps2}
            steps={customTimelineSteps2}
            description={orgDetails.DESCRIPTION}
          >
            <CriminalDisclosureAddDRPCourtDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.ADD_DRP_IND_EVENT_STAT}
              values={values}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.IND_CRIM_DISCL}
              data={
                data[convertGroupKey(headerText.IND_CRIM_DISCL)].courtDetails
              }
              groupKey="courtDetails"
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_IND_EVENT_STAT:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={2}
            setCustomTimelineSteps={setCustomTimelineSteps2}
            steps={customTimelineSteps2}
            details="Event Status"
          >
            <CriminalDisclosureAddDRPEventStatusForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_IND_COURT_DETAILS}
              nextFormId={CCB_FORM_ID.ADD_DRP_IND_EVENT_DISP_DETAILS}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.IND_CRIM_DISCL}
              data={
                data[convertGroupKey(headerText.IND_CRIM_DISCL)].eventStatus
              }
              groupKey="eventStatus"
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_IND_EVENT_DISP_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={3}
            setCustomTimelineSteps={setCustomTimelineSteps2}
            steps={customTimelineSteps2}
            details="Event & Disposition Details"
          >
            <CriminalDisclosureAddDRPEventDispDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_IND_EVENT_STAT}
              nextFormId={CCB_FORM_ID.ADD_DRP_IND_COMMENTS}
              getSubmitDataValues={getSubmitDataValues}
              headerText={headerText.IND_CRIM_DISCL}
              data={
                data[convertGroupKey(headerText.IND_CRIM_DISCL)]
                  .eventDispDetails
              }
              groupKey="eventDispDetails"
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_IND_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={sectionState}
            activeStep={4}
            setCustomTimelineSteps={setCustomTimelineSteps2}
            steps={customTimelineSteps2}
            details="Comments"
          >
            <CriminalDisclosureAddDRPCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_IND_EVENT_DISP_DETAILS}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              headerText={headerText.IND_CRIM_DISCL}
              getSubmitDataValues={getSubmitDataValues}
              data={data[convertGroupKey(headerText.IND_CRIM_DISCL)]?.comments}
              groupKey="comments"
            />
          </AddDRPFormLayout>
        );
      default:
        break;
    }
  };

  return (
    <div className="criminal-disclosure-wrapper">
      {renderForm(currentFormId)}
    </div>
  );
};

export default CriminalDisclosure;

function ReviewCCB(props) {
  return (
    <div id="reviewCCB" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_ccb_info">Corporation Character, Credit, and Bonding</h3>
      </div>

      <div className="candidate-info">
        <div className="row">
          <div className="col-6">
            <strong id="ccbQuestion1">
              Is the Corporation now or has it in the past been directly or
              indirectly involved in a lawsuit since being in the business of
              insurance?*
            </strong>
          </div>
          <div id="ccbAnswer1" className="col-6 bo--text-color">
            {props.ccbInfo.involvedlawsuit === null ||
            props.ccbInfo.involvedlawsuit === ""
              ? "-"
              : props.ccbInfo.involvedlawsuit === false
              ? "No"
              : "Yes"}
          </div>
        </div>
        {props.ccbInfo.involvedlawsuit === true && (
          <div className="row">
            <div className="col-6">
              {props.id === "conf_ccb" && (
                <strong>
                  Please explain the Corporation's involvement in a lawsuit
                  relative to insurance practice.*
                </strong>
              )}
            </div>
            <div id="ccbReason1" className="col-6 bo--text-color">
              {props.ccbInfo.involvedlawsuitexp}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-6">
            <strong id="ccbQuestion2">
              Have any of the Corporate Officers ever been convicted of any
              crime (felony or misdemeanor)?*
            </strong>
          </div>
          <div id="ccbAnswer2" className="col-6 bo--text-color">
            {props.ccbInfo.convictedcrime === null ||
            props.ccbInfo.convictedcrime === ""
              ? "-"
              : props.ccbInfo.convictedcrime === false
              ? "No"
              : "Yes"}
          </div>
        </div>
        {props.ccbInfo.convictedcrime === true && (
          <div className="row">
            <div className="col-6">
              {props.id === "conf_ccb" && (
                <strong>
                  Please explain the crime (felony or misdemeanor) of which the
                  corporate officer has been convicted of.*
                </strong>
              )}
            </div>
            <div id="ccbReason2" className="col-6 bo--text-color">
              {props.ccbInfo.convictedcrimeexp}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-6">
            <strong id="ccbQuestion3">
              Has the Corporation ever had a professional license suspended,
              revoked, cancelled?*
            </strong>
          </div>
          <div id="ccbAnswer3" className="col-6 bo--text-color">
            {props.ccbInfo.licenseissues === null ||
            props.ccbInfo.licenseissues === ""
              ? "-"
              : props.ccbInfo.licenseissues === false
              ? "No"
              : "Yes"}
          </div>
        </div>
        {props.ccbInfo.licenseissues === true && (
          <div className="row">
            <div className="col-6">
              {props.id === "conf_ccb" && (
                <strong>
                  Please explain why the Corporation's professional license was
                  suspended, revoked, cancelled.*
                </strong>
              )}
            </div>
            <div id="ccbReason3" className="col-6 bo--text-color">
              {props.ccbInfo.licenseissuesexp}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-6">
            <strong id="ccbQuestion4">
              Has the Corporation ever declared bankruptcy, compromised with
              creditors, or had any liens or judgments against it?*
            </strong>
          </div>
          <div id="ccbAnswer4" className="col-6 bo--text-color">
            {props.ccbInfo.failedbusiness === null ||
            props.ccbInfo.failedbusiness === ""
              ? "-"
              : props.ccbInfo.failedbusiness === false
              ? "No"
              : "Yes"}
          </div>
        </div>
        {props.ccbInfo.failedbusiness === true && (
          <div className="row">
            <div className="col-6">
              {props.id === "conf_ccb" && (
                <strong>
                  Please explain if the Corporation has ever declared
                  bankruptcy, compromised with creditors, or had any liens or
                  judgments against it.*
                </strong>
              )}
            </div>
            <div id="ccbReason4" className="col-6 bo--text-color">
              {props.ccbInfo.failedbuisinessexp}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewCCB;

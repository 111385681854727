/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import InputMask from "react-input-mask";
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import moment from "moment";
import "../../BankruptcyDisclosure.scss";
import { CCB_FORM_ID } from "../../../constants";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";

const pendingOptions = {
  YES: "Yes",
  NO: "No",
};

export const dispositionOptions = {
  DIRECT_PAYMENT_PROCEDURE: "Direct Payment Procedure",
  DISCHARGED: "Discharged",
  DISMISSED: "Dismissed",
  DISSOLVED: "Dissolved",
  SATISFIED_RELEASED: "Satisfied/Released",
  SIPA_TRUSTEE_APPOINTED: "SIPA Trustee Appointed",
  OTHER: "Other",
};

const dateOptions = {
  EXACT: "Exact date",
  EXPLANATION: "Explanation",
};

const BankruptcyDisclosureDispositionDetails = ({
  updateCCBForm,
  backFormId,
  setNotApplicable,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  isOrg,
  hasCompromise,
  hasSIPA,
}) => {
  const dispatch = useDispatch();
  const [dispositionDate, setDispositionDate] = useState("");
  const [dispositionDateError, setDispositionDateError] = useState(false);
  const [dispositionDateErrorText, setDispositionDateErrorText] = useState("");
  const [dispositionOption, setDispositionOption] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [dispositionOptionError, setDispositionOptionError] = useState(false);
  const [dispositionDateExplanation, setDispositionDateExplanation] =
    useState("");
  const [dispositionDateExplanationError, setDispositionDateExplanationError] =
    useState(false);
  const [dispositionPendingType, setDispositionPendingType] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [dispositionPendingTypeError, setDispositionPendingTypeError] =
    useState(false);
  const [dispositionType, setDispositionType] = useState("");
  const [dispositionTypeError, setDispositionTypeError] = useState(false);
  const [dispositionTypeOtherText, setDispositionTypeOtherText] = useState("");
  const [dispositionTypeOtherTextError, setDispositionTypeOtherTextError] =
    useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      dispositionType: dispositionType,
      dispositionTypeOtherText: dispositionTypeOtherText,
      dispositionPendingType: dispositionPendingType,
      dispositionDate: dispositionDate,
      dispositionOption: dispositionOption,
      dispositionDateExplanation: dispositionDateExplanation,
    };

    if (hasCompromise(tmpDRP)) {
      if (
        (dispositionType !== dispositionOptions.SIPA_TRUSTEE_APPOINTED &&
          dispositionPendingType === "No" &&
          dispositionType !== "") ||
        dispositionPendingType === "Yes"
      ) {
        setNotApplicable(isOrg ? [6] : [5]);
      } else {
        setNotApplicable([]);
      }
    } else if (hasSIPA(tmpDRP)) {
      setNotApplicable(() => (!isOrg ? [4] : [5]));
    } else {
      setNotApplicable(() => (!isOrg ? [4, 5] : [5, 6]));

      if (
        dispositionPendingType === "" ||
        dispositionType === dispositionOptions.SIPA_TRUSTEE_APPOINTED ||
        (dispositionType === "" && dispositionPendingType === "No")
      ) {
        setNotApplicable(isOrg ? [5] : [4]);
      }
    }
  }, [dispositionPendingType, dispositionType]);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };

  const handleNext = () => {
    if (dispositionType !== dispositionOptions.SIPA_TRUSTEE_APPOINTED) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(tempBankruptcyDRP)) {
        if (key.slice(0, 4) === "sipa") {
          delete tempBankruptcyDRP[key];
        }
      }
    }

    const tmpDRP = {
      ...tempBankruptcyDRP,
      dispositionType: dispositionType,
      dispositionTypeOtherText: dispositionTypeOtherText,
      dispositionPendingType: dispositionPendingType,
      dispositionDate: dispositionDate,
      dispositionOption: dispositionOption,
      dispositionDateExplanation: dispositionDateExplanation,
    };
    updateTempBankruptcyDRP(tmpDRP);

    if (hasCompromise(tmpDRP)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_4);
    } else if (hasSIPA(tmpDRP)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_5);
    } else {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_COMMENTS);
    }

    hasCurrentFormChanged(false);
  };

  const handleBack = () => {
    updateCCBForm(backFormId);
  };

  const handleDispositionTypeChange = ({ target: { value } }) => {
    hasCurrentFormChanged(true);
    setDispositionType(value);
    if (value !== dispositionOptions.OTHER) {
      setDispositionTypeOtherText("");
      setDispositionTypeOtherTextError(false);
    }
  };

  const handlePendingTypeChange = ({ target: { value } }) => {
    hasCurrentFormChanged(true);
    setDispositionPendingType(value);
    if (value === pendingOptions.YES) {
      setDispositionType("");
      setDispositionTypeError(false);
    }
  };

  const handleDateChange = ({ target: { id, value } }) => {
    setDispositionDate(value);
    hasCurrentFormChanged(true);
  };

  const handleActionDateOptionChange = ({ target: { value } }) => {
    setDispositionOption(value);
    if (value === dateOptions.EXACT) {
      setDispositionDateExplanation("");
      setDispositionDateExplanationError(false);
    }
    hasCurrentFormChanged(true);
  };

  const handleTextInputChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "dispositionDate":
        setDispositionDate(value);
        break;
      case "dispositionTypeOtherText":
        setDispositionTypeOtherText(value);
        break;
      case "dispositionDateExplanation":
        setDispositionDateExplanation(value);
        break;
      default:
        break;
    }
  };

  const onBlurTextInput = ({ target: { id, value } }) => {
    switch (id) {
      case "dispositionDateExplanation":
        setDispositionDateExplanationError(value.trim().length < 2);
        break;
      case "dispositionTypeOtherText":
        setDispositionTypeOtherTextError(value.trim().length < 2);
        break;
      default:
        break;
    }

    setFocusedState(false);
  };

  const validationDate = (date, trueDate) => {
    return String(date) === String(trueDate);
  };

  const isInvalidDate = (value) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    return (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation) ||
      inputValue.isAfter(todaysDate) ||
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    );
  };

  const onBlurDateInput = ({ target: { id, value } }) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    let hasError = false;
    let errorText = "";
    if (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation)
    ) {
      errorText = "Please enter the Disposition Date";
      hasError = true;
    } else if (inputValue.isAfter(todaysDate)) {
      errorText = "Date cannot be a future date";
      hasError = true;
    } else if (
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    ) {
      errorText = "Date cannot be more than 100 years ago";
      hasError = true;
    }

    setFocusedState(false);
    setDispositionDateError(hasError);
    setDispositionDateErrorText(errorText);
  };

  const allFilled = () => {
    return (
      !isInvalidDate(dispositionDate) &&
      ((dispositionOption === dateOptions.EXPLANATION &&
        dispositionDateExplanation.trim().length > 1) ||
        dispositionOption === dateOptions.EXACT) &&
      dispositionPendingType !== "" &&
      (dispositionPendingType !== pendingOptions.NO ||
        (dispositionPendingType === pendingOptions.NO &&
          dispositionType !== "" &&
          (dispositionType !== dispositionOptions.OTHER ||
            (dispositionType === dispositionOptions.OTHER &&
              dispositionTypeOtherText.trim().length > 1))))
    );
  };
  const noErrors = () => {
    return (
      !dispositionTypeError &&
      !dispositionPendingTypeError &&
      !dispositionDateError &&
      !dispositionDateExplanationError &&
      !dispositionTypeOtherTextError &&
      !dispositionOptionError
    );
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });

  useEffect(() => {
    if (
      tempBankruptcyDRP?.dispositionPendingType &&
      tempBankruptcyDRP?.dispositionDate &&
      tempBankruptcyDRP?.dispositionOption
    ) {
      setDispositionType(tempBankruptcyDRP?.dispositionType);
      setDispositionPendingType(tempBankruptcyDRP?.dispositionPendingType);
      setDispositionDate(tempBankruptcyDRP?.dispositionDate);
      setDispositionOption(tempBankruptcyDRP?.dispositionOption);
      setDispositionDateExplanation(
        tempBankruptcyDRP?.dispositionDateExplanation
      );
      setDispositionTypeOtherText(tempBankruptcyDRP?.dispositionTypeOtherText);
      setIsFormComplete(true);
      //       if (editIndex !== null) {
      //   hasCurrentFormChanged(false);
      // } else {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      // }
    }
  }, []);

  useEffect(() => {
    if (currentFormChanged || !noErrors()) {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [currentFormChanged, noErrors(), dispatch]);

  return (
    <div className="bankruptcy-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="dispositionPendingTypeLabelId" className="field-label">
            Is action currently pending?*
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dispositionPendingTypeYes"
                type="radio"
                id="dispositionPendingTypeYes"
                className="custom-control-input"
                value={pendingOptions.YES}
                checked={dispositionPendingType === pendingOptions.YES}
                onChange={handlePendingTypeChange}
              />
              <label
                id="dispositionPendingTypeYesLabelId"
                htmlFor="dispositionPendingTypeYes"
                className="custom-control-label"
              >
                {pendingOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dispositionPendingTypeNo"
                type="radio"
                id="dispositionPendingTypeNo"
                className="custom-control-input"
                value={pendingOptions.NO}
                checked={dispositionPendingType === pendingOptions.NO}
                onChange={handlePendingTypeChange}
              />
              <label
                id="dispositionPendingTypeNoLabelId"
                htmlFor="dispositionPendingTypeNo"
                className="custom-control-label"
              >
                {pendingOptions.NO}
              </label>
            </div>
            <div id="dispositionPendingTypeError" className="invalid-feedback">
              Please select an option
            </div>
          </div>
        </div>
      </div>
      {dispositionPendingType === pendingOptions.NO && (
        <>
          <div className="row">
            <div className="col-md-12">
              <label id="dispositionTypeLabelId" className="field-label">
                If not pending, provide Disposition Type: *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeDPP"
                    type="radio"
                    id="dispositionTypeDPP"
                    className="custom-control-input"
                    value={dispositionOptions.DIRECT_PAYMENT_PROCEDURE}
                    checked={
                      dispositionType ===
                      dispositionOptions.DIRECT_PAYMENT_PROCEDURE
                    }
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeDPPLabelId"
                    htmlFor="dispositionTypeDPP"
                    className="custom-control-label"
                  >
                    {dispositionOptions.DIRECT_PAYMENT_PROCEDURE}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeDischarged"
                    type="radio"
                    id="dispositionTypeDischarged"
                    className="custom-control-input"
                    value={dispositionOptions.DISCHARGED}
                    checked={dispositionType === dispositionOptions.DISCHARGED}
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeDischargedLabelId"
                    htmlFor="dispositionTypeDischarged"
                    className="custom-control-label"
                  >
                    {dispositionOptions.DISCHARGED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeDismissed"
                    type="radio"
                    id="dispositionTypeDismissed"
                    className="custom-control-input"
                    value={dispositionOptions.DISMISSED}
                    checked={dispositionType === dispositionOptions.DISMISSED}
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeDismissedLabelId"
                    htmlFor="dispositionTypeDismissed"
                    className="custom-control-label"
                  >
                    {dispositionOptions.DISMISSED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeDissolved"
                    type="radio"
                    id="dispositionTypeDissolved"
                    className="custom-control-input"
                    value={dispositionOptions.DISSOLVED}
                    checked={dispositionType === dispositionOptions.DISSOLVED}
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeDissolvedLabelId"
                    htmlFor="dispositionTypeDissolved"
                    className="custom-control-label"
                  >
                    {dispositionOptions.DISSOLVED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeSR"
                    type="radio"
                    id="dispositionTypeSR"
                    className="custom-control-input"
                    value={dispositionOptions.SATISFIED_RELEASED}
                    checked={
                      dispositionType === dispositionOptions.SATISFIED_RELEASED
                    }
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeSRLabelId"
                    htmlFor="dispositionTypeSR"
                    className="custom-control-label"
                  >
                    {dispositionOptions.SATISFIED_RELEASED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeSIPA"
                    type="radio"
                    id="dispositionTypeSIPA"
                    className="custom-control-input"
                    value={dispositionOptions.SIPA_TRUSTEE_APPOINTED}
                    checked={
                      dispositionType ===
                      dispositionOptions.SIPA_TRUSTEE_APPOINTED
                    }
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeSIPALabelId"
                    htmlFor="dispositionTypeSIPA"
                    className="custom-control-label"
                  >
                    {dispositionOptions.SIPA_TRUSTEE_APPOINTED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="dispositionTypeOther"
                    type="radio"
                    id="dispositionTypeOther"
                    className="custom-control-input"
                    value={dispositionOptions.OTHER}
                    checked={dispositionType === dispositionOptions.OTHER}
                    onChange={handleDispositionTypeChange}
                  />
                  <label
                    id="dispositionTypeOtherLabelId"
                    htmlFor="dispositionTypeOther"
                    className="custom-control-label"
                  >
                    {dispositionOptions.OTHER}
                  </label>
                </div>
                {dispositionType === dispositionOptions.OTHER ? (
                  <div
                    className="disposition-type-other"
                    style={{ marginLeft: "25px" }}
                  >
                    <input
                      type="text"
                      id="dispositionTypeOtherText"
                      className={
                        dispositionTypeOtherTextError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      autoComplete="off"
                      value={dispositionTypeOtherText}
                      onChange={handleTextInputChange}
                      onBlur={onBlurTextInput}
                      onFocus={() => setFocusedState(true)}
                    />
                    <div
                      id="dispositionTypeOtherTextError"
                      className="invalid-feedback"
                    >
                      Please enter a response
                    </div>
                  </div>
                ) : null}

                <div id="dispositionTypeError" className="invalid-feedback">
                  Please enter a disposition type
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-md-12 action-date-wrapper">
          <label
            id="dispositionDateLabelId"
            className="field-label"
            htmlFor="dispositionDateId"
          >
            Disposition Date: *
          </label>
          <InputMask
            style={{ width: "25%" }}
            id="dispositionDateId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              dispositionDateError ? "form-control is-invalid" : "form-control"
            } action-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={dispositionDate}
            onChange={handleDateChange}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="dispositionDateError" className="invalid-feedback">
            {dispositionDateErrorText}
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="drp-radio-input-wrapper"
          style={{ marginLeft: "40px", width: "80%" }}
        >
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dispositionDateExact"
                type="radio"
                id="dispositionDateExact"
                className="custom-control-input"
                value={dateOptions.EXACT}
                checked={dispositionOption === dateOptions.EXACT}
                onChange={handleActionDateOptionChange}
              />
              <label
                htmlFor="dispositionDateExact"
                className="custom-control-label"
                id="dispositionDateExactLabel"
              >
                {dateOptions.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dispositionDateExplanation"
                type="radio"
                id="dispositionDateExplanation"
                className="custom-control-input"
                value={dateOptions.EXPLANATION}
                checked={dispositionOption === dateOptions.EXPLANATION}
                onChange={handleActionDateOptionChange}
              />
              <label
                htmlFor="dispositionDateExplanation"
                className="custom-control-label"
                id="dispositionDateExplanationLabel"
              >
                {dateOptions.EXPLANATION}
              </label>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "20px",
              width: "80% !important",
            }}
          >
            {dispositionOption === dateOptions.EXPLANATION ? (
              <div className="action-date-explanation-container">
                <label
                  htmlFor="dispositionDateExplanation"
                  className="field-label"
                  id="dispositionDateExplanationLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dispositionDateExplanation"
                  className={
                    dispositionDateExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  rows="3"
                  cols={500}
                  value={dispositionDateExplanation}
                  onChange={handleTextInputChange}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="dispositionDateExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row backbutton-drp-bankruptcy">
        <div className="col-md-12 buttons">
          <span
            id="bankruptcyAddDRPBack"
            className="back-button"
            onClick={() => {
              handleToggleBackModal(true);
            }}
          >
            <strong id="bankruptcyDisclBack">Back</strong>
          </span>
          <Button
            disabled={!isFormComplete || isFocusedState}
            id="bankruptcyAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BankruptcyDisclosureDispositionDetails;

import React from "react";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import "../../../assets/stylesheets/StartApplication/IndividualBroker/BrokerDealer.scss";
import Moment from "moment";
import InputMask from "react-input-mask";
import BackButtonModal from "../Forms/BackButtonModal";
import { putBrokerDealerInfo } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { isValidDate, onlyNumeric } from "../../../common/Functions";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

class BrokerDealerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleRegisteredChange = this.handleRegisteredChange.bind(this);
    this.handleCompletedChange = this.handleCompletedChange.bind(this);
    this.saveBrokerDealer = this.saveBrokerDealer.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    var registeredBrokerDealer = "";
    var brokerDealerName = "";
    var crdNumber = "";
    var completedAML = "";
    var amlCompleteDate = "";
    var candidateID = "";

    try {
      const result = this.props.brokerDealerInfo;
      registeredBrokerDealer =
        result.brokdealbool === null ? "" : result.brokdealbool;
      brokerDealerName =
        result.brokdealname === null ? "" : result.brokdealname;
      crdNumber = result.crdnumber === null ? "" : result.crdnumber;
      completedAML = result.amltraining === null ? "" : result.amltraining;
      amlCompleteDate =
        result.amltrainingdate === null ? "" : result.amltrainingdate;
      candidateID = result.candidateid === null ? "" : result.candidateid;

      if (registeredBrokerDealer === true) {
        registeredBrokerDealer = "Y";
      }
      if (registeredBrokerDealer === false) {
        registeredBrokerDealer = "N";
      }
      if (completedAML === true) {
        completedAML = "Y";
      }
      if (completedAML === false) {
        completedAML = "N";
      }

      await this.setState({
        registered: registeredBrokerDealer,
        completed: completedAML,
        broker_name: brokerDealerName,
        CRD: crdNumber,
        trainingdate: amlCompleteDate,
        candidate_id: candidateID,
        is_completed: true,
      });
      UtagTracking.sendPageNameView(
        "brokerdealerform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
      if (this.props.brokerDealerInfo.brokdealbool !== null)
        this.props.isFormComplete("BrokerCompleteInd", true);
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      registered: "",
      completed: "",
      broker_name: "",
      CRD: "",
      trainingdate: "",
      candidate_id: "",
      showBackModal: false,
      formHasChanged: false,
      is_completed: false,
      formHasError: false,
    };
  }

  async handleRegisteredChange(event) {
    this.props.isFormComplete("BrokerCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    await this.setState({
      formHasChanged: true,
      registered: event.target.value,
    });
    if (event.target.value === "N") {
      await this.setState({
        broker_name: "",
        CRD: "",
        completed: "",
        trainingdate: "",
        is_completed: false,
      });
      let CRDField = document.getElementById("CRD");
      let CRDErrorField = document.getElementById("CRD_error");
      CRDField.classList.remove("is-invalid");
      CRDErrorField.removeAttribute("style");

      let brokerNameField = document.getElementById("broker_name");
      let brokerNameErrorField = document.getElementById("broker_name_error");
      brokerNameField.classList.remove("is-invalid");
      brokerNameErrorField.removeAttribute("style");

      let trainingDateField = document.getElementById("trainingdate");
      let trainingDateErrorField =
        document.getElementById("trainingdate_error");
      trainingDateField.classList.remove("is-invalid");
      trainingDateErrorField.removeAttribute("style");
    }
    var obj_error = document.getElementById("registered_error");
    obj_error.style.display = "none";
    var registered_yes = document.getElementById("registered_yes");
    registered_yes.classList.remove("is-invalid");
    var registered_no = document.getElementById("registered_no");
    registered_no.classList.remove("is-invalid");

    var obj_error2 = document.getElementById("completed_error");
    obj_error2.style.display = "none";
    var completed_yes = document.getElementById("completed_yes");
    completed_yes.classList.remove("is-invalid");
    var completed_no = document.getElementById("completed_no");
    completed_no.classList.remove("is-invalid");
  }

  async handleCompletedChange(event) {
    this.props.isFormComplete("BrokerCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    this.setState({ completed: event.target.value, formHasChanged: true });
    if (event.target.value === "N") {
      this.setState({
        trainingdate: "",
        is_completed: false,
      });
      let trainingDateField = document.getElementById("trainingdate");
      let trainingDateErrorField =
        document.getElementById("trainingdate_error");
      trainingDateField.classList.remove("is-invalid");
      trainingDateErrorField.removeAttribute("style");
    }
    var obj_error2 = document.getElementById("completed_error");
    obj_error2.style.display = "none";
    var completed_yes = document.getElementById("completed_yes");
    completed_yes.classList.remove("is-invalid");
    var completed_no = document.getElementById("completed_no");
    completed_no.classList.remove("is-invalid");
  }

  saveBrokerDealer() {
    this.props.saveBrokerDealerInfo("brokerDealerInformation", {
      ...this.props.brokerDealerInfo,
      amltraining:
        this.state.completed === "Y"
          ? true
          : this.state.completed === "N"
          ? false
          : null,
      amltrainingdate: this.state.trainingdate,
      brokdealbool: this.state.registered === "Y",
      brokdealname: this.state.broker_name,
      crdnumber: this.state.CRD,
    });
  }

  async handleNext(source) {
    var allFilled = true;

    if (this.state.registered === "") {
      allFilled = false;
      var obj_error = document.getElementById("registered_error");
      obj_error.style.display = "block";
      var registered_yes = document.getElementById("registered_yes");
      registered_yes.classList.add("is-invalid");
      var registered_no = document.getElementById("registered_no");
      registered_no.classList.add("is-invalid");
    } else if (this.state.registered === "Y") {
      await this.setState({ formHasError: false });
      var brokernameEvent = new Event("blur");
      Object.defineProperty(brokernameEvent, "target", {
        writable: false,
        value: document.getElementById("broker_name"),
      });
      await this.onBlur(brokernameEvent);

      var crdEvent = new Event("blur");
      Object.defineProperty(crdEvent, "target", {
        writable: false,
        value: document.getElementById("CRD"),
      });
      await this.onBlur(crdEvent);

      if (this.state.completed === "") {
        var obj_error2 = document.getElementById("completed_error");
        obj_error2.style.display = "block";
        var completed_yes = document.getElementById("completed_yes");
        completed_yes.classList.add("is-invalid");
        var completed_no = document.getElementById("completed_no");
        completed_no.classList.add("is-invalid");
      } else if (this.state.completed === "Y") {
        var dateEvent = new Event("blur");
        Object.defineProperty(dateEvent, "target", {
          writable: false,
          value: document.getElementById("trainingdate"),
        });
        await this.onBlur(dateEvent);
      }
    }

    if (this.state.registered === "") {
      allFilled = false;
    }

    if (
      this.state.registered === "Y" &&
      (this.state.broker_name.trim().length < 2 ||
        this.state.CRD.trim().length === 0 ||
        this.state.completed === "")
    ) {
      allFilled = false;
    }

    if (
      this.state.registered === "Y" &&
      this.state.completed === "Y" &&
      this.state.trainingdate.trim().length === 0
    ) {
      allFilled = false;
    }

    //the trainingdate error could be showing even though the field passes length validation
    let trainingdate_error = document.getElementById("trainingdate_error");
    if (
      this.state.completed === "Y" &&
      trainingdate_error.style.display === "block"
    ) {
      allFilled = false;
    }

    if (allFilled) {
      this.saveBrokerDealer();
      var completedTraining;
      if (this.state.completed === "Y") {
        completedTraining = true;
      } else if (this.state.completed === "N") {
        completedTraining = false;
      } else {
        completedTraining = null;
      }
      let data = {
        candidateid: this.state.candidate_id,
        registered: this.state.registered === "Y" ? true : false,
        brokerdealername: this.state.broker_name,
        crdnumber: this.state.CRD,
        completedaml: completedTraining,
        amlcompletedate: this.state.trainingdate,
      };

      putBrokerDealerInfo(this.props.subId, data);
      this.setState({ is_completed: true });
      this.props.hasCurrentFormChanged(false);
      this.props.isFormComplete("BrokerCompleteInd", true);
      this.setState({ formHasChanged: false });
      this.props.toggleForm(FORM_ID.CCB);
    }
    this.props.isFormComplete("BrokerCompleteInd", allFilled);
  }

  onKeyDown() {
    this.props.isFormComplete("BrokerCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    this.setState({ is_completed: false });
  }

  handleChange(event) {
    this.props.isFormComplete("BrokerCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    this.setState({
      formHasChanged: true,
      is_completed: false,
      [event.target.id]: event.target.value,
    });
  }

  async onBlur(event) {
    if (typeof event.target.value !== "undefined") {
      var obj_error;
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.id.includes("trainingdate")) {
        var hasError;
        if (
          event.target.value.includes("_") ||
          event.target.value.trim().length === 0 ||
          isValidDate(event.target.value) === false ||
          event.target.value.slice(-4) === "0000"
        ) {
          obj_error.innerText = "Please enter a Completion Date";
          hasError = true;
        } else {
          var currDate = Moment();
          var dateParts = event.target.value.split("/");
          var month = dateParts[0];
          var day = dateParts[1];
          var year = dateParts[2];
          var completionDateCalc = Moment(year + "-" + month + "-" + day);
          var actualAge = currDate.diff(completionDateCalc, "year");

          if (completionDateCalc > currDate) {
            obj_error.innerText = "Completion Date cannot be a future date";
            hasError = true;
          } else if (actualAge > 19) {
            obj_error.innerText =
              "Completion Date cannot be more than 20 years ago";
            hasError = true;
          }
        }

        if (hasError) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      } else if (event.target.id === "CRD") {
        if (event.target.value.trim().length < 1) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      } else {
        if (event.target.value.trim().length < 2) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      }
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    const { brokdealbool } = this.props.brokerDealerInfo;
    if (brokdealbool !== null && brokdealbool !== undefined)
      this.props.isFormComplete("BrokerCompleteInd", true);
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.BUSINESS_INFO);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.BUSINESS_INFO);
    }
  }

  render() {
    return (
      <div id="brokerdealerform">
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <h1 id="broker_header">Broker Dealer Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span id="broker_required_text" className="required-field-text">
              * = Required Field
            </span>
          </div>
        </div>

        <div id="broker_question1" className="row">
          <div className="col-12">
            <h3 id="brokerDealerQuestion1Header">
              Are you registered with a Broker Dealer?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="registered_yes"
                name="registered"
                className="custom-control-input"
                checked={this.state.registered === "Y"}
                value="Y"
                onChange={this.handleRegisteredChange}
              />
              <label
                htmlFor="registered_yes"
                className="custom-control-label"
                id="registeredYesLabel"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="registered_no"
                name="registered"
                className="custom-control-input"
                checked={this.state.registered === "N"}
                value="N"
                onChange={this.handleRegisteredChange}
              />
              <label
                htmlFor="registered_no"
                className="custom-control-label"
                id="registeredNoLabel"
              >
                No
              </label>
            </div>
            <div id="registered_error" className="invalid-feedback">
              Please choose a response
            </div>
          </div>
        </div>

        <div
          id="brokers"
          className={this.state.registered === "Y" ? "d-block" : "d-none"}
        >
          <div className="row">
            <div className="col-12">
              <label id="brokerNameLabel" htmlFor="broker_name">
                <strong>Broker Dealer Name*</strong>
              </label>
              <input
                type="text"
                id="broker_name"
                className="form-control"
                autoComplete="off"
                value={this.state.broker_name}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                maxLength={320}
              />
              <div id="broker_name_error" className="invalid-feedback">
                Please enter a Broker Dealer Name
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label id="cenRegDepLabel" htmlFor="CRD">
                <strong>
                  Personal Central Registration Depository Number (CRD)*
                </strong>
              </label>
              <input
                type="text"
                id="CRD"
                className="form-control"
                value={this.state.CRD}
                maxLength={16}
                autoComplete="off"
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
              />
              <div id="CRD_error" className="invalid-feedback">
                Please enter a Personal Central Registration Depository Number
                (CRD)
              </div>
              <div>
                <span id="broker_forgot_text" className="help-text">
                  Forgot your CRD number? Look it up using your first and last
                  name at{" "}
                  <a
                    target="_blank"
                    href="https://brokercheck.finra.org"
                    rel="noopener noreferrer"
                    className="link-underline"
                    id="broker_finra_link"
                  >
                    brokercheck.finra.org
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          id="antimoney"
          className={this.state.registered === "Y" ? "d-block" : "d-none"}
        >
          <div id="broker_question2" className="row">
            <div className="col-12">
              <h3 id="brokerDealerQuestion2Header">
                Have you completed Anti-Money Laundering training through your
                Broker Dealer?*
              </h3>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="completed_yes"
                  name="completed"
                  className="custom-control-input"
                  checked={this.state.completed === "Y"}
                  value="Y"
                  onChange={this.handleCompletedChange}
                />
                <label
                  htmlFor="completed_yes"
                  className="custom-control-label"
                  id="completedYesLabel"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="completed_no"
                  name="completed"
                  className="custom-control-input"
                  checked={this.state.completed === "N"}
                  value="N"
                  onChange={this.handleCompletedChange}
                />
                <label
                  htmlFor="completed_no"
                  className="custom-control-label"
                  id="completedNoLabel"
                >
                  No
                </label>
              </div>
              <div id="completed_error" className="invalid-feedback">
                Please choose a response
              </div>
              <div
                id="completed"
                className={this.state.completed === "Y" ? "d-block" : "d-none"}
              >
                <div className="row">
                  <div className="col-12">
                    <label id="trainingDateLabel" htmlFor="trainingdate">
                      <strong>
                        Anti-Money Laundering Training Completion Date*
                      </strong>
                    </label>
                    <InputMask
                      id="trainingdate"
                      mask="99/99/9999"
                      maskChar="_"
                      className="form-control"
                      placeholder="MM/DD/YYYY"
                      autoComplete="off"
                      value={this.state.trainingdate}
                      onBlur={this.onBlur}
                      onChange={this.handleChange}
                      onKeyDown={this.onKeyDown}
                    />
                    <div id="trainingdate_error" className="invalid-feedback">
                      Please enter a Completion Date
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="actions" className="row">
          <div className="col-12">
            <span
              id="broker_back"
              onClick={() => {
                this.handleShowBackModal();
              }}
            >
              <strong> Back </strong>
            </span>
            <Button id="broker_next" onClick={this.handleNext}>
              Next
            </Button>
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </div>
    );
  }
}

export default BrokerDealerForm;

import { Button, FormCustomControl } from "react-blueprint-library";

import React, { useEffect } from "react";

function ReviewCorporationEOC(props) {
  let show_greater_text = false;
  let show_less_text = false;
  let show_group_text = false;
  let acknowledgement = false;
  if (typeof props.eocInfo !== "undefined") {
    show_greater_text =
      props.eocInfo.eopolicyovermillion === true &&
      props.eocInfo.eopolicyundermillion === false &&
      props.eocInfo.coveredundergrouppolicy === false;
    show_less_text =
      props.eocInfo.eopolicyovermillion === false &&
      props.eocInfo.eopolicyundermillion === true &&
      props.eocInfo.coveredundergrouppolicy === false;
    show_group_text =
      props.eocInfo.eopolicyovermillion === false &&
      props.eocInfo.eopolicyundermillion === false &&
      props.eocInfo.coveredundergrouppolicy === true;
    acknowledgement = props.eocInfo.eoacknowledgement;
  } else {
  }

  return (
    <div id="reviewEOC" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_ccd_info"> Corporation Errors and Omissions Coverage</h3>
      </div>

      <div className="candidate-info">
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-3">
            {props.id === "conf_eoc" ? (
              <strong id="eocInstructions">Status*</strong>
            ) : (
              <strong id="eocInstructions">
                Please select one of the following.*
              </strong>
            )}
          </div>
          <div id="eocSelectedOption" className="col-9 bo--text-color">
            {show_greater_text === true && (
              <>
                The Corporation currently has an E&O coverage policy that is
                equal to or greater than $1 million dollars
              </>
            )}
            {show_less_text === true && (
              <>
                The Corporation currently has an E&O coverage policy that is
                less than $1 million dollars
              </>
            )}
            {show_group_text === true && (
              <>The Corporation is currently covered under a Group E&O policy</>
            )}
            {show_greater_text === false &&
              show_less_text === false &&
              show_group_text === false && <> - </>}
          </div>
        </div>
        {props.id !== "conf_eoc" && (
          <div className="eoc-acknowledgement">
            <div
              id="eocAcknowledgementCheckHeader"
              style={{ marginBottom: "10px" }}
            >
              The candidate acknowledges the following:
            </div>
            <FormCustomControl
              id="eocAcknowledgementCheck"
              inputType="checkbox"
              label=" I understand that I must acquire and maintain a Life Agents Errors & Omissions Policy with a minimum coverage of $1,000,000 per claim/$1,000,000 per aggregate to obtain and maintain a contract. I agree that if I do not currently have coverage at these levels, I will increase my coverage to these levels at the next renewal of my policy and maintain these levels at each subsequent renewal. I agree to submit a copy of my Errors & Omissions policy to the General Agent executing the contract."
              aria-label="aria_eoc_acknowledgement_corp"
              checked={acknowledgement}
              disabled
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewCorporationEOC;

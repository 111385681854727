/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";

import BackButtonModal from "../../../../../Forms/BackButtonModal";

import { dateInputMask, eventStatusDateType } from "../../../constants";
import "./CivJudResDetailsForm.scss";

const matterResolvedType = {
  CONSENT: "Consent",
  JUDG: "Judgment Rendered",
  SETTLED: "Settled",
  VACATED: "Vacated",
  VACATED_NUNC: "Vacated Nunc Pro Tunc/ab initio",
  DISMISSED: "Dismissed",
  WITHDRAWN: "Withdrawn",
  OTHER: "Other",
};

const CivJudResDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);

  const [matterResolved, setMatterResolved] = useState(
    data?.matterResolved || ""
  );
  const [matterResolvedOther, setMatterResolvedOther] = useState(
    data?.matterResolvedOther || ""
  );
  const [matterResolvedError, setMatterResolvedError] = useState(false);

  const [resDateErrorMsg, setNoticeErrorMsg] = useState("");
  const [resDate, setNotice] = useState(data?.resDate || "");
  const [resDateError, setNoticeError] = useState(false);
  const [resDateRadio, setNoticeRadio] = useState(data?.resDateRadio || "");
  const [resDateExp, setNoticeExp] = useState(data?.resDateExp || "");
  const [resDateExpError, setNoticeExpError] = useState(false);

  const [isResDateNextDisabled, setIsResDateNextDisabled] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isError =
    matterResolvedError || resDateError || resDateError || resDateExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isResDateDisabled =
    !resDate?.length ||
    resDate.includes("_") ||
    resDate === dateInputMask.EMPTY ||
    (!resDate?.length && !resDateRadio?.length) ||
    !resDateRadio?.length ||
    (resDate?.length &&
      resDateRadio?.length &&
      resDateRadio === eventStatusDateType.EXPLANATION &&
      !resDateExp?.length) ||
    (resDateExp?.length && resDateExp?.length < 2);

  const isNextBtnDisabled =
    !!(
      isResDateNextDisabled ||
      isResDateDisabled ||
      !matterResolved?.length ||
      (matterResolved?.length &&
        matterResolved === matterResolvedType.OTHER &&
        !matterResolvedOther?.length) ||
      (matterResolved?.length &&
        matterResolved === matterResolvedType.OTHER &&
        matterResolvedOther?.length &&
        matterResolvedOther?.length < 2)
    ) ||
    isError ||
    isFocusedState;

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const onChangeMatterResolvedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setMatterResolvedOther(value);
  };
  const onBlurMatterResolvedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setMatterResolvedError(true)
      : setMatterResolvedError(false);
  };

  const handleChangeCheckedMatterResolved = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setMatterResolvedError(false);

    if (value !== matterResolvedType.OTHER) {
      setMatterResolvedOther("");
    }

    setMatterResolved(newValue);
  };

  const handleResDateChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setNoticeExpError(false);

    if (resDateExp.length && resDateRadio !== eventStatusDateType.EXPLANATION) {
      setNoticeExp("");
    }

    setNoticeRadio(newValue);
  };

  const resDateErrorHandler = (value) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsResDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setNoticeErrorMsg("Date cannot be a future date");
        setNoticeError(true);
      } else if (date < past) {
        setNoticeErrorMsg("Date cannot be more than 100 years ago");
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeErrorMsg("Please enter Resolution Date");
      setNoticeError(true);
    }
  };

  const onResDateOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNotice(value);

    if (value?.length === 10 && !value.includes("_") && !resDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsResDateNextDisabled(true);
        } else {
          setIsResDateNextDisabled(false);
        }
      } else {
        setIsResDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsResDateNextDisabled(true);
        } else {
          setIsResDateNextDisabled(false);
        }
      } else {
        setIsResDateNextDisabled(true);
      }
    }
  };

  const onResDateExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNoticeExp(value);
  };

  const onResDateBlurHandler = ({ target: { value } }) => {
    setFocusedState(false);
    resDateErrorHandler(value);
  };

  const onBlurResDateExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setNoticeExpError(true)
      : setNoticeExpError(false);
  };

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      matterResolved,
      matterResolvedOther,
      resDate,
      resDateRadio,
      resDateExp,
    };

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    setFormHasChanged(false);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    headerText,
    matterResolved,
    matterResolvedOther,
    getSubmitDataValues,
    groupKey,
    resDate,
    resDateRadio,
    resDateExp,
  ]);

  return (
    <div className="civ-jud-res-details-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label id="matterResolvedLabelId" className="court-action">
            How was the matter resolved? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="actionSec"
                type="radio"
                id="actionFormalId"
                className="custom-control-input"
                value={matterResolvedType.CONSENT}
                checked={matterResolved === matterResolvedType.CONSENT}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionFormalId"
                className="custom-control-label"
                id="actionConsentLabelId"
              >
                {matterResolvedType.CONSENT}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionState"
                type="radio"
                id="actionJudgmentId"
                className="custom-control-input"
                value={matterResolvedType.JUDG}
                checked={matterResolved === matterResolvedType.JUDG}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionJudgmentId"
                className="custom-control-label"
                id="actionJudgmentLabelId"
              >
                {matterResolvedType.JUDG}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionForeignFormal"
                type="radio"
                id="actionSettledId"
                className="custom-control-input"
                value={matterResolvedType.SETTLED}
                checked={matterResolved === matterResolvedType.SETTLED}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionSettledId"
                className="custom-control-label"
                id="actionSettledLabelId"
              >
                {matterResolvedType.SETTLED}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionFederal"
                type="radio"
                id="actionVacatedId"
                className="custom-control-input"
                value={matterResolvedType.VACATED}
                checked={matterResolved === matterResolvedType.VACATED}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionVacatedId"
                className="custom-control-label"
                id="actionVacatedLabelId"
              >
                {matterResolvedType.VACATED}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionVacNunc"
                type="radio"
                id="actionVacNuncId"
                className="custom-control-input"
                value={matterResolvedType.VACATED_NUNC}
                checked={matterResolved === matterResolvedType.VACATED_NUNC}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionVacNuncId"
                className="custom-control-label"
                id="stateVacNuncLabelId"
              >
                {matterResolvedType.VACATED_NUNC}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionDis"
                type="radio"
                id="actionDisId"
                className="custom-control-input"
                value={matterResolvedType.DISMISSED}
                checked={matterResolved === matterResolvedType.DISMISSED}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionDisId"
                className="custom-control-label"
                id="stateDisLabelId"
              >
                {matterResolvedType.DISMISSED}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionWith"
                type="radio"
                id="actionWithId"
                className="custom-control-input"
                value={matterResolvedType.WITHDRAWN}
                checked={matterResolved === matterResolvedType.WITHDRAWN}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="actionWithId"
                className="custom-control-label"
                id="stateWithLabelId"
              >
                {matterResolvedType.WITHDRAWN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="otherRadio"
                type="radio"
                id="otherRadioFormalId"
                className="custom-control-input"
                value={matterResolvedType.OTHER}
                checked={matterResolved === matterResolvedType.OTHER}
                onChange={handleChangeCheckedMatterResolved}
              />
              <label
                htmlFor="otherRadioFormalId"
                className="custom-control-label"
                id="otherRadioLabelId"
              >
                {matterResolvedType.OTHER}
              </label>
            </div>

            {matterResolved === matterResolvedType.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="otherText"
                  className={
                    matterResolvedError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={matterResolvedOther}
                  onChange={onChangeMatterResolvedHandler}
                  onBlur={onBlurMatterResolvedHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="matterResolvedError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label id="resDateLabelPaiId" htmlFor="resDateId">
            <strong id="resDateLabelTextId">Resolution Date: *</strong>
          </label>
          <InputMask
            id="resDateId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              resDateError ? "form-control is-invalid" : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={resDate}
            onChange={onResDateOnChangeHandler}
            onBlur={onResDateBlurHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="resDateError" className="invalid-feedback">
            {resDateErrorMsg?.length
              ? resDateErrorMsg
              : "Please enter a valid Notice Date"}
          </div>
        </div>

        <div className="row event-status-date-radio-group">
          <div className="custom-control">
            <div className="col-12 copy-md-8 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-resDateExactId"
                  type="radio"
                  id="resDateExactId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXACT}
                  checked={resDateRadio === eventStatusDateType.EXACT}
                  onChange={handleResDateChecked}
                />
                <label
                  htmlFor="resDateExactId"
                  className="custom-control-label"
                  id="label_exactDate"
                >
                  {eventStatusDateType.EXACT}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-resDateExplanationId"
                  type="radio"
                  id="resDateExplanationId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXPLANATION}
                  checked={resDateRadio === eventStatusDateType.EXPLANATION}
                  onChange={handleResDateChecked}
                />
                <label
                  htmlFor="resDateExplanationId"
                  className="custom-control-label"
                  id="label_explanation"
                >
                  {eventStatusDateType.EXPLANATION}
                </label>
              </div>

              {resDateRadio === eventStatusDateType.EXPLANATION ? (
                <div className="event-status-date-explanation-container">
                  <label
                    htmlFor="resDateExplanation"
                    className="event-status-date-exp"
                    id="resDateExplanationLabelId"
                  >
                    If not exact, provide explanation *
                  </label>
                  <textarea
                    id="resDateExplanation"
                    onChange={onResDateExplanationChange}
                    className={
                      resDateExpError
                        ? "form-control form-control-15 is-invalid"
                        : "form-control form-control-15"
                    }
                    maxLength={500}
                    minLength={2}
                    onBlur={onBlurResDateExpHandler}
                    onFocus={() => setFocusedState(true)}
                    rows="3"
                    cols={500}
                    value={resDateExp}
                  />
                  <div id="resDateExpError" className="invalid-feedback">
                    Please enter an Explanation
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong>Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CivJudResDetailsForm;

function ConfAppBrokerDealer(props) {
  return (
    <div id="confidential-broker-dealer">
      <div className="row">
        <div className="col-8 mm-spacing--minor">
          <h3 id="conf_broker_text">Broker Dealer Information</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <h5 id="conf_broker_q1_text">
            Are you registered with a Broker Dealer?*
          </h5>
        </div>
        <div id="registered" className="col-3 data">
          {props.broker_dealer_info.brokdealbool === true ? "Yes" : "No"}
        </div>
        {props.broker_dealer_info.brokdealbool === true && (
          <>
            <div className="col-3">
              <h5 id="conf_broker_q2_text">
                Have you completed Anti-Money Laundering training through your
                Broker Dealer?*
              </h5>
            </div>
            <div id="completed" className="col-3 data">
              {props.broker_dealer_info.amltraining === false ? "No" : "Yes"}
            </div>
            <div className="col-3">
              <h5 id="conf_broker_name_text">Broker Dealer Name*</h5>
            </div>
            <div id="conf_broker_name" className="col-3 data">
              {props.broker_dealer_info.brokdealname}
            </div>
            {props.broker_dealer_info.amltraining === true ? (
              <>
                <div className="col-3">
                  <h5 id="conf_trainingdate_text">
                    Anti-Money Laundering Training Completion Date*
                  </h5>
                </div>
                <div id="conf_training_date" className="col-3 data">
                  {props.broker_dealer_info.amltrainingdate}
                </div>
              </>
            ) : (
              <>
                <div className="col-3"></div>
                <div className="col-3"></div>
              </>
            )}

            <div className="col-3">
              <h5 id="conf_CRD_text">
                Personal Central Registration Depository Number (CRD)*
              </h5>
            </div>
            <div id="conf_CRD" className="col-3 data">
              {props.broker_dealer_info.crdnumber}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfAppBrokerDealer;

import { useEffect } from "react";

function ReviewDirectDeposit(props) {
  let compensationtype = null;
  let mmaccounttype = null;
  let mmroutingnumber = null;
  let mmaccountnumber = null;
  let corpaccounttype = null;
  let corproutingnumber = null;
  let corpaccountnumber = null;
  let participationrules = false;
  let netcompensation = false;
  let executorpayment = false;
  let sameaccount = false;

  if (typeof props.ddInfo !== "undefined") {
    compensationtype = props.ddInfo.compensationtypeid;
    mmaccounttype = props.ddInfo.mmaccounttypeid;
    mmroutingnumber = props.ddInfo.mmroutingnumber;
    mmaccountnumber = props.ddInfo.mmaccountnumber;
    corpaccounttype = props.ddInfo.corpaccounttype;
    corproutingnumber = props.ddInfo.corproutingnumber;
    corpaccountnumber = props.ddInfo.corpaccountnumber;
    participationrules = props.ddInfo.participationrules;
    netcompensation = props.ddInfo.netcompensation;
    executorpayment = props.ddInfo.executorpayment;

    if (
      mmaccounttype === corpaccounttype &&
      mmroutingnumber === corproutingnumber &&
      mmaccountnumber === corpaccountnumber
    ) {
      sameaccount = true;
    }
  }

  return (
    <div id="reviewDirectDeposit" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_ccd_info">Direct Deposit Information</h3>
      </div>

      <div className="candidate-info" style={{ marginBottom: "20px" }}>
        {compensationtype !== null && sameaccount === false && (
          <div id="massmutual-account">
            <div className="row">
              <div className="col-4">
                <strong id="mmCompensationTypeLabel">Compensation Type*</strong>
              </div>
              <div id="mmCompensationType" className="col-6 bo--text-color">
                {compensationtype === 1 ? "MassMutual Only" : "MassMutual"}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="mmAccountTypeLabel">Account Type*</strong>
              </div>
              <div id="mmAccountType" className="col-6 bo--text-color">
                {mmaccounttype === null || mmaccounttype === ""
                  ? "-"
                  : mmaccounttype === 1
                  ? "Checking"
                  : "Savings"}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="mmRoutingNumberLabel">Routing Number*</strong>
              </div>
              <div id="mmRoutingNumber" className="col-6 bo--text-color">
                {mmroutingnumber === null || mmroutingnumber === ""
                  ? "-"
                  : mmroutingnumber}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="mmAccountNumberLabel">Account Number*</strong>
              </div>
              <div id="mmAccountNumber" className="col-6 bo--text-color">
                {mmaccountnumber === null || mmaccountnumber === ""
                  ? "-"
                  : mmaccountnumber}
              </div>
            </div>
          </div>
        )}
        {compensationtype === 2 && sameaccount === true && (
          <div id="affiliated-account">
            <div className="row">
              <div className="col-4">
                <strong id="affCompensationTypeLabel">
                  Compensation Type*
                </strong>
              </div>
              <div id="affCompensationType" className="col-6 bo--text-color">
                MassMutual and Affiliated Broker Dealer (MMLIS Registered
                Business)
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affAccountTypeLabel">Account Type*</strong>
              </div>
              <div id="affAccountType" className="col-6 bo--text-color">
                {corpaccounttype === null || corpaccounttype === ""
                  ? "-"
                  : corpaccounttype === 1
                  ? "Checking"
                  : "Savings"}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affRoutingNumberLabel">Routing Number*</strong>
              </div>
              <div id="affRoutingNumber" className="col-6 bo--text-color">
                {corproutingnumber === null || corproutingnumber === ""
                  ? "-"
                  : corproutingnumber}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affAccountNumberLabel">Account Number*</strong>
              </div>
              <div id="affAccountNumber" className="col-6 bo--text-color">
                {corpaccountnumber === null || corpaccountnumber === ""
                  ? "-"
                  : corpaccountnumber}
              </div>
            </div>
          </div>
        )}
        {compensationtype === 2 && sameaccount === false && (
          <div id="affiliated-account">
            <div className="row">
              <div className="col-4">
                <strong id="affCompensationTypeLabel">
                  Compensation Type*
                </strong>
              </div>
              <div id="affCompensationType" className="col-6 bo--text-color">
                Affiliated Broker Dealer (MMLIS Registered Business)
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affAccountTypeLabel">Account Type*</strong>
              </div>
              <div id="affAccountType" className="col-6 bo--text-color">
                {corpaccounttype === null || corpaccounttype === ""
                  ? "-"
                  : corpaccounttype === 1
                  ? "Checking"
                  : "Savings"}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affRoutingNumberLabel">Routing Number*</strong>
              </div>
              <div id="affRoutingNumber" className="col-6 bo--text-color">
                {corproutingnumber === null || corproutingnumber === ""
                  ? "-"
                  : corproutingnumber}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong id="affAccountNumberLabel">Account Number*</strong>
              </div>
              <div id="affAccountNumber" className="col-6 bo--text-color">
                {corpaccountnumber === null || corpaccountnumber === ""
                  ? "-"
                  : corpaccountnumber}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewDirectDeposit;

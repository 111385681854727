/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import svgs, { svgType } from "../../../../../../../assets/svgs";
import CheckboxList from "../../../common/CheckboxList";
import {
  DRPCheckboxListValuesSanctionsOrdered,
  DRPSanctOrdCheckList,
} from "../../../constants";
import "./RegActionSanctionDetailsForm.scss";

import NewMonetarySanctionList from "../../../common/NewMonetarySanctionList";
import NewRequalificationList from "../../../common/NewRequalificationList";
import NewSanctionList from "../../../common/NewSanctionList";
import AddNewMonetarySanctionModal from "./AddNewMonetarySanctionModal";
import AddNewMonetarySanctionContent from "./AddNewMonetarySanctionModal/AddNewMonetarySanctionContent";
import AddNewRequalificationModal from "./AddNewRequalificationModal";
import AddNewRequalificationContent from "./AddNewRequalificationModal/AddNewRequalificationContent";
import AddNewSanctionModal from "./AddNewSanctionModal";
import AddNewSanctionContent from "./AddNewSanctionModal/AddNewSanctionContent";

const initialEditSanctionState = { list: [], index: null };
const initialEditRequalificationState = { list: [], index: null };
const initialEditMonetarySanctionState = { list: [], index: null };

const RegActionSanctionDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [other, setOther] = useState(data?.other || "");
  const [otherError, setOtherError] = useState(false);

  const [showAddNewSanctionModal, setShowAddNewSanctionModal] = useState(false);
  const [showAddNewRequalificationModal, setShowAddNewRequalificationModal] =
    useState(false);
  const [showAddNewMonetarySanctionModal, setShowAddNewMonetarySanctionModal] =
    useState(false);

  const [editSanctionDetailsList, setEditSanctionDetailsList] = useState({
    ...initialEditSanctionState,
  });
  const [editRequalificationDetailsList, setEditRequalificationDetailsList] =
    useState({
      ...initialEditRequalificationState,
    });
  const [editMonetarySanctionDetailsList, setEditMonetarySanctionDetailsList] =
    useState({
      ...initialEditMonetarySanctionState,
    });

  const [isSanctionModalUpdates, setIsSanctionModalUpdates] = useState(false);
  const [isRequalificationModalUpdates, setIsRequalificationModalUpdates] =
    useState(false);
  const [isMonetarySanctionModalUpdates, setIsMonetarySanctionModalUpdates] =
    useState(false);

  const [newSanctionDetailsList, setNewSanctionDetailsList] = useState(
    data?.newSanctionDetailsList || []
  );
  const [newRequalificationDetailsList, setNewRequalificationDetailsList] =
    useState(data?.newRequalificationDetailsList || []);
  const [newMonetarySanctionDetailsList, setMonetarySanctionDetailsList] =
    useState(data?.newMonetarySanctionDetailsList || []);

  const [selectedSanctionOrderedValues, setSelectedSanctionOrderedValues] =
    useState(data?.selectedSanctionOrderedValues || []);
  const [isFocusedState, setFocusedState] = useState(false);

  const checkSuspOrBarred = (d) =>
    [
      DRPCheckboxListValuesSanctionsOrdered.SUSP,
      DRPCheckboxListValuesSanctionsOrdered.BAR_PERM,
      DRPCheckboxListValuesSanctionsOrdered.BAR_TEMP,
    ].includes(d);

  const isSuspendedOrBarred = !!selectedSanctionOrderedValues.find((data) =>
    checkSuspOrBarred(data)
  );
  const isRequalification = !!selectedSanctionOrderedValues.find(
    (data) => data === DRPCheckboxListValuesSanctionsOrdered.REQUALIFICATION
  );

  const onChangeCheckedFullName = ({ target: { value, checked } }) => {
    setFormHasChanged(true);

    let updatedSelectedValues;

    if (checked) {
      updatedSelectedValues = [...selectedSanctionOrderedValues, value];
    } else {
      updatedSelectedValues = selectedSanctionOrderedValues.filter(
        (item) => item !== value
      );

      if (value === DRPCheckboxListValuesSanctionsOrdered.REQUALIFICATION) {
        setNewRequalificationDetailsList([]);
      }
      if (
        checkSuspOrBarred(value) &&
        !updatedSelectedValues.some(checkSuspOrBarred)
      ) {
        setNewSanctionDetailsList([]);
      }
    }

    //Maintain the order based on the original list
    const orderedSelectedValues = DRPSanctOrdCheckList.filter(({ value }) =>
      updatedSelectedValues.includes(value)
    ).map(({ value }) => value);
    setSelectedSanctionOrderedValues(orderedSelectedValues);
  };

  const onOtherSanctionChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOther(value);
  };

  const onBlurOtherSanctionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherError(true)
      : setOtherError(false);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isError = otherError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const getAddNewSanctionValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setNewSanctionDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setNewSanctionDetailsList((prevState) => [...prevState, val]);
      }
    }
  };

  const getAddNewRequalificationValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setNewRequalificationDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setNewRequalificationDetailsList((prevState) => [...prevState, val]);
      }
    }
  };

  const getMonetarySanctionValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setMonetarySanctionDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setMonetarySanctionDetailsList((prevState) => [...prevState, val]);
      }
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      other,
      selectedSanctionOrderedValues,
      newSanctionDetailsList,
      newRequalificationDetailsList,
      newMonetarySanctionDetailsList,
    };

    setFormHasChanged(false);
    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    dispatch,
    groupKey,
    newSanctionDetailsList,
    newRequalificationDetailsList,
    newMonetarySanctionDetailsList,
    selectedSanctionOrderedValues,
    other,
    headerText,
  ]);

  const isNextBtnDisabled =
    selectedSanctionOrderedValues?.filter((x) => typeof x === "string")
      ?.length === 0 ||
    !other?.length ||
    other?.length < 2 ||
    (isSuspendedOrBarred && !newSanctionDetailsList?.length) ||
    (isRequalification && !newRequalificationDetailsList?.length) ||
    isError ||
    isFocusedState;

  return (
    <div className="reg-action-sanc-details-form-wrapper">
      <div style={{ marginTop: "24px" }}>
        <CheckboxList
          selectedItems={selectedSanctionOrderedValues}
          list={DRPSanctOrdCheckList}
          header="Were any of the following sanctions ordered? (select all that apply): *"
          onChangeChecked={onChangeCheckedFullName}
        />
      </div>

      <div className="reg-action-sanc-details-other-container">
        <label
          htmlFor="sanctionDetailsOtherId"
          id="sanctionDetailsOtherIdLabel"
          className="other-label"
        >
          Other sanctions ordered: *
        </label>
        <textarea
          id="sanctionDetailsOtherId"
          onChange={onOtherSanctionChangeHandler}
          className={otherError ? "is-invalid form-control" : "form-control"}
          onBlur={onBlurOtherSanctionHandler}
          onFocus={() => setFocusedState(true)}
          minLength={2}
          maxLength={500}
          rows="3"
          autoComplete="off"
          cols={500}
          value={other}
        />
        <div id="otherError" className="invalid-feedback">
          Please enter Other sanctions ordered
        </div>
      </div>

      {isSuspendedOrBarred && (
        <>
          <hr className="custom-hr" />

          <div className="row">
            <div
              id="regSanctionText"
              className="add-new-charge-desctiption-label"
            >
              Sanction *
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label
                className="add-new-charge-label"
                id="addNewSanctionLabelId"
              >
                If “Suspended” or “Barred”, add a new Sanction item below.
              </label>
            </div>
          </div>

          {newSanctionDetailsList.length ? (
            <div className="new-charges-list">
              <NewSanctionList
                list={newSanctionDetailsList}
                setNewChargesList={setNewSanctionDetailsList}
                setEditChargeDetailsList={setEditSanctionDetailsList}
                setShowAddNewChargeModal={setShowAddNewSanctionModal}
              />
            </div>
          ) : null}

          <div className="add-drp-charge-btn-wrapper">
            <Button
              id="add-drp-charge"
              variant="secondary"
              className="add-drp-charge-btn"
              onClick={() => {
                setEditSanctionDetailsList({ ...initialEditSanctionState });
                setShowAddNewSanctionModal(true);
              }}
            >
              <div className="btn-content">
                <div className="plus-sign">
                  <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
                </div>
                <div className="text-wrapper">
                  <strong id="add-new-sanction-text">Add New Sanction</strong>
                </div>
              </div>
            </Button>
          </div>
        </>
      )}

      {isRequalification && (
        <>
          <hr className="custom-hr" />

          <div className="row">
            <div
              id="regRequalificationText"
              className="add-new-charge-desctiption-label"
            >
              Requalification *
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label
                className="add-new-charge-label"
                id="addNewRequalificationLabelId"
              >
                If “Requalification” by exam/training was a condition of the
                sanction, add new Requalification item below.
              </label>
            </div>
          </div>

          {newRequalificationDetailsList.length ? (
            <div className="new-charges-list">
              <NewRequalificationList
                list={newRequalificationDetailsList}
                setNewChargesList={setNewRequalificationDetailsList}
                setEditChargeDetailsList={setEditRequalificationDetailsList}
                setShowAddNewChargeModal={setShowAddNewRequalificationModal}
              />
            </div>
          ) : null}

          <div className="add-drp-charge-btn-wrapper">
            <Button
              id="add-drp-charge"
              variant="secondary"
              className="add-drp-charge-btn"
              style={{ maxWidth: "235px" }}
              onClick={() => {
                setEditRequalificationDetailsList({
                  ...initialEditRequalificationState,
                });
                setShowAddNewRequalificationModal(true);
              }}
            >
              <div className="btn-content">
                <div className="plus-sign">
                  <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
                </div>
                <div className="text-wrapper">
                  <strong id="add-new-requalification-text">
                    Add New Requalification
                  </strong>
                </div>
              </div>
            </Button>
          </div>
        </>
      )}

      <hr className="custom-hr" />

      <div className="row">
        <div id="regMonetaryText" className="add-new-charge-desctiption-label">
          Monetary Sanction (optional)
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label className="add-new-charge-label" id="addNewMonetaryLabelId">
            If disposition resulted in a fine, penalty, restitution,
            disgorgement or monetary compensation, add new Monetary Sanction
            item below.
          </label>
        </div>
      </div>

      {newMonetarySanctionDetailsList.length ? (
        <div className="new-charges-list">
          <NewMonetarySanctionList
            list={newMonetarySanctionDetailsList}
            setNewChargesList={setMonetarySanctionDetailsList}
            setEditChargeDetailsList={setEditMonetarySanctionDetailsList}
            setShowAddNewChargeModal={setShowAddNewMonetarySanctionModal}
          />
        </div>
      ) : null}

      <div className="add-drp-charge-btn-wrapper">
        <Button
          id="add-drp-charge"
          variant="secondary"
          className="add-drp-charge-btn"
          style={{ maxWidth: "255px" }}
          onClick={() => {
            setEditMonetarySanctionDetailsList({
              ...initialEditMonetarySanctionState,
            });
            setShowAddNewMonetarySanctionModal(true);
          }}
        >
          <div className="btn-content">
            <div className="plus-sign">
              <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
            </div>
            <div className="text-wrapper">
              <strong id="add-new-monerary-text">
                Add New Monetary Sanction
              </strong>
            </div>
          </div>
        </Button>
      </div>

      <hr className="custom-hr" />

      {showAddNewSanctionModal && (
        <AddNewSanctionModal
          showAddNewChargeModal={showAddNewSanctionModal}
          setShowAddNewChargeModal={setShowAddNewSanctionModal}
          isUpdates={isSanctionModalUpdates}
        >
          <AddNewSanctionContent
            data={editSanctionDetailsList.list}
            dataIndex={editSanctionDetailsList.index}
            getSubmitDataValues={getAddNewSanctionValues}
            setShowAddNewChargeModal={setShowAddNewSanctionModal}
            setEditChargeDetailsList={setEditSanctionDetailsList}
            setIsModalUpdates={setIsSanctionModalUpdates}
            headerText={headerText}
          />
        </AddNewSanctionModal>
      )}

      {showAddNewRequalificationModal && (
        <AddNewRequalificationModal
          showAddNewChargeModal={showAddNewRequalificationModal}
          setShowAddNewChargeModal={setShowAddNewRequalificationModal}
          isUpdates={isRequalificationModalUpdates}
        >
          <AddNewRequalificationContent
            data={editRequalificationDetailsList.list}
            dataIndex={editRequalificationDetailsList.index}
            getSubmitDataValues={getAddNewRequalificationValues}
            setShowAddNewChargeModal={setShowAddNewRequalificationModal}
            setEditChargeDetailsList={setEditRequalificationDetailsList}
            setIsModalUpdates={setIsRequalificationModalUpdates}
            headerText={headerText}
          />
        </AddNewRequalificationModal>
      )}

      {showAddNewMonetarySanctionModal && (
        <AddNewMonetarySanctionModal
          showAddNewChargeModal={showAddNewMonetarySanctionModal}
          setShowAddNewChargeModal={setShowAddNewMonetarySanctionModal}
          isUpdates={isMonetarySanctionModalUpdates}
        >
          <AddNewMonetarySanctionContent
            data={editMonetarySanctionDetailsList.list}
            dataIndex={editMonetarySanctionDetailsList.index}
            getSubmitDataValues={getMonetarySanctionValues}
            setShowAddNewChargeModal={setShowAddNewMonetarySanctionModal}
            setEditChargeDetailsList={setEditMonetarySanctionDetailsList}
            setIsModalUpdates={setIsMonetarySanctionModalUpdates}
            headerText={headerText}
          />
        </AddNewMonetarySanctionModal>
      )}

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            id="regNext"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionSanctionDetailsForm;

/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import InputMask from "react-input-mask";
import { eventStatusDateType } from "../../../constants";

const DateFirstCharged = ({
  dateFirstChargedError,
  dateFirstChargedErrorMsg,
  dateFirstCharged,
  onDateFirstChargedChangeHandler,
  onDateFirstChargedBlurHandler,
  dateFirstChargedRadio,
  handleChangeDateFirstChargedChecked,
  onDateFirstChargedExplanationChange,
  dateFirstChargedExpError,
  onBlurDateFirstChargedExpHandler,
  onFocusHandler,
  dateFirstChargedExp,
}) => {
  return (
    <div className="row">
      <div className="col-md-12 event-status-date-wrapper">
        <label id="dateFirstChargedLabelId" htmlFor="dateFirstChargedId">
          <strong id="dateFirstChargedText">Date First Charged: *</strong>
        </label>
        <InputMask
          id="dateFirstChargedId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            dateFirstChargedError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={dateFirstCharged}
          onChange={onDateFirstChargedChangeHandler}
          onBlur={onDateFirstChargedBlurHandler}
          onFocus={onFocusHandler}
        />
        <div id="dateFirstChargedError" className="invalid-feedback">
          {dateFirstChargedErrorMsg?.length
            ? dateFirstChargedErrorMsg
            : "Please enter Date First Charged"}
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateFirstChargedExactId"
                type="radio"
                id="dateFirstChargedExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={dateFirstChargedRadio === eventStatusDateType.EXACT}
                onChange={handleChangeDateFirstChargedChecked}
              />
              <label
                htmlFor="dateFirstChargedExactId"
                className="custom-control-label"
                id="label_exactDate"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateFirstChargedExplanationId"
                type="radio"
                id="dateFirstChargedExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={
                  dateFirstChargedRadio === eventStatusDateType.EXPLANATION
                }
                onChange={handleChangeDateFirstChargedChecked}
              />
              <label
                htmlFor="dateFirstChargedExplanationId"
                className="custom-control-label"
                id="label_explanation"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {dateFirstChargedRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="dateFirstChargedExplanation"
                  className="event-status-date-exp"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dateFirstChargedExplanation"
                  onChange={onDateFirstChargedExplanationChange}
                  className={
                    dateFirstChargedExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurDateFirstChargedExpHandler}
                  onFocus={onFocusHandler}
                  rows="3"
                  cols={500}
                  value={dateFirstChargedExp}
                />
                <div id="dateFirstChargedExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFirstCharged;

import { Component } from "react";
import { Button } from "react-blueprint-library";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../constants/Contract";
import { FORM_ID } from "../../../../constants/Forms";
import "../../../../assets/stylesheets/ConfidentialApplication/ConfidentialApplication.scss";
class ReviewPersonalInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opportunities: "",
      personalInfo: "",
      opportunitydesc: "",
      section_head: "",
    };
  }

  render() {
    return (
      <div id="reviewPersonalInfo" className="review-form">
        <div className="col-7 row mm-spacing--minor">
          <h3 id="conf_personal_info">Personal Information</h3>
        </div>

        <div className="candidate-info">
          <div className="row">
            <div className="col-5">
              <strong id="piLegalFirstNameLabel">Legal First Name*</strong>
            </div>
            <div id="piFirstName" className="col-6 bo--text-color">
              {this.props.personalInfo.firstname !== ""
                ? this.props.personalInfo.firstname
                : "-"}
            </div>
          </div>

          {this.props.id === "confidential_pinfo" && (
            <div className="row">
              <div className="col-5">
                {this.props.personalInfo.middlename !== "" ? (
                  <strong id="piMiddleNameLabel">Middle Name*</strong>
                ) : (
                  <strong id="piMiddleNameLabel">Middle Name</strong>
                )}
              </div>
              <div id="piPreferredFirstName" className="col-6 bo--text-color">
                {this.props.personalInfo.middlename !== ""
                  ? this.props.personalInfo.middlename
                  : "-"}
              </div>
            </div>
          )}

          {this.props.id === "personal_info" &&
            (this.props.personalInfo.middlename !== null ||
              this.props.personalInfo.middlename !== "") && (
              <div className="row">
                <div className="col-4">
                  {this.props.personalInfo.middlename !== "" ? (
                    <strong id="piMiddleNameLabel">Middle Name*</strong>
                  ) : (
                    <strong id="piMiddleNameLabel">Middle Name</strong>
                  )}
                </div>
                <div id="piMiddleName" className="col-6 bo--text-color">
                  {this.props.personalInfo.middlename !== ""
                    ? this.props.personalInfo.middlename
                    : "-"}
                </div>
              </div>
            )}

          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piLegalLastNameLabel">Legal Last Name*</strong>
            </div>
            <div id="piLastName" className="col-6 bo--text-color">
              {this.props.personalInfo.lastname !== ""
                ? this.props.personalInfo.lastname
                : "-"}
            </div>
          </div>

          {this.props.id === "confidential_pinfo" && (
            <div className="row">
              <div className="col-5">
                <strong id="piPreferredFirstNameLabel">Suffix</strong>
              </div>
              <div id="piPreferredFirstName" className="col-6 bo--text-color">
                {this.props.personalInfo.suffix !== ""
                  ? this.props.personalInfo.suffix
                  : "-"}
              </div>
            </div>
          )}

          {this.props.id === "personal_info" &&
            (this.props.personalInfo.suffix !== null ||
              this.props.personalInfo.suffix !== "") && (
              <div className="row">
                <div className={"col-4"}>
                  <strong id="piSuffixLabel">Suffix</strong>
                </div>
                <div id="piSuffix" className="col-6 bo--text-color">
                  {this.props.personalInfo.suffix !== ""
                    ? this.props.personalInfo.suffix
                    : "-"}
                </div>
              </div>
            )}

          {this.props.personalInfo.preferredfirstname !== null &&
            this.props.personalInfo.preferredfirstname !== "" && (
              <div className="row">
                <div
                  className={
                    this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                  }
                >
                  <strong id="piPreferredFirstNameLabel">
                    Preferred First Name*
                  </strong>
                </div>
                <div id="piPreferredFirstName" className="col-6 bo--text-color">
                  {this.props.personalInfo.preferredfirstname}
                </div>
              </div>
            )}

          {this.props.id === "personal_info" &&
          this.props.personalInfo.preferredfirstname !== null &&
          this.props.personalInfo.preferredfirstname !== "" ? (
            <div className="row">
              <div className="col-4">
                <strong id="piPreferredLastNameLabel">
                  Preferred Last Name
                </strong>
              </div>
              <div id="piPreferredLastName" className="col-5">
                {this.props.personalInfo.preferredlastname !== ""
                  ? this.props.personalInfo.preferredlastname
                  : "-"}
              </div>
            </div>
          ) : (
            <>
              {this.props.personalInfo.preferredfirstname !== null &&
                this.props.personalInfo.preferredfirstname !== "" && (
                  <div className="row">
                    <div className="col-5">
                      <strong id="piPreferredLastNameLabel">
                        Preferred Last Name
                      </strong>
                    </div>
                    <div
                      id="piPreferredLastName"
                      className="col-6 bo--text-color"
                    >
                      {this.props.personalInfo.preferredlastname !== ""
                        ? this.props.personalInfo.preferredlastname
                        : "-"}
                    </div>
                  </div>
                )}
            </>
          )}

          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piSSNLabel">Social Security Number*</strong>
            </div>

            <div id="piSSN" className={"col-7 bo--text-color"}>
              {this.props?.personalInfo?.ssn ? (
                <>
                  <>{`XXX-XX-${this.props.personalInfo.maskedssn}`}</>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piDOBLabel">Date of Birth*</strong>
            </div>
            <div id="piDOB" className="col-6 bo--text-color">
              {this.props.personalInfo.dateofbirth === null ||
              this.props.personalInfo.dateofbirth === ""
                ? "-"
                : this.props.personalInfo.dateofbirth}
            </div>
          </div>
          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piGenderLabel">Gender*</strong>
            </div>
            <div id="piGender" className="col-6 bo--text-color">
              {this.props.personalInfo.genderid === null ||
              this.props.personalInfo.genderid === ""
                ? "-"
                : this.props.personalInfo.genderid === 1
                ? "Male"
                : this.props.personalInfo.genderid === 2
                ? "Female"
                : "-"}
            </div>
          </div>
          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piPhoneNumberLabel">Phone Number*</strong>
            </div>
            <div id="piPhoneNumber" className="col-6 peacock-blue">
              <strong>
                {this.props.personalInfo.phonenumber === null ||
                this.props.personalInfo.phonenumber === ""
                  ? "-"
                  : this.props.personalInfo.phonenumber}
              </strong>
            </div>
          </div>
          <div className="row">
            <div
              className={
                this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
              }
            >
              <strong id="piEmailLabel">Email*</strong>
            </div>
            <div id="piEmailAddress" className="col-6 peacock-blue">
              <strong>
                {this.props.personalInfo.emailaddress === null ||
                this.props.personalInfo.emailaddress === ""
                  ? "-"
                  : this.props.personalInfo.emailaddress}
              </strong>
            </div>
          </div>
          {this.props.contracttype !==
          CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY ? (
            <div className="row">
              <div
                className={
                  this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                }
              >
                <strong id="piOpportunityLabel">
                  How did you hear about us?*
                </strong>
              </div>
              <div id="piOpportunity" className="col-6 bo--text-color">
                {this.props.personalInfo.opportunitydesc === null ||
                this.props.personalInfo.opportunitydesc === ""
                  ? "-"
                  : this.props.personalInfo.opportunitydesc}
              </div>
            </div>
          ) : null}

          {this.props.personalInfo.opportunitydesc === "Other" &&
          this.props.contracttype !==
            CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY ? (
            <div className="row">
              <div
                className={
                  this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                }
              >
                <strong id="piOtherOpportunityLabel">
                  If other, please specify*
                </strong>
              </div>
              <div id="piOtherOpportunity" className="col-6 bo--text-color">
                {this.props.personalInfo.opportunityother}
              </div>
            </div>
          ) : null}
          {(this.props.contracttype ===
            CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
            this.props.contracttype ===
              CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
            this.props.contracttype ===
              CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
            this.props.contracttype ===
              CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
            this.props.contracttype === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
            this.props.contracttype ===
              CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY) && (
            <>
              <div className="row">
                <div
                  className={
                    this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                  }
                >
                  <strong id="piMilitaryLabel">
                    Are you currently in or have you served in the military?*
                  </strong>
                </div>
                <div id="piMilitary" className="col-6 bo--text-color">
                  {this.props.personalInfo.militaryservice ? "Yes" : "No"}
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                  }
                >
                  <strong id="piRegisteredFINRALabel">
                    Have you ever been, or are you currently, registered with
                    FINRA?*
                  </strong>
                </div>
                <div id="piRegisteredFINRA" className="col-6 bo--text-color">
                  {this.props.personalInfo.finrareg ? "Yes" : "No"}
                </div>
              </div>
              {this.props.personalInfo.finrareg && (
                <>
                  <div className="row">
                    <div
                      className={
                        this.props.id === "confidential_pinfo"
                          ? "col-5"
                          : "col-4"
                      }
                    >
                      <strong id="piRegisteredFINRAPrincipalLabel">
                        Are you a FINRA registered principal?*
                      </strong>
                    </div>
                    <div
                      id="piRegisteredFINRAPrincipal"
                      className="col-6 bo--text-color"
                    >
                      {this.props.personalInfo.finraregprinc ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        this.props.id === "confidential_pinfo"
                          ? "col-5"
                          : "col-4"
                      }
                    >
                      <strong id="piCRDLabel">
                        What is your Personal Central Registration Depository
                        Number (CRD)?*
                      </strong>
                    </div>
                    <div id="piCRD" className="col-6 bo--text-color">
                      {this.props.personalInfo.crdnumber}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        this.props.id === "confidential_pinfo"
                          ? "col-5"
                          : "col-4"
                      }
                    >
                      <strong id="piFirmLabel">
                        What firm are/were you registered with?*
                      </strong>
                    </div>
                    <div id="piFirm" className="col-6 bo--text-color">
                      {this.props.personalInfo.finraregprincfirm}
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div
                  className={
                    this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                  }
                >
                  <strong id="piRelatedLabel">
                    Are you related to any current MassMutual associates?*
                  </strong>
                </div>
                <div id="piRelated" className="col-6 bo--text-color">
                  {this.props.personalInfo.relatedmmemps ? "Yes" : "No"}
                </div>
              </div>
              {this.props.personalInfo.relatedmmemps && (
                <>
                  <div className="row">
                    <div
                      className={
                        this.props.id === "confidential_pinfo"
                          ? "col-5"
                          : "col-4"
                      }
                    >
                      <strong id="piRelativeNameLabel">
                        If yes, please provide their name?*
                      </strong>
                    </div>
                    <div id="piRelativeName" className="col-6 bo--text-color">
                      {this.props.personalInfo.relatedempname}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        this.props.id === "confidential_pinfo"
                          ? "col-5"
                          : "col-4"
                      }
                    >
                      <strong id="piRelationshipLabel">
                        What is their relationship to you?*
                      </strong>
                    </div>
                    <div id="piRelationship" className="col-6 bo--text-color">
                      {this.props.personalInfo.employeerelationdesc}
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div
                  className={
                    this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                  }
                >
                  <strong id="piNonCompeteLabel">
                    If you become a MassMutual career agent, will you be subject
                    to a non-compete agreement that will limit your sales and/or
                    recruiting activities for a period of time?*
                  </strong>
                </div>
                <div id="piNonCompete" className="col-6 bo--text-color">
                  {this.props.personalInfo.noncompete ? "Yes" : "No"}
                </div>
              </div>
              {this.props.personalInfo.noncompete && (
                <div className="row">
                  <div
                    className={
                      this.props.id === "confidential_pinfo" ? "col-5" : "col-4"
                    }
                  >
                    <strong id="piNonCompeteWhomLabel">
                      If yes, with whom?*
                    </strong>
                  </div>
                  <div id="piNonCompeteWhom" className="col-6 bo--text-color">
                    {this.props.personalInfo.finraregfirm}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ReviewPersonalInfo;

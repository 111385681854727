import DRPSectionDivider from "../common/DRPSectionDivider";
import React from "react";
import "./ReviewCivilJudicialDRP.scss";

const ReviewCivilJudicialDRP = ({
  drp: {
    actDetails,
    details,
    sanctionDetails,
    comments,
    resDetails,
    statusDetails,
  },
}) => {
  return (
    <div id="ReviewCivilJudicialDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">
          {actDetails?.drpCheckList
            .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
            .join(", ")}
        </div>
      </div>
      <div id="actionDetailsSection">
        <DRPSectionDivider section={"Action Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Court action initiated by: *</strong>
          </div>
          <div className="col-6">{actDetails?.courtAction || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Name of party initiating the proceeding: *</strong>
          </div>
          <div className="col-6">{actDetails?.procName || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Formal action was brought in: *</strong>
          </div>
          <div className="col-6">
            {actDetails?.formalAction || "-"}
            {actDetails?.formalAction === "Other"
              ? `: ${actDetails?.formalActionOther}`
              : null}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Name of Court: *</strong>
          </div>
          <div className="col-6">{actDetails?.courtName || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Location of Court (City or County or State or Country): *
            </strong>
          </div>
          <div className="col-6">{actDetails?.locationCourt || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Docket/Case #: *</strong>
          </div>
          <div className="col-6">{actDetails?.docketOrCase || "-"}</div>
        </div>
      </div>
      <div id="detailsSection">
        <DRPSectionDivider section={"Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Filing date of court action: *</strong>
          </div>
          <div className="col-6">
            {details?.filingDate}
            <br />
            {details?.filingDateRadio}
            {details?.filingDateRadio === "Explanation"
              ? `: ${details?.filingDateExp}`
              : null}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Date Notice/Process was Served: *</strong>
          </div>
          <div className="col-6">
            {details?.noticeDate}
            <br />
            {details?.noticeDateRadio}
            {details?.noticeDateRadio === "Explanation"
              ? `: ${details?.noticeDateExp}`
              : null}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Describe the allegations related to this civil action: *
            </strong>
          </div>
          <div className="col-6">{details?.civilActionDes}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Employing Firm when activity occurred which led to civil judicial
              action: *
            </strong>
          </div>
          <div className="col-6">{details?.emplCivJudAction}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Product Type(s) (Select all that apply): *</strong>
          </div>
          <div className="col-6">
            {details?.selectedProdValues.map((product, key) => (
              <React.Fragment key={key}>
                <span>{`${product}${
                  product === "Other" ? `: ${details?.otherProdText}` : ""
                }`}</span>
                {key !== details?.selectedProdValues.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Relief Sought (Select all that apply): *</strong>
          </div>
          <div className="col-6">
            {details?.selectedReliefSoughtValues.map((product, key) => (
              <React.Fragment key={key}>
                <span>{`${product}${
                  product === "Other"
                    ? `: ${details?.otherReliefSoughtText}`
                    : ""
                }`}</span>
                {key !== details?.selectedReliefSoughtValues.length - 1 && (
                  <br />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div id="statusDetailsSection">
        <DRPSectionDivider section={"Status Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Current status: *</strong>
          </div>
          <div className="col-6">{statusDetails?.status || "-"}</div>
        </div>

        {statusDetails?.status === "On Appeal" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Action appealed to (provide name of court): *</strong>
              </div>
              <div className="col-6">
                {statusDetails?.actionAppealed || "-"}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Court Location: *</strong>
              </div>
              <div className="col-6">{statusDetails?.locationCourt || "-"}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Docket/Case #: *</strong>
              </div>
              <div className="col-6">{statusDetails?.docketOrCase || "-"}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Date appeal filed: *</strong>
              </div>
              <div className="col-6">
                {statusDetails?.dateAppealFiled || "-"}
                <br />
                {statusDetails?.dateAppealFiledRadio}
                {statusDetails?.dateAppealFiledRadio === "Explanation"
                  ? `: ${statusDetails?.dateAppealFiledExp}`
                  : null}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Appeal details (including status): *</strong>
              </div>
              <div className="col-6">{statusDetails?.appealDetDes || "-"}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  If on Appeal and any limitations or restrictions are currently
                  in effect, provide details:
                </strong>
              </div>
              <div className="col-6">
                {statusDetails?.appealLimitDes || "-"}
              </div>
            </div>
          </>
        )}
        {statusDetails?.status === "Pending" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  If pending and any limitations or restrictions are currently
                  in effect, provide details:
                </strong>
              </div>
              <div className="col-6">{statusDetails?.pendingDetDes || "-"}</div>
            </div>
          </>
        )}
      </div>
      {resDetails ? (
        <div id="resolutionDetailsSection">
          <DRPSectionDivider section={"Resolution Details"} />
          <div className="row">
            <div className="col-6">
              <strong>How was the matter resolved? *</strong>
            </div>
            <div className="col-6">
              {resDetails?.matterResolved || "-"}
              {resDetails?.matterResolved === "Other"
                ? `: ${resDetails?.matterResolvedOther}`
                : null}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Resolution Date: *</strong>
            </div>
            <div className="col-6">
              {resDetails?.resDate || "-"}
              <br />
              {resDetails?.resDateRadio}
              {resDetails?.resDateRadio === "Explanation"
                ? `: ${resDetails?.resDateExp}`
                : null}
            </div>
          </div>
        </div>
      ) : null}
      {sanctionDetails ? (
        <div id="sanctionDetailsSection">
          <DRPSectionDivider section={"Sanction Details"} />
          <div className="row">
            <div className="col-6">
              <strong>
                Were any of the following Sanctions Ordered or Relief Granted?
                (select all that apply): *
              </strong>
            </div>
            <div className="col-6">
              {sanctionDetails?.selectedOrderedReliefGranted.map(
                (value, key) => (
                  <React.Fragment key={key}>
                    {value}
                    <br />
                  </React.Fragment>
                )
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Other sanctions: (optional)</strong>
            </div>
            <div className="col-6">
              {sanctionDetails?.otherSanctionsDes || "-"}
            </div>
          </div>
          {sanctionDetails?.newInjunctionDetailsList && (
            <>
              {sanctionDetails?.newInjunctionDetailsList.map(
                (injunction, key) => (
                  <React.Fragment key={key}>
                    <br />
                    <div className="injunction">
                      <div className="injunction-header">
                        <div className="row">
                          <div className="col-6">
                            <strong>Injunction</strong>
                          </div>
                        </div>
                      </div>
                      <div className="injunction-body">
                        <div className="row">
                          <div className="col-6">
                            <strong>
                              Registration Capacities Affected (e.g., General
                              Securities Principal, Financial Operations
                              Principal, All Capacities, etc.):
                            </strong>
                          </div>
                          <div className="col-6">
                            {injunction?.regCapacitiesAffected}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Duration: *</strong>
                          </div>
                          <div className="col-6">
                            {injunction?.duration}
                            <br />
                            {injunction?.durationRadio}
                            {injunction?.durationRadio === "Explanation"
                              ? `: ${injunction?.durationExp}`
                              : null}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Start Date: *</strong>
                          </div>
                          <div className="col-6">
                            {injunction?.startDate}
                            <br />
                            {injunction?.startDateRadio}
                            {injunction?.startDateRadio === "Explanation"
                              ? `: ${injunction?.startDateExp}`
                              : null}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>End Date: *</strong>
                          </div>
                          <div className="col-6">
                            {injunction?.endDate}
                            <br />
                            {injunction?.endDateRadio}
                            {injunction?.endDateRadio === "Explanation"
                              ? `: ${injunction?.endDateExp}`
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              )}
            </>
          )}

          {sanctionDetails?.newMonetaryRelatedSanctionDetailsList && (
            <>
              {sanctionDetails?.newMonetaryRelatedSanctionDetailsList.map(
                (sanction, key) => (
                  <React.Fragment key={key}>
                    <br />
                    <div className="monetary-related-sanction">
                      <div className="monetary-related-sanction-header">
                        <div className="row">
                          <div className="col-6">
                            <strong>Monetary Related Sanction</strong>
                          </div>
                        </div>
                      </div>
                      <div className="monetary-related-sanction-body">
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Monetary Related Santion Type: *</strong>
                          </div>
                          <div className="col-6">
                            {sanction?.sanctionType}
                            {sanction?.sanctionType === "Other" && (
                              <>
                                <br />
                                {sanction?.sanctionTypeOther}
                              </>
                            )}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Total Amount: *</strong>
                          </div>
                          <div className="col-6">${sanction?.totalAmount}</div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Portion levied against you: *</strong>
                          </div>
                          <div className="col-6">
                            ${sanction?.portionLevied}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>Date Paid by you: *</strong>
                          </div>
                          <div className="col-6">
                            {sanction?.datePaid || "-"}
                            <br />
                            {sanction?.datePaidRadio}
                            {sanction?.datePaidRadio === "Explanation"
                              ? `: ${sanction?.datePaidExp}`
                              : null}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-6">
                            <strong>
                              Was any portion of penalty waived? *
                            </strong>
                          </div>
                          <div className="col-6">
                            {sanction?.portionPenaltyWaived ? "Yes" : "No"}
                            <br />
                            {sanction?.portionPenaltyWaived
                              ? `Amount: $${sanction?.portionOfPenaltyAmount}`
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              )}
            </>
          )}
        </div>
      ) : null}
      <div id="commentsSection">
        <DRPSectionDivider section={"comments"} />
        <div className="row">
          <div className="col-6">
            <strong>
              You may use this field to provide a brief summary of the
              circumstances leading to the action as well as the current status
              or final disposition. (Optional)
            </strong>
          </div>
          <div className="col-6">{comments || "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCivilJudicialDRP;

import React, { useState } from "react";

import "./DRPSummaryList.scss";
import ActionDropdown from "../ActionDropdown";
import { SECTION } from "../../constants";
import { headerText } from "../../RegulatoryActionForm/constants";
import { headerText as sectionCiv } from "../../CivilJudicialForm/constants";
import DeleteModal from "../DeleteModal";

const regulatorySectionsList = [
  headerText.REG,
  headerText.REG_FORM_INV,
  headerText.REG_FORM_ACT,
  headerText.REG_PRO_SUSP,
  headerText.REG_ACT_SRO,
  headerText.REG_ACT_REG,
  headerText.REG_SEC_CFTC,
];

const descStyle = {
  marginTop: "5px",
  marginBottom: "5px",
};

const DRPSummaryList = ({
  list,
  isLoading = false,
  deleteDRP = () => {},
  editDRP = () => {},
  section = "",
}) => {
  const [show, setShow] = useState(false);

  const [sectionState, setSectionState] = useState(null);
  const [questionKeyState, setQuestionKeyState] = useState(null);
  const [mainIndexState, setMainIndexState] = useState(null);
  const [indexState, setIndexState] = useState(null);

  const isRegulatory = regulatorySectionsList.includes(section);
  const isIndividualCriminalDisclosure = [
    SECTION.individualCriminalDisclosure,
  ].includes(section);
  const isOrgCriminalDisclosure =
    section === SECTION.organizationCriminalDisclosure;
  const isCustomerComplaintArbitrationCivilLitigation =
    section === SECTION.customerComplaintArbitrationCivilLitigation;
  const isBond = section === SECTION.bondDisclosures;
  const isJudgmentLien = section === SECTION.judgementLienDisclosures;
  const isTermination = section === SECTION.terminationDisclosures;
  const isOrgBankruptcy = section === SECTION.organizationBankruptcy;
  const isPersBankruptcy = section === SECTION.personalBankruptcy;
  const isCivilJud = section === sectionCiv.CIV_JUD_ACT;
  const isInvestigation = section === headerText.REG_FORM_INV;

  const numberOfColumns = () => {
    if (isTermination || isBond) {
      return "two-col";
    } else if (isIndividualCriminalDisclosure) {
      return "ind-crim-row";
    } else if (isOrgCriminalDisclosure) {
      return "org-crim-row";
    } else if (isRegulatory && !isInvestigation) {
      return "regulatory-row";
    } else if (isInvestigation) {
      return "investigation-row";
    } else if (isCivilJud) {
      return "civil-judicial-row";
    } else if (isCustomerComplaintArbitrationCivilLitigation) {
      return "customer-complaint-row";
    } else if (isPersBankruptcy) {
      return "personal-bankruptcy-row";
    } else if (isOrgBankruptcy) {
      return "organizational-bankruptcy-row";
    } else if (isJudgmentLien) {
      return "judgment-lien-row";
    } else {
      return "";
    }
  };
  return (
    <div className="drp-sum-list">
      <div className="list-header">
        <div className={`left ${numberOfColumns()}`}>
          {isIndividualCriminalDisclosure ? (
            <>
              <div>Date First Charged</div>
              <div>Description</div>
            </>
          ) : null}
          {isOrgCriminalDisclosure && (
            <>
              <div>Date First Charged</div>
              <div>Org Name</div>
              <div>Description</div>
            </>
          )}
          {isRegulatory && !isInvestigation ? (
            <>
              <div>Date Initiated</div>
              <div>Action Initiated by</div>
              <div>Status</div>
            </>
          ) : null}
          {isInvestigation ? (
            <>
              <div>Notice Date</div>
              <div>Notice received from:</div>
              <div>Status</div>
            </>
          ) : null}
          {isCivilJud ? (
            <>
              <div>Filing Date</div>
              <div>Action Initiated by</div>
              <div>Status</div>
            </>
          ) : null}
          {isCustomerComplaintArbitrationCivilLitigation ? (
            <>
              <div>Date received</div>
              <div>Reporting Type</div>
              <div>Status</div>
            </>
          ) : null}
          {isTermination ? (
            <>
              <div>Termination Date</div>
              <div>Termination Type</div>
            </>
          ) : null}
          {isPersBankruptcy ? (
            <>
              <div>Action Date</div>
              <div>Action Type</div>
              <div>Disposition</div>
            </>
          ) : null}
          {isOrgBankruptcy ? (
            <>
              <div>Action Date</div>
              <div>Org Name</div>
              <div>Action Type</div>
              <div>Disposition</div>
            </>
          ) : null}
          {isBond ? (
            <>
              <div>Firm Name</div>
              <div>Disposition Type</div>
            </>
          ) : null}
          {isJudgmentLien ? (
            <>
              <div>Date filed with Court</div>
              <div>Judgment/Lien</div>
              <div>Judgment/Lien Holder</div>
            </>
          ) : null}
        </div>
        <div className="action">Action</div>
      </div>
      <hr />
      <div className="rows">
        {list.map(
          (
            {
              date,
              description,
              status = "",
              qKey: questionKey,
              questionKey: qK = "",
              isDraft,
              orgName,
              mainIndex,
            },
            index
          ) => (
            <div key={`${index}-${questionKey || qK}`} className="rows-wrapper">
              <div
                className={isDraft ? "drp-list-row is-draft" : "drp-list-row"}
              >
                <div
                  className={`left ${numberOfColumns()}`}
                  style={{ pointerEvents: "none" }}
                >
                  {isIndividualCriminalDisclosure ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                    </>
                  ) : null}
                  {isOrgCriminalDisclosure ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{orgName || "N/A"}</div>
                      <div>
                        {description?.length
                          ? description?.map((desc, i) => (
                              <div
                                title={desc || ""}
                                key={`${desc}_${i * 1}`}
                                style={{ ...descStyle }}
                              >
                                {desc || "N/A"}
                              </div>
                            ))
                          : "N/A"}
                      </div>
                    </>
                  ) : null}
                  {isRegulatory && !isInvestigation ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                      <div>{status || "N/A"}</div>
                    </>
                  ) : null}
                  {isInvestigation ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                      <div>{status || "N/A"}</div>
                    </>
                  ) : null}
                  {isCivilJud ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                      <div>{status || "N/A"}</div>
                    </>
                  ) : null}
                  {isCustomerComplaintArbitrationCivilLitigation ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                      <div>{orgName || "Pending"}</div>
                    </>
                  ) : null}
                  {isTermination ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                    </>
                  ) : null}
                  {isPersBankruptcy ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {description?.length
                          ? description?.map((desc, i) => (
                              <div
                                title={desc || ""}
                                key={`${desc}_${i * 1}`}
                                style={{ ...descStyle }}
                              >
                                {desc || "N/A"}
                              </div>
                            ))
                          : "N/A"}
                      </div>
                      <div
                        style={{
                          ...descStyle,
                        }}
                      >
                        {status?.length ? (
                          <div
                            key={`${status}_${index}`}
                            style={{
                              ...descStyle,
                            }}
                          >
                            {status || "N/A"}
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </>
                  ) : null}
                  {isOrgBankruptcy ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{orgName || "N/A"}</div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {description?.length
                          ? description?.map((desc, i) => (
                              <div
                                title={desc || ""}
                                key={`${desc}_${i * 1}`}
                                style={{ ...descStyle }}
                              >
                                {desc || "N/A"}
                              </div>
                            ))
                          : "N/A"}
                      </div>
                      <div
                        style={{
                          ...descStyle,
                          marginRight: "12px",
                        }}
                      >
                        {status?.length ? (
                          <div
                            key={`${status}_${index}`}
                            style={{
                              ...descStyle,
                            }}
                          >
                            {status || "N/A"}
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </>
                  ) : null}
                  {isBond ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                    </>
                  ) : null}
                  {isJudgmentLien ? (
                    <>
                      <div>{date || "N/A"}</div>
                      <div>{description || "N/A"}</div>
                      <div>{orgName || "N/A"}</div>
                    </>
                  ) : null}
                </div>
                <div className="action">
                  <ActionDropdown
                    questionKey={questionKey || qK}
                    isFullWidth
                    showDelete={show}
                    isLoading={isLoading}
                    editAction={() => {
                      editDRP({
                        section,
                        questionKey: questionKey || qK,
                        dataIndex: mainIndex,
                        index,
                        isDraft,
                      });
                    }}
                    deleteAction={() => {
                      setSectionState(section);
                      setQuestionKeyState(questionKey || qK);
                      setMainIndexState(mainIndex);
                      setIndexState(index);

                      setShow(true);
                    }}
                  />
                </div>
              </div>
              <div>
                <hr />
              </div>
              <DeleteModal
                isOpen={show}
                showSpinner={false}
                closeClick={() => setShow(false)}
                deleteClick={() => {
                  if (
                    questionKeyState &&
                    (sectionState || sectionState === 0) &&
                    (mainIndexState || mainIndexState === 0) &&
                    (indexState || indexState === 0)
                  ) {
                    deleteDRP({
                      section: sectionState,
                      questionKey: questionKeyState,
                      dataIndex: mainIndexState,
                      index: indexState,
                    });
                  }

                  setShow(false);
                }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default DRPSummaryList;

import { Button } from "react-blueprint-library";

import Moment from "moment";
import { Fragment } from "react";

import "../../../../assets/stylesheets/ConfidentialApplication/ReviewEmploymentHistory.scss";
import React from "react";

function ReviewEmploymentHistory(props) {
  return (
    <div id="reviewEmploymentHistory" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_education">Employment History</h3>
      </div>

      <div
        className={props.id === "confidential_emphist" ? "custom-control" : ""}
        style={{ top: props.id === "confidential_emphist" ? "-20px" : "" }}
      >
        <ul className="mm-vertical-timeline">
          {Array.from(props.empHistory).map((job, key) => {
            return (
              <Fragment key={`container-${key}`}>
                <li
                  id={`address${key}`}
                  key={`${key}-address`}
                  className="active timeline-li"
                >
                  <div
                    className={
                      key === 0
                        ? "custom-table-row-no-border"
                        : "custom-table-row"
                    }
                  >
                    <div className="custom-table-column">
                      <span className="timeline-date-text">
                        {job.currentemployer === true
                          ? `Present`
                          : Moment(job.enddate).format("MM/DD/YYYY")}
                        <br></br>-<br></br>
                        {Moment(job.startdate).format("MM/DD/YYYY")}
                      </span>
                    </div>
                    <div className="custom-table-column">
                      {job.companyname !== null && (
                        <>
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistPositionLabel">Title*</strong>
                            </div>
                            <div
                              id="empHistPositionTitle"
                              className="col-9 bo--text-color"
                            >
                              {job.jobtitle !== null ? job.jobtitle : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistCompanyNameLabel">
                                Company Name*
                              </strong>
                            </div>
                            <div
                              id="empHistCompanyName"
                              className="col-9 bo--text-color"
                            >
                              {job.companyname !== null ? job.companyname : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistAddressLabel">
                                Address of Employer*
                              </strong>
                            </div>
                            <div
                              id="empHistAddress"
                              className="col-9 bo--text-color"
                            >
                              {job.empaddress1 !== null ? (
                                <>
                                  {job.empstate === "PR" &&
                                    job.urbanization !== "" &&
                                    job.urbanization !== null &&
                                    job.urbanization}
                                  {job.empstate === "PR" &&
                                    job.urbanization !== "" &&
                                    job.urbanization !== null && <br></br>}
                                  {job.empaddress1},
                                  {job.empaddress2 !== "" &&
                                    `${job.empaddress2}, `}
                                  <br></br>
                                  {job.empcity}, {job.empstate} {job.empzip}
                                  <br></br>
                                  {job.empcountry}
                                </>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistIndustryLabel">
                                Industry*
                              </strong>
                            </div>
                            <div
                              id="empHistIndustry"
                              className="col-9 bo--text-color"
                            >
                              {job?.industrytype !== null
                                ? job?.industrytype?.industryname
                                : "-"}
                            </div>
                          </div>
                          {job.currentemployer === false && (
                            <div className="row">
                              <div className="col-3">
                                <strong id="empHistReasonLeavingLabel">
                                  Reason for leaving? (optional)
                                </strong>
                              </div>
                              <div
                                id="empHistReasonLeaving"
                                className="col-9 bo--text-color"
                              >
                                {job?.reasonforleaving !== null &&
                                job?.reasonforleaving !== "" ? (
                                  <>{job?.reasonforleaving}</>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          )}
                          {job.salesposition === true && (
                            <div className="row">
                              <div className="col-3">
                                <strong id="empHistFullTimeRoleLabel">
                                  Was this a full time role?*
                                </strong>
                              </div>
                              <div
                                id="empHistFullTimeRole"
                                className="col-9 bo--text-color"
                              >
                                {job.fulltime === true ? (
                                  <>
                                    Yes<br></br>
                                  </>
                                ) : (
                                  "No"
                                )}
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistContactEmployerLabel">
                                May we contact this employer?*
                              </strong>
                            </div>
                            <div
                              id="empHistContactEmployer"
                              className="col-9 bo--text-color"
                            >
                              {job.contactemployer === true ? (
                                <>
                                  Yes<br></br>
                                </>
                              ) : (
                                "No"
                              )}
                            </div>
                          </div>
                          {job.contactemployer === true && (
                            <div className="row">
                              <div className="col-3">
                                <strong id="empHistContactPhoneLabel">
                                  Phone number of Contact*
                                </strong>
                              </div>
                              <div
                                id="empHistContactPhone"
                                className="col-9 peacock-blue"
                              >
                                <strong>
                                  {job.empphonenumber
                                    .replaceAll(" ", "-")
                                    .replaceAll("+", "")}
                                </strong>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistIsInSalesLabel">
                                Is this position in Sales?*
                              </strong>
                            </div>
                            <div
                              id="empHistIsInSales"
                              className="col-9 bo--text-color"
                            >
                              {job.salesposition === true ? (
                                <>
                                  Yes<br></br>
                                  {job.solicitindlife === true &&
                                    "Individual Life Insurance"}
                                  {job.solicitindlife === true && <br></br>}
                                  {job.solicitindannuity === true &&
                                    "Individual Annuity"}
                                  {job.solicitindannuity === true && <br></br>}
                                  {job.solicitinddisability === true &&
                                    "Individual Disability Income"}
                                  {job.solicitinddisability === true && (
                                    <br></br>
                                  )}
                                  {job.solicitindltc === true &&
                                    "Individual Long Term Care Insurance"}
                                </>
                              ) : (
                                "No"
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <strong id="empHistInvestmentRelatedLabel">
                                Is this position in Investment Related
                                Business?*
                              </strong>
                            </div>
                            <div
                              id="empHistInvestmentRelated"
                              className="col-9 bo--text-color"
                            >
                              {job.invrelatedbus === true ? (
                                <>
                                  Yes<br></br>
                                </>
                              ) : (
                                "No"
                              )}
                            </div>
                          </div>
                          {job.salesposition === true && (
                            <div className="row">
                              <div className="col-3">
                                <strong id="empHistTrainingSubsidyLabel">
                                  Did you receive a training or financing
                                  subsidy while employed by this company?*
                                </strong>
                              </div>
                              <div
                                id="empHistTrainingSubsidy"
                                className="col-9 bo--text-color"
                              >
                                {job.trainingsubsidy === true ? (
                                  <>
                                    Yes<br></br>
                                    {Moment(job.trainingstartdate).format(
                                      "MM/DD/YYYY"
                                    )}{" "}
                                    -<br></br>
                                    {Moment(job.trainingenddate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </>
                                ) : (
                                  "No"
                                )}
                              </div>
                            </div>
                          )}
                          {!props.hasNYRegs && job.trainingsubsidy === true && (
                            <div className="row">
                              <div className="col-3">
                                <strong id="empHistTotalSubsidyLabel">
                                  Total subsidy received from this employer:*
                                </strong>
                              </div>
                              <div
                                id="empHistTotalSubsidy"
                                className="col-9 bo--text-color"
                              >
                                {job.totalsubsidyrecd !== null ? (
                                  <>{`$${job.totalsubsidyrecd.toLocaleString()}`}</>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {job.companyname === null && (
                        <>
                          <div className="row">
                            <div className="col-6 bo--text-color">
                              {job.jobtitle === "Other"
                                ? job.description
                                : job.jobtitle}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ReviewEmploymentHistory;

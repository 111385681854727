import React from "react";
import BackButtonModal from "../Forms/BackButtonModal";
import "../StartApplication.scss";
import "../../../assets/stylesheets/StartApplication/MMLIS/MMLIS.scss";
import { insertMMLISObaCria } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";
import { OverlayTrigger, Popover } from "react-bootstrap";

class MMLISOBACRIAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleOBAChange = this.handleOBAChange.bind(this);
    this.handleCRIAChange = this.handleCRIAChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.mmlisData[0].outsidebusact === true) {
        await this.setState({ oba: "Y" });
      } else if (this.props.mmlisData[0].outsidebusact === false) {
        await this.setState({ oba: "N" });
      }

      if (this.props.mmlisData[0].corpreg === true) {
        await this.setState({ cria: "Y" });
      } else if (this.props.mmlisData[0].corpreg === false) {
        await this.setState({ cria: "N" });
      }

      await this.setState({
        candidate_id: this.props.candidateId,
      });

      UtagTracking.sendPageNameView(
        "mmlisobacriaform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      oba: "",
      cria: "",
      showBackModal: false,
      formHasChanged: false,
      is_form_saved: false,
    };
  }

  async handleOBAChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
      formHasChanged: true,
    });

    var obj_error = document.getElementById("oba_error");
    obj_error.style.display = "none";
    var oba_yes = document.getElementById("oba_yes");
    oba_yes.classList.remove("is-invalid");
    var oba_no = document.getElementById("oba_no");
    oba_no.classList.remove("is-invalid");

    this.props.hasCurrentFormChanged(true);
    this.props.isFormComplete("MMLISOBACRIACompleteInd", false);
  }

  async handleCRIAChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
      formHasChanged: true,
    });

    var obj_error = document.getElementById("cria_error");
    obj_error.style.display = "none";
    var cria_yes = document.getElementById("cria_yes");
    cria_yes.classList.remove("is-invalid");
    var cria_no = document.getElementById("cria_no");
    cria_no.classList.remove("is-invalid");

    this.props.hasCurrentFormChanged(true);
    this.props.isFormComplete("MMLISOBACRIACompleteInd", false);
  }

  async handleNext() {
    this.setState({ oba_error: false, cria_error: false });
    if (this.state.oba === "" || this.state.cria === "") {
      if (this.state.oba === "") {
        var obj_error = document.getElementById("oba_error");
        obj_error.style.display = "block";
        var oba_yes = document.getElementById("oba_yes");
        oba_yes.classList.add("is-invalid");
        var oba_no = document.getElementById("oba_no");
        oba_no.classList.add("is-invalid");
        var label_oba_yes = document.getElementById("label_oba_yes");
        label_oba_yes.style.color = "#555";
        var label_oba_no = document.getElementById("label_oba_no");
        label_oba_no.style.color = "#555";
      }
      if (this.state.cria === "") {
        var obj_error2 = document.getElementById("cria_error");
        obj_error2.style.display = "block";
        var cria_yes = document.getElementById("cria_yes");
        cria_yes.classList.add("is-invalid");
        var cria_no = document.getElementById("cria_no");
        cria_no.classList.add("is-invalid");
        var label_cria_yes = document.getElementById("label_cria_yes");
        label_cria_yes.style.color = "#555";
        var label_cria_no = document.getElementById("label_cria_no");
        label_cria_no.style.color = "#555";
      }
    } else {
      if (this.state.formHasChanged === true) {
        let obabool = "";
        if (this.state.oba === "Y") {
          obabool = true;
        } else {
          obabool = false;
        }

        let criabool = "";
        if (this.state.cria === "Y") {
          criabool = true;
        } else {
          criabool = false;
        }

        let data = {
          candidateid: this.state.candidate_id,
          outsidebus: obabool,
          corpreg: criabool,
        };
        await insertMMLISObaCria(this.props.subId, data);
        this.props.saveMMLISOBACRIAInfo();
      }

      await this.setState({ is_form_saved: true, formHasChanged: false });
      this.props.hasCurrentFormChanged(false);
      this.props.isFormComplete("MMLISOBACRIACompleteInd", true);
      this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.MMLIS_PAY_TO_PLAY_2);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.MMLIS_PAY_TO_PLAY_2);
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <h1>MML Investors Services</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-md-12">
            <h3>Disclosure of Outside Business Activity (OBA)</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p
              id="oba_text"
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Are you currently engaged in any other business either as a
              proprietor, partner, officer, director, employee, trustee, agent
              or otherwise? (Please exclude non investment-related activity that
              is exclusively charitable, civic, religious or fraternal and is
              recognized as tax exempt.)*{" "}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover
                    id="popover-basic-oba"
                    style={{
                      padding: "10px",
                      backgroundColor: "#555",

                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    You will receive an Outside Business Activity packet, sent
                    from your agency in the coming days.
                  </Popover>
                }
              >
                <button
                  variant="link"
                  className="btn btn-link"
                  id="oba_button"
                  style={{ paddingTop: "1px" }}
                >
                  <span className="icon-info"></span>
                </button>
              </OverlayTrigger>
            </p>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="oba_yes"
                name="oba"
                className="custom-control-input"
                checked={this.state.oba === "Y"}
                value="Y"
                onChange={this.handleOBAChange}
              />
              <label
                htmlFor="oba_yes"
                className="custom-control-label"
                id="label_oba_yes"
              >
                Yes
              </label>
            </div>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="oba_no"
                name="oba"
                className="custom-control-input"
                checked={this.state.oba === "N"}
                value="N"
                onChange={this.handleOBAChange}
              />
              <label
                htmlFor="oba_no"
                className="custom-control-label"
                id="label_oba_no"
              >
                No
              </label>
            </div>
            <div
              id="oba_error"
              className="invalid-feedback"
              style={{ marginLeft: "20px" }}
            >
              Please choose a response
            </div>
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div className="row" style={{ marginTop: "35px" }}>
          <div className="col-md-12">
            <h3>Corporate Registered Investment Adviser (CRIA)</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p
              id="cria_text"
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Are you requesting affiliation with MMLIS Corporate Registered
              Investment Advisor (CRIA) to provide fee-based investment advisory
              services? (Note: you must have a valid Series 65, Series 66 or
              other qualifying designation.)?*{" "}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover
                    id="popover-basic-cria"
                    style={{
                      padding: "10px",
                      backgroundColor: "#555",

                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    If you answer yes, you will receive the Corporate Registered
                    Investment Advisor packet, sent from your agency in the
                    coming days.
                  </Popover>
                }
              >
                <button
                  variant="link"
                  className="btn btn-link"
                  id="cria_button"
                  style={{ paddingTop: "1px" }}
                >
                  <span className="icon-info"></span>
                </button>
              </OverlayTrigger>
            </p>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="cria_yes"
                name="cria"
                className="custom-control-input"
                checked={this.state.cria === "Y"}
                value="Y"
                onChange={this.handleCRIAChange}
              />
              <label
                htmlFor="cria_yes"
                className="custom-control-label"
                id="label_cria_yes"
              >
                Yes
              </label>
            </div>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="cria_no"
                name="cria"
                className="custom-control-input"
                checked={this.state.cria === "N"}
                value="N"
                onChange={this.handleCRIAChange}
              />
              <label
                htmlFor="cria_no"
                className="custom-control-label"
                id="label_cria_no"
              >
                No
              </label>
            </div>
            <div
              id="cria_error"
              className="invalid-feedback"
              style={{ marginLeft: "20px" }}
            >
              Please choose a response
            </div>
          </div>
        </div>

        <NavigationButtons
          onBack={this.handleShowBackModal}
          onNext={this.handleNext}
          nxtbtnid={"mmlis_oba_cria_next"}
          bckbtnid={"mmlis_oba_cria_back"}
          isDisabled={false}
        />

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default MMLISOBACRIAForm;

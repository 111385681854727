import React from "react";

const questionMapping = {
  //CriminalDiscloure
  //Individual Criminal
  convictedfelony: "14A(1)(a)",
  chargedfelony: "14A(1)(b)",
  convictedmisdemeanor: "14B(1)(a)",
  chargedmisdemeanor: "14B(1)(b)",
  //Organization Criminal
  orgconvictedfelony: "14A(2)(a)",
  orgchargedfelony: "14A(2)(b)",
  orgconvictedmisdemeanor: "14B(2)(a)",
  orgchargedmisdemeanor: "14B(2)(b)",
  //RegulatoryAction
  secfalsestatement: "14C(1)",
  secinvolvedviolation: "14C(2)",
  seccauseinvestrelatedauth: "14C(3)",
  secorderagainst: "14C(4)",
  secceaseanddesist: "14C(5)",
  secviolatedsecuritiesact: "14C(6)",
  secaidedabettersecuritiesact: "14C(7)",
  secfailedtosupervise: "14C(8)",
  fedfalsestatement: "14D(1)(a)",
  fedinvolvedviolation: "14D(1)(b)",
  fedcauseinvestrelatedauth: "14D(1)(c)",
  fedorderagainst: "14D(1)(d)",
  fedregdenied: "14D(1)(e)",
  finalorderbarred: "14D(2)(a)",
  finalorderviolations: "14D(2)(b)",
  selffalsestatement: "14E(1)",
  selfinvolvedviolation: "14E(2)",
  selfcauseinvestrelatedauth: "14E(3)",
  selfdisciplined: "14E(4)",
  selfviolatedsecuritiesact: "14E(5)",
  selfaidedabettersecuritiesact: "14E(6)",
  selffailedtosupervise: "14E(7)",
  authrevoked: "14F",
  notifiedproceeding: "14G(1)",
  //finiancial
  notifiedinvestigation: "14G(2)",
  //civilJudicialActions
  enjoined: "14H(1)(a)",
  foundinvolvedviolation: "14H(1)(b)",
  dismissedcivilaction: "14H(1)(c)",
  namedinpendingcivilaction: "14H(2)",
  //customerComplaintArbitrationCivilLitigation
  litigationpending: "14I(1)(a)",
  litigationaward: "14I(1)(b)",
  litigationsettledprior: "14I(1)(c)",
  litigationsettledafter: "14I(1)(d)",
  complaintsettledprior: "14I(2)(a)",
  complaintsettledafter: "14I(2)(b)",
  complaint24monthssalesviolation: "14I(3)(a)",
  complaint24monthsforgery: "14I(3)(b)",
  violationover15k: "14I(4)(a)",
  violationaward: "14I(4)(b)",
  arbitrationsalesviolation: "14I(5)(a)",
  arbitrationforgery: "14I(5)(b)",
  //terminationDisclosures
  termviolation: "14J(1)",
  termfraud: "14J(2)",
  termfailuretosupervise: "14J(3)",
  //Bankruptcy
  //personalBankruptcy
  tenyearscompromise: "14K(1)",
  //orgBankruptcy
  tenyearsorgcompromise: "14K(2)",
  tenyearsbankruptcyboolean: "14K(3)",
  //Bound
  deniedbonding: "14L",
  //Lient/Judgement
  unsatisfiedjudgements: "14M",
};

const KEYS = {
  individualDisclosure: [
    "convictedfelony",
    "chargedfelony",
    "convictedmisdemeanor",
    "chargedmisdemeanor",
  ],
  organizationDisclosure: [
    "orgconvictedfelony",
    "orgchargedfelony",
    "orgconvictedmisdemeanor",
    "orgchargedmisdemeanor",
  ],
  regulatoryActionSECorCFTC: [
    "secfalsestatement",
    "secinvolvedviolation",
    "seccauseinvestrelatedauth",
    "secorderagainst",
    "secceaseanddesist",
    "secviolatedsecuritiesact",
    "secaidedabettersecuritiesact",
    "secfailedtosupervise",
    "fedfalsestatement",
    "fedinvolvedviolation",
    "fedcauseinvestrelatedauth",
    "fedorderagainst",
    "fedregdenied",
    "finalorderbarred",
    "finalorderviolations",
    "selffalsestatement",
    "selfinvolvedviolation",
    "selfcauseinvestrelatedauth",
    "selfdisciplined",
    "selfviolatedsecuritiesact",
    "selfaidedabettersecuritiesact",
    "selffailedtosupervise",
    "authrevoked",
    "notifiedproceeding",
  ],
  investigation: ["notifiedinvestigation"],
  civilJudicialActions: [
    "enjoined",
    "foundinvolvedviolation",
    "dismissedcivilaction",
    "namedinpendingcivilaction",
  ],
  customerComplaintArbitrationCivilLitigation: [
    "litigationpending",
    "litigationaward",
    "litigationsettledprior",
    "litigationsettledafter",
    "complaintsettledprior",
    "complaintsettledafter",
    "complaint24monthssalesviolation",
    "complaint24monthsforgery",
    "violationover15k",
    "violationaward",
    "arbitrationsalesviolation",
    "arbitrationforgery",
  ],
  terminationDisclosures: [
    "termviolation",
    "termfraud",
    "termfailuretosupervise",
  ],
  personalBankrupcy: ["tenyearscompromise"],
  orgBankrupcy: ["tenyearsorgcompromise", "tenyearsbankruptcyboolean"],

  bondDisclosures: ["deniedbonding"],
  judgementLienDisclosures: ["unsatisfiedjudgements"],
};

const SECTION = {
  criminalDisclosure: "Criminal Disclosure",
  individualCriminal: "Individual Criminal",
  organizationCriminal: "Organization Criminal",
  regulatoryActionSECorCFTC: "Regulatory Action Disclosure",
  investigation: "Investigation",
  customerComplaintArbitrationCivilLitigation: "Customer Complaint Disclosure",
  civilJudicial: "Civil Judicial Disclosure",
  terminationDisclosures: "Termination Disclosure",
  bankrupcy: "Personal Bankruptcy",
  orgbankrupcy: "Organization Bankruptcy",
  bound: "Bond",
  lien: "Judgment/Lien",
};

const getSpecificObjByKeys = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));

function ConfAppCCB({ ccb_info }) {
  // Filter data for individual disclosures
  const individualData = getSpecificObjByKeys(
    ccb_info,
    KEYS.individualDisclosure
  );
  //filter data for organizational disclosure
  const organizationalData = getSpecificObjByKeys(
    ccb_info,
    KEYS.organizationDisclosure
  );
  // Filter data for Regulatory Action
  const regulatoryAct = getSpecificObjByKeys(
    ccb_info,
    KEYS.regulatoryActionSECorCFTC
  );
  //Investigation
  const investigationData = getSpecificObjByKeys(ccb_info, KEYS.investigation);
  // Filter data for CustomerComplaint
  const customerCmp = getSpecificObjByKeys(
    ccb_info,
    KEYS.customerComplaintArbitrationCivilLitigation
  );

  const civilJud = getSpecificObjByKeys(ccb_info, KEYS.civilJudicialActions);
  // Filter data for TerminationDisclosure
  const terminationDis = getSpecificObjByKeys(
    ccb_info,
    KEYS.terminationDisclosures
  );
  //bnkrupcy
  const bnkrpcy = getSpecificObjByKeys(ccb_info, KEYS.personalBankrupcy);
  const orgBnkrpcy = getSpecificObjByKeys(ccb_info, KEYS.orgBankrupcy);
  const bndDisclsures = getSpecificObjByKeys(ccb_info, KEYS.bondDisclosures);
  const judgement = getSpecificObjByKeys(
    ccb_info,
    KEYS.judgementLienDisclosures
  );

  // Function to create disclosure items from filtered data
  const createDisclosureItems = (filteredData) =>
    Object.entries(filteredData)
      .map(([key, value]) => {
        const questionNumber = questionMapping[key]; // Use the mapping to get the question number
        if (!questionNumber) return null; // Skip keys that don't have a question number
        return {
          questionNumber,
          answer: value ? "Yes" : "No",
        };
      })
      .filter(Boolean); // Filter out null values

  // Create disclosure items for both sections
  const disclosureItems = createDisclosureItems(individualData);
  const orgDisclosureItems = createDisclosureItems(organizationalData);
  const regulatoryAction = createDisclosureItems(regulatoryAct);
  const investigationItems = createDisclosureItems(investigationData);
  const civilJudicial = createDisclosureItems(civilJud);
  const customerComplaint = createDisclosureItems(customerCmp);
  const terminationDisclosures = createDisclosureItems(terminationDis);
  const bankrupcy = createDisclosureItems(bnkrpcy);
  const orgbankrupcy = createDisclosureItems(orgBnkrpcy);
  const boundDisclosures = createDisclosureItems(bndDisclsures);
  const lienData = createDisclosureItems(judgement);

  return (
    <div>
      <h3>Character, Credit, and Bonding</h3>
      <h3 className="mm-spacing--minor">Criminal Disclosure</h3>
      <div className="mm-spacing--minor">
        <strong>{SECTION.individualCriminal}</strong>
      </div>
      <div className="custom-control">
        {disclosureItems.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <div className="col-5">
              <strong>{item.questionNumber}</strong>
            </div>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mm-spacing--minor">
        <strong>{SECTION.organizationCriminal} </strong>
      </div>
      <div className="custom-control">
        {orgDisclosureItems.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <div className="col-5">
              <strong>{item.questionNumber}</strong>
            </div>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="mm-spacing--minor">{SECTION.regulatoryActionSECorCFTC}</h3>
      <div className="mm-spacing--minor">
        <strong>
          {SECTION.regulatoryActionSECorCFTC.replace("Disclosure", "")}
        </strong>
      </div>
      <div className="custom-control">
        {regulatoryAction.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mm-spacing--minor">
        <strong>{SECTION.investigation}</strong>
      </div>
      <div className="custom-control">
        {investigationItems.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="mm-spacing--minor">{SECTION.civilJudicial}</h3>
      <div>
        {civilJudicial.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div className="col-7">
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="mm-spacing--minor">
        {SECTION.customerComplaintArbitrationCivilLitigation}
      </h3>
      <div>
        {customerComplaint.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div className="col-7">
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="mm-spacing--minor">{SECTION.terminationDisclosures}</h3>
      <div>
        {terminationDisclosures.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div className="col-7">
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="mm-spacing--minor">Financial Disclosure</h3>
      <div className="mm-spacing--minor">
        <strong>{SECTION.bankrupcy}</strong>
      </div>
      <div className="custom-control">
        {bankrupcy.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mm-spacing--minor">
        <strong>{SECTION.orgbankrupcy}</strong>
      </div>
      <div className="custom-control">
        {orgbankrupcy.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mm-spacing--minor">
        <strong className="mm-spacing--minor">{SECTION.bound}</strong>
      </div>
      <div className="custom-control">
        {boundDisclosures.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mm-spacing--minor">
        <strong className="mm-spacing">{SECTION.lien}</strong>
      </div>
      <div className="custom-control">
        {lienData.map((item, index) => (
          <div key={index} className="row mm-spacing--minor">
            <strong className="col-5">{item.questionNumber}</strong>
            <div>
              {item.answer === "Yes" ? (
                <strong>{item.answer}</strong>
              ) : (
                <span>{item.answer}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ConfAppCCB;

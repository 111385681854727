import React, { Component, Fragment } from "react";
import { Card } from "react-blueprint-library";
import { Accordion, AccordionButton } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import Moment from "moment";
class ReviewPSRMonths extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.props.id === "confidential_psr" ? (
            <>
              <div className="footer-link row">
                <strong className="col-3">Months of experience:</strong>
                <strong className="col-9">
                  {this.props.data.totalMonthsOfExp.toFixed(2)}
                </strong>
              </div>
              <section className="row">
                <div className="col-md-12 align-self-stretch">
                  <div style={{ width: "100%" }}>
                    <br></br>
                    <table width="100%" cellPadding={8} cellSpacing={8}>
                      <tbody>
                        <tr className="psr-row">
                          <td width="6%" className="psr-cell">
                            <strong>Year</strong>
                          </td>
                          <td width="20%" className="psr-cell">
                            <strong>Dates of Employment</strong>
                          </td>
                          <td width="20%" className="psr-cell">
                            <strong>Company Name</strong>
                          </td>
                          <td width="20%" className="psr-cell">
                            <strong>Role</strong>
                          </td>
                          <td width="12%" className="psr-cell">
                            <strong>% of Time selling LADL</strong>
                          </td>
                          <td width="10%" className="psr-cell">
                            <strong>Months in role</strong>
                          </td>
                          <td width="10%" className="psr-cell">
                            <strong>Months of experience</strong>
                          </td>
                        </tr>
                        {Array.from(this.props.data.monthsOfExp).map(
                          (psr, key) => {
                            return (
                              <Fragment key={key}>
                                <tr className="psr-row">
                                  <td className="psr-cell">{psr.year}</td>
                                  <td className="psr-cell">
                                    {Moment(psr.startDate).format("MM/DD/YYYY")}
                                    -
                                    {psr.isCurrentEmpl &&
                                    psr.year === Moment().year()
                                      ? "Present"
                                      : Moment(psr.endDate).format(
                                          "MM/DD/YYYY"
                                        )}
                                  </td>
                                  <td className="psr-cell">
                                    {psr.companyname}
                                  </td>
                                  <td className="psr-cell">{psr.role}</td>
                                  <td className="psr-cell">
                                    {psr.positionladlpct}
                                  </td>
                                  <td className="psr-cell">
                                    {psr.monthsInRole}
                                  </td>
                                  <td className="psr-cell">
                                    {psr.monthsOfExpNr}
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          }
                        )}
                        <tr className="psr-row-gray">
                          <td colSpan="6" className="psr-cell">
                            <strong>Total Experience in Months</strong>
                          </td>
                          <td className="psr-cell">
                            <strong>
                              {this.props.data.totalMonthsOfExp.toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <Card>
              <Accordion>
                <div className="mm-collapse">
                  <AccordionButton
                    id="psr_months_of_experience"
                    className="btn-collapse"
                  >
                    <table width="100%">
                      <tbody>
                        <tr className="psr-first-row">
                          <td width="3%">&nbsp;</td>
                          <td width="22%">
                            <strong className="footer-link psr-accordion">
                              <p>Months of experience:</p>
                            </strong>
                          </td>
                          <td width="72%">
                            <strong className="footer-link psr-accordion">
                              <p>
                                {this.props.data.totalMonthsOfExp.toFixed(2)}
                              </p>
                            </strong>
                          </td>
                          <td>
                            <span className="indicator psr-chevron"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </AccordionButton>
                  <AccordionBody>
                    <section className="row">
                      <div className="col-md-12 align-self-stretch">
                        <div style={{ width: "100%" }}>
                          <br></br>
                          <table width="100%" cellPadding={8} cellSpacing={8}>
                            <tbody>
                              <tr className="psr-row">
                                <td width="6%" className="psr-cell">
                                  <strong>Year</strong>
                                </td>
                                <td width="20%" className="psr-cell">
                                  <strong>Dates of Employment</strong>
                                </td>
                                <td width="20%" className="psr-cell">
                                  <strong>Company Name</strong>
                                </td>
                                <td width="20%" className="psr-cell">
                                  <strong>Role</strong>
                                </td>
                                <td width="12%" className="psr-cell">
                                  <strong>% of Time selling LADL</strong>
                                </td>
                                <td width="10%" className="psr-cell">
                                  <strong>Months in role</strong>
                                </td>
                                <td width="10%" className="psr-cell">
                                  <strong>Months of experience</strong>
                                </td>
                              </tr>
                              {Array.from(this.props.data.monthsOfExp).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <tr className="psr-row">
                                        <td className="psr-cell">{psr.year}</td>
                                        <td className="psr-cell">
                                          {Moment(psr.startDate).format(
                                            "MM/DD/YYYY"
                                          )}
                                          -
                                          {psr.isCurrentEmpl &&
                                          psr.year === Moment().year()
                                            ? "Present"
                                            : Moment(psr.endDate).format(
                                                "MM/DD/YYYY"
                                              )}
                                        </td>
                                        <td className="psr-cell">
                                          {psr.companyname}
                                        </td>
                                        <td className="psr-cell">{psr.role}</td>
                                        <td className="psr-cell">
                                          {psr.positionladlpct}
                                        </td>
                                        <td className="psr-cell">
                                          {psr.monthsInRole}
                                        </td>
                                        <td className="psr-cell">
                                          {psr.monthsOfExpNr}
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                }
                              )}
                              <tr className="psr-row-gray">
                                <td colSpan="6" className="psr-cell">
                                  <strong>Total Experience in Months</strong>
                                </td>
                                <td className="psr-cell">
                                  <strong>
                                    {this.props.data.totalMonthsOfExp.toFixed(
                                      2
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </section>
                  </AccordionBody>
                </div>
              </Accordion>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default ReviewPSRMonths;

function ConfAppEOC(props) {
  return (
    <div id="confidential-eoc">
      <div className="row">
        <div className="col-8 mm-spacing--minor">
          <h3 id="conf_eoc_text">Errors and Omissions Coverage</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <h5 id="conf_eoc_status_text">Status*</h5>
        </div>
        <div id="eoc_status" className="col-9 data">
          {props.eoc_info.eoindgreatermil === true &&
            "I currently have an individual E&O coverage policy that is equal to or greater than $1 million"}
          {props.eoc_info.eoindlessmil === true &&
            "I currently have an individual E&O coverage policy that is less than $1 million"}
          {props.eoc_info.eogroup === true &&
            "I am currently covered under a Group E&O policy"}
        </div>
      </div>
    </div>
  );
}

export default ConfAppEOC;

import React from "react";
import DRPSectionDivider from "../common/DRPSectionDivider";
import { isNotNullUndefinedOrEmpty } from "../../../../../../../common/Functions";

const ReviewCustComplaintDRP = ({
  drp: {
    arbContribAmount,
    arbDispositionDate,
    arbDispositionDateExplanation,
    arbDispositionDateRadio,
    arbDocketOrCase,
    arbIsPending,
    arbMonetaryComp,
    arbNoticeServedDate,
    arbNoticeServedDateExplanation,
    arbNoticeServedDateRadio,
    arbReparationClaim,
    arbStatusOption,
    arbStatusOptionExplanation,
    civLitAppealCourtLocation,
    civLitAppealCourtName,
    civLitAppealCourtType,
    civLitAppealDocketOrCase,
    civLitAppealFileCourtTypeOther,
    civLitAppealFileDate,
    civLitAppealFileDateExplanation,
    civLitAppealFileDateRadio,
    civLitContribAmount,
    civLitDispositionDate,
    civLitDispositionDateExplanation,
    civLitDispositionDateRadio,
    civLitDispositionOption,
    civLitDispositionOptionOtherExplanation,
    civLitFiledCourtLocation,
    civLitFiledCourtName,
    civLitFiledCourtType,
    civLitFiledCourtTypeOther,
    civLitFiledDocketOrCase,
    civLitIsOnAppeal,
    civLitIsPending,
    civLitMonetaryComp,
    civLitServedDate,
    civLitServedDateExplanation,
    civLitServedDateRadio,
    comments,
    custComplaintCourtLocation,
    custComplaintCourtName,
    custComplaintDateReceived,
    custComplaintDateReceivedExplanation,
    custComplaintDateReceivedRadio,
    custComplaintDocketOrCase,
    custComplaintFilingDate,
    custComplaintIsArbitrationCivilLitigation,
    custComplaintIsComplaintPending,
    custComplaintIsOralComplaint,
    custComplaintIsWrittenComplaint,
    custComplaintStatusDate,
    custComplaintStatusDateExplanation,
    custComplaintStatusDateRadio,
    custComplaintStatusOption,
    custComplaintMonetaryComp,
    custComplaintContribAmount,
    drpSelectedCheckList,
    selectedProdValues,
    statusDetailsAllegedDamageAmountRadio,
    statusDetailsCustomerAllegations,
    statusDetailsCustomerDamageAmount,
    statusDetailsCustomerEmployingFirm,
    statusDetailsCustomerExplanation,
    statusDetailsCustomerNames,
    statusDetailsCustomerOtherStates,
    statusDetailsCustomerState,
    statusDetailsMatter,
    statusDetailsNamedPartyRadio,
    statusDetailsOtherProdText,
  },
}) => {
  return (
    <div id="ReviewCustComplaintDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">
          {drpSelectedCheckList
            .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
            .join(", ")}
        </div>
      </div>
      <div id="statusDetailsSection">
        <DRPSectionDivider section={"STATUS DETAILS"} />
        <div className="row">
          <div className="col-6">
            <strong>What type of matter are you reporting? *</strong>
          </div>
          <div className="col-6">{statusDetailsMatter}</div>
        </div>
        {statusDetailsMatter !== "Customer Complaint" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Are you named party/respondent? *</strong>
              </div>
              <div className="col-6">{statusDetailsNamedPartyRadio}</div>
            </div>
          </>
        )}

        <br />
        <div className="row">
          <div className="col-6">
            <strong>Customer Name(s) *</strong>
          </div>
          <div className="col-6">{statusDetailsCustomerNames}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Customer(s) state of residence (choose "not on list" if foreign
              address) *
            </strong>
          </div>
          <div className="col-6">{statusDetailsCustomerState}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Other state(s) of residence/detail (optional)</strong>
          </div>
          <div className="col-6">
            {isNotNullUndefinedOrEmpty(statusDetailsCustomerOtherStates)
              ? statusDetailsCustomerOtherStates
              : "-"}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Employing Firm when activities occurred which led to the customer
              complaint, arbitration, CFTC reparation or civil litigation *
            </strong>
          </div>
          <div className="col-6">{statusDetailsCustomerEmployingFirm}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Allegation(s) and a brief summary of events related to the
              allegation(s) including dates when activities leading to the
              allegation(s) occurred *
            </strong>
          </div>
          <div className="col-6">{statusDetailsCustomerAllegations}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Product Type(s) (Select all that apply): *</strong>
          </div>
          <div className="col-6">
            {selectedProdValues.map((product, key) => (
              <React.Fragment key={key}>
                <span>{`${
                  typeof product === "object" ? product?.value : product
                }${
                  product === "Other" ? `: ${statusDetailsOtherProdText}` : ""
                }`}</span>
                {key !== selectedProdValues.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Alleged Compensatory Damage Amount *</strong>
          </div>
          <div className="col-6">
            ${statusDetailsCustomerDamageAmount}
            <br />
            {`${statusDetailsAllegedDamageAmountRadio}${
              statusDetailsAllegedDamageAmountRadio === "Explanation"
                ? `: ${statusDetailsCustomerExplanation}`
                : ""
            }`}
          </div>
        </div>
      </div>
      <div id="customerComplaintSection">
        <DRPSectionDivider section={"CUSTOMER COMPLAINT"} />
        <div className="row">
          <div className="col-6">
            <strong>Is this an oral complaint? *</strong>
          </div>
          <div className="col-6">{custComplaintIsOralComplaint}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Is this a written complaint? *</strong>
          </div>
          <div className="col-6">{custComplaintIsWrittenComplaint}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Is this an arbitration/CFTC reparation or civil litigation? *
            </strong>
          </div>
          <div className="col-6">
            {custComplaintIsArbitrationCivilLitigation}
          </div>
        </div>
        {custComplaintIsArbitrationCivilLitigation === "Yes" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Arbitration/reparation forum or court name: *</strong>
              </div>
              <div className="col-6">{custComplaintCourtName}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  Location of court (City or County and State or Country) *
                </strong>
              </div>
              <div className="col-6">{custComplaintCourtLocation}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Docket/Case #: *</strong>
              </div>
              <div className="col-6">{custComplaintDocketOrCase}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  Filing date of arbitration/CFTC reparation or civil
                  litigation: *
                </strong>
              </div>
              <div className="col-6">{custComplaintFilingDate}</div>
            </div>
          </>
        )}
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Date received by/served on firm:*</strong>
          </div>
          <div className="col-6">
            {custComplaintDateReceived}
            <br />
            {`${custComplaintDateReceivedRadio}${
              custComplaintDateReceivedRadio === "Explanation"
                ? `: ${custComplaintDateReceivedExplanation}`
                : " date"
            }`}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Is the complaint, arbitration/CFTC reparation or civil litigation
              pending? *
            </strong>
          </div>
          <div className="col-6">{custComplaintIsComplaintPending}</div>
        </div>
        {custComplaintIsComplaintPending === "No" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  If the complaint, arbitration/CFTC reparation or civil
                  litigation is not pending, provide status: *
                </strong>
              </div>
              <div className="col-6">{custComplaintStatusOption}</div>
            </div>
          </>
        )}
        {(custComplaintStatusOption === "Settled" ||
          custComplaintStatusOption ===
            "Arbitration Award/ Monetary Judgement (for claimants/plaintiffs)" ||
          custComplaintStatusOption ===
            "Arbitration Award/ Monetary Judgement (for respondents/defendants)") && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Settlement/Award/Monetary Judgment award *</strong>
              </div>
              <div className="col-6">${custComplaintMonetaryComp}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Your Contribution Amount *</strong>
              </div>
              <div className="col-6">${custComplaintContribAmount}</div>
            </div>
          </>
        )}

        <br />
        <div className="row">
          <div className="col-6">
            <strong>Status Date: *</strong>
          </div>
          <div className="col-6">
            {custComplaintStatusDate}
            <br />
            {`${custComplaintStatusDateRadio}${
              custComplaintStatusDateRadio === "Explanation"
                ? `: ${custComplaintStatusDateExplanation}`
                : " date"
            }`}
          </div>
        </div>
      </div>
      {statusDetailsMatter.includes("Arbitration/CFTC") &&
        statusDetailsNamedPartyRadio === "Yes" && (
          <div id="arbitrationCFTCSection">
            <DRPSectionDivider section={"ARBITRATION/CFTC"} />
            <div className="row">
              <div className="col-6">
                <strong>
                  Arbitration/CFTC reparation claim filed with (FINRA, AAA,
                  CFTC, etc) *
                </strong>
              </div>
              <div className="col-6">{arbReparationClaim}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Docket/Case #: *</strong>
              </div>
              <div className="col-6">{arbDocketOrCase}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Date notice/process was served: *</strong>
              </div>
              <div className="col-6">
                {arbNoticeServedDate}
                <br />
                {`${arbNoticeServedDateRadio}${
                  arbNoticeServedDateRadio === "Explanation"
                    ? `: ${arbNoticeServedDateExplanation}`
                    : " date"
                }`}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Is the arbitration/CFTC reparation pending? *</strong>
              </div>
              <div className="col-6">{arbIsPending}</div>
            </div>
            {arbIsPending === "No" && (
              <>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>
                      If the arbitration/CFTC reparation is not pending, what
                      was the disposition? *
                    </strong>
                  </div>
                  <div className="col-6">
                    {arbStatusOption}
                    {arbStatusOption === "Other"
                      ? `: ${arbStatusOptionExplanation}`
                      : ""}
                  </div>
                </div>
                <br />
              </>
            )}

            {(arbStatusOption === "Settled" ||
              arbStatusOption === "Award to Applicant (Agent/Representative)" ||
              arbStatusOption === "Award to Customer") && (
              <>
                <div className="row">
                  <div className="col-6">
                    <strong>
                      Monetary Compensation Details (award settlement,
                      reparation amount) *
                    </strong>
                  </div>
                  <div className="col-6">${arbMonetaryComp}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Your Contribution Amount *</strong>
                  </div>
                  <div className="col-6">${arbContribAmount}</div>
                </div>
              </>
            )}
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Disposition Date: *</strong>
              </div>
              <div className="col-6">
                {arbDispositionDate}
                <br />
                {`${arbDispositionDateRadio}${
                  arbDispositionDateRadio === "Explanation"
                    ? `: ${arbDispositionDateExplanation}`
                    : " date"
                }`}
              </div>
            </div>
          </div>
        )}
      {statusDetailsMatter === "Civil Litigation" &&
        statusDetailsNamedPartyRadio === "Yes" && (
          <div id="civilLitigationSection">
            <DRPSectionDivider section={"CIVIL LITIGATION"} />
            <div className="row">
              <div className="col-6">
                <strong>Court in which case was filed: *</strong>
              </div>
              <div className="col-6">
                {civLitFiledCourtType}
                {civLitFiledCourtType === "Other"
                  ? `: ${civLitFiledCourtTypeOther}`
                  : ""}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Name of court: *</strong>
              </div>
              <div className="col-6">{civLitFiledCourtName}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  Location of court (City or County and State or Country): *
                </strong>
              </div>
              <div className="col-6">{civLitFiledCourtLocation}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Docket/Case #: *</strong>
              </div>
              <div className="col-6">{civLitFiledDocketOrCase}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Date received by/served on firm: *</strong>
              </div>
              <div className="col-6">
                {civLitServedDate}
                <br />
                {`${civLitServedDateRadio}${
                  civLitServedDateRadio === "Explanation"
                    ? `: ${civLitServedDateExplanation}`
                    : " date"
                }`}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Is the civil litigation pending? *</strong>
              </div>
              <div className="col-6">{civLitIsPending}</div>
            </div>
            {civLitIsPending === "No" && (
              <>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>
                      If the civil litigation is not pending, what was the
                      disposition? *
                    </strong>
                  </div>
                  <div className="col-6">
                    {civLitDispositionOption}
                    {civLitDispositionOption === "Other"
                      ? `: ${civLitDispositionOptionOtherExplanation}`
                      : ""}
                  </div>
                </div>
                <br />
                {(civLitDispositionOption === "Settled" ||
                  civLitDispositionOption ===
                    "Monetary Judgement to Applicant (Agent/Representative)" ||
                  civLitDispositionOption ===
                    "Monetary Judgement to Customer") && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Monetary Compensation Details (award settlement,
                          reparation amount) *
                        </strong>
                      </div>
                      <div className="col-6">${civLitMonetaryComp}</div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Your Contribution Amount *</strong>
                      </div>
                      <div className="col-6">${civLitContribAmount}</div>
                    </div>
                    <br />
                  </>
                )}
              </>
            )}

            <br />
            <div className="row">
              <div className="col-6">
                <strong>Disposition Date: *</strong>
              </div>
              <div className="col-6">
                {civLitDispositionDate}
                <br />
                {`${civLitDispositionDateRadio}${
                  civLitDispositionDateRadio === "Explanation"
                    ? `: ${civLitDispositionDateExplanation}`
                    : " date"
                }`}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Is the action currently on appeal? *</strong>
              </div>
              <div className="col-6">{civLitIsOnAppeal}</div>
            </div>
            {civLitIsOnAppeal === "Yes" && (
              <>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Date appeal filed: *</strong>
                  </div>
                  <div className="col-6">
                    {civLitAppealFileDate}
                    <br />
                    {`${civLitAppealFileDateRadio}${
                      civLitAppealFileDateRadio === "Explanation"
                        ? `: ${civLitAppealFileDateExplanation}`
                        : " date"
                    }`}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Court appeal filed in: *</strong>
                  </div>
                  <div className="col-6">
                    {civLitAppealCourtType}
                    {civLitAppealCourtType === "Other"
                      ? `: ${civLitAppealFileCourtTypeOther}`
                      : ""}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Name of court: *</strong>
                  </div>
                  <div className="col-6">{civLitAppealCourtName}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>
                      Location of court (City of County and State or Country): *
                    </strong>
                  </div>
                  <div className="col-6">{civLitAppealCourtLocation}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Docket/Case #: *</strong>
                  </div>
                  <div className="col-6">{civLitAppealDocketOrCase}</div>
                </div>
              </>
            )}
          </div>
        )}
      <div id="commentsSection">
        <DRPSectionDivider section={"COMMENTS"} />
        <div className="row">
          <div className="col-6">
            <strong>
              You may use this field to provide a brief summary of the
              circumstances leading to the customer complaint, arbitration/CFTC
              reparation and/or civil litigation as well as the current status
              or final disposition(s).(Optional)
            </strong>
          </div>
          <div className="col-6">{comments === "" ? "-" : comments}</div>
        </div>
      </div>
    </div>
  );
};
export default ReviewCustComplaintDRP;

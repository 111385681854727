export const headerText = {
  MAIN_HEADER: "Customer Complaint/Arbitration/Civil Litigation Disclosure",
  SECTION_HEADER: "Customer Complaint Disclosure",
  CUST_COMP_ARB_CIV_LIT: "Customer Complaint/Arbitration/Civil Litigation",
};

export const CCB_FORM_ID = {
  CUST_COMP_DISCL: 1,
  DRP_SUMMARY: 2,
  ADD_DRP_STATUS_DETAILS: 3,
  ADD_DRP_CUSTOMER_COMPLAINT: 4,
  ADD_DRP_ARBITRATION_CFTC: 5,
  ADD_DRP_CIVIL_LITIGATION: 6,
  ADD_DRP_COMMENTS: 7,
};

const labels = {
  LABEL_ONE: "Status Details",
  LABEL_TWO: "Customer Complaint",
  LABEL_THREE: "Arbitration/CFTC",
  LABEL_FOUR: "Civil Litigation",
  LABEL_FIVE: "Comments",
};

const commonParts = { active: false, notApplicable: false, completed: false };

export const defTimelineSteps = [
  {
    id: 1,
    label: labels.LABEL_ONE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 5,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
];

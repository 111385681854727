import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

function InfoTooltip(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const text = props?.text;

  return (
    <>
      <Button
        variant="link"
        ref={target}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <span className="icon-info">
          <span className="sr-only" />
        </span>
      </Button>
      <Overlay
        target={target.current}
        show={show}
        placement={props?.placement ?? "right"}
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "#555555",
              padding: "2px 10px",
              maxWidth: "170px",
              fontSize: "14px",
              color: "white",
              zIndex: "9999",
              borderRadius: 3,
              ...props.style,
            }}
          >
            <span>{text}</span>
          </div>
        )}
      </Overlay>
    </>
  );
}

export default InfoTooltip;

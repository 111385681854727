import logo from "../../../../assets/img/MM_Onboarding_Logo.png";
import { Button } from "react-blueprint-library";

function ConfidentialAppHeader(props) {
  const handleExitClick = () => {
    window.close();
  };

  const handlePrintClick = () => {
    props.printFunction();
  };
  return (
    <>
      <div id="confidential-app-header">
        <div className="row">
          <div className="col-8">
            <h2>
              <img alt="MassMutual" src={logo} />
            </h2>
          </div>
          <div className="col-4">
            <div>
              <div>
                <Button
                  id="confidential_app_print_button"
                  onClick={handlePrintClick}
                >
                  Print
                </Button>
              </div>

              <span id="confidential_app_exit_button" onClick={handleExitClick}>
                <strong>Exit</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfidentialAppHeader;

export const headerText = {
  HEADER: "Financial Disclosure",
  PERS_BANKRUPTCY: "Personal Bankruptcy",
  ORG_BANKRUPTCY: "Organization Bankruptcy",
  BOND_DISCL: "Bond Disclosures",
  JUDG_LIEN_DISCL: "Judgment/Lien Disclosures",
};

export const subHeaderText = {
  PERS_BANKRUPTCY_ADD_DRP: "Personal Bankruptcy/SIPC/Compromise with Creditor",
  ORG_BANKRUPTCY: "Organization Bankruptcy",
  BOND_DISCL_ADD_DRP: "Bonding Payouts Or Revocations",
  JUDG_LIEN_ADD_DRP: "Judgment/Lien",
};

export const bankruptcyStepHeaderText = {
  ACTION_DETAILS: "Action Details",
  COURT_DETAILS: "Court Details",
  DISPOSITION_DETAILS: "Disposition Details",
  COMPROMISE_DETAILS: "Compromise Details",
  SIPA_DETAILS: "SIPA Details",
  ORG_DETAILS: "Organization Details",
  COMMENTS: "Comments",
};

export const bondStepHeaderText = {
  ORGANIZATION_INFORMATION: "Organization Information",
  DISPOSITION_DETAILS: "Disposition Details",
  COMMENTS: "Comments",
};

export const judgmentLienStepHeaderText = {
  JUDGMENT_LIEN_DETAILS: "Judgment/Lien Details",
  COURT_DETAILS: "Court Details",
  DISPOSITION_DETAILS: "Disposition Details",
  COMMENTS: "Comments",
};

export const CCB_FORM_ID = {
  FINANCIAL_DISCL: 1,
  DRP_SUMMARY: 2,
  ADD_DRP: 3,
  ADD_BOND_DRP_PAGE_1: 4,
  ADD_BOND_DRP_PAGE_2: 5,
  ADD_BOND_DRP_COMMENTS: 6,
  ADD_BANKRUPTCY_DRP_PAGE_1: 7,
  ADD_BANKRUPTCY_DRP_PAGE_2: 8,
  ADD_BANKRUPTCY_DRP_PAGE_3: 9,
  ADD_BANKRUPTCY_DRP_PAGE_4: 10,
  ADD_BANKRUPTCY_DRP_PAGE_5: 11,
  ADD_BANKRUPTCY_DRP_COMMENTS: 12,
  ADD_BANKRUPTCY_ORG: 13,
  ADD_JUDGMENT_LIEN_DRP_PAGE_1: 14,
  ADD_JUDGMENT_LIEN_DRP_PAGE_2: 15,
  ADD_JUDGMENT_LIEN_DRP_PAGE_3: 16,
  ADD_JUDGMENT_LIEN_DRP_COMMENTS: 17,
};

const labels = {
  LABEL_ONE: bankruptcyStepHeaderText.ORG_DETAILS,
  LABEL_TWO: bankruptcyStepHeaderText.ACTION_DETAILS,
  LABEL_THREE: bankruptcyStepHeaderText.COURT_DETAILS,
  LABEL_FOUR: bankruptcyStepHeaderText.DISPOSITION_DETAILS,
  LABEL_FIVE: bankruptcyStepHeaderText.COMPROMISE_DETAILS,
  LABEL_SIX: bankruptcyStepHeaderText.SIPA_DETAILS,
  LABEL_SEVEN: bankruptcyStepHeaderText.COMMENTS,
  LABEL_EIGHT: bondStepHeaderText.ORGANIZATION_INFORMATION,
  LABEL_NINE: judgmentLienStepHeaderText.JUDGMENT_LIEN_DETAILS,
};

const commonParts = { active: false, notApplicable: false, completed: false };

export const defTimelineSteps = [
  {
    id: 1,
    label: labels.LABEL_ONE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 5,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
  {
    id: 6,
    label: labels.LABEL_SIX,
    ...commonParts,
  },
  {
    id: 7,
    label: labels.LABEL_SEVEN,
    ...commonParts,
  },
];

export const defTimelineSteps2 = [
  {
    id: 1,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
  {
    id: 5,
    label: labels.LABEL_SIX,
    ...commonParts,
  },
  {
    id: 6,
    label: labels.LABEL_SEVEN,
    ...commonParts,
  },
];

export const defTimelineSteps3 = [
  {
    id: 1,
    label: labels.LABEL_EIGHT,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_SEVEN,
    ...commonParts,
  },
];

export const defTimelineSteps4 = [
  {
    id: 1,
    label: labels.LABEL_NINE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_SEVEN,
    ...commonParts,
  },
];

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button } from "react-blueprint-library";
import ReactInputMask from "react-input-mask";
import { dateValidator } from "../../../../../../../../common/Functions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import {
  dateOptions,
  matterResolvedOptions,
  yesNoOptions,
} from "../../constants";
import "./JudgmentLienDispositionForm.scss";

const JudgmentLienAddDRPDispositionDetailsForm = ({
  backFormId,
  nextFormId,
  updateCCBForm,
  updateTempDRP,
  tempJudgmentLienDRP: {
    judgmentLienDetails = {},
    courtDetails = {},
    dispositionDetails = {},
    comments = "",
  },
}) => {
  const [isJudgmentLienOutstanding, setIsJudgmentLienOutstanding] = useState(
    dispositionDetails?.isJudgmentLienOutstanding || ""
  );
  const [matterResolvedOption, setMatterResolvedOption] = useState(
    dispositionDetails?.matterResolvedOption || ""
  );
  const [startDate, setStartDate] = useState(
    dispositionDetails?.startDate || ""
  );
  const [startDateError, setStartDateError] = useState(false);
  const [startDateErrorMsg, setStartDateErrorMsg] = useState("");
  const [startDateOption, setStartDateOption] = useState(
    dispositionDetails?.startDateOption || ""
  );
  const [startDateExplanation, setStartDateExplanation] = useState(
    dispositionDetails?.startDateExplanation || ""
  );
  const [startDateExplanationError, setStartDateExplanationError] =
    useState(false);

  const [showBackModal, toggleShowBackModal] = useState(false);

  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    toggleShowBackModal(value);
  };
  const handleNext = () => {
    const tmpDRP = {
      judgmentLienDetails: {
        ...judgmentLienDetails,
      },
      courtDetails: {
        ...courtDetails,
      },
      dispositionDetails: {
        isJudgmentLienOutstanding,
        matterResolvedOption,
        startDate,
        startDateOption,
        startDateExplanation,
      },
      comments,
    };
    updateTempDRP(tmpDRP);
    updateCCBForm(nextFormId);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleIsOutstandingChange = ({ target: { value } }) => {
    if (value === yesNoOptions.YES) {
      setMatterResolvedOption("");
      setStartDate("");
      setStartDateError(false);
      setStartDateErrorMsg("");
      setStartDateOption("");
      setStartDateExplanation("");
      setStartDateExplanationError(false);
    }
    setIsJudgmentLienOutstanding(value);
  };
  const handleMatterChange = ({ target: { value } }) => {
    setMatterResolvedOption(value);
  };
  const handleStartDateOptionChange = ({ target: { value } }) => {
    setStartDateOption(value);
    if (value !== "Explanation") {
      setStartDateExplanation("");
      setStartDateExplanationError(false);
    }
  };
  const handleTextInputChange = ({ target: { id, value } }) => {
    switch (id) {
      case "startDate":
        return setStartDate(value);
      case "startDateExplanation":
        return setStartDateExplanation(value);
      default:
        break;
    }
  };
  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    switch (id) {
      case "startDateExplanation":
        return setStartDateExplanationError(value.length < 2);
      default:
        break;
    }
  };

  const onBlurDateInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const { isInvalid } = dateValidator(value);
    switch (id) {
      case "startDate":
        setStartDateError(isInvalid);
        setStartDateErrorMsg("Please enter the Start Date");
        break;
      default:
        break;
    }
  };
  const isError = startDateError || startDateExplanationError;

  const isUpdates =
    isJudgmentLienOutstanding?.length ||
    matterResolvedOption?.length ||
    startDate?.length ||
    startDateOption?.length ||
    startDateExplanation?.length ||
    isError;

  const isNextDisabled =
    isJudgmentLienOutstanding?.length < 1 ||
    (isJudgmentLienOutstanding === yesNoOptions.NO &&
      (matterResolvedOption?.length < 1 ||
        startDate?.length < 2 ||
        startDate.includes("_") ||
        dateValidator(startDate).isInvalid ||
        startDateOption?.length < 1 ||
        (startDateOption === dateOptions.EXPLANATION &&
          startDateExplanation?.length < 2))) ||
    isError ||
    isFocusedState;

  return (
    <div className="judgment-lien-disposition-details-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="judgmentLienTypeLabelId" className="field-label">
            Is Judgment/Lien outstanding? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="isJudgmentLienOutstandingYes"
                type="radio"
                id="isJudgmentLienOutstandingYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={isJudgmentLienOutstanding === yesNoOptions.YES}
                onChange={handleIsOutstandingChange}
              />
              <label
                id="isJudgmentLienOutstandingYesLabel"
                htmlFor="isJudgmentLienOutstandingYes"
                className="custom-control-label"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="isJudgmentLienOutstandingNo"
                type="radio"
                id="isJudgmentLienOutstandingNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={isJudgmentLienOutstanding === yesNoOptions.NO}
                onChange={handleIsOutstandingChange}
              />
              <label
                id="isJudgmentLienOutstandingNoLabel"
                htmlFor="isJudgmentLienOutstandingNo"
                className="custom-control-label"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {isJudgmentLienOutstanding === yesNoOptions.NO && (
        <>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <label id="ifNotOutstandingLabel" className="field-label">
                If not outstanding, provide details below:
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label id="matterResolvedLabel" className="field-label">
                How was the matter resolved? *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="matterResolvedDischarged"
                    type="radio"
                    id="matterResolvedDischarged"
                    className="custom-control-input"
                    value={matterResolvedOptions.DISCHARGED}
                    checked={
                      matterResolvedOption === matterResolvedOptions.DISCHARGED
                    }
                    onChange={handleMatterChange}
                  />
                  <label
                    id="matterResolvedDischargedLabel"
                    htmlFor="matterResolvedDischarged"
                    className="custom-control-label"
                  >
                    {matterResolvedOptions.DISCHARGED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="matterResolvedReleased"
                    type="radio"
                    id="matterResolvedReleased"
                    className="custom-control-input"
                    value={matterResolvedOptions.RELEASED}
                    checked={
                      matterResolvedOption === matterResolvedOptions.RELEASED
                    }
                    onChange={handleMatterChange}
                  />
                  <label
                    id="matterResolvedReleasedLabel"
                    htmlFor="matterResolvedReleased"
                    className="custom-control-label"
                  >
                    {matterResolvedOptions.RELEASED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="matterResolvedRemoved"
                    type="radio"
                    id="matterResolvedRemoved"
                    className="custom-control-input"
                    value={matterResolvedOptions.REMOVED}
                    checked={
                      matterResolvedOption === matterResolvedOptions.REMOVED
                    }
                    onChange={handleMatterChange}
                  />
                  <label
                    id="matterResolvedRemovedLabel"
                    htmlFor="matterResolvedRemoved"
                    className="custom-control-label"
                  >
                    {matterResolvedOptions.REMOVED}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="matterResolvedSatisfield"
                    type="radio"
                    id="matterResolvedSatisfield"
                    className="custom-control-input"
                    value={matterResolvedOptions.SATISFIED}
                    checked={
                      matterResolvedOption === matterResolvedOptions.SATISFIED
                    }
                    onChange={handleMatterChange}
                  />
                  <label
                    id="matterResolvedSatisfieldLabel"
                    htmlFor="matterResolvedSatisfield"
                    className="custom-control-label"
                  >
                    {matterResolvedOptions.SATISFIED}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row date-wrapper">
            <div className="col-md-12">
              <label
                id="startDateLabel"
                className="field-label"
                htmlFor="startDate"
              >
                Start Date: *
              </label>
              <ReactInputMask
                id="startDate"
                mask="99/99/9999"
                maskChar="_"
                className={
                  startDateError ? "form-control is-invalid" : "form-control"
                }
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                value={startDate}
                onChange={handleTextInputChange}
                onBlur={onBlurDateInput}
                onFocus={() => setFocusedState(true)}
              />
              <div id="startDateError" className="invalid-feedback">
                {startDateErrorMsg}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-8 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="startDateOptionExact"
                    type="radio"
                    id="startDateOptionExact"
                    className="custom-control-input"
                    value={dateOptions.EXACT}
                    checked={startDateOption === dateOptions.EXACT}
                    onChange={handleStartDateOptionChange}
                  />
                  <label
                    htmlFor="startDateOptionExact"
                    className="custom-control-label"
                    id="startDateOptionExactLabel"
                  >
                    {dateOptions.EXACT}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="startDateOptionExplanation"
                    type="radio"
                    id="startDateOptionExplanation"
                    className="custom-control-input"
                    value={dateOptions.EXPLANATION}
                    checked={startDateOption === dateOptions.EXPLANATION}
                    onChange={handleStartDateOptionChange}
                  />
                  <label
                    htmlFor="startDateOptionExplanation"
                    className="custom-control-label"
                    id="startDateOptionExplanationLabel"
                  >
                    {dateOptions.EXPLANATION}
                  </label>
                </div>
                {startDateOption === dateOptions.EXPLANATION ? (
                  <div className="start-date-explanation-container">
                    <label
                      htmlFor="startDateExplanation"
                      className="field-label"
                      id="startDateExplanationLabel"
                    >
                      If not exact, provide explanation *
                    </label>
                    <textarea
                      id="startDateExplanation"
                      className={
                        startDateExplanationError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      rows="3"
                      cols={500}
                      value={startDateExplanation}
                      onChange={handleTextInputChange}
                      onBlur={onBlurTextInput}
                      onFocus={() => setFocusedState(true)}
                    />
                    <div
                      id="dateLearnedExplanationError"
                      className="invalid-feedback"
                    >
                      Please enter an Explanation
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row backbutton-drp-judgment-lien">
        <div className="col-md-12 buttons">
          <span
            id="judgmentLienAddDRPBack"
            className="back-button"
            onClick={() => {
              isUpdates ? handleToggleBackModal(true) : handleBack();
            }}
          >
            <strong id="judgmentLienBack">Back</strong>
          </span>
          <Button
            disabled={isNextDisabled}
            id="judgmentLienAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};

export default JudgmentLienAddDRPDispositionDetailsForm;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "./BondDisclosureOrgInfoForm.scss";

const BondAddDRPOrganizationInformationForm = ({
  backFormId,
  nextFormId,
  updateCCBForm,
  updateTempDRP,
  tempBondDRP,
}) => {
  const dispatch = useDispatch();
  const [firmName, setFirmName] = useState(tempBondDRP?.firmName || "");
  const [firmNameError, setFirmNameError] = useState(false);
  const [bondingCompanyName, setBondingCompanyName] = useState(
    tempBondDRP?.bondingCompanyName || ""
  );
  const [bondingCompanyNameError, setBondingCompanyNameError] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    toggleShowBackModal(value);
  };
  const handleNext = () => {
    const tmpDRP = {
      ...tempBondDRP,
      firmName,
      bondingCompanyName,
    };
    updateTempDRP(tmpDRP);
    updateCCBForm(nextFormId);
  };
  const handleBack = () => {
    updateTempDRP({});
    updateCCBForm(backFormId);
  };

  const handleChange = ({ target: { id, value } }) => {
    switch (id) {
      case "firmName":
        setFirmName(value);
        break;
      case "companyName":
        setBondingCompanyName(value);
        break;
      default:
        break;
    }
  };

  const onBlur = ({ target: { id, value } }) => {
    setFocusedState(false);
    const isInvalid = value?.trim()?.length < 2 || value.trim()?.length > 200;
    switch (id) {
      case "firmName":
        setFirmNameError(isInvalid);
        break;
      case "companyName":
        setBondingCompanyNameError(isInvalid);
        break;
      default:
        break;
    }
  };
  const isError = firmNameError || bondingCompanyNameError;
  const isUpdates = firmName?.length || bondingCompanyName?.length || isError;
  const isNextDisabled =
    firmName.length < 1 ||
    bondingCompanyName.length < 1 ||
    isError ||
    isFocusedState;

  useEffect(() => {
    if (isUpdates) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    } else {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    }
  }, [isUpdates, dispatch]);

  return (
    <div className="bond-organization-information-form-wrapper">
      <div className="row">
        <div className="col-md-12 info-label" id="bondMultipleText">
          If multiple, unrelated events result in the same affirmative answer,
          details must be provided on separate DRPs.
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6">
          <label id="firmNameLabel" className="field-label" htmlFor="firmName">
            Firm Name (Policy Holder):*
          </label>
          <input
            id="firmName"
            type="text"
            className={
              firmNameError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
            value={firmName}
            maxLength={200}
            autoComplete="off"
          ></input>
          <div id="firmNameError" className="invalid-feedback">
            Please enter Firm Name (Policy Holder)
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6">
          <label
            id="companyNameLabel"
            className="field-label"
            htmlFor="companyName"
          >
            Bonding Company Name:*
          </label>
          <input
            id="companyName"
            type="text"
            className={
              bondingCompanyNameError
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
            value={bondingCompanyName}
            maxLength={200}
            autoComplete="off"
          ></input>
          <div id="companyNameError" className="invalid-feedback">
            Please enter Bonding Company Name
          </div>
        </div>
      </div>
      <div className="row backbutton-drp-bond">
        <div className="col-md-12 buttons">
          <span
            id="bondAddDRPBack"
            className="back-button"
            onClick={() => {
              isUpdates ? handleToggleBackModal(true) : handleBack();
            }}
          >
            <strong id="bondDisclBack">Back</strong>
          </span>
          <Button
            disabled={isNextDisabled}
            id="bondAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BondAddDRPOrganizationInformationForm;

import React, { useEffect } from "react";

import svgs, { svgType } from "../../../../../../assets/svgs";
import "./CustomTimeLine.scss";

const activeSteps = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
};

/** type StepsType = {
 * id: number,
 * label: string,
 * active: boolean, 
 * notApplicable: boolean, 
 * completed: boolean
} */

const CustomTimeLine = ({
  activeStep /* number */,
  steps /* StepsType */,
  notApplicable = [] /* Array<numbers> */,
  setCustomTimelineSteps,
  notPassed /* boolean */,
}) => {
  useEffect(() => {
    setCustomTimelineSteps((prevState) => {
      const newState = prevState.map((step) => {
        if (notApplicable?.includes(step.id)) {
          return {
            ...step,
            completed: notPassed ? false : true,
            active: false,
            notApplicable: true,
          };
        } else if (step?.id < activeStep) {
          return {
            ...step,
            completed: true,
            active: false,
            notApplicable: false,
          };
        } else if (step?.id === activeStep) {
          return {
            ...step,
            completed: false,
            active: true,
            notApplicable: false,
          };
        } else {
          return {
            ...step,
            completed: false,
            active: false,
            notApplicable: false,
          };
        }
      });

      if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
        return newState;
      }

      return prevState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, notApplicable, setCustomTimelineSteps]);

  const stepsRender = (s) => {
    let content = svgs[svgType.one];

    switch (s) {
      case activeSteps.ONE:
        content = svgs[svgType.one];
        break;
      case activeSteps.TWO:
        content = svgs[svgType.twoInactive];
        break;
      case activeSteps.THREE:
        content = svgs[svgType.threeInactive];
        break;
      case activeSteps.FOUR:
        content = svgs[svgType.fourInactive];
        break;
      case activeSteps.FIVE:
        content = svgs[svgType.fiveInactive];
        break;
      case activeSteps.SIX:
        content = svgs[svgType.sixInactive];
        break;
      case activeSteps.SEVEN:
        content = svgs[svgType.sevenInactive];
        break;
      case activeSteps.EIGHT:
        /** TODO: add/change the svg */
        content = svgs[svgType.timelineCheckmark];
        break;
      case activeSteps.NINE:
        /** TODO: add/change the svg */
        content = svgs[svgType.timelineCheckmark];
        break;
      case activeSteps.TEN:
        /** TODO: add/change the svg */
        content = svgs[svgType.timelineCheckmark];
        break;
      default:
        /** TODO: add/change the svg */
        content = svgs[svgType.timelineCheckmark];
        break;
    }

    return content;
  };

  return (
    <div className="ccb-custom-timeline">
      <div className="ccb-timeline">
        {steps.map(
          (
            {
              id,
              label = "",
              active = false,
              notApplicable = [],
              completed = false,
            },
            index
          ) => {
            const lastItem = steps?.length === index + 1;

            return (
              <div
                className={`ccb-timeline-item ${lastItem ? "last-item" : ""}`}
                key={`timeline-item-${id}-${label}`}
              >
                <div className="ccb-timeline-section-wrapper">
                  <div className="ccb-timeline-content">
                    <div
                      className={`ccb-timeline-item-icon ${
                        active ? "active" : ""
                      } ${notApplicable ? "not-applicable" : ""}`}
                    >
                      {completed
                        ? svgs[svgType.timelineCheckmark]
                        : stepsRender(id)}
                    </div>
                    <div className="ccb-timeline-item-content">
                      <div
                        className={`ccb-timeline-item-title ${
                          notApplicable ? "not-applicable" : ""
                        } ${active ? "active" : ""}`}
                      >
                        {label || "N/A"}
                      </div>
                      {notApplicable && (
                        <div className="ccb-timeline-item-description">
                          Not Applicable
                        </div>
                      )}
                    </div>
                  </div>

                  {!lastItem && (
                    <div
                      className={`line-separator ${
                        completed ? "completed" : ""
                      } ${notApplicable ? "not-applicable" : ""}`}
                    />
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default CustomTimeLine;

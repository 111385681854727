import React from "react";
import ReviewCorporationInfo from "./ReviewCorporationInfo";
import ReviewCorporationBusinessInformation from "./ReviewCorporationBusiness";
import ReviewCorporationCCB from "./ReviewCorporationCCB";
import ReviewCorporationEOC from "./ReviewCorporationEOC";
import ReviewCorporationDirectDeposit from "./ReviewCorporationDirectDeposit";

const BrokerCorporationSection = ({
  contracttypedesc,
  corporationData,
  corporationCCBData,
  corporationEOData,
  corporationDDData,
  brokerDealerInfo,
  subId,
  contracttypeid,
}) => {
  return (
    <>
      <hr />
      <div className="col-7 row">
        <h2 id="conf_broker_corporation" style={{ color: "#000000" }}>
          Broker Corporation
        </h2>
      </div>
      <ReviewCorporationInfo
        contracttype={contracttypedesc}
        corpInfo={corporationData}
        id="confidential_corpinfo"
        contracttypeid={contracttypeid}
        subId={subId}
      />
      <hr />
      <ReviewCorporationBusinessInformation
        id="conf_bussinfo"
        contracttype={contracttypedesc}
        busAddress={corporationData}
        subId={subId}
        contracttypeid={contracttypeid}
      />
      <hr />
      <ReviewCorporationCCB
        ccbInfo={corporationCCBData}
        contracttypeid={contracttypeid}
        subId={subId}
        id="conf_ccb"
      />
      <hr />
      <ReviewCorporationEOC
        eocInfo={corporationEOData}
        subId={subId}
        contracttypeid={contracttypeid}
        id="conf_eoc"
      />
      <hr />
      <ReviewCorporationDirectDeposit
        ddInfo={corporationDDData}
        brokerDealerInfo={brokerDealerInfo}
        isAgencyUser={false}
        isHomeOfficeUser={true}
        subId={subId}
        contractDesc={contracttypedesc}
        contracttypeid={contracttypeid}
        id="confidential_cdd"
      />
    </>
  );
};

export default BrokerCorporationSection;

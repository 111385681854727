import { isNotNullUndefinedOrEmpty } from "../../../../../../../common/Functions";
import { CCB_SUB_SECTION_TITLE } from "../../CCB";
import DRPSectionDivider from "../common/DRPSectionDivider";

const ReviewBankruptcyDRP = ({
  drp: {
    actionBankruptcyOption,
    actionDate,
    actionDateExplanation,
    actionOption,
    actionType,
    actionTypeOtherText,
    bankruptcyComments,
    compCreditorName,
    compOriginalAmountOwed,
    compTermsReachedText,
    courtAction,
    courtActionOther,
    courtDockCaseNumber,
    courtIsSSNBankIDCheck,
    courtLocation,
    courtName,
    dispositionDate,
    dispositionDateExplanation,
    dispositionOption,
    dispositionPendingType,
    dispositionType,
    dispositionTypeOtherText,
    drpList,
    drpType,
    orgInvestType,
    orgName,
    orgPrtName,
    sipaAmountPaid,
    sipaAppointedDate,
    sipaAppointedDateExplanation,
    sipaCurrentlyOpen,
    sipaDateOption,
    sipaTrusteeName,
  },
}) => {
  const isOrgBankruptcy =
    drpType === CCB_SUB_SECTION_TITLE.organizationalBankruptcy;
  return (
    <div id="ReviewBankruptcyDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">
          {isOrgBankruptcy
            ? drpList
                .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
                .join(", ")
            : "14K(1)"}
        </div>
      </div>
      {isOrgBankruptcy && (
        <div id="organizationDetails">
          <DRPSectionDivider section={"Organization Details"} />
          <div className="row">
            <div className="col-6">
              <strong>
                If charge(s) were brought against an organization over which you
                exercise(d) control:
              </strong>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Organization Name *</strong>
            </div>
            <div className="col-6">{orgName}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Investment related business? *</strong>
            </div>
            <div className="col-6">{orgInvestType}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Position, Title, or Relationship *</strong>
            </div>
            <div className="col-6">{orgPrtName}</div>
          </div>
        </div>
      )}
      <div id="actionDetails">
        <DRPSectionDivider section={"Action Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Action Type (select appropriate item) *</strong>
          </div>
          <div className="col-6">
            {actionType}
            {actionType === "Other" ? `: ${actionTypeOtherText}` : ""}
            {actionType === "Bankruptcy" ? `: ${actionBankruptcyOption}` : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Action Date (Provide date bankruptcy was filed, or date SIPC was
              initiated, or date of compromise with creditor) *
            </strong>
          </div>
          <div className="col-6">
            {actionDate}
            <br />
            {actionOption}
            {actionOption === "Explanation" ? `: ${actionDateExplanation}` : ""}
          </div>
        </div>
        <DRPSectionDivider section={"Court Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Court action brought in: *</strong>
          </div>
          <div className="col-6">
            {courtAction}
            {courtAction === "Other" ? `: ${courtActionOther}` : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Name of court: *</strong>
          </div>
          <div className="col-6">{courtName}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Location of Court (City or County{" "}
              <span style={{ textDecoration: "underline" }}>and</span> State or
              Country): *
            </strong>
          </div>
          <div className="col-6">{courtLocation}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Docket/Case #: *</strong>
          </div>
          <div className="col-6">{courtDockCaseNumber}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Check this box if the Docket/Case # is your SSN, a Bank Card
              number, or a Personal Identification Number.
            </strong>
          </div>
          <div className="col-6">{courtIsSSNBankIDCheck ? "Checked" : "-"}</div>
        </div>
      </div>
      <div id="dispositionDetails">
        <DRPSectionDivider section={"Disposition Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Is action currently pending?*</strong>
          </div>
          <div className="col-6">{dispositionPendingType}</div>
        </div>
        {dispositionPendingType === "No" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>If not pending, provide Disposition Type: *</strong>
              </div>
              <div className="col-6">
                {dispositionType}
                {dispositionType === "Other"
                  ? `: ${dispositionTypeOtherText}`
                  : ""}
              </div>
            </div>
          </>
        )}
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Disposition Date: *</strong>
          </div>
          <div className="col-6">
            {dispositionDate}
            <br />
            {dispositionOption}
            {dispositionOption === "Explanation"
              ? `: ${dispositionDateExplanation}`
              : ""}
          </div>
        </div>
      </div>
      {actionType === "Compromise" && (
        <div id="compromiseDetails">
          <DRPSectionDivider section={"Compromise Details"} />
          <div className="row">
            <div className="col-6">
              <strong>Name of Creditor: *</strong>
            </div>
            <div className="col-6">{compCreditorName}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Original Amount Owed: *</strong>
            </div>
            <div className="col-6">${compOriginalAmountOwed}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Terms/Compromise reached with creditor: *</strong>
            </div>
            <div className="col-6">{compTermsReachedText}</div>
          </div>
        </div>
      )}
      {dispositionType === "SIPA Trustee Appointed" && (
        <div id="SIPADetails">
          <DRPSectionDivider section={"SIPA Details"} />
          <div className="row">
            <div className="col-6">
              <strong>
                If a SIPA Trustee was appointed or a direct payment procedure
                was begun:
              </strong>
            </div>
            <div className="col-6">{}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Amount paid or agreed to be paid by you: *</strong>
            </div>
            <div className="col-6">
              {isNotNullUndefinedOrEmpty(sipaAmountPaid)
                ? "$" + sipaAmountPaid
                : "-"}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Or the name of the Trustee</strong>
            </div>
            <div className="col-6">
              {isNotNullUndefinedOrEmpty(sipaTrusteeName)
                ? sipaTrusteeName
                : "-"}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Currently Open? *</strong>
            </div>
            <div className="col-6">{sipaCurrentlyOpen}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>
                Date Direct Payment Initiated/Filed or Trustee Appointed: *
              </strong>
            </div>
            <div className="col-6">
              {sipaAppointedDate}
              <br />
              {sipaDateOption}
              {sipaDateOption === "Explanation"
                ? `: ${sipaAppointedDateExplanation}`
                : ""}
            </div>
          </div>
        </div>
      )}

      <div id="comments">
        <DRPSectionDivider section={"Comments"} />
        <div className="row">
          <div className="col-6">
            <strong>
              You may use this field to provide a brief summary of the
              circumstances leading to the action as well as the current status
              or final disposition. (Optional)
            </strong>
          </div>
          <div className="col-6">{bankruptcyComments || "-"}</div>
        </div>
      </div>
    </div>
  );
};
export default ReviewBankruptcyDRP;

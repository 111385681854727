import React, { useState, useEffect } from "react";
import Moment from "moment";

const ConfAppMMLIS1 = ({ contrib, hideReview }) => {
  const [state, setState] = useState({
    contribdate: "",
    contribamt: "",
    contribform: "",
    contribofficeheld: "",
    contribofficerunning: "",
    contribrecipient: "",
    contributionsmanageid: 0,
    electiontype: 0,
    entitledtovote: false,
    subdivision: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const result = contrib;
    const amt = result?.contribamt?.replace(".00", "");

    setState({
      contribdate: Moment.utc(result.contribdate).format("MM/DD/YYYY"),
      contribamt: amt,
      contribform: result.contribform,
      contribofficeheld: result.contribofficeheld,
      contribofficerunning: result.contribofficerunning,
      contribrecipient: result.contribrecipient,
      contributionsmanageid: result.contributionsmanageid,
      electiontype: result.electiontype,
      entitledtovote: result.entitledtovote,
      subdivision: result.subdivision,
    });
  }, [contrib]);

  return (
    <div id="reviewMMLISInfo" className="review-form">
      <div className="candidate-info">
        <div className="row">
          <div className="col-5">
            <strong id="mmlisDateLabel">Date of Contribution*</strong>
          </div>
          <div id="mmlisDate" className="col-6 bo--text-color">
            {state.contribdate !== "" ? state.contribdate : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisAmountLabel">Amount*</strong>
          </div>
          <div id="mmlisAmount" className="col-6 bo--text-color">
            ${state.contribamt !== "" ? state.contribamt : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisFormLabel">
              Form of contribution if other than cash or check
            </strong>
          </div>
          <div id="mmlisForm" className="col-6 bo--text-color">
            {state.contribform !== "" ? state.contribform : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisRecipientLabel">
              Recipient of the contribution (candidate, officeholder) *
            </strong>
          </div>
          <div id="mmlisRecipient" className="col-6 bo--text-color">
            {state.contribrecipient !== "" ? state.contribrecipient : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisOfficeLabel">
              Political Office held by Candidate at time of Contribution (if
              any)
            </strong>
          </div>
          <div id="mmlisOffice" className="col-6 bo--text-color">
            {state.contribofficeheld !== "" ? state.contribofficeheld : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisRunningLabel">
              Office (mayor, governor, etc) for which candidate was running *
            </strong>
          </div>
          <div id="mmlisRunning" className="col-6 bo--text-color">
            {state.contribofficerunning !== ""
              ? state.contribofficerunning
              : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisSubdivisionLabel">
              Political Subdivision (city, county, state, federal) *
            </strong>
          </div>
          <div id="mmlisSubdivision" className="col-6 bo--text-color">
            {state.subdivision !== "" ? state.subdivision : "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisElectionTypeLabel">Type of Election *</strong>
          </div>
          <div id="mmlisElectionType" className="col-6 bo--text-color">
            {state.electiontype === 1 ? "Primary" : "General"}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <strong id="mmlisEntitledLabel">
              Were you entitled to vote for the candidate at the time of
              contribution? *
            </strong>
          </div>
          <div id="mmlisEntitled" className="col-6 bo--text-color">
            {state.entitledtovote ? "Yes" : "No"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfAppMMLIS1;

import { Component } from "react";

import ReviewPSRMonths from "./ReviewPSRMonths";
import ReviewPSRFinancialPlans from "./ReviewPSRFinancialPlans";
import ReviewPSRCommissions from "./ReviewPSRCommissions";
import "../../../../assets/stylesheets/ConfidentialApplication/PSR.scss";

class ReviewPSR extends Component {
  constructor(props) {
    super(props);

    this.buildFinancialPlanData = this.buildFinancialPlanData.bind(this);
    this.buildCommissionData = this.buildCommissionData.bind(this);
    this.BuildCommissionDetail = this.BuildCommissionDetail.bind(this);

    this.state = {
      opportunities: "",
      personalInfo: "",
      opportunitydesc: "",
      hasDataLoaded: false,
      ranOnce: false,
      psrData: [],
      psrChartData: [],
      psrNextEnabled: false,
      ProfessionalDesignationsData: "",
      hasNYRegs: false,
      formHasError: false,
      financial_plan_data: [],
      commission_data: [],
      total_fin_plans: 0,
      psr_data_length: 0,
      has_ind_life: false,
      has_ind_annuity: false,
      has_ind_disabilty: false,
      has_ind_long_term: false,
      has_gross_commissions: false,
      has_fee_based_sales: false,
    };
  }
  async componentDidMount() {
    await this.setState({ hasNYRegs: this.props.hasNYRegs });
    await this.buildFinancialPlanData();
    await this.buildCommissionData();
    await this.BuildCommissionDetail();
  }

  async buildFinancialPlanData() {
    var matchObj = [];
    var yearArray = [];
    var financialplanArray = [];

    var currentYear = new Date().getFullYear();
    yearArray.push(currentYear);
    for (var i = 1; i < 6; ++i) {
      yearArray.push(currentYear - i);
    }

    for (var j = 0; j < 6; ++j) {
      for (var x = 0; x < this.props.chartInfo.finPlans.length; ++x) {
        if (yearArray[j] === this.props.chartInfo.finPlans[x].year) {
          matchObj.push(this.props.chartInfo.finPlans[x]);
        }
      }
      if (matchObj.length > 0) {
        var finPlans = 0;
        var year = 0;
        for (var y = 0; y < matchObj.length; ++y) {
          year = matchObj[0].year;
          finPlans = finPlans + matchObj[y].finPlanNr;
        }
        financialplanArray.push([year, finPlans]);
      } else {
        financialplanArray.push([yearArray[j], 0]);
      }
      matchObj = [];
    }
    await this.setState({ financial_plan_data: financialplanArray });
  }

  async buildCommissionData() {
    var matchObj = [];
    var yearArray = [];
    let emptyArray = [];
    var commissionArray = [];

    var currentYear = new Date().getFullYear();
    yearArray.push(currentYear);
    for (var i = 1; i < 6; ++i) {
      yearArray.push(currentYear - i);
    }

    for (var j = 0; j < 6; ++j) {
      for (var x = 0; x < this.props.chartInfo.comissions.length; ++x) {
        if (yearArray[j] === this.props.chartInfo.comissions[x].year) {
          matchObj.push(this.props.chartInfo.comissions[x]);
          break;
        }
      }
      if (matchObj.length > 0) {
        commissionArray.push(matchObj);
      } else {
        emptyArray = [
          {
            year: yearArray[j],
            indlifirstyear: "0",
            indlirenewcom: "0",
            indannfirstyear: "0",
            indannrenewcom: "0",
            inddisincfirstyear: "0",
            inddisincrenewcom: "0",
            indlongtermfirstyear: "0.00",
            indlongtermrenewcom: "0.00",
            feebasedsalesrelfees: "0.00",
            totalPerYear: "0.00",
          },
        ];
        commissionArray.push(emptyArray);
      }
      matchObj = [];
    }
    await this.setState({ commission_data: commissionArray });
  }

  async BuildCommissionDetail() {
    this.setState({
      has_ind_life: false,
      has_ind_annuity: false,
      has_ind_disabilty: false,
      has_ind_long_term: false,
      has_gross_commissions: false,
      has_fee_based_sales: false,
    });
    // individual life
    let indLifeTotal = 0;
    for (var i = 0; i < this.state.commission_data.length; ++i) {
      indLifeTotal =
        indLifeTotal +
        parseFloat(this.state.commission_data[i][0].indlifirstyear);

      indLifeTotal =
        indLifeTotal +
        parseFloat(this.state.commission_data[i][0].indlirenewcom);
      //console.log(indLifeTotal);
      if (indLifeTotal > 0) {
        await this.setState({ has_ind_life: true });
        break;
      }
    }
    // individual annuity
    let indAnnuityTotal = 0;
    for (var j = 0; j < this.state.commission_data.length; ++j) {
      indAnnuityTotal =
        indAnnuityTotal +
        parseFloat(this.state.commission_data[j][0].indannfirstyear);

      indAnnuityTotal =
        indAnnuityTotal +
        parseFloat(this.state.commission_data[j][0].indannrenewcom);

      if (indAnnuityTotal > 0) {
        await this.setState({ has_ind_annuity: true });
        break;
      }
    }
    // individual disaability
    let indDisabilityTotal = 0;
    for (var k = 0; k < this.state.commission_data.length; ++k) {
      indDisabilityTotal =
        indDisabilityTotal +
        parseFloat(this.state.commission_data[k][0].inddisincfirstyear);

      indDisabilityTotal =
        indDisabilityTotal +
        parseFloat(this.state.commission_data[k][0].inddisincrenewcom);

      if (indDisabilityTotal > 0) {
        await this.setState({ has_ind_disabilty: true });
        break;
      }
    }
    // individual long term care
    let indLongTermTotal = 0;
    for (var l = 0; l < this.state.commission_data.length; ++l) {
      indLongTermTotal =
        indLongTermTotal +
        parseFloat(this.state.commission_data[l][0].indlongtermfirstyear);

      indLongTermTotal =
        indLongTermTotal +
        parseFloat(this.state.commission_data[l][0].indlongtermrenewcom);

      if (indLongTermTotal > 0) {
        await this.setState({ has_ind_long_term: true });
        break;
      }
    }
    // investment product gross dealer commissions
    let invProdGross = 0;
    for (var m = 0; m < this.state.commission_data.length; ++m) {
      if (
        this.state.commission_data[m][0].invprodgrossdealercom !== null &&
        typeof this.state.commission_data[m][0].invprodgrossdealercom !==
          "undefined"
      ) {
        invProdGross =
          invProdGross +
          parseFloat(this.state.commission_data[m][0].invprodgrossdealercom);
      }

      if (invProdGross > 0) {
        await this.setState({ has_gross_commissions: true });
        break;
      }
    }
    // fee based sales
    let feeBasedSales = 0;
    for (var n = 0; n < this.state.commission_data.length; ++n) {
      if (
        this.state.commission_data[n][0].feebasedsalesrelfees !== null &&
        typeof this.state.commission_data[n][0].feebasedsalesrelfees !==
          "undefined"
      ) {
        feeBasedSales =
          feeBasedSales +
          parseFloat(this.state.commission_data[n][0].feebasedsalesrelfees);
      }
      if (feeBasedSales > 0) {
        await this.setState({ has_fee_based_sales: true });
        break;
      }
    }
  }

  render() {
    return (
      <div id="reviewPersonalInfo" className="review-form">
        <div className="col-7 row">
          <h3 id="conf_psr">{this.props.heading}</h3>
        </div>

        <div className="candidate-info">
          {this.props.chartInfo.payload.length > 0 && (
            <ReviewPSRMonths
              data={this.props.chartInfo}
              id={this.props.id}
            ></ReviewPSRMonths>
          )}

          {this.props.chartInfo.payload.length > 0 &&
            this.props.chartInfo.payload[0].isFinPlanning && (
              <ReviewPSRFinancialPlans
                data={this.props.chartInfo}
                financialData={this.state.financial_plan_data}
                id={this.props.id}
              ></ReviewPSRFinancialPlans>
            )}

          {this.props.chartInfo.payload.length > 0 && (
            <ReviewPSRCommissions
              data={this.props.chartInfo}
              comData={this.state.commission_data}
              hasNYRegs={this.state.hasNYRegs}
              has_ind_life={this.state.has_ind_life}
              has_ind_annuity={this.state.has_ind_annuity}
              has_ind_disabilty={this.state.has_ind_disabilty}
              has_ind_long_term={this.state.has_ind_long_term}
              has_gross_commissions={this.state.has_gross_commissions}
              has_fee_based_sales={this.state.has_fee_based_sales}
              id={this.props.id}
            ></ReviewPSRCommissions>
          )}
        </div>
      </div>
    );
  }
}

export default ReviewPSR;

import React from "react";
import Moment from "moment";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../constants/Contract";

const ConfEducation = ({
  educationData,
  professionalDesignations,
  contracttype,
}) => {
  const formatDate = (date) => {
    return date ? Moment(date, "MM/YYYY").format("MM/YYYY") : "-";
  };
  return (
    <div id="reviewEducation" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_education">Education</h3>
      </div>
      <div className="candidate-info">
        {contracttype === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ? (
          <div className="candidate-info">
            <div className="row">
              <div className="col-5">
                <strong id="eduEnrolledLabel">
                  Are you currently enrolled full time in college?*
                </strong>
              </div>
              <div id="eduEnrolled" className="col-6 bo--text-color">
                {educationData.ieh_fulltimeenrolled ? "Yes" : "No"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduAcademicYearLabel">
                  What academic year are you currently enrolled in?*
                </strong>
              </div>
              <div id="eduAcademicYear" className="col-6 bo--text-color">
                {educationData.ieh_currentacademicyearid === 1 && "Junior"}
                {educationData.ieh_currentacademicyearid === 2 && "Senior"}
                {educationData.ieh_currentacademicyearid === 3 &&
                  "Graduate Program"}
              </div>
            </div>

            <div className="row">
              <div className="col-5">
                <strong id="eduCollegeNameLabel">College Name*</strong>
              </div>
              <div id="eduCollegeName" className="col-6 bo--text-color">
                {educationData.ieh_institutionname}
              </div>
            </div>

            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">City*</strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.ieh_city}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">State/Province*</strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.ieh_state}{" "}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduDateEnrolledLabel">
                  Which date did you enroll?*
                </strong>
              </div>
              <div id="eduDateEnrolled" className="col-6 bo--text-color">
                {educationData.ieh_enrolldate}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduDateGraduateLabel">
                  Which date did you expect to graduate?*
                </strong>
              </div>
              <div id="eduDateGraduate" className="col-6 bo--text-color">
                {educationData.ieh_expectgraddate}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduDegreeSeekingLabel">
                  Which degree are you seeking?*
                </strong>
              </div>
              <div id="eduDegreeSeeking" className="col-6 bo--text-color">
                {educationData.ieh_degreeseeking}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduMajorLabel">What is your major?*</strong>
              </div>
              <div id="eduMajor" className="col-6 bo--text-color">
                {educationData.ieh_major}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduCurrentGPALabel">Current GPA Overall*</strong>
              </div>
              <div id="eduCurrentGPA" className="col-6 bo--text-color">
                {educationData.ieh_currentgpaoverall}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduCurrentGPAMajorLabel">
                  Current GPA in Major*
                </strong>
              </div>
              <div id="eduCurrentGPAMajor" className="col-6 bo--text-color">
                {educationData.ieh_currentgpamajor}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduProfDesignationsLabel">
                  Do you hold any professional certifications, trade
                  certifications, or professional designations?*
                </strong>
              </div>
              <div id="eduProdDesignations" className="col-6 bo--text-color">
                {professionalDesignations.hasprofdesignation ? "Yes" : "No"}
              </div>
            </div>
            {professionalDesignations.length > 0 && (
              <>
                <br />
                <div id="professionalDesignations">
                  <div className="row">
                    <div className="col-5">
                      <strong id="professionalDesignationsLabel">
                        Professional Designations:
                      </strong>
                    </div>
                    <div className="col-5">
                      <strong id="dateAttainedLabel">Date attained:</strong>
                    </div>
                  </div>
                  {professionalDesignations.map((designation, key) => (
                    <div className="row" key={key}>
                      <div
                        id={`designation-${key}-label`}
                        className="col-5 bo--text-color"
                      >
                        {`${
                          designation.profdesignationid === 14 ? "Other - " : ""
                        }${designation.designationdescription}`}
                      </div>
                      <div
                        id={`designation-${key}`}
                        className="col-6 bo--text-color"
                      >
                        {Moment(designation.dateacquired).format("MM/DD/YYYY")}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">
                  What was your highest level of education you received?*
                </strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.educationleveldesc || "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">
                  Which Degree did you receive?*
                </strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.educationdegreedesc || "-"}
              </div>
            </div>
            {educationData.educationlevelid > 2 && (
              <div className="row">
                <div className="col-5">
                  <strong id="eduHighestLevelLabel">
                    What did you major in?*
                  </strong>
                </div>
                <div id="eduHighestLevel" className="col-6 bo--text-color">
                  {educationData.major || "-"}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">Institution Name*</strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.institutionname || "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">
                  Which dates did you attend?*
                </strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.startdate && educationData.enddate
                  ? `${formatDate(educationData.startdate)} - ${formatDate(
                      educationData.enddate
                    )}`
                  : "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">City*</strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.city || "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">
                  State/Province
                  {educationData.country === "US" ? "*" : ""}
                </strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.state || "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">Country*</strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {educationData.country || "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <strong id="eduHighestLevelLabel">
                  Do you hold any professional certifications, trade
                  certifications, or professional designations?*
                </strong>
              </div>
              <div id="eduHighestLevel" className="col-6 bo--text-color">
                {professionalDesignations?.professionalDesignations?.length > 0
                  ? "Yes"
                  : "No"}
              </div>
            </div>
          </>
        )}

        {professionalDesignations.hasprofdesignation && (
          <>
            <br />
            <div id="professionalDesignations">
              <div className="row">
                <div className="col-5">
                  <strong id="professionalDesignationsLabel">
                    Professional Designations:
                  </strong>
                </div>
                <div className="col-5">
                  <strong id="dateAttainedLabel">Date attained:</strong>
                </div>
              </div>
              {professionalDesignations?.professionalDesignations?.map(
                (designation, key) => (
                  <div className="row" key={key}>
                    <div
                      id={`designation-${key}-label`}
                      className="col-5 bo--text-color"
                    >
                      {`${
                        designation.profdesignationid === 14 ? "Other - " : ""
                      }${designation.designationdescription}`}
                    </div>
                    <div
                      id={`designation-${key}`}
                      className="col-6 bo--text-color"
                    >
                      {Moment(designation.dateacquired).format("MM/DD/YYYY")}
                    </div>
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfEducation;

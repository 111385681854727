import iconCheckCircle from "./iconCheckCircle";
import iconCaret from "./iconCaret";
import iconAddDRP from "./iconAddDRP";
import danger from "./danger";
import close from "./close";
import {
  one,
  twoInactive,
  threeInactive,
  fourInactive,
  fiveInactive,
  sixInactive,
  sevenInactive,
  timelineCheckmark,
} from "./timelineNumbers";

export const svgType = {
  iconCheckCircle: "iconCheckCircle",
  iconCaret: "iconCaret",
  iconAddDRP: "iconAddDRP",
  danger: "danger",
  close: "close",
  one: "one",
  twoInactive: "twoInactive",
  threeInactive: "threeInactive",
  fourInactive: "fourInactive",
  fiveInactive: "fiveInactive",
  sixInactive: "sixInactive",
  sevenInactive: "sevenInactive",
  timelineCheckmark: "timelineCheckmark",
};

const svgs = {
  iconCheckCircle,
  iconCaret,
  iconAddDRP,
  danger,
  close,
  one,
  twoInactive,
  threeInactive,
  fourInactive,
  fiveInactive,
  sixInactive,
  sevenInactive,
  timelineCheckmark,
};

export default svgs;

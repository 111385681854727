import React from "react";
import { Button, Modal } from "react-blueprint-library";
import InputMask from "react-input-mask";
import Moment from "moment";
import "../StartApplication.scss";
import {
  insertMMLISManagedContribution,
  updateMMLISManagedContribution,
} from "../../../api/makeAPIRequest";
import {
  isValidDate,
  onlyNumeric,
  pasteCommaDelimited,
  createDateObject,
  formatMoney,
  pasteValue,
} from "../../../common/Functions";
import BackButtonModal from "../Forms/BackButtonModal";

class MMLISAddContributionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.cancelClick = this.cancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.handleElectionTypeChange = this.handleElectionTypeChange.bind(this);
    this.handleEntitledVoteChange = this.handleEntitledVoteChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    this.loadData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.reviewEditData !== prevProps.reviewEditData ||
      this.props.isOpen !== prevProps.isOpen
    ) {
      this.loadData();
    }
  }

  async loadData() {
    if (this.props.reviewEditData !== "") {
      if (
        this.props.reviewEditData.contribution_date !==
          this.state.contribution_date ||
        this.props.reviewEditData.contribution_amount !==
          this.state.contribution_amount ||
        this.props.reviewEditData.contribution_recipient !==
          this.state.contribution_recipient ||
        this.props.reviewEditData.office_running !==
          this.state.office_running ||
        this.props.reviewEditData.political_subdivision !==
          this.state.political_subdivision
      ) {
        let contribution_date = Moment(this.props.reviewEditData.contribdate)
          .utc()
          .format("MM/DD/YYYY");

        var commaNumber = this.props.reviewEditData.contribamt.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        let election_type = "";
        if (this.props.reviewEditData.electiontype === 1) {
          election_type = "Primary";
        } else {
          election_type = "General";
        }

        let entitled_vote = "";
        if (this.props.reviewEditData.entitledtovote === true) {
          entitled_vote = "Y";
        } else {
          entitled_vote = "N";
        }

        await this.setState({
          contribution_id: this.props.reviewEditData.contributionsmanageid,
          contribution_date: contribution_date,
          contribution_amount: commaNumber.replace(".00", ""),
          contribution_form: this.props.reviewEditData.contribform,
          contribution_recipient: this.props.reviewEditData.contribrecipient,
          office_held: this.props.reviewEditData.contribofficeheld,
          office_running: this.props.reviewEditData.contribofficerunning,
          political_subdivision: this.props.reviewEditData.subdivision,
          election_type: election_type,
          entitled_vote: entitled_vote,
        });
      }
    }
    this.validateFields();
  }

  async validateFields() {
    this.setState({ is_add_disabled: true });

    var contribution_date_error = document.getElementById(
      "contribution_date_error"
    );

    if (
      this.state.contribution_date !== "" &&
      (contribution_date_error.style.display === "none" ||
        contribution_date_error.style.display === "") &&
      this.state.contribution_amount !== "" &&
      this.state.contribution_recipient !== "" &&
      this.state.office_running !== "" &&
      this.state.political_subdivision !== "" &&
      this.state.election_type !== "" &&
      this.state.entitled_vote !== ""
    ) {
      this.setState({ is_add_disabled: false });
    }
  }

  onKeyDown() {
    this.setState({ form_has_changed: true });
  }

  initialState() {
    return {
      is_add_disabled: true,
      contribution_id: "",
      contribution_date: "",
      contribution_amount: "",
      contribution_form: "",
      contribution_recipient: "",
      office_held: "",
      office_running: "",
      political_subdivision: "",
      election_type: "",
      entitled_vote: "",
      formHasError: false,
      is_form_saved: false,
      review_edit: false,
      showModal: false,
      form_has_changed: false,
      showBackModal: false,
    };
  }

  async handleAdd(x) {
    // insert/update data
    var election_type;
    if (this.state.election_type === "Primary") {
      election_type = 1;
    } else {
      election_type = 2;
    }

    var entitled_vote;
    if (this.state.entitled_vote === "Y") {
      entitled_vote = true;
    } else {
      entitled_vote = false;
    }

    let data = {
      candidateid: this.props.candidateId,
      contributionsmanageid: this.state.contribution_id,
      contribdate: createDateObject(this.state.contribution_date),
      contribamt: this.state.contribution_amount.replaceAll(",", ""),
      contribform: this.state.contribution_form,
      contribrecipient: this.state.contribution_recipient,
      contribofficeheld: this.state.office_held,
      contribofficerunning: this.state.office_running,
      subdivision: this.state.political_subdivision,
      electiontype: election_type,
      entitledtovote: entitled_vote,
    };

    if (data.contributionsmanageid !== "") {
      await updateMMLISManagedContribution(this.props.subId, data);
    } else {
      await insertMMLISManagedContribution(this.props.subId, data);
    }

    this.props.showAlert();
    this.props.actionCancel();
    this.resetFields();
  }

  async cancelClick() {
    this.props.actionCancel(null);
    this.resetFields();
  }

  async handleChange(event) {
    //await this.setState({ form_has_changed: true });

    await this.setState({
      [event.target.id]: event.target.value,
    });

    if (event.target.id.startsWith("contribution_amount")) {
      await this.setState({
        [event.target.id]: formatMoney(event.target.value),
      });
    }

    //this.validateFields();
  }

  async resetFields() {
    await this.setState({
      is_add_disabled: true,
      contribution_id: "",
      contribution_date: "",
      contribution_amount: "",
      contribution_form: "",
      contribution_recipient: "",
      office_held: "",
      office_running: "",
      political_subdivision: "",
      election_type: "",
      entitled_vote: "",
      formHasError: false,
      is_form_saved: false,
      review_edit: false,
      showModal: false,
      form_has_changed: false,
      showBackModal: false,
    });
  }

  async onBlur(event) {
    var obj_error;
    if (event.target.id === "contribution_recipient") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 1) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "contribution_amount") {
      obj_error = document.getElementById(event.target.id + "_error");
      let obj_input_$ = document.getElementById(event.target.id + "_input_$");

      if (
        event.target.value.trim(" ").length === 0 ||
        parseInt(event.target.value) < 1
      ) {
        obj_error.style.display = "block";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderLeft = "0px";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        obj_input_$.removeAttribute("style");
      }
    } else if (event.target.id === "office_running") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 1) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.startsWith("contribution_date")) {
      var hasError;
      obj_error = document.getElementById("contribution_date_error");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter Date of Contribution";
        hasError = true;
      } else {
        var currDate = Moment();
        var dateParts = event.target.value.split("/");
        var month = dateParts[0];
        var day = dateParts[1];
        var year = dateParts[2];
        var dateCalc = Moment(year + "-" + month + "-" + day);
        var yearDiff = currDate.diff(dateCalc, "year");

        if (dateCalc > currDate) {
          obj_error.innerText = "Date of Contribution cannot be a future date";
          hasError = true;
        } else if (yearDiff >= 100) {
          obj_error.innerText =
            "Date of Contribution cannot be more than 100 years ago";
          hasError = true;
        }
      }
      if (hasError) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");
      }
    } else if (event.target.id === "political_subdivision") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 1) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    }
    this.validateFields();
  }

  async handleCloseModal(x) {
    if (x === "confirm") {
      await this.setState({ form_has_changed: true });
    }
    if (this.state.form_has_changed && x !== "confirm") {
      this.setState({ showBackModal: true });
    } else {
      this.setState({ showBackModal: false });
      if (x !== "confirm") {
        this.cancelClick();
      }
    }
    if (x === "close") {
      this.setState({ show_modal: false });
      this.setState({ showBackModal: false });
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ form_has_changed: false });
    this.handleCloseBackModal();
    this.resetFields();
    this.props.actionCancel(null);
  }

  async handleElectionTypeChange(event) {
    await this.setState({ election_type: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }

  async handleEntitledVoteChange(event) {
    await this.setState({ entitled_vote: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }

  render() {
    return (
      <>
        <div>
          <div id="addressModal"></div>

          <div>
            <Modal
              id="addContributionModal"
              show={this.props.isOpen}
              className="modal-md"
              title=""
            >
              <div className="row">
                <div className="col-md-10">
                  <h3 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                    Add Contribution Details
                  </h3>
                </div>
                <div className="col-md-2">
                  <h2 style={{ marginBottom: ".4rem", marginTop: "14px" }}>
                    <button
                      id="close_modal"
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close modal dialog"
                      onClick={this.handleCloseModal}
                    />
                  </h2>
                </div>
              </div>
              <div id="top_element">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <span className="required-field-text">
                        * = Required Field
                      </span>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-5">
                      <label
                        htmlFor="contribution_date"
                        id="contribution_date_label"
                      >
                        <strong>Date of Contribution *</strong>
                      </label>
                      <InputMask
                        id="contribution_date"
                        mask="99/99/9999"
                        maskChar="_"
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                        autoComplete="off"
                        value={this.state.contribution_date}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                      />
                    </div>
                    <div
                      id="contribution_date_error"
                      className="invalid-feedback"
                      style={{ marginLeft: "15px" }}
                    >
                      Please enter a Date of Contribution
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label
                        htmlFor="contribution_amount"
                        id="contribution_amount_label"
                      >
                        <strong>Amount *</strong>
                      </label>
                      <div
                        className="input-group"
                        id="contribution_amount_input"
                      >
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text input-group-text-pad-right"
                            id="contribution_amount_input_$"
                          >
                            $
                          </span>
                        </div>
                        <input
                          type="text"
                          id="contribution_amount"
                          className="form-control input-pad-left"
                          spellCheck="false"
                          maxLength={15}
                          value={this.state.contribution_amount}
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          onKeyDown={this.onKeyDown}
                          onKeyPress={onlyNumeric}
                          onPaste={pasteCommaDelimited}
                          //onPaste={pasteValue}
                          autoComplete="off"
                        />
                      </div>
                      <div
                        id="contribution_amount_error"
                        className="invalid-feedback"
                      >
                        Please enter Amount
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-md-12">
                      <label htmlFor="title" id="contribution_form_label">
                        <strong>
                          Form of contribution if other than cash or check
                        </strong>
                      </label>
                      <input
                        type="text"
                        id="contribution_form"
                        className="form-control"
                        spellCheck="false"
                        maxLength={100}
                        autoComplete="off"
                        value={this.state.contribution_form}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                      />
                      <div
                        id="contribution_form_error"
                        className="invalid-feedback"
                      >
                        Please enter a valid Contribution Form
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label
                        htmlFor="contribution_recipient"
                        id="contribution_recipient_label"
                      >
                        <strong>
                          Recipient of the contribution (candidate,
                          officeholder) *
                        </strong>
                      </label>
                      <input
                        type="text"
                        id="contribution_recipient"
                        className="form-control"
                        spellCheck="false"
                        maxLength={100}
                        value={this.state.contribution_recipient}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        autoComplete="off"
                      />
                      <div
                        id="contribution_recipient_error"
                        className="invalid-feedback"
                      >
                        Please enter Recipient of the contribution
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label htmlFor="office_held" id="office_held_label">
                        <strong>
                          Political Office held by Candidate at time of
                          Contribution (if any)
                        </strong>
                      </label>
                      <input
                        type="text"
                        id="office_held"
                        className="form-control"
                        spellCheck="false"
                        maxLength={100}
                        value={this.state.office_held}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        autoComplete="off"
                      />
                      <div id="office_held_error" className="invalid-feedback">
                        Please enter a valid Office Held
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label htmlFor="office_running" id="office_running_label">
                        <strong>
                          Office (mayor, governor, etc) for which candidate was
                          running *
                        </strong>
                      </label>
                      <input
                        type="text"
                        id="office_running"
                        className="form-control"
                        spellCheck="false"
                        maxLength={100}
                        value={this.state.office_running}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        autoComplete="off"
                      />
                      <div
                        id="office_running_error"
                        className="invalid-feedback"
                      >
                        Please enter Office for which candidate was running
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label
                        htmlFor="political_subdivision"
                        id="political_subdivision_label"
                      >
                        <strong>
                          Political Subdivision (city, county, state, federal) *
                        </strong>
                      </label>
                      <input
                        type="text"
                        id="political_subdivision"
                        className="form-control"
                        spellCheck="false"
                        maxLength={100}
                        value={this.state.political_subdivision}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        autoComplete="off"
                      />
                      <div
                        id="political_subdivision_error"
                        className="invalid-feedback"
                      >
                        Please enter Political Subdivision
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label
                        id="election_type_label"
                        style={{
                          paddingBottom: "5px",
                          paddingTop: "10px",
                        }}
                      >
                        <strong>Type of Election *</strong>
                      </label>
                      <div
                        className="custom-control custom-radio"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          id="election_type_primary"
                          name="election_type"
                          className="custom-control-input"
                          checked={this.state.election_type === "Primary"}
                          value="Primary"
                          onChange={this.handleElectionTypeChange}
                        />
                        <label
                          htmlFor="election_type_primary"
                          className="custom-control-label"
                          id="label_election_type_primary"
                        >
                          <strong>Primary</strong>
                        </label>
                      </div>
                      <div
                        className="custom-control custom-radio"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          id="election_type_general"
                          name="election_type"
                          className="custom-control-input"
                          checked={this.state.election_type === "General"}
                          value="General"
                          onChange={this.handleElectionTypeChange}
                        />
                        <label
                          htmlFor="election_type_general"
                          className="custom-control-label"
                          id="label_election_type_general"
                        >
                          <strong>General</strong>
                        </label>
                      </div>
                      <div
                        id="election_type_error"
                        className="invalid-feedback"
                      >
                        Please choose a response.
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <label
                        id="entitled_vote_label"
                        style={{
                          paddingBottom: "5px",
                        }}
                      >
                        <strong>
                          Were you entitled to vote for the candidate at the
                          time of contribution? *
                        </strong>
                      </label>
                      <div
                        className="custom-control custom-radio"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          id="entitled_vote_yes"
                          name="entitled_vote"
                          className="custom-control-input"
                          checked={this.state.entitled_vote === "Y"}
                          value="Y"
                          onChange={this.handleEntitledVoteChange}
                        />
                        <label
                          htmlFor="entitled_vote_yes"
                          className="custom-control-label"
                          id="label_entitled_vote_yes"
                        >
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div
                        className="custom-control custom-radio"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          id="entitled_vote_no"
                          name="entitled_vote"
                          className="custom-control-input"
                          checked={this.state.entitled_vote === "N"}
                          value="N"
                          onChange={this.handleEntitledVoteChange}
                        />
                        <label
                          htmlFor="entitled_vote_no"
                          className="custom-control-label"
                          id="label_entitled_vote_no"
                        >
                          <strong>No</strong>
                        </label>
                      </div>
                      <div
                        id="entitled_vote_error"
                        className="invalid-feedback"
                      >
                        Please choose a response.
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        display: "inline",
                        float: "right",
                        textAlign: "right",
                      }}
                    >
                      <button
                        id="mmlis_cancel_button"
                        type="button"
                        className="btn btn-link"
                        onClick={this.handleCloseModal}
                        style={{
                          textAlign: "left",
                          fontSize: "1.4rem",
                          padding: "4px 0px",
                        }}
                      >
                        Cancel
                      </button>
                      <Button
                        label="Add"
                        onClick={this.handleAdd}
                        id="add_button"
                        style={{
                          padding: "5px",
                          float: "none",
                          width: "150px",
                          fontSize: "1.3rem",
                          textTransform: "none",
                        }}
                        disabled={this.state.is_add_disabled}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default MMLISAddContributionModal;

import React, { Component } from "react";
import { Button } from "react-blueprint-library";
import "react-step-progress-bar/styles.css";
import {
  getBIGFICRADisclosure,
  getContract,
  getMMLISAddOnContract,
} from "../../../api/makeAPIRequest";
import { withOktaAuth } from "@okta/okta-react";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../constants/Contract";

class PDFForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate_endorsed_contract_url: "",
      background_disclosures_url: "",
      mmlis_add_on_url: "",
      oktaSubId: this.props.authState.accessToken.claims.uid,
      can_name: this.props.authState.idToken.claims.name.split(", "),
      showLoader: true,
    };
    this.setPDFs = this.setPDFs.bind(this);
    this.downloadAll = this.downloadAll.bind(this);
    this.openContract = this.openContract.bind(this);
    this.openDisclosure = this.openDisclosure.bind(this);
    this.openMMLISAddOnContract = this.openMMLISAddOnContract.bind(this);
    this.openConsumerNotificationRights =
      this.openConsumerNotificationRights.bind(this);
  }

  componentDidMount() {
    this.setPDFs();
  }

  async setPDFs() {
    const { contractid, mmlis } = this.props;
    const { oktaSubId } = this.state;
    let pdfs;

    pdfs = mmlis
      ? await Promise.all([
          getContract(oktaSubId, contractid),
          getBIGFICRADisclosure(oktaSubId, contractid),
          getMMLISAddOnContract(oktaSubId, contractid),
        ])
      : await Promise.all([
          getContract(oktaSubId, contractid),
          getBIGFICRADisclosure(oktaSubId, contractid),
        ]);

    try {
      const contractPDF = pdfs[0];
      if (typeof contractPDF !== "string" && contractPDF.byteLength > 10000) {
        const contractURL = URL.createObjectURL(
          new Blob([contractPDF], { type: "application/pdf" })
        );
        this.setState({ candidate_endorsed_contract_url: contractURL });
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const disclosurePDF = pdfs[1];
      if (
        typeof disclosurePDF !== "string" &&
        disclosurePDF.byteLength > 10000
      ) {
        const disclosureURL = URL.createObjectURL(
          new Blob([disclosurePDF], { type: "application/pdf" })
        );
        this.setState({ background_disclosures_url: disclosureURL });
      }
    } catch (error) {
      console.log(error);
    }
    if (mmlis) {
      try {
        const mmlisPDF = pdfs[2];
        if (typeof mmlisPDF !== "string" && mmlisPDF.byteLength > 10000) {
          const mmlisURL = URL.createObjectURL(
            new Blob([mmlisPDF], { type: "application/pdf" })
          );
          this.setState({ mmlis_add_on_url: mmlisURL });
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({ showLoader: false });
  }

  async openContract() {
    try {
      if (this.state.candidate_endorsed_contract_url !== "")
        window.open(this.state.candidate_endorsed_contract_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  }
  async openDisclosure() {
    try {
      if (this.state.background_disclosures_url !== "")
        window.open(this.state.background_disclosures_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  }
  async openMMLISAddOnContract() {
    try {
      if (this.state.mmlis_add_on_url !== "")
        window.open(this.state.mmlis_add_on_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  }
  async openConsumerNotificationRights() {
    try {
      window.open(
        window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL,
        "_blank"
      );
    } catch (e) {
      console.log(e);
    }
  }

  async downloadAll() {
    const { mmlis, contract } = this.props;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    var link = document.createElement("a");

    if (
      this.state.candidate_endorsed_contract_url !== "" &&
      contract !== CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
    ) {
      link.download = `${this.state.can_name[1]}_${this.state.can_name[0]}_${
        contract === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
          ? "Application"
          : "Endorsed_Contract"
      }.pdf`;
      link.href = this.state.candidate_endorsed_contract_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }

    if (this.state.background_disclosures_url !== "") {
      link.download = `${this.state.can_name[1]}_${this.state.can_name[0]}_Endorsed_BIG_FICRA_Disclosure.pdf`;
      link.href = this.state.background_disclosures_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }

    if (
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== null &&
      typeof window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !==
        "undefined" &&
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== ""
    ) {
      link.setAttribute("download", "");
      link.href = window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (mmlis) {
      //Add Delay to Safari because Safari does not allow multiple file download
      if (isSafari) {
        await delay(750);
      }

      if (this.state.mmlis_add_on_url !== "") {
        link.download = `${this.state.can_name[1]}_${this.state.can_name[0]}_Endorsed_MMLIS_CONTRACT.pdf`;
        link.href = this.state.mmlis_add_on_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  render() {
    const {
      showLoader,
      candidate_endorsed_contract_url,
      background_disclosures_url,
    } = this.state;
    const { applicationStatus, contract } = this.props;
    const ContractButtonLabel =
      applicationStatus === CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
      applicationStatus === CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED ||
      applicationStatus === CONTRACT_STATUS_DESC.MMLIS_Pending
        ? "Fully Endorsed Contract"
        : contract === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
        ? "Candidate Application"
        : "Candidate Endorsed Contract";

    const noFormsInd =
      contract === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
        ? background_disclosures_url !== ""
        : background_disclosures_url !== "" &&
          candidate_endorsed_contract_url !== "";
    return (
      <>
        {showLoader ? (
          <div className="mm-dots-loading" />
        ) : (
          <>
            {noFormsInd && (
              <div style={{ padding: "0px, 16px, 0px, 16px" }}>
                {contract !== CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
                  <>
                    <Button
                      id="CandidateEndorsedContractBtn"
                      variant="link"
                      style={{ padding: "0px", fontSize: "18px" }}
                      onClick={this.openContract}
                    >
                      {ContractButtonLabel}
                    </Button>
                    <br />
                  </>
                )}
                <Button
                  id="BackgroundDisclosuresBtn"
                  style={{ padding: "0px", fontSize: "18px" }}
                  variant="link"
                  onClick={this.openDisclosure}
                >
                  Background Disclosures
                </Button>
                <br />
                <Button
                  id="ConsumerNotificationRightsBtn"
                  style={{
                    padding: "0px",
                    fontSize: "18px",
                  }}
                  variant="link"
                  onClick={this.openConsumerNotificationRights}
                >
                  Consumer Notification and Rights
                </Button>
                {this.props?.mmlis && (
                  <>
                    <br />
                    <Button
                      id="MMLISAddOnBtn"
                      style={{
                        padding: "0px",
                        fontSize: "18px",
                      }}
                      variant="link"
                      onClick={this.openMMLISAddOnContract}
                    >
                      {applicationStatus ===
                      CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED
                        ? "MMLIS Endorsed Contract"
                        : "MMLIS Contract"}
                    </Button>
                  </>
                )}
                <div
                  id="DownloadAllBtn"
                  style={{
                    marginTop: "10px",
                    padding: "0px",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={this.downloadAll}
                  className="col-md-12"
                  role="presentation"
                >
                  <span className="icon-download"></span>
                  <span
                    style={{ fontWeight: "450", color: "#005A9F" }}
                    id="dwnldall"
                  >
                    {" "}
                    Download All
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default withOktaAuth(PDFForm);

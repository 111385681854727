import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FORM_ID } from "../../../../../constants/Forms";
import { CCB_FORM_ID, defTimelineSteps, headerText } from "./constants";
import { isDrpSummaryPage } from "./utils";

import DisclosureReportingPageLayout from "../common/DRPLayout";
import TerminationDisclosureForm from "./TerminationDisclosureForm";
import TerminationDisclosureDRPSummary from "./TerminationDisclosureDRPSummary";
import TerminationDisclosureAddDRPForm from "./TerminationDisclosureAddDRPForm";
import TerminationDisclosureCommentsForm from "./TerminationDisclosureCommentsForm";
import AddDRPFormLayout from "../common/AddDRPFormLayout";
import {
  deleteTerminationDRP,
  getTerminationDRP,
  insertTerminationDRP,
  updateTerminationDRP,
} from "../../../../../api/CCB/DRP/terminationDisclosureDRP";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import { useOktaAuth } from "@okta/okta-react";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const TerminationDisclosure = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  hasCurrentFormChanged,
  currentFormChanged,
  candidateId,
  agencynumber,
  contracttypeid,
}) => {
  const { data, isBack } = useSelector((state) => state.ccb14);
  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.TERMINATION_DISCLOSURE
  );
  const [terminationDisclosureDRPs, setTerminationDisclosureDRPs] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const [customTimelineSteps, setCustomTimelineSteps] =
    useState(defTimelineSteps);

  const { termDiscl, unchecked } = isDrpSummaryPage(data);
  const [editIndex, setEditIndex] = useState(null);

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);
  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(false);

  const {
    authState: {
      idToken: {
        claims: { sub },
      },
    },
  } = useOktaAuth();

  const setData = async () => {
    setLoading(true);
    const { data } = await getTerminationDRP(sub);
    if (data !== "") {
      setTerminationDisclosureDRPs(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (termDiscl?.length && isBack) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }
    UtagTracking.sendPageNameView(
      "terminationdisclosureform",
      candidateId,
      agencynumber,
      contracttypeid
    );
    setData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (currentFormId === CCB_FORM_ID.DRP_SUMMARY) {
      setEditIndex(null);
      updateTempDRP("");
    }
  }, [editIndex, currentFormId]);

  const [tempDRP, setTempDRP] = useState("");

  const getDRPList = (termDRPList) => {
    let list = [];
    if (termDRPList !== list && termDRPList !== "") {
      for (let i in termDRPList) {
        let currDRP = termDRPList[i].terminationdrp;

        if (currDRP.drpList.length > 0) {
          for (let j in currDRP.drpList) {
            list.push({
              date: currDRP.termDate,
              description: [currDRP.termType],
              questionKey: currDRP.drpList[j],
              mainIndex: termDRPList[i].ccbdrpid,
            });
          }
        }
      }
    }
    return list;
  };

  const updateTerminationDisclosureDRPs = async (newDRP) => {
    setLoading(true);
    if (editIndex !== null) {
      await updateTerminationDRP(sub, {
        terminationjsondata: newDRP,
        ccbdrpid: editIndex,
      });
      setEditIndex(null);
    } else {
      await insertTerminationDRP(sub, {
        terminationjsondata: newDRP,
      });
    }
    setData();
  };

  const deleteTerminationDisclosureDRPs = async ({ dataIndex }) => {
    setLoading(true);
    await deleteTerminationDRP(sub, dataIndex);
    setData();
  };

  const updateTempDRP = (tempDRP) => {
    setTempDRP(tempDRP);
  };

  const updateCCBForm = (formId, options) => {
    if (!isDRPCompleted && (isBack || options?.isBackClick)) {
      updateCCB14Step({ termDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ termDisclosureCompleteInd: false });
    }

    setCurrentFormId(formId);
  };

  const editTerminationDisclosureDRPs = ({ dataIndex }) => {
    setEditIndex(dataIndex);
    const drpToEdit = terminationDisclosureDRPs.filter(
      (e) => e.ccbdrpid === dataIndex
    )[0];
    updateTempDRP(drpToEdit.terminationdrp);
    updateCCBForm(CCB_FORM_ID.ADD_DRP);
  };

  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.TERMINATION_DISCLOSURE:
        return (
          <TerminationDisclosureForm
            header={headerText.HEADER}
            termDRPList={terminationDisclosureDRPs}
            setData={setData}
            isLoadingProp={loading}
            setIsLoadingProp={setLoading}
            termDiscl={termDiscl}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            backFormId={FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
            unchecked={unchecked}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.HEADER}
            backFormId={CCB_FORM_ID.TERMINATION_DISCLOSURE}
            nextFormId={FORM_ID.FINANCIAL_DISCLOSURE}
            updateCCBForm={updateCCBForm}
            toggleForm={toggleForm}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            isNextDisabled={isDRPNextDisabled}
            isLoading={loading}
            ind="termDisclosureCompleteInd"
          >
            {loading ? (
              <>
                <span className="mm-dots-loading"></span>
              </>
            ) : (
              <TerminationDisclosureDRPSummary
                oktaSubId={oktaSubId}
                updateCCBForm={updateCCBForm}
                termDiscl={termDiscl}
                loading={loading}
                setIsDRPCompleted={setIsDRPCompleted}
                updateCCB14Step={updateCCB14Step}
                setLoading={setLoading}
                getDRPList={getDRPList}
                terminationDisclosureDRPs={terminationDisclosureDRPs}
                deleteTerminationDisclosureDRPs={
                  deleteTerminationDisclosureDRPs
                }
                editTerminationDisclosureDRPs={editTerminationDisclosureDRPs}
                isDRPNextDisabled={isDRPNextDisabled}
                setIsDRPNextDisabled={setIsDRPNextDisabled}
                updateCCB14CompletedInd={updateCCB14CompletedInd}
              />
            )}
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.ADD_DRP:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Details"
          >
            <TerminationDisclosureAddDRPForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_DRP_COMMENT}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              tempDrp={tempDRP}
              updateTempDRP={updateTempDRP}
              hasCurrentFormChanged={hasCurrentFormChanged}
              currentFormChanged={currentFormChanged}
              candidateId={candidateId}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_COMMENT:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={2}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Comments"
          >
            <TerminationDisclosureCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              updateTempDRP={updateTempDRP}
              tempDRP={tempDRP}
              editIndex={editIndex}
              updateTerminationDisclosureDRPs={updateTerminationDisclosureDRPs}
              hasCurrentFormChanged={hasCurrentFormChanged}
              currentFormChanged={currentFormChanged}
              candidateId={candidateId}
              sleep={sleep}
            />
          </AddDRPFormLayout>
        );

      default:
        break;
    }
  };

  return (
    <div className="termination-disclosure-wrapper">
      {renderForm(currentFormId)}
    </div>
  );
};

export default TerminationDisclosure;

import { convertGroupKey } from "../../helpers";

export const headerText = {
  HEADER: "Civil Judicial Disclosure",
  CIV_JUD_ACT: "Civil Judicial Actions",
};

export const initialState = {
  [convertGroupKey(headerText.CIV_JUD_ACT)]: {
    actDetails: null,
    details: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
};

export const civJudDesc = {
  DEF_DESCRIPTION: `Use only one DRP to report details related to the same event. Unrelated civil judicial actions must be reported on separate DRPs.`,
};

export const CCB_FORM_ID = {
  CIVIL_DISCLOSURE: 1,
  DRP_SUMMARY: 2,
  CIV_JUD_ACT_DETAILS: 31,
  CIV_JUD_DETAILS: 32,
  CIV_JUD_STATUS_DETAILS: 33,
  CIV_JUD_RES_DETAILS: 34,
  CIV_JUD_SANCT_DETAILS: 35,
  CIV_JUD_COMMENTS: 36,
};

const labels = {
  LABEL_ONE: "Action Details",
  LABEL_TWO: "Details",
  LABEL_THREE: "Status Details",
  LABEL_FOUR: "Resolution Details",
  LABEL_FIVE: "Sanction Details",
  LABEL_SIX: "Comments",
};

const commonParts = { active: false, notApplicable: false, completed: false };

export const defTimelineSteps = [
  {
    id: 1,
    label: labels.LABEL_ONE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 5,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
  {
    id: 6,
    label: labels.LABEL_SIX,
    ...commonParts,
  },
];

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { RADIO, dateInputMask, eventStatusDateType } from "../../../constants";

import "./RegActionStatusDetails.scss";

export const eventStatus = {
  PENDING: "Pending",
  APPEAL: "On Appeal",
  FINAL: "Final",
};

const actionAppealedType = {
  SEC: "SEC",
  SRO: "SRO",
  CFTC: "CFTC",
  FEDERAL: "Federal Court",
  STATE: "State Agency or Commission",
  COURT: "State Court",
  OTHER: "Other",
};

const RegActionStatusDetails = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  setSkipResAndSanctDetails,
  skipFormId,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);

  const [formHasChanged, setFormHasChanged] = useState(false);

  const [status, setStatus] = useState(data?.status || "");

  const [actionAppealed, setActionAppealed] = useState(
    data?.actionAppealed || ""
  );
  const [restrictionEffect, setRestrictionEffect] = useState(
    typeof data?.restrictionEffect == "boolean" ? data?.restrictionEffect : null
  );
  const [actionAppealedOther, setActionAppealedOther] = useState(
    data?.actionAppealedOther || ""
  );
  const [actionAppealedError, setActionAppealedError] = useState(false);

  const [dateAppealErrorMsg, setDateAppealErrorMsg] = useState("");
  const [dateAppeal, setDateAppeal] = useState(data?.dateAppeal || "");
  const [dateAppealError, setDateAppealError] = useState(false);
  const [dateAppealRadio, setDateAppealRadio] = useState(
    data?.dateAppealRadio || ""
  );
  const [dateAppealExp, setDateAppealExp] = useState(data?.dateAppealExp || "");
  const [restrictionEffectExp, setRestrictionEffectExp] = useState(
    data?.restrictionEffectExp || ""
  );
  const [dateAppealExpError, setDateAppealExpError] = useState(false);
  const [restrictionEffectExpError, setRestrictionEffectExpError] =
    useState(false);

  const [resEffectAppeal, setResEffectAppeal] = useState(
    typeof data?.resEffectAppeal == "boolean" ? data?.resEffectAppeal : null
  );

  const [resEffectAppealExp, setResEffectAppealExp] = useState(
    data?.resEffectAppealExp || ""
  );
  const [resEffectAppealExpError, setResEffectAppealExpError] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (data !== null && data && !Object.keys(data).length) {
      setFormHasChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDateDateAppealDisabled =
    !dateAppeal?.length ||
    dateAppeal.includes("_") ||
    dateAppeal === dateInputMask.EMPTY ||
    (!dateAppeal?.length && !dateAppealRadio?.length) ||
    !dateAppealRadio?.length ||
    (dateAppeal?.length &&
      dateAppealRadio?.length &&
      dateAppealRadio === eventStatusDateType.EXPLANATION &&
      !dateAppealExp?.length) ||
    (dateAppealExp?.length && dateAppealExp?.length < 2);

  const isError =
    actionAppealedError ||
    dateAppealError ||
    dateAppealExpError ||
    restrictionEffectExpError ||
    resEffectAppealExpError ||
    dateAppealError ||
    dateAppealExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isPending =
    (status?.length &&
      status === eventStatus.PENDING &&
      restrictionEffect === true &&
      !restrictionEffectExp?.length) ||
    (status?.length &&
      status === eventStatus.PENDING &&
      restrictionEffect === true &&
      restrictionEffectExp?.length &&
      restrictionEffectExp?.length < 2) ||
    (status?.length &&
      status === eventStatus.PENDING &&
      typeof restrictionEffect !== "boolean");

  const isAppeal =
    (status?.length &&
      status === eventStatus.APPEAL &&
      (!actionAppealed?.length ||
        (actionAppealed.length &&
          actionAppealed === actionAppealedType.OTHER &&
          !actionAppealedOther?.length))) ||
    (isDateDateAppealDisabled &&
      status?.length &&
      status === eventStatus.APPEAL) ||
    (status?.length &&
      status === eventStatus.APPEAL &&
      typeof resEffectAppeal !== "boolean") ||
    (status?.length &&
      status === eventStatus.APPEAL &&
      resEffectAppeal === true &&
      !resEffectAppealExp?.length) ||
    (status?.length &&
      status === eventStatus.APPEAL &&
      resEffectAppeal === true &&
      resEffectAppealExp?.length &&
      resEffectAppealExp?.length < 2) ||
    (status?.length &&
      status === eventStatus.APPEAL &&
      typeof resEffectAppeal !== "boolean");

  const isNextBtnDisabled =
    status === eventStatus.FINAL
      ? false
      : !!(isPending || isAppeal) ||
        isError ||
        !status.length ||
        isFocusedState;

  const handleChangeCheckedInvRes = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setActionAppealedError(false);

    if (value !== actionAppealedType.OTHER) {
      setActionAppealedOther("");
    }

    setActionAppealed(newValue);
  };

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setActionAppealedOther("");
    setRestrictionEffectExp("");
    setRestrictionEffect("");
    setActionAppealedError(false);
    setActionAppealed("");
    setDateAppealError("");
    setDateAppeal("");
    setDateAppealErrorMsg("");
    setDateAppealRadio("");
    setDateAppealExp("");
    setResEffectAppeal("");
    setDateAppealExpError(false);
    setRestrictionEffectExpError(false);

    setStatus(newValue);
  };

  const handleDateDateAppealChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setDateAppealExpError(false);
    if (
      dateAppealExp.length &&
      dateAppealRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateAppealExp("");
    }

    setDateAppealExpError(false);
    setDateAppealRadio(newValue);
  };

  const onDateDateAppealOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateAppeal(value);
  };

  const onDateDateAppealExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateAppealExp(value);
  };

  const onBlurRestrictionEffectExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setRestrictionEffectExpError(true)
      : setRestrictionEffectExpError(false);
  };

  const onRestrictionEffectExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setRestrictionEffectExp(value);
  };

  const onBlurResEffectAppealExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setResEffectAppealExpError(true)
      : setResEffectAppealExpError(false);
  };

  const onResEffectAppealExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setResEffectAppealExp(value);
  };

  useEffect(() => {
    const isPendingStatus = status === eventStatus.PENDING;

    setSkipResAndSanctDetails(isPendingStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onDateDateAppealBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateAppealError(true);
      } else {
        setDateAppealError(false);
      }
    } else {
      setDateAppealError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateAppealErrorMsg("Date cannot be a future date");
        setDateAppealError(true);
      } else if (date < past) {
        setDateAppealErrorMsg("Date cannot be more than 100 years ago");
        setDateAppealError(true);
      } else {
        setDateAppealError(false);
      }
    } else {
      setDateAppealErrorMsg("Please enter Date appeal filed");
      setDateAppealError(true);
    }

    setFocusedState(false);
  };

  const onBlurDateDateAppealExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDateAppealExpError(true)
      : setDateAppealExpError(false);
  };

  const handleChangeCheckedRestrictionEffect = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setRestrictionEffectExp("");
    setRestrictionEffectExpError(false);

    setRestrictionEffect(newValue);
  };

  const handleChangeCheckedResEffectAppeal = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setResEffectAppealExp("");
    setResEffectAppealExpError(false);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setResEffectAppeal(newValue);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  const onChangeInvResolvedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setActionAppealedOther(value);
  };
  const onBlurInvResolvedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setActionAppealedError(true)
      : setActionAppealedError(false);
  };

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      dateAppeal,
      dateAppealRadio,
      dateAppealExp,
      status,
      resEffectAppeal,
      actionAppealed,
      actionAppealedOther,
      restrictionEffect,
      restrictionEffectExp,
      resEffectAppealExp,
    };

    const isPendingStatus = status === eventStatus.PENDING;

    setSkipResAndSanctDetails(isPendingStatus);

    setFormHasChanged(false);

    let clearData = {};

    if (isPendingStatus) {
      clearData = {
        resDetails: null,
        sanctionDetails: null,
      };
    }

    getSubmitDataValues(
      { [`${groupKey}`]: submitData, ...clearData },
      headerText
    );

    updateCCBForm(isPendingStatus ? skipFormId : nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    headerText,
    status,
    resEffectAppealExp,
    dateAppeal,
    dateAppealRadio,
    dateAppealExp,
    resEffectAppeal,
    groupKey,
    actionAppealedOther,
    restrictionEffect,
    restrictionEffectExp,
    actionAppealed,
    setSkipResAndSanctDetails,
    skipFormId,
  ]);

  return (
    <div className="reg-action-status-det-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label id="eventStatusLabelId" className="event-status">
            Current status: *
          </label>
        </div>
      </div>

      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="pendingStatus"
                type="radio"
                id="pending"
                className="custom-control-input"
                value={eventStatus.PENDING}
                checked={status === eventStatus.PENDING}
                onChange={handleChangeChecked}
              />
              <label
                id="federalLabelId"
                htmlFor="pending"
                className="custom-control-label"
              >
                {eventStatus.PENDING}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="appealStatus"
                type="radio"
                id="appealStatusId"
                className="custom-control-input"
                value={eventStatus.APPEAL}
                checked={status === eventStatus.APPEAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="appealStatusId"
                id="appealStatusLabelId"
                className="custom-control-label"
              >
                {eventStatus.APPEAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="finalStatus"
                type="radio"
                id="finalStatusId"
                className="custom-control-input"
                value={eventStatus.FINAL}
                checked={status === eventStatus.FINAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="finalStatusId"
                id="finalStatusLabelId"
                className="custom-control-label"
              >
                {eventStatus.FINAL}
              </label>
            </div>
          </div>
        </div>
      </div>

      {status === eventStatus.PENDING && (
        <>
          <div className="row">
            <div className="col-md-12">
              <label className="inv-business" id="restrictionEffectLabelId">
                If pending, are there any limitations or restrictions currently
                in effect? *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="restrictionEffect"
                    type="radio"
                    id="restrictionEffect_yes"
                    className="custom-control-input"
                    value={true}
                    checked={restrictionEffect === true}
                    onChange={handleChangeCheckedRestrictionEffect}
                  />
                  <label
                    id="restriction_yes"
                    htmlFor="restrictionEffect_yes"
                    className="custom-control-label"
                  >
                    Yes
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="restrictionEffect"
                    type="radio"
                    id="restrictionEffect_no"
                    className="custom-control-input"
                    value={false}
                    checked={restrictionEffect === false}
                    onChange={handleChangeCheckedRestrictionEffect}
                  />
                  <label
                    id="restriction_no"
                    htmlFor="restrictionEffect_no"
                    className="custom-control-label"
                  >
                    No
                  </label>
                </div>
                <div id="radio_error" className="invalid-feedback">
                  Please choose a response.
                </div>
              </div>
            </div>
          </div>

          {restrictionEffect === true ? (
            <div className="event-status-date-explanation-container">
              <label
                htmlFor="restrictionEffectExplanation"
                className="event-status-date-exp"
                id="restrictionEffectExplanationLabelId"
              >
                If the answer is 'yes', provide details: *
              </label>
              <textarea
                id="restrictionEffectExplanation"
                onChange={onRestrictionEffectExplanationChange}
                className={
                  restrictionEffectExpError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={500}
                minLength={2}
                onBlur={onBlurRestrictionEffectExpHandler}
                onFocus={() => setFocusedState(true)}
                rows="3"
                cols={500}
                value={restrictionEffectExp}
              />
              <div id="restrictionEffectExpError" className="invalid-feedback">
                Please enter limitations or restrictions currently in effect
              </div>
            </div>
          ) : null}
        </>
      )}

      {status === eventStatus.APPEAL && (
        <>
          <>
            <div className="sentence-hr">
              <hr />
              <div className="eyebrow-text-wrapper">
                <strong id="regAppealEyebrowText" className="eyebrow">
                  Appeal details
                </strong>
              </div>
              <hr />
            </div>

            <div className="row">
              <div className="col-md-12">
                <label id="regAppealedLabelId" className="court-action">
                  Action appealed to: *
                </label>
              </div>
            </div>
            <div className="row">
              <div className="drp-radio-input-wrapper">
                <div className="col-12 copy-md-4 form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="actionSec"
                      type="radio"
                      id="actionSecId"
                      className="custom-control-input"
                      value={actionAppealedType.SEC}
                      checked={actionAppealed === actionAppealedType.SEC}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="actionSecId"
                      className="custom-control-label"
                      id="actionSecIdLabel"
                    >
                      {actionAppealedType.SEC}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="actionSRO"
                      type="radio"
                      id="actionSROId"
                      className="custom-control-input"
                      value={actionAppealedType.SRO}
                      checked={actionAppealed === actionAppealedType.SRO}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="actionSROId"
                      className="custom-control-label"
                      id="actionSROIdLabel"
                    >
                      {actionAppealedType.SRO}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="actionCFTC"
                      type="radio"
                      id="actionCFTCId"
                      className="custom-control-input"
                      value={actionAppealedType.CFTC}
                      checked={actionAppealed === actionAppealedType.CFTC}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="actionCFTCId"
                      className="custom-control-label"
                      id="actionCFTCIdLabel"
                    >
                      {actionAppealedType.CFTC}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="actionFederal"
                      type="radio"
                      id="actionFederalId"
                      className="custom-control-input"
                      value={actionAppealedType.FEDERAL}
                      checked={actionAppealed === actionAppealedType.FEDERAL}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="actionFederalId"
                      className="custom-control-label"
                      id="actionFederalIdLabel"
                    >
                      {actionAppealedType.FEDERAL}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="actionState"
                      type="radio"
                      id="actionStateId"
                      className="custom-control-input"
                      value={actionAppealedType.STATE}
                      checked={actionAppealed === actionAppealedType.STATE}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="actionStateId"
                      className="custom-control-label"
                      id="actionStateIdLabel"
                    >
                      {actionAppealedType.STATE}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="stateCourt"
                      type="radio"
                      id="stateCourtId"
                      className="custom-control-input"
                      value={actionAppealedType.COURT}
                      checked={actionAppealed === actionAppealedType.COURT}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="stateCourtId"
                      className="custom-control-label"
                      id="stateCourtIdLabel"
                    >
                      {actionAppealedType.COURT}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="otherRadio"
                      type="radio"
                      id="otherRadioId"
                      className="custom-control-input"
                      value={actionAppealedType.OTHER}
                      checked={actionAppealed === actionAppealedType.OTHER}
                      onChange={handleChangeCheckedInvRes}
                    />
                    <label
                      htmlFor="otherRadioId"
                      className="custom-control-label"
                      id="otherRadioIdLabel"
                    >
                      {actionAppealedType.OTHER}
                    </label>
                  </div>

                  {actionAppealed === actionAppealedType.OTHER ? (
                    <div className="court-action-other">
                      <input
                        type="text"
                        id="otherText"
                        className={
                          actionAppealedError
                            ? "form-control form-control-15 is-invalid"
                            : "form-control form-control-15"
                        }
                        maxLength={500}
                        minLength={2}
                        autoComplete="off"
                        value={actionAppealedOther}
                        onChange={onChangeInvResolvedHandler}
                        onBlur={onBlurInvResolvedHandler}
                        onFocus={() => setFocusedState(true)}
                      />
                      <div
                        id="actionAppealedError"
                        className="invalid-feedback"
                      >
                        Please enter a response
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 event-status-date-wrapper">
                <label id="dateAppealLabelPaiId" htmlFor="dateAppealId">
                  <strong id="regDateAppealFiled">Date appeal filed: *</strong>
                </label>
                <InputMask
                  id="dateAppealId"
                  mask="99/99/9999"
                  maskChar="_"
                  className={`${
                    dateAppealError ? "form-control is-invalid" : "form-control"
                  } event-status-date-input`}
                  placeholder="MM/DD/YYYY"
                  autoComplete="off"
                  required
                  value={dateAppeal}
                  onChange={onDateDateAppealOnChangeHandler}
                  onBlur={onDateDateAppealBlurHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="dateAppealError" className="invalid-feedback">
                  {dateAppealErrorMsg?.length
                    ? dateAppealErrorMsg
                    : "Please enter a valid Date appeal"}
                </div>
              </div>

              <div className="row event-status-date-radio-group">
                <div className="custom-control">
                  <div className="col-12 copy-md-8 form-group">
                    <div className="custom-control custom-radio">
                      <input
                        name="radio-guide-dateAppealExactId"
                        type="radio"
                        id="dateAppealExactId"
                        className="custom-control-input"
                        value={eventStatusDateType.EXACT}
                        checked={dateAppealRadio === eventStatusDateType.EXACT}
                        onChange={handleDateDateAppealChecked}
                      />
                      <label
                        htmlFor="dateAppealExactId"
                        className="custom-control-label"
                        id="label_exactDate"
                      >
                        {eventStatusDateType.EXACT}
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        name="radio-guide-dateAppealExplanationId"
                        type="radio"
                        id="dateAppealExplanationId"
                        className="custom-control-input"
                        value={eventStatusDateType.EXPLANATION}
                        checked={
                          dateAppealRadio === eventStatusDateType.EXPLANATION
                        }
                        onChange={handleDateDateAppealChecked}
                      />
                      <label
                        htmlFor="dateAppealExplanationId"
                        className="custom-control-label"
                        id="label_explanation"
                      >
                        {eventStatusDateType.EXPLANATION}
                      </label>
                    </div>

                    {dateAppealRadio === eventStatusDateType.EXPLANATION ? (
                      <div className="event-status-date-explanation-container">
                        <label
                          htmlFor="dateAppealExplanation"
                          className="event-status-date-exp"
                          id="dateAppealExplanationLabelId"
                        >
                          If not exact, provide explanation *
                        </label>
                        <textarea
                          id="dateAppealExplanation"
                          onChange={onDateDateAppealExplanationChange}
                          className={
                            dateAppealExpError
                              ? "form-control form-control-15 is-invalid"
                              : "form-control form-control-15"
                          }
                          maxLength={500}
                          minLength={2}
                          onBlur={onBlurDateDateAppealExpHandler}
                          onFocus={() => setFocusedState(true)}
                          rows="3"
                          cols={500}
                          value={dateAppealExp}
                        />
                        <div
                          id="dateAppealExpError"
                          className="invalid-feedback"
                        >
                          Please enter an Explanation
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="row">
            <div className="col-md-12">
              <label className="inv-business" id="resEffectAppealLabelId">
                Are there any limitations or restrictions currently in effect
                while on appeal? *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="resEffectAppeal"
                    type="radio"
                    id="resEffectAppeal_yes"
                    className="custom-control-input"
                    value={true}
                    checked={resEffectAppeal === true}
                    onChange={handleChangeCheckedResEffectAppeal}
                  />
                  <label
                    id="resEffect_yes"
                    htmlFor="resEffectAppeal_yes"
                    className="custom-control-label"
                  >
                    Yes
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="resEffectAppeal"
                    type="radio"
                    id="resEffectAppeal_no"
                    className="custom-control-input"
                    value={false}
                    checked={resEffectAppeal === false}
                    onChange={handleChangeCheckedResEffectAppeal}
                  />
                  <label
                    id="resEffect_no"
                    htmlFor="resEffectAppeal_no"
                    className="custom-control-label"
                  >
                    No
                  </label>
                </div>
                <div id="radio_error" className="invalid-feedback">
                  Please choose a response.
                </div>
              </div>
            </div>
          </div>

          {resEffectAppeal === true ? (
            <div className="event-status-date-explanation-container">
              <label
                htmlFor="restrictionEffectExplanation"
                className="event-status-date-exp"
                id="restrictionEffectExplanationLabelId"
              >
                If the answer is 'yes', provide details: *
              </label>
              <textarea
                id="restrictionEffectExplanation"
                onChange={onResEffectAppealExplanationChange}
                className={
                  resEffectAppealExpError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={500}
                minLength={2}
                onBlur={onBlurResEffectAppealExpHandler}
                onFocus={() => setFocusedState(true)}
                rows="3"
                cols={500}
                value={resEffectAppealExp}
              />
              <div id="resEffectAppealExpError" className="invalid-feedback">
                Please enter limitations or restrictions currently in effect
              </div>
            </div>
          ) : null}
        </>
      )}

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            id="regNext"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionStatusDetails;

import { convertGroupKey } from "../../helpers";

export const headerText = {
  HEADER: "Regulatory Action Disclosure",
  REG: "Regulatory Action",
  REG_SEC_CFTC: "Regulatory Action by SEC or CFTC",
  REG_ACT_REG: "Regulatory Action by other regulators",
  REG_ACT_SRO: "Regulatory Action by SRO",
  REG_PRO_SUSP: "Professional Suspension",
  REG_FORM_ACT: "Formal Pending Action",
  REG_FORM_INV: "Investigation",
  REG_FORM_ACT_INV: "Formal Pending Action/Investigation",
};

export const regActDetails = {
  DEF_DESCRIPTION: `One event may result in more than one affirmative answer to the below items. Use only one DRP to report details to the same event. If an event gives rise to actions by more than one regulator, provide details to each action on a separate DRP.`,
  INV_DESCRIPTION: `One event may result in more than one investigation. If more than one authority is investigating you, use a separate DRP to provide details.`,
};

export const initialState = {
  [convertGroupKey(headerText.REG_SEC_CFTC)]: {
    actDetails: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.REG_ACT_REG)]: {
    actDetails: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.REG_ACT_SRO)]: {
    actDetails: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.REG_PRO_SUSP)]: {
    actDetails: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.REG_FORM_ACT)]: {
    actDetails: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.REG_FORM_INV)]: {
    invDetails: null,
    statusDetails: null,
    comments: null,
  },
};

export const CCB_FORM_ID = {
  REGULATORY_ACTION: 1,
  DRP_SUMMARY: 2,
  REG_SEC_CFTC_ACT_DETAILS: 31,
  REG_SEC_CFTC_STATUS_DETAILS: 32,
  REG_SEC_CFTC_RES_DETAILS: 33,
  REG_SEC_CFTC_SANCT_DETAILS: 34,
  REG_SEC_CFTC_COMMENTS: 35,
  REG_ACT_REG_ACT_DETAILS: 41,
  REG_ACT_REG_STATUS_DETAILS: 42,
  REG_ACT_REG_RES_DETAILS: 43,
  REG_ACT_REG_SANCT_DETAILS: 44,
  REG_ACT_REG_COMMENTS: 45,
  REG_ACT_SRO_ACT_DETAILS: 51,
  REG_ACT_SRO_STATUS_DETAILS: 52,
  REG_ACT_SRO_RES_DETAILS: 53,
  REG_ACT_SRO_SANCT_DETAILS: 54,
  REG_ACT_SRO_COMMENTS: 55,
  REG_PRO_SUSP_ACT_DETAILS: 61,
  REG_PRO_SUSP_STATUS_DETAILS: 62,
  REG_PRO_SUSP_RES_DETAILS: 63,
  REG_PRO_SUSP_SANCT_DETAILS: 64,
  REG_PRO_SUSP_COMMENTS: 65,
  REG_FORM_ACT_ACT_DETAILS: 71,
  REG_FORM_ACT_STATUS_DETAILS: 72,
  REG_FORM_ACT_RES_DETAILS: 73,
  REG_FORM_ACT_SANCT_DETAILS: 74,
  REG_FORM_ACT_COMMENTS: 75,
  REG_FORM_INV_DETAILS: 81,
  REG_FORM_INV_STATUS_DETAILS: 82,
  REG_FORM_INV_COMMENTS: 83,
  REG_ACT_DETAILS: 91,
  REG_STATUS_DETAILS: 92,
  REG_RES_DETAILS: 93,
  REG_SANCT_DETAILS: 94,
  REG_COMMENTS: 95,
};

const labels = {
  LABEL_ONE: "Action Details",
  LABEL_TWO: "Status Details",
  LABEL_THREE: "Resolution Details",
  LABEL_FOUR: "Sanction Details",
  LABEL_FIVE: "Comments",
  LABEL_SIX: "Investigation Details",
};

const commonParts = { active: false, notApplicable: false, completed: false };

export const defTimelineSteps = [
  {
    id: 1,
    label: labels.LABEL_ONE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_THREE,
    ...commonParts,
  },
  {
    id: 4,
    label: labels.LABEL_FOUR,
    ...commonParts,
  },
  {
    id: 5,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
];

export const defTimelineSteps2 = [...defTimelineSteps];
export const defTimelineSteps3 = [...defTimelineSteps];
export const defTimelineSteps4 = [...defTimelineSteps];
export const defTimelineSteps5 = [...defTimelineSteps];

export const defTimelineSteps6 = [
  {
    id: 1,
    label: labels.LABEL_SIX,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
  {
    id: 3,
    label: labels.LABEL_FIVE,
    ...commonParts,
  },
];

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import ReactInputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import {
  dateValidator,
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import { logoutActions } from "../../../../../../../../store/actions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import { civilTaxOptions, dateOptions } from "../../constants";
import "./JudgmentLienDetailsForm.scss";

const JudgmentLienAddDRPJudgmentLienDetailsForm = ({
  backFormId,
  nextFormId,
  updateCCBForm,
  updateTempDRP,
  tempJudgmentLienDRP: {
    judgmentLienDetails = {},
    courtDetails = {},
    dispositionDetails = {},
    comments = "",
  },
}) => {
  const dispatch = useDispatch();

  /*********************
   * Initialize States *
   *********************/
  const [judgmentLienType, setJudgmentLienType] = useState(
    judgmentLienDetails?.judgmentLienType || ""
  );
  const [judgmentLienAmount, setJudgmentLienAmount] = useState(
    judgmentLienDetails?.judgmentLienAmount || ""
  );
  const [judgmentLienAmountError, setJudgmentLienAmountError] = useState(false);
  const [judgmentLienAmountErrorMsg, setJudgmentLienAmountErrorMsg] =
    useState("");
  const [judgmentLienHolder, setJudgmentLienHolder] = useState(
    judgmentLienDetails?.judgmentLienHolder || ""
  );
  const [judgmentLienHolderError, setJudgmentLienHolderError] = useState(false);
  const [dateFiledWithCourt, setDateFiledWithCourt] = useState(
    judgmentLienDetails?.dateFiledWithCourt || ""
  );
  const [dateFiledWithCourtError, setDateFiledWithCourtError] = useState(false);
  const [dateFiledWithCourtErrorMsg, setDateFiledWithCourtErrorMsg] =
    useState("");
  const [dateFiledWithCourtOption, setDateFiledWithCourtOption] = useState(
    judgmentLienDetails?.dateFiledWithCourtOption || ""
  );
  const [dateFiledWithCourtExplanation, setDateFiledWithCourtExplanation] =
    useState(judgmentLienDetails?.dateFiledWithCourtExplanation || "");
  const [
    dateFiledWithCourtExplanationError,
    setDateFiledWithCourtExplanationError,
  ] = useState(false);

  const [dateLearned, setDateLearned] = useState(
    judgmentLienDetails?.dateLearned || ""
  );
  const [dateLearnedError, setDateLearnedError] = useState(false);
  const [dateLearnedErrorMsg, setDateLearnedErrorMsg] = useState("");
  const [dateLearnedOption, setDateLearnedOption] = useState(
    judgmentLienDetails?.dateLearnedOption || ""
  );
  const [dateLearnedExplanation, setDateLearnedExplanation] = useState(
    judgmentLienDetails?.dateLearnedExplanation || ""
  );
  const [dateLearnedExplanationError, setDateLearnedExplanationError] =
    useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    toggleShowBackModal(value);
  };
  const handleNext = () => {
    const tmpDRP = {
      judgmentLienDetails: {
        judgmentLienType,
        judgmentLienAmount,
        judgmentLienHolder,
        dateFiledWithCourt,
        dateFiledWithCourtOption,
        dateFiledWithCourtExplanation,
        dateLearned,
        dateLearnedOption,
        dateLearnedExplanation,
      },
      courtDetails: { ...courtDetails },
      dispositionDetails: { ...dispositionDetails },
      comments,
    };
    updateTempDRP(tmpDRP);
    updateCCBForm(nextFormId);
  };
  const handleBack = () => {
    updateTempDRP({});
    updateCCBForm(backFormId);
  };

  const handleJudgmentLienTypeChange = ({ target: { value } }) => {
    setJudgmentLienType(value);
  };
  const handleDateFiledWithCourtOptionChange = ({ target: { value } }) => {
    if (value === dateOptions.EXACT) {
      setDateFiledWithCourtExplanation("");
      setDateFiledWithCourtExplanationError(false);
    }
    setDateFiledWithCourtOption(value);
  };
  const handleDateLearnedOptionChange = ({ target: { value } }) => {
    if (value === dateOptions.EXACT) {
      setDateLearnedExplanation("");
      setDateLearnedExplanationError(false);
    }
    setDateLearnedOption(value);
  };
  const handleTextInputChange = ({ target: { id, value } }) => {
    switch (id) {
      case "judgmentLienAmount":
        return setJudgmentLienAmount(formatMoney(value));
      case "judgmentLienHolder":
        return setJudgmentLienHolder(value);
      case "dateFiledWithCourt":
        return setDateFiledWithCourt(value);
      case "dateFiledWithCourtExplanation":
        return setDateFiledWithCourtExplanation(value);
      case "dateLearned":
        return setDateLearned(value);
      case "dateLearnedExplanation":
        return setDateLearnedExplanation(value);
      default:
        break;
    }
  };
  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    switch (id) {
      case "judgmentLienHolder":
        return setJudgmentLienHolderError(value.length < 2);
      case "dateFiledWithCourtExplanation":
        return setDateFiledWithCourtExplanationError(value.length < 2);
      case "dateLearnedExplanation":
        return setDateLearnedExplanationError(value.length < 2);
      default:
        break;
    }
  };
  const onBlurDateInput = ({ target: { id, value } }) => {
    const { isInvalid } = dateValidator(value);
    setFocusedState(false);
    switch (id) {
      case "dateFiledWithCourt":
        setDateFiledWithCourtError(isInvalid);
        setDateFiledWithCourtErrorMsg("Please enter Date filed with Court");
        break;
      case "dateLearned":
        setDateLearnedError(isInvalid);
        setDateLearnedErrorMsg(
          "Please enter Date individual learned of the Judgment/Lien"
        );
        break;
      default:
        break;
    }
  };

  const onBlurMoneyInput = ({ target: { id, value } }) => {
    const isMoneyError =
      parseInt(value?.replaceAll(",", "")) > 100000000 || value?.length < 1;
    const moneyErrMessage =
      parseInt(value?.replaceAll(",", "")) > 100000000
        ? "Please enter a valid number"
        : value?.length < 1
        ? "Please enter Judgment/Lien Amount"
        : "";

    setFocusedState(false);

    switch (id) {
      case "judgmentLienAmount":
        setJudgmentLienAmountError(isMoneyError);
        setJudgmentLienAmountErrorMsg(moneyErrMessage);
        break;
      default:
        break;
    }
  };

  const isError =
    judgmentLienAmountError ||
    judgmentLienHolderError ||
    dateFiledWithCourtError ||
    dateFiledWithCourtExplanationError ||
    dateLearnedError ||
    dateLearnedExplanationError;

  const isUpdates =
    judgmentLienType?.length ||
    judgmentLienAmount?.length ||
    judgmentLienHolder?.length ||
    dateFiledWithCourt?.length ||
    dateFiledWithCourtOption?.length ||
    dateFiledWithCourtExplanation?.length ||
    dateLearned?.length ||
    dateLearnedOption?.length ||
    dateLearnedExplanation?.length ||
    isError;

  const isNextDisabled =
    judgmentLienType?.length < 2 ||
    judgmentLienAmount?.length < 1 ||
    judgmentLienHolder?.length < 2 ||
    (dateFiledWithCourt?.length < 2 && !dateFiledWithCourt?.includes("_")) ||
    dateValidator(dateFiledWithCourt).isInvalid ||
    dateFiledWithCourtOption?.length < 1 ||
    (dateFiledWithCourtOption === dateOptions.EXPLANATION &&
      dateFiledWithCourtExplanation?.length < 2) ||
    (dateLearned?.length < 2 && !dateLearned?.includes("_")) ||
    dateValidator(dateLearned).isInvalid ||
    dateLearnedOption?.length < 1 ||
    (dateLearnedOption === dateOptions.EXPLANATION &&
      dateLearnedExplanation?.length < 2) ||
    isError ||
    isFocusedState;

  useEffect(() => {
    if (isUpdates) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    } else {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    }
  }, [isUpdates, dispatch]);
  return (
    <div className="judgment-lien-details-form-wrapper">
      <div className="row">
        <div className="col-md-12 info-label" id="judgMultipleText">
          If multiple, unrelated events result in the same affirmative answer,
          details must be provided on separate DRPs.
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="judgmentLienTypeLabelId" className="field-label">
            Judgment/Lien Type: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div
            id="judgmentLienTypeOptions"
            className="col-12 copy-md-4 form-group"
          >
            <div className="custom-control custom-radio">
              <input
                name="judgmentLienTypeCivil"
                type="radio"
                id="judgmentLienTypeCivil"
                className="custom-control-input"
                value={civilTaxOptions.Civil}
                checked={judgmentLienType === civilTaxOptions.Civil}
                onChange={handleJudgmentLienTypeChange}
              />
              <label
                id="judgmentLienTypeCivilLabel"
                htmlFor="judgmentLienTypeCivil"
                className="custom-control-label"
              >
                {civilTaxOptions.Civil}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="judgmentLienTypeTax"
                type="radio"
                id="judgmentLienTypeTax"
                className="custom-control-input"
                value={civilTaxOptions.Tax}
                checked={judgmentLienType === civilTaxOptions.Tax}
                onChange={handleJudgmentLienTypeChange}
              />
              <label
                id="judgmentLienTypeTaxLabel"
                htmlFor="judgmentLienTypeTax"
                className="custom-control-label"
              >
                {civilTaxOptions.Tax}
              </label>
            </div>
            <div id="dispositionTypeError" className="invalid-feedback">
              Please enter a Disposition Type
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label id="judgmentLienAmountLabel" className="field-label">
            Judgment/Lien Amount *
          </label>
          <div
            className={
              judgmentLienAmountError ? "input-group is-invalid" : "input-group"
            }
            id="judgmentLienAmountInput"
            style={{ width: "35%" }}
          >
            <div className="is-invalid input-group-prepend">
              <span
                className="input-group-text input-group-text-pad-right"
                id="judgmentLienAmount$"
              >
                $
              </span>
            </div>
            <input
              type="text"
              id="judgmentLienAmount"
              className="form-control form-control-15"
              spellCheck="false"
              maxLength={11}
              value={judgmentLienAmount}
              onBlur={onBlurMoneyInput}
              onFocus={() => setFocusedState(true)}
              onChange={handleTextInputChange}
              onKeyPress={onlyNumeric}
              onPaste={pasteCommaDelimited}
              autoComplete="off"
            />
          </div>
          <div
            id="judgmentLienAmountError"
            className={
              judgmentLienAmountError
                ? "show invalid-feedback"
                : "invalid-feedback"
            }
          >
            {judgmentLienAmountErrorMsg}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <label
            id="judgmentLienHolderLabel"
            className="field-label"
            htmlFor="judgmentLienHolder"
          >
            Judgment/Lien Holder: *
          </label>
          <input
            id="judgmentLienHolder"
            type="text"
            className={
              judgmentLienHolderError
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={handleTextInputChange}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
            value={judgmentLienHolder}
            maxLength={200}
            autoComplete="off"
            style={{ width: "60%" }}
          ></input>
          <div id="judgmentLienHolderError" className="invalid-feedback">
            Please enter Judgment/Lien Holder
          </div>
        </div>
      </div>
      <br />
      <div className="row date-wrapper">
        <div className="col-md-12">
          <label
            id="dateFiledWithCourtLabel"
            className="field-label"
            htmlFor="dateFiledWithCourt"
          >
            Date filed with Court: *
          </label>
          <ReactInputMask
            id="dateFiledWithCourt"
            mask="99/99/9999"
            maskChar="_"
            className={
              dateFiledWithCourtError
                ? "form-control is-invalid"
                : "form-control"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={dateFiledWithCourt}
            onChange={handleTextInputChange}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
            style={{ width: "35%" }}
          />
          <div id="dateFiledWithCourtError" className="invalid-feedback">
            {dateFiledWithCourtErrorMsg}
          </div>
        </div>
      </div>
      <div className="row" style={{ marginLeft: "15px" }}>
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dateFiledWithCourtOptionExact"
                type="radio"
                id="dateFiledWithCourtOptionExact"
                className="custom-control-input"
                value={dateOptions.EXACT}
                checked={dateFiledWithCourtOption === dateOptions.EXACT}
                onChange={handleDateFiledWithCourtOptionChange}
              />
              <label
                htmlFor="dateFiledWithCourtOptionExact"
                className="custom-control-label"
                id="dateFiledWithCourtOptionExactLabel"
              >
                {dateOptions.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dateFiledWithCourtOptionExplanation"
                type="radio"
                id="dateFiledWithCourtOptionExplanation"
                className="custom-control-input"
                value={dateOptions.EXPLANATION}
                checked={dateFiledWithCourtOption === dateOptions.EXPLANATION}
                onChange={handleDateFiledWithCourtOptionChange}
              />
              <label
                htmlFor="dateFiledWithCourtOptionExplanation"
                className="custom-control-label"
                id="dateFiledWithCourtOptionExplanationLabel"
              >
                {dateOptions.EXPLANATION}
              </label>
            </div>
            {dateFiledWithCourtOption === dateOptions.EXPLANATION ? (
              <div className="date-explanation-container">
                <label
                  htmlFor="dateFiledWithCourtExplanation"
                  className="field-label"
                  id="dateFiledWithCourtExplanationLabel"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dateFiledWithCourtExplanation"
                  className={
                    dateFiledWithCourtExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  rows="3"
                  cols={500}
                  value={dateFiledWithCourtExplanation}
                  onChange={handleTextInputChange}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="dateFiledWithCourtExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row date-wrapper">
        <div className="col-md-12">
          <label
            id="dateLearnedLabel"
            className="field-label"
            htmlFor="dateLearned"
          >
            Date individual learned of the Judgment/Lien: *
          </label>
          <ReactInputMask
            id="dateLearned"
            mask="99/99/9999"
            maskChar="_"
            className={
              dateLearnedError ? "form-control is-invalid" : "form-control"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={dateLearned}
            onChange={handleTextInputChange}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
            style={{ width: "35%" }}
          />
          <div id="dateLearnedError" className="invalid-feedback">
            {dateLearnedErrorMsg}
          </div>
        </div>
      </div>
      <div className="row" style={{ marginLeft: "15px" }}>
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="dateLearnedOptionExact"
                type="radio"
                id="dateLearnedOptionExact"
                className="custom-control-input"
                value={dateOptions.EXACT}
                checked={dateLearnedOption === dateOptions.EXACT}
                onChange={handleDateLearnedOptionChange}
              />
              <label
                htmlFor="dateLearnedOptionExact"
                className="custom-control-label"
                id="dateLearnedOptionExactLabel"
              >
                {dateOptions.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="dateLearnedOptionExplanation"
                type="radio"
                id="dateLearnedOptionExplanation"
                className="custom-control-input"
                value={dateOptions.EXPLANATION}
                checked={dateLearnedOption === dateOptions.EXPLANATION}
                onChange={handleDateLearnedOptionChange}
              />
              <label
                htmlFor="dateLearnedOptionExplanation"
                className="custom-control-label"
                id="dateLearnedOptionExplanationLabel"
              >
                {dateOptions.EXPLANATION}
              </label>
            </div>
            {dateLearnedOption === dateOptions.EXPLANATION ? (
              <div className="date-explanation-container">
                <label
                  htmlFor="dateLearnedExplanation"
                  className="field-label"
                  id="dateLearnedExplanationLabel"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dateLearnedExplanation"
                  className={
                    dateLearnedExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  rows="3"
                  cols={500}
                  value={dateLearnedExplanation}
                  onChange={handleTextInputChange}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="dateLearnedExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row backbutton-drp-judgment-lien">
        <div className="col-md-12 buttons">
          <span
            id="judgmentLienAddDRPBack"
            className="back-button"
            onClick={() => {
              isUpdates ? handleToggleBackModal(true) : handleBack();
            }}
          >
            <strong id="judgmentLienBack">Back</strong>
          </span>
          <Button
            disabled={isNextDisabled}
            id="judgmentLienAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};

export default JudgmentLienAddDRPJudgmentLienDetailsForm;

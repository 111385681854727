import { Component } from "react";
import { Button } from "react-blueprint-library";

import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../../../constants/Contract";

class ReviewCorporationInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: "",
      personalInfo: "",
      opportunitydesc: "",
    };
  }

  render() {
    return (
      <div id="reviewCorporationInfo" className="review-form">
        <div className="col-7 row mm-spacing--minor">
          <h3 id="conf_corporation_info">Corporation Information</h3>
        </div>

        {this.props.contracttype === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ? (
          <>
            <div className="candidate-info">
              <div className="row">
                <div className="col-5">
                  <strong id="corpLegalCorpNameLabel">
                    Legal Registered Corporation Name*
                  </strong>
                </div>
                <div id="corpCorpName" className="col-6 bo--text-color">
                  {this.props.corpInfo.corpname !== ""
                    ? this.props.corpInfo.corpname
                    : "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <strong id="corpOfficerNameLabel">
                    Name of Corporation Officer*
                  </strong>
                </div>
                <div id="corpOfficerName" className="col-6 bo--text-color">
                  {this.props.corpInfo.corpofficername !== ""
                    ? this.props.corpInfo.corpofficername
                    : "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <strong id="corpTaxIDLabel">Tax ID*</strong>
                </div>
                <div id="piSSN" className={"col-7 bo--text-color"}>
                  {this.props?.corpInfo?.corptaxid ? (
                    <>{`${this.props?.corpInfo?.corptaxid.slice(
                      0,
                      2
                    )}-XXXXXXX`}</>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <strong id="corpNumberOfRepsLabel">
                    Number of Representatives affiliated with Corporation*
                  </strong>
                </div>
                <div id="corpNumberOfReps" className="col-6 bo--text-color">
                  {this.props.corpInfo.numberofreps !== ""
                    ? this.props.corpInfo.numberofreps
                    : "-"}
                </div>
              </div>

              <div className="row">
                <div className="col-5">
                  <strong id="corpEmailAddressLabel">
                    Broker Corporation Email Address*
                  </strong>
                </div>
                <div id="corpEmailAddress" className="col-6 bo--text-color">
                  {this.props.corpInfo.corpemailaddress !== ""
                    ? this.props.corpInfo.corpemailaddress
                    : "-"}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="candidate-info">
              <div className="row">
                <div className="col-5">
                  <strong id="corpLegalCorpNameLabel">
                    Legal Registered Corporation Name*
                  </strong>
                </div>
                <div id="corpCorpName" className="col-6 bo--text-color">
                  {this.props.corpInfo.corpname !== ""
                    ? this.props.corpInfo.corpname
                    : "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <strong id="corpTaxIDLabel">Tax ID*</strong>
                </div>
                <div id="piSSN" className={"col-7 bo--text-color"}>
                  {this.props?.corpInfo?.corptaxid ? (
                    <>{`XXX-XX-${this.props?.corpInfo?.corptaxid.slice(-7)}`}</>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ReviewCorporationInfo;

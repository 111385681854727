function ConfAppResAddress(props) {
  let resAddress = getResAddress(props.res_address);

  return (
    <div id="confidential-residential-address">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_resaddress_text">Residential Address</h3>
      </div>

      <div className="row">
        <div className="col-3">
          <strong id="conf_address_text">Address*</strong>
        </div>
        <div id="conf_residential_address" className="col-9 data">
          {resAddress}
        </div>
      </div>
    </div>
  );
}

function getResAddress(residentialAddress) {
  try {
    let resAddress = "";
    const { address1, address2, city, state, zip, resurbanization } =
      residentialAddress;
    const isThereUrbanization =
      resurbanization !== undefined &&
      resurbanization !== "" &&
      resurbanization !== null;

    resAddress = `
    ${state === "PR" && isThereUrbanization ? `${resurbanization}, ` : ""}
    ${address1}, ${
      address2 === "" ? "" : `${address2}, `
    }${city}, ${state}, ${zip}`;

    return resAddress;
  } catch (e) {
    console.log(e);
    return { error: "error with residential address" };
  }
}

export default ConfAppResAddress;

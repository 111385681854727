import React, { useEffect, useMemo } from "react";
import DRPCard from "../../common/DRPCard";
import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";

import "./RegulatoryActionDRPSummary.scss";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../store/actions";
import { groupArrayByKey } from "../../helpers";
import {
  deleteSingleCCB14DrpReg,
  getSingleCCBDRPRegByQkey,
} from "../../../../../../api/CCB/DRP/regulatoryDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import { putCCBStatus } from "../../../../../../store/middlewares";

const RegulatoryActionDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  setIsDRPNextDisabled,
  updateCCB14CompletedInd,
  isLoading,
  setIsLoading,
  setIsDRPCompleted,
  updateCCB14Step,
  data,
  secCFTC = [],
  actReg = [],
  actSRO = [],
  proSusp = [],
  regFormInv = {},
  regFormAct = {},
  setIsDataRefetch,
}) => {
  const dispatch = useDispatch();

  const resetShowBackModal = () =>
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

  const regFormActArr = Object.keys(regFormAct).length ? [regFormAct] : [];

  const restREG = [
    ...secCFTC,
    ...actReg,
    ...actSRO,
    ...proSusp,
    ...regFormActArr,
  ];

  const existingRegKeys = restREG?.flatMap((x) => x.key); // TOOD: change it here add the arrays
  const currRegKeys = [...new Set(data?.reg.flatMap((x) => x.qKey))];

  const currRegFormInvKeys = !!Object.keys(regFormInv)?.length
    ? [regFormInv]?.flatMap((x) => x.key)
    : [];
  const existingRegFormInvKeys = [
    ...new Set(data?.regFormInv?.flatMap((x) => x.qKey)),
  ];

  const regDraft = data?.reg.find((x) => x.isDraft === true);
  const regFormInvDraft = data?.regFormInv?.find((x) => x.isDraft === true);

  const isDisabled =
    existingRegKeys.length !== currRegKeys.length ||
    currRegFormInvKeys.length !== existingRegFormInvKeys.length ||
    regDraft ||
    regFormInvDraft;

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { regulatorystatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ regActionDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ regActionDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ regActionDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isLoading]);

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case headerText.REG:
        s = "reg";
        break;
      case headerText.REG_PRO_SUSP:
        s = "prof";
        break;
      case headerText.REG_ACT_REG:
        s = "other";
        break;
      case headerText.REG_SEC_CFTC:
        s = "sec_cftc";
        break;
      case headerText.REG_ACT_SRO:
        s = "sro";
        break;
      case headerText.REG_FORM_ACT:
        s = "form";
        break;
      case headerText.REG_FORM_INV:
        s = "inv";
        break;
      default:
        break;
    }

    return s;
  };

  const handleOnClickReg = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_ACT_DETAILS, {
      section: headerText.REG,
      values: restREG,
      isReset: true,
    });
  };

  const handleOnClickRegFormInv = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_FORM_INV_DETAILS, {
      section: headerText.REG_FORM_INV,
      values: [regFormInv],
      isReset: true,
    });
  };

  const listREG = useMemo(() => {
    const regData = [...data?.reg];

    return groupArrayByKey(regData, "qKey");
  }, [data?.reg]);

  const listRegFormInv = useMemo(
    () => groupArrayByKey(data?.regFormInv, "qKey"),
    [data?.regFormInv]
  );

  const editDRPREG = async ({ section, questionKey, dataIndex, isDraft }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });

    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_ACT_DETAILS, {
      section: headerText.REG,
      values: restREG,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPregFormInv = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_FORM_INV_DETAILS, {
      section: headerText.REG_FORM_INV,
      values: [regFormInv],
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const deleteDRPReg = async ({ section, questionKey, dataIndex }) => {
    setIsLoading(true);

    await deleteSingleCCB14DrpReg({
      oktaSubId,
      section: sectionHandler(section),
      questionKey,
      index: dataIndex,
      drpTypeId: DRP_TYPES.Regulatory,
    });

    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.DRP_SUMMARY, {
      isDelete: true,
      index: dataIndex,
    });

    setIsDataRefetch(true);
  };

  return (
    <div className="reg-act-disclosure-drp-summary">
      {restREG?.length ? (
        <DRPCard header={headerText.REG} onClick={handleOnClickReg}>
          {restREG?.map(({ key, mainQuestion, question }, index) => (
            <>
              <DRPSummaryListingCard
                key={`${index}_${key}_${question}`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                length={restREG?.length}
                list={listREG[key]}
                isSummaryPage
                isLoading={isLoading}
                editDRP={editDRPREG}
                deleteDRP={deleteDRPReg}
                section={headerText.REG}
              />
            </>
          ))}
        </DRPCard>
      ) : null}

      <>
        {regFormInv &&
        Object.keys(regFormInv)?.length &&
        [regFormInv]?.length ? (
          <DRPCard
            header={headerText.REG_FORM_INV}
            onClick={handleOnClickRegFormInv}
          >
            <DRPSummaryListingCard
              key={`${1}_${regFormInv?.key}`}
              questionKey={regFormInv?.key}
              mainQuestion={regFormInv?.mainQuestion}
              question={regFormInv?.question}
              index={1}
              length={1}
              list={listRegFormInv[regFormInv?.key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPregFormInv}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_FORM_INV}
            />
          </DRPCard>
        ) : null}
      </>
    </div>
  );
};

export default RegulatoryActionDRPSummary;

export const TOAST_MESSAGE = "Your Disclosure has been saved successfully.";

export const INTRO_TEXT = `If the answer to any of the following questions is “Yes”, please
provide details of all events or proceedings on the appropriate
Disclosure Reporting Page(s).`;

export const INTRO_REV = `Rev. Form U4`;

export const SECTION = {
  individualCriminalDisclosure: 1,
  organizationCriminalDisclosure: 2,
  regulatoryActionSECorCFTC: 3,
  regulatoryActionOtherRegulators: 4,
  regulatoryActionSRO: 5,
  professionalSuspension: 6,
  formalPendingActionInvestigation: 7,
  civilJudicialActions: 8,
  customerComplaintArbitrationCivilLitigation: 9,
  terminationDisclosures: 10,
  personalBankruptcy: 11,
  organizationBankruptcy: 12,
  bondDisclosures: 13,
  judgementLienDisclosures: 14,
};

export const CCB_SECTION_TITLE = {
  criminalDisclosure: "Criminal Disclosure",
  regulatoryAction: "Regulatory Action Disclosure",
  civilJudicial: "Civil Judicial Disclosure",
  customerComplaint: "Customer Complaint Disclosure",
  terminationDisclosure: "Termination Disclosure",
  financialDisclosure: "Financial Disclosure",
};

export const CCB_SUB_SECTION_TITLE = {
  bond: "Bond",
  individualBankruptcy: "Personal Bankruptcy",
  organizationalBankruptcy: "Organization Bankruptcy",
  judgmentLien: "Judgment/Lien",
  individual: "Individual Criminal",
  organization: "Organization Criminal",
  regulatoryActionSRO: "Regulatory Action by SRO",
  regulatoryActionSECorCFTC: "Regulatory Action by SEC or CFTC",
  regulatoryAction: "Regulatory Action",
  regulatoryActionOtherRegulators: "Regulatory Action by other regulators",
  professionalSuspension: "Professional Suspension",
  formalPendingActionInvestigation: "Formal Pending Action",
  investigation: "Investigation",
  termination: "Termination",
  customerComplaint: "Customer Complaint",
  civilJudicial: "Civil Judicial",
};

export const CCB_DATA_TABLE_HEADERS = {
  criminalDisclosureIndividual: [
    "Date First Charged",
    "Description",
    "Disclosure Question(s)",
  ],
  criminalDisclosureOrganization: [
    "Date First Charged",
    "Org Name",
    "Description",
    "Disclosure Question(s)",
  ],
  regulatoryAction: [
    "Date Initiated",
    "Action Initiated by",
    "Status",
    "Disclosure Question(s)",
  ],
  investigation: [
    "Notice Date",
    "Notice received from:",
    "Status",
    "Disclosure Question(s)",
  ],
  bankruptcy: [
    "Action Date",
    "Action Type",
    "Disposition",
    "Disclosure Question(s)",
  ],
  organizationBankruptcy: [
    "Action Date",
    "Org Name",
    "Action Type",
    "Disposition",
    "Disclosure Question(s)",
  ],
  bond: ["Firm Name", "Disposition Type", "Disclosure Question(s)"],
  judgmentLien: [
    "Date filed with Court",
    "Judgment/Lien",
    "Judgment/Lien Holder",
    "Disclosure Question(s)",
  ],
  termination: [
    "Termination Date",
    "Termination Type",
    "Disclosure Question(s)",
  ],
  customerComplaint: [
    "Date received",
    "Reporting Type",
    "Status",
    "Disclosure Question(s)",
  ],
  civilJudicial: [
    "Filing Date",
    "Action Initiated by",
    "Status",
    "Disclosure Question(s)",
  ],
};

export const CCB_DUMMY_DATA = {
  criminalDisclosure: [
    {
      datefirstcharged: "01/01/1999",
      description: {
        0: "Felony: 1 count of Possession of controlled substance",
      },
      disclosurequestions: { 0: "14A(1)(a)", 1: "14A(1)(b)" },
    },
    {
      datefirstcharged: "01/01/1999",
      description: {
        0: "Felony: 1 count of Possession of controlled substance",
        1: "Felony: 2 counts of sale of controlled substance",
      },
      disclosurequestions: { 0: "14A(1)(b)" },
    },
  ],
  regulatoryAction: [
    {
      dateinitiated: "01/01/1999",
      actioninitiatedby: "SEC",
      status: "Final",
      disclosurequestions: { 0: "14G(1)" },
    },
    {
      dateinitiated: "01/01/1999",
      actioninitiatedby: "SRO",
      status: "On Appeal",
      disclosurequestions: { 0: "14G(2)" },
    },
  ],
  bankruptcy: [
    {
      actiondate: "01/01/1999",
      actiontype: "Bankruptcy: Chapter 11",
      disposition: "Discharged",
    },
    {
      actiondate: "01/01/1999",
      actiontype: "Bankruptcy: [bankruptcy dropdown]",
      disposition: "Direct Payment Procedure",
    },
    {
      actiondate: "01/01/1999",
      actiontype: "Compromise: [name of creditor]",
      disposition: "Pending",
    },
  ],
  bond: [
    { firmname: "ABC Company", dispositiontype: "Denied" },
    { firmname: "XYZ Firm Inc.", dispositiontype: "Payout" },
  ],
};
export const KEYS = {
  individualCriminalDisclosure: [
    "convictedfelony",
    "chargedfelony",
    "convictedmisdemeanor",
    "chargedmisdemeanor",
  ],
  organizationCriminalDisclosure: [
    "orgconvictedfelony",
    "orgchargedfelony",
    "orgconvictedmisdemeanor",
    "orgchargedmisdemeanor",
  ],
  regulatoryActionSECorCFTC: [
    "secfalsestatement",
    "secinvolvedviolation",
    "seccauseinvestrelatedauth",
    "secorderagainst",
    "secceaseanddesist",
    "secviolatedsecuritiesact",
    "secaidedabettersecuritiesact",
    "secfailedtosupervise",
  ],
  regulatoryActionOtherRegulators: [
    "fedfalsestatement",
    "fedinvolvedviolation",
    "fedcauseinvestrelatedauth",
    "fedorderagainst",
    "fedregdenied",
    "finalorderbarred",
    "finalorderviolations",
  ],
  regulatoryActionSRO: [
    "selffalsestatement",
    "selfinvolvedviolation",
    "selfcauseinvestrelatedauth",
    "selfdisciplined",
    "selfviolatedsecuritiesact",
    "selfaidedabettersecuritiesact",
    "selffailedtosupervise",
  ],
  professionalSuspension: ["authrevoked"],
  formalPendingActionInvestigation: [
    "notifiedproceeding",
    "notifiedinvestigation",
  ],
  civilJudicialActions: [
    "enjoined",
    "foundinvolvedviolation",
    "dismissedcivilaction",
    "namedinpendingcivilaction",
  ],
  customerComplaintArbitrationCivilLitigation: [
    "litigationpending",
    "litigationaward",
    "litigationsettledprior",
    "litigationsettledafter",
    "complaintsettledprior",
    "complaintsettledafter",
    "complaint24monthssalesviolation",
    "complaint24monthsforgery",
    "violationover15k",
    "violationaward",
    "arbitrationsalesviolation",
    "arbitrationforgery",
  ],
  terminationDisclosures: [
    "termviolation",
    "termfraud",
    "termfailuretosupervise",
  ],
  personalBankruptcy: ["tenyearscompromise"],
  organizationBankruptcy: [
    "tenyearsorgcompromise",
    "tenyearsbankruptcyboolean",
  ],
  bondDisclosures: ["deniedbonding"],
  judgementLienDisclosures: ["unsatisfiedjudgements"],
};

export const initialState = {
  enjoined: null,
  foundinvolvedviolation: null,
  dismissedcivilaction: null,
  namedinpendingcivilaction: null,
};

const civJudAct = {
  mainQuestion: "Has any domestic or foreign court ever:",
  questionA: `(a) enjoined you in connection with any investment-related activity?`,
  questionB: `(b) found that you were involved in a violation of any investment-related statute(s) or regulation(s)?`,
  questionC: `(c) dismissed, pursuant to a settlement agreement, an investment-related civil action brought against you by a state or foreign financial regulatory authority?`,
};

const civJudActPend = {
  mainQuestion: civJudAct.mainQuestion,
  questionA: `Are you named in any pending investment-related civil action that could result in a "yes" answer to any part of 14H(1)?`,
};

export const DRPSummaryCivilJudicialActions = {
  enjoined: {
    key: "14H(1)(a)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionA,
  },
  foundinvolvedviolation: {
    key: "14H(1)(b)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionB,
  },
  dismissedcivilaction: {
    key: "14H(1)(c)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionC,
  },
  namedinpendingcivilaction: {
    key: "14H(2)",
    mainQuestion: "",
    question: civJudActPend.questionA,
  },
};

export const civilJudicialActions = (disabled) => [
  {
    header: `14H (1). ${civJudAct.mainQuestion}`,
    subHeader: "",
    questionA: civJudAct.questionA,
    questionB: civJudAct.questionB,
    questionC: civJudAct.questionC,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "enjoined-y",
      idTwo: "enjoined-n",
      name: "enjoined",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "foundinvolvedviolation-y",
      idTwo: "foundinvolvedviolation-n",
      name: "foundinvolvedviolation",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "dismissedcivilaction-y",
      idTwo: "dismissedcivilaction-n",
      name: "dismissedcivilaction",
      disabled: disabled,
    },
  },
  {
    header: "",
    subHeader: "(2) " + civJudActPend.questionA,
    questionA: ``,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "namedinpendingcivilaction-y",
      idTwo: "namedinpendingcivilaction-n",
      name: "namedinpendingcivilaction",
      disabled: disabled,
    },
  },
];

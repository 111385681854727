import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isDrpSummaryPage } from "./utils";
import { FORM_ID } from "../../../../../constants/Forms";
import {
  CCB_FORM_ID,
  headerText,
  initialState,
  regActDetails,
  defTimelineSteps,
  // defTimelineSteps2,
  // defTimelineSteps3,
  // defTimelineSteps4,
  // defTimelineSteps5,
  defTimelineSteps6,
} from "./constants";
import { convertGroupKey } from "../helpers";
import { UtagTracking } from "../../../../../utils/utag-tracking";

import DisclosureReportingPageLayout from "../common/DRPLayout";
import RegulatoryActionForm from "./RegulatoryActionForm";
import RegulatoryActionDRPSummary from "./RegulatoryActionDRPSummary";
import AddDRPFormLayout from "../common/AddDRPFormLayout";

import RegActionDetailsForm from "./AddDRPForms/RegActionDetailsForm";
import RegActionCommentsForm from "./AddDRPForms/RegActionCommentsForm";
import RegActionInvStatusDetails from "./AddDRPForms/RegActionInvStatusDetails";
import RegActionStatusDetails, {
  eventStatus,
} from "./AddDRPForms/RegActionStatusDetails";
import RegActionResDetailsForm from "./AddDRPForms/RegActionResDetailsForm";
import RegActionInvDetails from "./AddDRPForms/RegActionInvDetails";
import RegActionSanctionDetailsForm from "./AddDRPForms/RegActionSanctionDetailsForm";
import {
  getCCBDRPRegList,
  insertUpdateCCB14DRP,
  updateSingleCCB14DrpReg,
} from "../../../../../api/CCB/DRP/regulatoryDRP";
import { DRP_TYPES } from "../../../../../api/helpers/constants";

const drpListInitState = {
  reg: [],
  regFormInv: [],
};

const RegulatoryAction = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  candidateId,
  agencynumber,
  contracttypeid,
}) => {
  const { data: ccbData, isBack } = useSelector((state) => state.ccb14);
  const [data, setData] = useState(initialState);
  const [values, setValues] = useState(null);

  const [customTimelineSteps, setCustomTimelineSteps] =
    useState(defTimelineSteps);
  const [customTimelineSteps6, setCustomTimelineSteps6] =
    useState(defTimelineSteps6);

  const [skipResAndSanctDetails, setSkipResAndSanctDetails] = useState(false);

  const [isDraft, setIsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setIsDataRefetch] = useState();
  const [drpList, setDrpList] = useState({
    ...drpListInitState,
  });
  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(false);
  const [isNewData, setIsNewData] = useState(false);
  const [dataIndex, setDataIndex] = useState(null);

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);

  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.REGULATORY_ACTION
  );

  const {
    secCFTC,
    actReg,
    actSRO,
    proSusp,
    regFormActInv,
    uncheckedSecCFTC,
    uncheckedActReg,
    uncheckedActSRO,
    uncheckedProSusp,
    uncheckedRegFormActInv,
  } = isDrpSummaryPage(ccbData);

  useEffect(() => {
    if (
      (secCFTC.length ||
        actReg.length ||
        actSRO.length ||
        proSusp.length ||
        regFormActInv.length) &&
      isBack
    ) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }
    UtagTracking.sendPageNameView(
      "regulatoryactionform",
      candidateId,
      agencynumber,
      contracttypeid
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isMounted = useRef(false);

  const fetchCCB14DRP = async () => {
    try {
      setIsLoading(true);
      const list = await getCCBDRPRegList({
        oktaSubId,
        drpTypeId: DRP_TYPES.Regulatory,
      });

      setDrpList({
        reg: list?.reg || [],
        regFormInv: list.regFormInv,
      });

      isMounted.current = false;
      setIsLoading(false);
      setIsDataRefetch(false);
    } catch (err) {
      isMounted.current = false;
      setIsLoading(false);
      setIsDataRefetch(false);
      if (err) {
        console.error(err);
      }
    }
  };

  useEffect(
    () => {
      isMounted.current = true;

      if (isMounted.current) {
        fetchCCB14DRP();
      }

      return () => {
        isMounted.current = false;
        window.onbeforeunload = null;
        setIsDataRefetch(false);
      };
    } /* eslint-disable-next-line */,
  	[]
  );

  useEffect(
    () => {
      if (isRefetch) {
        fetchCCB14DRP();
      }
    } /* eslint-disable-next-line */,
  	[isRefetch]
  );

  const updateCCBForm = (formId, options = null) => {
    if (options?.values) {
      setValues(options?.values);
    }

    if (options?.isReset) {
      setIsDataRefetch(true);
      setIsNewData(true);
      setDataIndex(null);
      setData(initialState);
    }

    if (options?.isEdit) {
      if (options.data && options.section) {
        const { dataIndex: dI, isDraft, ...rest } = options.data;
        setDataIndex(dI);
        setIsDraft(isDraft === false ? false : null);
        setData({ [convertGroupKey(options.section)]: rest });
        setIsNewData(false);
      }
    }

    if (options?.isDelete) {
      if (typeof options.index === "number" && options.index >= 0) {
        setDataIndex(options.index);
        setIsNewData(false);
      }
    }

    if (!isDRPCompleted && options?.isBackClick) {
      updateCCB14Step({ regActionDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: false });
    }

    setCurrentFormId(formId);
  };

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case headerText.REG:
        s = "reg";
        break;
      case headerText.REG_FORM_INV:
        s = "inv";
        break;
      default:
        break;
    }

    return s;
  };

  const getSubmitDataValues = (newData, name, completed = false) => {
    let groupKey = "";

    switch (name) {
      case headerText.REG:
        groupKey = convertGroupKey(name);
        break;
      case headerText.REG_FORM_INV:
        groupKey = convertGroupKey(name);
        break;
      default:
        break;
    }

    const result = { ...data[groupKey], ...newData };

    const submitData = async () => {
      setIsLoading(true);

      try {
        await insertUpdateCCB14DRP({
          oktaSubId,
          drpTypeId: DRP_TYPES.Regulatory,
          data: {
            groupKey,
            data: result,
            isNewData,
            isDraft: !completed,
          },
        });
        if (completed) {
          setIsLoading(true);
          setIsDataRefetch(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    const updateData = async () => {
      try {
        await updateSingleCCB14DrpReg({
          oktaSubId,
          drpTypeId: DRP_TYPES.Regulatory,
          data: {
            groupKey,
            data: result,
            isNewData,
            isDraft: isDraft === false ? isDraft : !completed,
          },
          section: sectionHandler(name),
          index: dataIndex,
        });
        if (completed) {
          setIsLoading(true);
          setIsDataRefetch(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    if (Object.keys(newData) && dataIndex === null && completed) {
      setIsLoading(true);
      submitData();
      setIsNewData(false);
    } else {
      if (typeof dataIndex === "number" && completed) {
        setIsLoading(true);
        updateData();
      }
    }

    setData((prevData) => ({
      ...prevData,
      [groupKey]: { ...prevData[groupKey], ...newData },
    }));
  };

  const inv1 =
    regFormActInv?.length && regFormActInv.find((reg) => reg?.key === "14G(1)");
  const inv2 =
    regFormActInv?.length && regFormActInv.find((reg) => reg?.key === "14G(2)");

  const inv1Unchecked =
    uncheckedRegFormActInv?.length &&
    uncheckedRegFormActInv.find((reg) => reg?.key === "14G(1)");
  const inv2Unchecked =
    uncheckedRegFormActInv?.length &&
    uncheckedRegFormActInv.find((reg) => reg?.key === "14G(2)");

  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.REGULATORY_ACTION:
        return (
          <RegulatoryActionForm
            header={headerText.HEADER}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            uncheckedSecCFTC={uncheckedSecCFTC}
            uncheckedActReg={uncheckedActReg}
            uncheckedActSRO={uncheckedActSRO}
            uncheckedProSusp={uncheckedProSusp}
            uncheckedRegFormInv={inv1Unchecked}
            uncheckedRegFormAct={inv2Unchecked}
            uncheckedRegFormActInv={uncheckedRegFormActInv}
            backFormId={FORM_ID.CRIMINAL_DISCLOSURE}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
            setIsDataRefetch={setIsDataRefetch}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.HEADER}
            backFormId={CCB_FORM_ID.REGULATORY_ACTION}
            nextFormId={FORM_ID.CIVIL_JUDICIAL_DISCLOSURE}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            ind="regActionDisclosureCompleteInd"
            updateCCBForm={updateCCBForm}
            isNextDisabled={isDRPNextDisabled || isLoading}
            toggleForm={toggleForm}
            isLoading={isLoading}
          >
            <RegulatoryActionDRPSummary
              oktaSubId={oktaSubId}
              setIsDRPNextDisabled={setIsDRPNextDisabled}
              updateCCB14CompletedInd={updateCCB14CompletedInd}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={drpList}
              updateCCB14Step={updateCCB14Step}
              setIsDRPCompleted={setIsDRPCompleted}
              updateCCBForm={updateCCBForm}
              secCFTC={secCFTC}
              actReg={actReg}
              actSRO={actSRO}
              proSusp={proSusp}
              regFormAct={inv1}
              regFormInv={inv2}
              setIsDataRefetch={setIsDataRefetch}
            />
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.REG_ACT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            notPassed
            notApplicable={skipResAndSanctDetails ? [3, 4] : []}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Action Details"
            description={regActDetails.DEF_DESCRIPTION}
          >
            <RegActionDetailsForm
              data={data[convertGroupKey(headerText.REG)]?.actDetails}
              status={
                data[convertGroupKey(headerText.REG)]?.statusDetails?.status
              }
              setSkipResAndSanctDetails={setSkipResAndSanctDetails}
              values={values}
              groupKey="actDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.REG_STATUS_DETAILS}
              headerText={headerText.REG}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_STATUS_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={2}
            steps={customTimelineSteps}
            notApplicable={skipResAndSanctDetails ? [3, 4] : []}
            notPassed
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Status Details"
          >
            <RegActionStatusDetails
              data={data[convertGroupKey(headerText.REG)]?.statusDetails}
              setSkipResAndSanctDetails={setSkipResAndSanctDetails}
              groupKey="statusDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.REG_ACT_DETAILS}
              nextFormId={CCB_FORM_ID.REG_RES_DETAILS}
              skipFormId={CCB_FORM_ID.REG_COMMENTS}
              headerText={headerText.REG}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_RES_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={3}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Resolution Details"
          >
            <RegActionResDetailsForm
              data={data[convertGroupKey(headerText.REG)]?.resDetails}
              groupKey="resDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.REG_STATUS_DETAILS}
              nextFormId={CCB_FORM_ID.REG_SANCT_DETAILS}
              headerText={headerText.REG}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_SANCT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={4}
            steps={customTimelineSteps}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Sanction Details"
          >
            <RegActionSanctionDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.REG_RES_DETAILS}
              nextFormId={CCB_FORM_ID.REG_COMMENTS}
              groupKey="sanctionDetails"
              getSubmitDataValues={getSubmitDataValues}
              data={data[convertGroupKey(headerText.REG)]?.sanctionDetails}
              headerText={headerText.REG}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={5}
            steps={customTimelineSteps}
            notApplicable={skipResAndSanctDetails ? [3, 4] : []}
            setCustomTimelineSteps={setCustomTimelineSteps}
            details="Comments"
          >
            <RegActionCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={
                skipResAndSanctDetails === true ||
                data[convertGroupKey(headerText.REG)]?.statusDetails?.status ===
                  eventStatus.PENDING
                  ? CCB_FORM_ID.REG_STATUS_DETAILS
                  : CCB_FORM_ID.REG_SANCT_DETAILS
              }
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              getSubmitDataValues={getSubmitDataValues}
              groupKey="comments"
              data={data[convertGroupKey(headerText.REG)]?.comments}
              headerText={headerText.REG}
            />
          </AddDRPFormLayout>
        );

      case CCB_FORM_ID.REG_FORM_INV_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={headerText.REG_FORM_INV}
            activeStep={1}
            steps={customTimelineSteps6}
            setCustomTimelineSteps={setCustomTimelineSteps6}
            details="Investigation Details"
            description={regActDetails.INV_DESCRIPTION}
          >
            <RegActionInvDetails
              data={data[convertGroupKey(headerText.REG_FORM_INV)]?.invDetails}
              groupKey="invDetails"
              values={values}
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.REG_FORM_INV_STATUS_DETAILS}
              headerText={headerText.REG_FORM_INV}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_FORM_INV_STATUS_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={headerText.REG_FORM_INV}
            activeStep={2}
            steps={customTimelineSteps6}
            setCustomTimelineSteps={setCustomTimelineSteps6}
            details="Status Details"
          >
            <RegActionInvStatusDetails
              data={
                data[convertGroupKey(headerText.REG_FORM_INV)]?.statusDetails
              }
              groupKey="statusDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.REG_FORM_INV_DETAILS}
              nextFormId={CCB_FORM_ID.REG_FORM_INV_COMMENTS}
              headerText={headerText.REG_FORM_INV}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.REG_FORM_INV_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={headerText.REG_FORM_INV}
            activeStep={3}
            steps={customTimelineSteps6}
            setCustomTimelineSteps={setCustomTimelineSteps6}
            details="Comments"
          >
            <RegActionCommentsForm
              updateCCBForm={updateCCBForm}
              isInv
              backFormId={CCB_FORM_ID.REG_FORM_INV_STATUS_DETAILS}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              getSubmitDataValues={getSubmitDataValues}
              data={data[convertGroupKey(headerText.REG_FORM_INV)]?.comments}
              groupKey="comments"
              headerText={headerText.REG_FORM_INV}
            />
          </AddDRPFormLayout>
        );

      default:
        break;
    }
  };

  return (
    <div className="regulatory-action-disclosure-wrapper">
      {renderForm(currentFormId)}
    </div>
  );
};

export default RegulatoryAction;

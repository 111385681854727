import React, { useEffect, useState } from "react";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../constants/Contract";
import {
  isBrokerORMOC,
  isCareerContract,
  isNotNullUndefinedOrEmpty,
} from "../../../../common/Functions";

const ConfAppContractDetails = ({
  contract_details,
  contracttypedesc,
  hasFinancingAddon,
}) => {
  const [contractDetails, setContractDetails] = useState({});

  useEffect(() => {
    if (contract_details) {
      setContractDetails(contract_details);
    }
  }, [contract_details]);

  const renderContractDetailRow = (label, value) => (
    <div className="row">
      <div className="col-3">
        <strong>{label}&nbsp;</strong>
      </div>
      <div className="col-6 data">{value || "-"}</div>
    </div>
  );

  return (
    <div id="confidential-contract">
      <div className="mm-spacing">
        <div className="col-7 row mm-spacing--minor">
          <h3 id="conf_personal_info">Contract Details</h3>
        </div>
        {contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS && (
          <>
            {renderContractDetailRow(
              "Contract Effective Date*",
              contractDetails.effectivedate
            )}
            {renderContractDetailRow(
              isBrokerORMOC(contracttypedesc)
                ? "Brokerage Director Name*"
                : "Sales Manager Name*",
              contractDetails.brokeragedirectorname
            )}
            {renderContractDetailRow(
              isBrokerORMOC(contracttypedesc)
                ? "Brokerage Unit Number*"
                : "Sales Unit Number*",
              contractDetails.brokerageunitnumber
            )}
            {(!isCareerContract(contracttypedesc) ||
              !contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) &&
              renderContractDetailRow(
                "Comments from Agency",
                contractDetails.comment
              )}
          </>
        )}
      </div>
      {(isCareerContract(contracttypedesc) ||
        contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) && (
        <>
          <div className="mm-section mm-spacing">
            MASSMUTUAL BUSINESS ADDRESS
          </div>
          {renderContractDetailRow(
            "Street Address*",
            contractDetails.mmbusinessaddress1
          )}
          {renderContractDetailRow(
            "Apartment, Suite, Building, Gate Code",
            contractDetails.mmbusinessaddress2
          )}
          {renderContractDetailRow("City*", contractDetails.mmbusinesscity)}
          {renderContractDetailRow(
            "State/Province*",
            contractDetails.mmbusinessstate
          )}
          {renderContractDetailRow(
            "ZIP Code*",
            contractDetails.mmbusinesszipcode
          )}
          {contractDetails.mmbusinessstate === "PR" &&
            renderContractDetailRow(
              "Urbanization",
              contractDetails.mmbusinessurbanization
            )}
          {renderContractDetailRow(
            "Business Phone Number*",
            contractDetails.mmbusinessphonenumber
          )}
          {!hasFinancingAddon && (
            <>
              <hr />
              {renderContractDetailRow(
                "Comments from Agency",
                contractDetails.comment
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ConfAppContractDetails;

import Moment from "moment";
import { Component, Fragment } from "react";
import "../../../../../assets/stylesheets/ConfidentialApplication/MMLIS.scss";
import ConfAppMMLIS1 from "./ConfAppMMLIS1";
import ConfAppMMLIS2 from "./ConfAppMMLIS2";
import ReviewMMLISPayToPlay1 from "./ReviewMMLISPayToPlay1";
import ReviewMMLISPayToPlay2 from "./ReviewMMLISPayToPlay2";

class ReviewMMLIS extends Component {
  constructor(props) {
    super(props);
    this.toggleReview1 = this.toggleReview1.bind(this);
    this.toggleReview2 = this.toggleReview2.bind(this);
    this.hideReview = this.hideReview.bind(this);
    this.state = {
      showContrib1: false,
      showContrib2: false,
      manage: "",
      contribute: "",
      oba: "",
      cria: "",
      manageList: "",
      generalList: "",
      contrib: "",
    };
  }

  async componentDidMount() {
    if (this.props.mmlisData[0].managebool === true) {
      await this.setState({ manage: "Yes" });
    } else if (this.props.mmlisData[0].managebool === false) {
      await this.setState({ manage: "No" });
    }

    if (this.props.mmlisData[0].contributebool === true) {
      await this.setState({ contribute: "Yes" });
    } else if (this.props.mmlisData[0].contributebool === false) {
      await this.setState({ contribute: "No" });
    }

    if (this.props.mmlisObaCriaData[0].outsidebusact === true) {
      await this.setState({ oba: "Yes" });
    } else if (this.props.mmlisObaCriaData[0].outsidebusact === false) {
      await this.setState({ oba: "No" });
    }

    if (this.props.mmlisObaCriaData[0].corpreg === true) {
      await this.setState({ cria: "Yes" });
    } else if (this.props.mmlisObaCriaData[0].corpreg === false) {
      await this.setState({ cria: "No" });
    }

    if (this.props.mmlisManageData[0].contributionsmanageid !== null) {
      var managedlist = this.props.mmlisManageData.sort(
        (a, b) => new Date(a.contribdate) - new Date(b.contribdate)
      );

      await this.setState({
        manageList: managedlist,
      });
    }

    if (this.props.mmlisGeneralData[0].contributionsgeneralid !== null) {
      var generallist = this.props.mmlisGeneralData.sort(
        (a, b) => new Date(a.contribdate) - new Date(b.contribdate)
      );

      await this.setState({
        generalList: generallist,
      });
    }
  }

  async toggleReview1(data) {
    await this.setState({ contrib: data });
    await this.setState({ showContrib1: true });
  }

  async toggleReview2(data) {
    await this.setState({ contrib: data });
    await this.setState({ showContrib2: true });
  }

  async hideReview() {
    window.scrollTo(0, 0);
    await this.setState({ showContrib1: false, showContrib2: false });
  }

  render() {
    return (
      <>
        {this.state.showContrib1 && (
          <ReviewMMLISPayToPlay1
            hideReview={this.hideReview}
            contrib={this.state.contrib}
            contracttypeid={this.props.contracttypeid}
          />
        )}

        {this.state.showContrib2 && (
          <ReviewMMLISPayToPlay2
            hideReview={this.hideReview}
            contrib={this.state.contrib}
            contracttypeid={this.props.contracttypeid}
          />
        )}

        {this.state.showContrib1 === false &&
          this.state.showContrib2 === false && (
            <div id="ReviewMMLIS" className="review-form">
              <div className="col-7 row">
                <h3 id="conf_psr">MMLIS</h3>
              </div>

              <div className="agency-view">
                <div
                  className="mm-section mm-spacing"
                  style={{ marginTop: 40 }}
                >
                  PAY TO PLAY COMPLIANCE DISCLOSURE FORM
                </div>
                {this.props.id === "conf_info" ? (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Do you control or help manage any political action
                          committee ("PAC") or political party committee (“PPC”)
                          that contributes to a state or local official or
                          candidate? Please note that MMPAC is exempt.*
                        </strong>
                      </div>

                      <div className="col-6">{this.state.contribute}</div>
                    </div>
                    {this.state.contribute === "Yes" && (
                      <div className="col-md-9">
                        {this.props?.mmlisManageData?.map((data, index) => {
                          return (
                            <ConfAppMMLIS1
                              key={index}
                              contrib={data}
                              id="conf"
                            />
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <strong className="select-one">
                        Do you control or help manage any political action
                        committee ("PAC") or political party committee (“PPC”)
                        that contributes to a state or local official or
                        candidate? Please note that MMPAC is exempt.*
                      </strong>
                    </div>
                    <div className="col-md-6">
                      {this.state.manage}
                      <div className="col-md-6" style={{ paddingLeft: "0px" }}>
                        <table width="100%">
                          <tbody>
                            {Array.from(this.state.manageList).map(
                              (contrib, key) => {
                                let contribDate = Moment.utc(
                                  contrib.contribdate
                                ).format("MM/DD/YYYY");

                                var commaNumber = contrib.contribamt.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );

                                commaNumber = commaNumber.replace(".00", "");

                                return (
                                  <Fragment key={key}>
                                    <tr>
                                      <td width="50%">{contribDate}</td>
                                      <td width="50%">
                                        <button
                                          className="btn btn-link"
                                          onClick={({ target }) =>
                                            this.toggleReview1(
                                              JSON.stringify(contrib)
                                            )
                                          }
                                        >
                                          <strong
                                            className="peacock-blue"
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            ${commaNumber}
                                          </strong>
                                        </button>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-9">
                    <hr></hr>
                  </div>
                </div>
                {this.props.id === "conf_info" ? (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Have you, your spouse/domestic partner, dependent
                          child, or business entity made any contributions to
                          state or local officials, candidates, PACs, PPCs, or
                          any other political organization directly or
                          indirectly, including through a third party, within
                          two years of today's date? *
                        </strong>
                      </div>
                      <div className="col-6">{this.state.contribute}</div>
                    </div>
                    {this.state.contribute === "Yes" && (
                      <div className="col-md-9">
                        {this.props?.mmlisGeneralData?.map((data, index) => {
                          return <ConfAppMMLIS2 key={index} contrib={data} />;
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <strong className="select-one">
                        Have you, your spouse/domestic partner, dependent child,
                        or business entity made any contributions to state or
                        local officials, candidates, PACs, PPCs, or any other
                        political organization directly or indirectly, including
                        through a third party, within two years of today's date?
                        *
                      </strong>
                    </div>
                    <div className="col-md-6">
                      {this.state.contribute}{" "}
                      <div className="col-md-6" style={{ paddingLeft: "0px" }}>
                        <table width="100%">
                          <tbody>
                            {Array.from(this.state.generalList).map(
                              (contrib, key) => {
                                let contribDate = Moment.utc(
                                  contrib.contribdate
                                ).format("MM/DD/YYYY");

                                var commaNumber = contrib.contribamt.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );

                                commaNumber = commaNumber.replace(".00", "");

                                return (
                                  <Fragment key={key}>
                                    <tr>
                                      <td width="50%">{contribDate}</td>
                                      <td width="50%">
                                        <button
                                          className="btn btn-link"
                                          onClick={() =>
                                            this.toggleReview2(
                                              JSON.stringify(contrib)
                                            )
                                          }
                                        >
                                          <strong
                                            className="peacock-blue"
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            ${commaNumber}
                                          </strong>
                                        </button>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="mm-section mm-spacing"
                  style={{ marginTop: 40 }}
                >
                  OBA QUESTION
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <strong className="select-one">
                      Are you currently engaged in any other business either as
                      a proprietor, partner, officer, director, employee,
                      trustee, agent or otherwise? (Please exclude non
                      investment-related activity that is exclusively
                      charitable, civic, religious or fraternal and is
                      recognized as tax exempt.)*
                    </strong>
                  </div>
                  <div className="col-md-6">{this.state.oba}</div>
                </div>
                <div
                  className="mm-section mm-spacing"
                  style={{ marginTop: 40 }}
                >
                  CRIA QUESTION
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <strong className="select-one">
                      Are you requesting affiliation with MMLIS Corporate
                      Registered Investment Advisor (CRIA) to provide fee-based
                      investment advisory services? (Note: you must have a valid
                      Series 65, Series 66 or other qualifying designation.)?*
                    </strong>
                  </div>
                  <div className="col-md-6">{this.state.cria}</div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

export default ReviewMMLIS;

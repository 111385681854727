import { CCB_SUB_SECTION_TITLE } from "../ConfAppSections/ReviewCCBDRP/CCB";

import React from "react";
import ReviewCCBDRP from "./ReviewCCBDRP";

const ConfDRPData = ({
  ccbDRPData: {
    bankruptcyDRPs = [],
    bondDRPs = [],
    civilJudicialDRPs = [],
    criminalDRPs = [],
    customerComplaintDRPs = [],
    investigationDRPs = [],
    judgmentLienDRPs = [],
    regulatoryDRPs = [],
    terminationDRPs = [],
  },
}) => {
  let personalBankruptcy = [];
  let organizationBankruptcy = [];
  if (bankruptcyDRPs?.length > 0) {
    for (let i = 0; i < bankruptcyDRPs?.length; i++) {
      const nextDRP = bankruptcyDRPs[i];
      for (const [key, value] of Object.entries(nextDRP?.drpvalues)) {
        if (key.slice(0, 3) === "org") {
          organizationBankruptcy.push(nextDRP);
          break;
        }
      }
    }
    personalBankruptcy = bankruptcyDRPs.filter(
      (x) => !organizationBankruptcy.includes(x)
    );
  }

  const renderCriminalDRPs = () => {
    if (criminalDRPs.length > 0) {
      return criminalDRPs.map((drp, index) => {
        return Object.keys(CRIMINAL_SECTION_TITLE)
          .map((key) => {
            const drpValueKey = Object.keys(drp.drpvalues).map((k) =>
              k.toLowerCase()
            );

            if (drpValueKey.includes(key)) {
              return drp.drpvalues[key].map((form, ind) => {
                return (
                  <ReviewCCBDRP
                    key={`${key}-${ind}`}
                    drp={{
                      ...form,
                      drpType: CRIMINAL_SECTION_TITLE[key],
                    }}
                    source="confidential"
                  />
                );
              });
            }
            return null;
          })
          .filter(Boolean);
      });
    }
  };

  // Function to render Regulatory DRPs based on the dynamic presence of subsections
  const renderRegulatoryDRPs = () => {
    if (regulatoryDRPs.length > 0) {
      return regulatoryDRPs.map((drp, index) => {
        // Iterate through keys of CCB_SUB_SECTION_TITLE to find which one is present in the drpvalues
        return Object.keys(REGULATORY_SECTION_TITLE)
          .map((key) => {
            const drpValueKeys = Object.keys(drp.drpvalues).map((k) =>
              k.toLowerCase()
            );
            const formattedKey = key.toLowerCase();
            if (drpValueKeys.includes(formattedKey)) {
              // Check if the subsection exists in the data
              return drp.drpvalues[formattedKey].map((form, ind) => {
                return (
                  <ReviewCCBDRP
                    key={`${key}-${ind}`}
                    drp={{
                      ...form,
                      drpType: REGULATORY_SECTION_TITLE[key],
                    }}
                    source="confidential"
                  />
                );
              });
            }
            return null;
          })
          .filter(Boolean);
      });
    }
  };

  const finialcialDRPData = {
    personalBankruptcy,
    organizationBankruptcy,
    bondDRPs,
    judgmentLienDRPs,
  };
  const renderFiniancialDRPs = () => {
    return Object.keys(FINIANCIAL_SECTION_TITLE).map((key) => {
      const sectionArray = finialcialDRPData[key];

      if (sectionArray && sectionArray.length > 0) {
        return sectionArray.map((drp, index) => {
          return (
            <ReviewCCBDRP
              key={`${key}-${index}`}
              drp={{
                ...drp.drpvalues,
                drpType: FINIANCIAL_SECTION_TITLE[key],
              }}
              source="confidential"
            />
          );
        });
      }
    });
  };
  const renderTerminationDRPs = () => {
    if (terminationDRPs.length > 0) {
      return terminationDRPs.map((drp, index) => {
        return (
          <ReviewCCBDRP
            key={`${index}`}
            drp={{
              ...drp.drpvalues,
              drpType: CCB_SUB_SECTION_TITLE.termination,
            }}
            source="confidential"
          />
        );
      });
    }
  };

  const renderCivilJudicialDRPs = () => {
    if (civilJudicialDRPs.length > 0) {
      return civilJudicialDRPs.map((drp, index) => {
        return drp.drpvalues?.civiljudicialactions?.map((drpval, ind) => {
          return (
            <ReviewCCBDRP
              key={`${ind}`}
              drp={{
                ...drpval,
                drpType: CCB_SUB_SECTION_TITLE.civilJudicial,
              }}
              source="confidential"
            />
          );
        });
      });
    }
  };
  const renderCustomerComplaintDRPs = () => {
    if (customerComplaintDRPs.length > 0) {
      return customerComplaintDRPs.map((drp, index) => {
        return (
          <ReviewCCBDRP
            key={index}
            drp={{
              ...drp.drpvalues,
              drpType: CCB_SUB_SECTION_TITLE.customerComplaint,
            }}
            source="confidential"
          />
        );
      });
    }
  };

  return (
    <div>
      {renderCriminalDRPs()}
      {renderRegulatoryDRPs()}
      {renderCivilJudicialDRPs()}
      {renderCustomerComplaintDRPs()}
      {renderTerminationDRPs()}
      {renderFiniancialDRPs()}
    </div>
  );
};

export default ConfDRPData;

const CRIMINAL_SECTION_TITLE = {
  individualcriminaldisclosure: "Individual Criminal",
  organizationcriminaldisclosure: "Organization Criminal",
};
const REGULATORY_SECTION_TITLE = {
  regulatoryaction: "Regulatory Action",
  investigation: "Investigation",
};
const FINIANCIAL_SECTION_TITLE = {
  personalBankruptcy: "Personal Bankruptcy",
  organizationBankruptcy: "Organization Bankruptcy",
  bondDRPs: "Bond",
  judgmentLienDRPs: "Judgment/Lien",
};

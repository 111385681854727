function ConfAppESignature(props) {
  return (
    <div id="e-signature-wrapper">
      <img
        id="ESignatureImage"
        src={props.imageData}
        alt="ESignatureImage"
      ></img>
    </div>
  );
}

export default ConfAppESignature;

import Moment from "moment";
import React, { Fragment } from "react";
import "../../../../assets/stylesheets/ConfidentialApplication/ReviewResidentialHistory.scss";

function ReviewResidentialHistory(props) {
  return (
    <div id="reviewResidentialHistory" className="review-form">
      <div className="row col-7">
        <h3 id="conf_education">Residential History</h3>
      </div>
      <ul
        className="mm-vertical-timeline"
        style={{ marginTop: props.id === "confidential" ? "30px" : "45px" }}
      >
        {Array.from(props.resHistory).map((residence, key, { length }) => {
          return (
            <Fragment key={key}>
              <li
                id={`address${key}`}
                key={`${key}-address`}
                className="active timeline-li"
              >
                <div className="column-border-top-wrapper">
                  <div className="column-border-top-pad"></div>
                  <div className="column-border-top"></div>
                </div>
                <div className="custom-table-row">
                  <div
                    className="custom-table-timeline-dot"
                    style={{ left: props.id === "confidential" ? "124px" : "" }}
                  ></div>
                  <div className="custom-table-date-column">
                    <span className="timeline-date-text">
                      {residence.currentaddress === true
                        ? `Present`
                        : Moment(residence.enddate)
                            // .tz("America/New_York")
                            .format("MM/DD/YYYY")}
                      <br></br>-<br></br>
                      {Moment(residence.begindate)
                        // .tz("America/New_York")
                        .format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <div className="custom-table-residence-column">
                    <div className="residential-history-address">
                      {residence.urbanization !== undefined &&
                        residence.urbanization !== null &&
                        residence.urbanization !== "" &&
                        residence.state === "PR" && (
                          <>{residence.urbanization}, </>
                        )}
                      <>{residence.address1}</>
                      {residence.address2 !== "" && <> {residence.address2}</>}
                      <>, {residence.city}</>
                      {residence.country === "US" ? (
                        <>, {residence.state}</>
                      ) : (
                        <></>
                      )}
                      <>, {residence.zipcode}</>
                      <>, {residence.country}</>
                    </div>
                  </div>
                </div>
                {key === length - 1 && (
                  <div className="column-border-bottom-wrapper">
                    <div className="column-border-bottom-pad"></div>
                    <div className="column-border-bottom"></div>
                  </div>
                )}
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
}

export default ReviewResidentialHistory;

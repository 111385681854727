import React from "react";
import DRPSectionDivider from "../common/DRPSectionDivider";
import "./ReviewRegulatoryActionDRP.scss";

const ReviewRegulatoryActionDRP = ({
  drp: { actDetails, resDetails, comments, sanctionDetails, statusDetails },
}) => {
  //To ensure "Other" remain at last of all values
  const sortOtherValueToLastIndex = actDetails?.selectedFullNameValues.sort(
    (a, b) => {
      const aValue = typeof a === "object" ? a.value : a;
      const bValue = typeof b === "object" ? b.value : b;
      if (aValue === "Other") return 1;
      if (bValue === "Other") return -1;
      return 0;
    }
  );
  const sortOtherValueToLastIndex1 = actDetails?.selectedProdValues.sort(
    (a, b) => {
      const aValue = typeof a === "object" ? a.value : a;
      const bValue = typeof b === "object" ? b.value : b;
      if (aValue === "Other") return 1;
      if (bValue === "Other") return -1;
      return 0;
    }
  );

  const drpCheckListArray = actDetails?.drpCheckList.map((item) => item.trim());
  const sortedChecklist = drpCheckListArray.sort((a, b) => {
    const extractParts = (str) => {
      // Match patterns like 14D, 14D(1), 14D(1)(a)
      const regex = /(\d+)([A-Z])(?:\((\d+)\))?(?:\((\w+)\))?/;
      const match = str.match(regex);
      // Returning matched parts, with possible undefined for simpler patterns
      return match
        ? [
            parseInt(match[1]),
            match[2],
            parseInt(match[3] || 0),
            match[4] || "",
          ]
        : [];
    };
    const aParts = extractParts(a);
    const bParts = extractParts(b);
    // Compare the extracted parts, element by element
    if (aParts.length && bParts.length) {
      for (let i = 0; i < aParts.length; i++) {
        if (aParts[i] !== bParts[i]) {
          if (typeof aParts[i] === "number") {
            return aParts[i] - bParts[i]; // Numerical comparison
          } else {
            return aParts[i].localeCompare(bParts[i]); // String comparison
          }
        }
      }
    }
    return 0;
  });
  const sortedChecklistString = sortedChecklist
    ?.sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
    ?.join(", ");
  return (
    <div id="ReviewRegulatoryActionDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">{sortedChecklistString?.toString()}</div>
      </div>
      <div id="actionDetailsSection">
        <DRPSectionDivider section={"Action Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Regulatory Action initiated by: *</strong>
          </div>
          <div className="col-6">
            {actDetails?.selectedRegActionInitiated}
            {actDetails?.selectedRegActionInitiated === "Other"
              ? `: ${actDetails?.regActionOther}`
              : ""}
          </div>
        </div>

        {actDetails?.selectedRegActionInitiated !== "SEC" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  Full name of regulator (if other than SEC) that initiated the
                  action: *
                </strong>
              </div>
              <div className="col-6">
                {actDetails?.fullNameReg || "-"}
                {actDetails?.fullNameReg === "Other" && (
                  <>
                    <br />
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <br />
        <div className="row">
          <div className="col-6">
            <strong>Sanction(s) Sought: * (Select all that apply):</strong>
          </div>
          <div className="col-6">
            {sortOtherValueToLastIndex?.map((product, key) => (
              <React.Fragment key={key}>
                {typeof product === "object" ? (
                  <span>{product?.value}</span>
                ) : (
                  <span>
                    {`${product}`}
                    {product === "Other" && (
                      <>{`: ${actDetails.otherFullNameText}`}</>
                    )}
                  </span>
                )}
                {key !== sortOtherValueToLastIndex.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Date Initiated:*</strong>
          </div>
          <div className="col-6">
            {actDetails?.dateInitiated || "-"}
            <br />
            {actDetails?.dateInitiatedRadio || "-"}
            {actDetails?.dateInitiatedRadio === "Explanation"
              ? `: ${actDetails?.dateInitiatedExp}`
              : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Docket/Case #: *</strong>
          </div>
          <div className="col-6">{actDetails?.docketOrCase || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Employing firm when activity occurred which led to the regulatory
              action: *
            </strong>
          </div>
          <div className="col-6">{actDetails?.emplRegAction || "-"}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Product Type(s) (Select all that apply): *</strong>
          </div>
          <div className="col-6">
            {sortOtherValueToLastIndex1.map((product, key) => (
              <React.Fragment key={key}>
                <span>{`${
                  product
                    ? typeof product === "object"
                      ? product?.value
                      : product
                    : "-"
                }${
                  product === "Other" ? `: ${actDetails?.otherProdText}` : ""
                }`}</span>
                {key !== sortOtherValueToLastIndex1.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Describe the allegations related to this regulatory action: *
            </strong>
          </div>
          <div className="col-6">
            {actDetails?.regActionAllegationDes || "-"}
          </div>
        </div>
      </div>
      <div id="statusDetailsSection">
        <DRPSectionDivider section={"Status Details"} />
        <div className="row">
          <div className="col-6">
            <strong>Current status: *</strong>
          </div>
          <div className="col-6">{statusDetails?.status || "-"}</div>
        </div>
        {statusDetails?.status === "Pending" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  If pending, are there any limitations or restrictions
                  currently in effect? *
                </strong>
              </div>
              <div className="col-6">
                {statusDetails?.restrictionEffectExp
                  ? `Yes: ${statusDetails?.restrictionEffectExp}`
                  : "No"}
              </div>
            </div>
          </>
        )}
        {statusDetails?.status === "On Appeal" && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Action appealed to: *</strong>
              </div>
              <div className="col-6">
                {statusDetails?.actionAppealed || "-"}{" "}
                {statusDetails?.actionAppealed === "Other" && (
                  <div>{statusDetails?.actionAppealedOther || "-"}</div>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Date appeal filed: *</strong>
              </div>
              <div className="col-6">
                {statusDetails?.dateAppeal || "-"}
                <br />
                {statusDetails?.dateAppealRadio}
                {statusDetails?.dateAppealRadio === "Explanation"
                  ? `: ${statusDetails?.dateAppealExp}`
                  : ""}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>
                  Are there any limitations or restrictions currently in effect
                  while on appeal? *
                </strong>
              </div>
              <div className="col-6">
                {statusDetails?.resEffectAppeal ? "Yes" : "No" || "-"}
                <br />
                {statusDetails?.resEffectAppeal
                  ? statusDetails?.resEffectAppealExp
                  : ""}
              </div>
            </div>
          </>
        )}
      </div>
      {(statusDetails?.status === "Final" ||
        statusDetails?.status === "On Appeal") && (
        <div id="resolutionDetailsSection">
          <DRPSectionDivider section={"Resolution Details"} />
          <div className="row">
            <div className="col-6">
              <strong>How was the matter resolved? *</strong>
            </div>
            <div className="col-6">
              {resDetails?.selectedMatterResolved}
              {resDetails?.selectedMatterResolved === "Other"
                ? `: ${resDetails?.selectedMatterResolvedExp}`
                : ""}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Resolution Date: *</strong>
            </div>
            <div className="col-6">
              {resDetails?.resolutionDate}
              <br />
              {resDetails?.resolutionDateRadio}
              {resDetails?.resolutionDateRadio === "Explanation"
                ? `: ${resDetails?.resolutionDateExp}`
                : ""}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>
                Does the order constitute a final order based on violations of
                any laws or regulations that prohibit fraudulent, manipulative,
                or deceptive conduct? *
              </strong>
            </div>
            <div className="col-6">{resDetails?.finalOrder ? "Yes" : "No"}</div>
          </div>
        </div>
      )}

      {sanctionDetails !== null && (
        <div id="sanctionDetailsSection">
          <DRPSectionDivider section={"Sanction Details"} />
          <div className="row">
            <div className="col-6">
              <strong>
                Were any of the following sanctions ordered? (select all that
                apply): *
              </strong>
            </div>
            <div className="col-6">
              {sanctionDetails?.selectedSanctionOrderedValues.map(
                (product, key) => (
                  <>
                    <React.Fragment key={key}>
                      <span>{`${
                        typeof product === "object" ? product?.value : product
                      }${product === "Other" ? `: ${product}` : ""}`}</span>
                      {key !==
                        sanctionDetails?.selectedSanctionOrderedValues.length -
                          1 && <br />}
                    </React.Fragment>
                  </>
                )
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Other sanctions ordered: *</strong>
            </div>
            <div className="col-6">{sanctionDetails?.other || "-"}</div>
          </div>
          <br />
          {sanctionDetails?.newSanctionDetailsList.map(
            (
              {
                duration,
                durationExp,
                durationRadio,
                endDate,
                endDateExp,
                endDateRadio,
                regCapacitiesAffected,
                sanctionType,
                startDate,
                startDateExp,
                startDateRadio,
              },
              key,
              { length }
            ) => {
              return (
                <React.Fragment key={key}>
                  <div id={`sanction${key}`}>
                    <div className="row">
                      <div className="col-6">
                        <strong>Sanction</strong>
                      </div>
                    </div>
                    <br />
                    <div className="sanction-body">
                      <div className="row">
                        <div className="col-6">
                          <strong>Sanction Type: *</strong>
                        </div>
                        <div className="col-6">{sanctionType}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>
                            Registration Capacities Affected (e.g. General
                            Securities Principal, Financial Operations
                            Principal, All Capacities, etc): *
                          </strong>
                        </div>
                        <div className="col-6">{regCapacitiesAffected}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Duration (Length of time): *</strong>
                        </div>
                        <div className="col-6">
                          {duration}
                          <br />
                          {durationRadio}
                          {durationRadio === "Explanation"
                            ? `: ${durationExp}`
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Start Date: *</strong>
                        </div>
                        <div className="col-6">
                          {startDate}
                          <br />
                          {startDateRadio}
                          {startDateRadio === "Explanation"
                            ? `: ${startDateExp}`
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>End Date: *</strong>
                        </div>
                        <div className="col-6">
                          {endDate}
                          <br />
                          {endDateRadio}
                          {endDateRadio === "Explanation"
                            ? `: ${endDateExp}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              );
            }
          )}
          {sanctionDetails?.newRequalificationDetailsList.map(
            (
              {
                explanation,
                lengthOfTime,
                lengthOfTimeExp,
                lengthOfTimeRadio,
                requalificationType,
                requalificationTypeOther,
                satisfiedCondition,
                typeOfExam,
              },
              key,
              { length }
            ) => {
              return (
                <React.Fragment key={key}>
                  <div id={`requalification${key}`}>
                    <div className="row">
                      <div className="col-6">
                        <strong>Requalification</strong>
                      </div>
                    </div>
                    <br />
                    <div className="requalification-body">
                      <div className="row">
                        <div className="col-6">
                          <strong>Requalification Type: *</strong>
                        </div>
                        <div className="col-6">
                          {requalificationType}
                          {requalificationType === "Other"
                            ? `: ${requalificationTypeOther}`
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>
                            Length of time given to requalify/retrain: *
                          </strong>
                        </div>
                        <div className="col-6">
                          {lengthOfTime}
                          <br />
                          {lengthOfTimeRadio}
                          {lengthOfTimeRadio === "Explanation"
                            ? `: ${lengthOfTimeExp}`
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Type of exam required: *</strong>
                        </div>
                        <div className="col-6">{typeOfExam}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Has condition been satisfied? *</strong>
                        </div>
                        <div className="col-6">
                          {satisfiedCondition ? "Yes" : "No"}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Explanation: (Optional)</strong>
                        </div>
                        <div className="col-6">{explanation || "-"}</div>
                      </div>
                      <br />
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          )}
          {sanctionDetails?.newMonetarySanctionDetailsList.map(
            (
              {
                datePaid,
                datePaidExp,
                datePaidRadio,
                isCurrentPaymentPlan,
                paymentPlan,
                portionLevied,
                portionOfPenaltyAmount,
                portionPenaltyWaived,
                sanctionType,
                totalAmount,
              },
              key,
              { length }
            ) => {
              return (
                <React.Fragment key={key}>
                  <div id={`monetarySanction${key}`}>
                    <div className="row">
                      <div className="col-6">
                        <strong>Monetary Sanction</strong>
                      </div>
                    </div>
                    <br />
                    <div className="monetary-sanction-body">
                      <div className="row">
                        <div className="col-6">
                          <strong>Monetary Related Sanction Type: *</strong>
                        </div>
                        <div className="col-6">{sanctionType}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Total Amount: *</strong>
                        </div>
                        <div className="col-6">${totalAmount}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Portion levied against you: *</strong>
                        </div>
                        <div className="col-6">${portionLevied}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Payment Plan: *</strong>
                        </div>
                        <div className="col-6">{paymentPlan}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Is Payment Plan current? *</strong>
                        </div>
                        <div className="col-6">
                          {isCurrentPaymentPlan ? "Yes" : "No"}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Date paid by you: *</strong>
                        </div>
                        <div className="col-6">
                          {datePaid}
                          <br />
                          {datePaidRadio}
                          {datePaidRadio === "Explanation"
                            ? `: ${datePaidExp}`
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Was any portion of penalty waived? *</strong>
                        </div>
                        <div className="col-6">
                          {portionPenaltyWaived ? "Yes" : "No"}
                          {portionPenaltyWaived && (
                            <>
                              <br />
                              Amount: ${portionOfPenaltyAmount}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
      <div id="commentsSections">
        <DRPSectionDivider section={"COMMENTS"} />
        <div className="row">
          <div className="col-6">
            <strong>
              You may use this field to provide a brief summary of the
              circumstances leading to the action as well as the current status
              or final disposition and/or finding(s). (Optional)
            </strong>
          </div>
          <div className="col-6">{comments || "-"}</div>
        </div>
      </div>
    </div>
  );
};
export default ReviewRegulatoryActionDRP;

import DRPSectionDivider from "../common/DRPSectionDivider";

const ReviewBondDRP = ({
  drp: {
    bondComments,
    bondingCompanyName,
    dispositionDate,
    dispositionDateExplanation,
    dispositionOption,
    dispositionType,
    firmName,
    payoutAmount,
    payoutDate,
    payoutDateExplanation,
    payoutOption,
  },
}) => {
  return (
    <div id="ReviewBondDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">14L</div>
      </div>
      <DRPSectionDivider section={"ORGANIZATION INFORMATION"} />
      <div className="row">
        <div className="col-6">
          <strong>Firm Name (Policy Holder): *</strong>
        </div>
        <div className="col-6">{firmName}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Bonding Company Name: *</strong>
        </div>
        <div className="col-6">{bondingCompanyName}</div>
      </div>
      <DRPSectionDivider section={"DISPOSITION DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Disposition Type: *</strong>
        </div>
        <div className="col-6">{dispositionType}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Disposition Date: *</strong>
        </div>
        <div className="col-6">
          {dispositionDate}
          <br />
          {`${dispositionOption}${
            dispositionOption === "Explanation"
              ? `: ${dispositionDateExplanation}`
              : " date"
          }`}
        </div>
      </div>
      {dispositionType === "Payout" && (
        <>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Payout Amount *</strong>
            </div>
            <div className="col-6">${payoutAmount}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Payout Date *</strong>
            </div>
            <div className="col-6">
              {payoutDate}
              <br />
              {`${payoutOption}${
                payoutOption === "Explanation"
                  ? `: ${payoutDateExplanation}`
                  : ""
              }`}
            </div>
          </div>
        </>
      )}
      <DRPSectionDivider section={"COMMENTS"} />
      <div className="row">
        <div className="col-6">
          <strong>
            You may use this field to provide a brief summary of the
            circumstances leading to the action as well as the current status or
            final disposition. (Optional)
          </strong>
        </div>
        <div className="col-6">{bondComments === "" ? "-" : bondComments}</div>
      </div>
    </div>
  );
};
export default ReviewBondDRP;

export const headerText = {
  HEADER: "Termination Disclosure",
  TERM_DISCL: "Termination Disclosures",
};

export const CCB_FORM_ID = {
  TERMINATION_DISCLOSURE: 1,
  DRP_SUMMARY: 2,
  ADD_DRP: 3,
  ADD_DRP_COMMENT: 4,
};

const labels = {
  LABEL_ONE: "Details",
  LABEL_TWO: "Comments",
};

const commonParts = { active: false, notApplicable: false, completed: false };

export const defTimelineSteps = [
  {
    id: 1,
    label: labels.LABEL_ONE,
    ...commonParts,
  },
  {
    id: 2,
    label: labels.LABEL_TWO,
    ...commonParts,
  },
];

import moment from "moment";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../constants/Contract";

export function isValidDate(date) {
  let dateParts = date.split("/");
  let month, day, year, checkDate;

  if (dateParts.length === 3) {
    month = dateParts[0];
    day = dateParts[1];
    year = dateParts[2];
    checkDate = moment(month + "/" + day + "/" + year, "MM/DD/YYYY", true);
    return checkDate.isValid();
  } else if (dateParts.length === 2) {
    month = dateParts[0];
    year = dateParts[1];
    checkDate = moment(month + "/" + year, "MM/YYYY", true);
    return checkDate.isValid();
  } else {
    return false;
  }
}

export function clickFunc(event) {
  event.target.focus();
}

export function onlyNumeric(event) {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function onlyAlphaNumeric(event) {
  if (!/[a-zA-Z0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function createDateObject(dateString) {
  var momentObj = moment(dateString, "MM-DD-YYYY");
  var momentString = momentObj.format("YYYY-MM-DD");
  return momentString;
}

export function pasteCommaDelimited(event) {
  const paste = event.clipboardData.getData("text/plain");
  const cleanPaste = paste.replaceAll(",", "");

  if (isNaN(cleanPaste)) {
    event.preventDefault();
  }
}

export function pasteZipCode(event) {
  var paste = event.clipboardData.getData("text/plain");
  if (!paste.match(/^[0-9a-z]+$/)) {
    event.preventDefault();
  }
}

export function pasteValue(event) {
  var paste = event.clipboardData.getData("text/plain");
  if (!paste.match(/^[0-9]+$/)) {
    event.preventDefault();
  }
}

export function isSafari() {
  const isSafariBrowser =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  return isSafariBrowser;
}

export function formatMoney(value) {
  let cleanNumber = value;
  if (value?.length > 1) {
    while (cleanNumber.charAt(0) === "0") {
      cleanNumber = cleanNumber.substring(1);
    }
  }
  return cleanNumber
    .replace(/,/gi, "")
    .replace("-", "")
    .split(/(?=(?:\d{3})+$)/)
    .join(",");
}
export function dateValidator(value) {
  const today = moment();
  const date = moment(value, "MM/DD/YYYY");
  const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
  let isInvalid;
  let errMessage = "";
  if (
    date._i.length === 10 &&
    !date._i.includes("_") &&
    date.isValid() &&
    !value.includes("0000")
  ) {
    if (date < past || value.slice(-4, -2) === "00") {
      errMessage = "Date cannot be more than 100 years ago";
      isInvalid = true;
    } else if (moment(value) > today) {
      errMessage = "Date cannot be a future date";
      isInvalid = true;
    } else {
      isInvalid = false;
    }
  } else {
    errMessage = "Please enter a valid date";
    isInvalid = true;
  }
  return { isInvalid, errMessage };
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isCareerContract = (contractType) => {
  return (
    contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
    contractType === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
  );
};

export function isNotNullUndefinedOrEmpty(value) {
  return value !== null && value !== undefined && value !== "";
}

export function getContractTypeDescription(
  contracttypedesc,
  mmlis,
  hasFinancingAddon,
  agencynumber
) {
  let description = contracttypedesc.replace("Contract", "");

  if (mmlis && hasFinancingAddon) {
    description += " + MMLIS, FINANCING";
  } else if (mmlis) {
    description += " + MMLIS";
  } else if (hasFinancingAddon) {
    description += " + FINANCING";
  }
  description += ` | Agency #${agencynumber}`;

  return description;
}

export function isBrokerCorpContract(contracttype) {
  return contracttype === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP;
}

export function isFinanceContract(contracttype) {
  return contracttype === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY;
}

export function isBrokerORMOC(contractstatusdesc) {
  return (
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_OF_CORPORATION ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORPORATION
  );
}

export function isCareerAndMMLISContract(contracttype) {
  return (
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.MMLIS
  );
}

export function isFinanceORBrokerORMMLISORCorpORMOC(contractstatusdesc) {
  return (
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
    contractstatusdesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
  );
}

export function isBrokerContract(contracttype) {
  return contracttype === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP;
}

export function isCorporateAndFinanceContract(contracttype) {
  return (
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
    contracttype === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
  );
}

export const PAGE_SECTION = {
  PERSONAL_INFORMATION: "Personal Information",
  RESIDENTIAL_ADDRESS: "Residential Address",
  RESIDENTIAL_HISTORY: "Residential History",
  BUSINESS_INFORMATION: "Business Information",
  BROKER_DEALER_INFORMATION: "Broker Dealer Information",
  CHARACTER_CREDIT_AND_BONDING: "Character, Credit, and Bonding",
  ERRORS_AND_OMISSIONS: "Errors & Omissions Coverage, Group Insurance",
  ERRORS_AND_OMISSIONS_CONF: "Corporation Errors and Omissions Coverage",
  DIRECT_DEPOSIT: "Direct Deposit Information",
  Education: "Education",
  EMPLOYMENT_HISTORY: "Employment History",
  PSR: "Personal Sales Record",
  PPR: "Personal Production Record",
  CORPORATION_INFORMATION: "Corporation Information",
  CORPORATION_CCB: "Corporation Character, Credit, and Bonding",
  AFFIDAVIT_OF_PRIOR_INCOME: "Affidavit of Prior Income",
  CONTRACT_DETAILS: "Contract Details",
};

export function bin2String(array) {
  let result = "";
  array.forEach((letterByte) => {
    let byte = String.fromCharCode(letterByte);
    result += byte;
  });

  return result;
}

export const SECTION_TITLE = "CANDIDATE APPLICATION";
export const SECTION_TITLE_BG_AND_REG = "Background and Regulatory";
export const SECTION_TITLE_CONTR_DET = "Submission Summary";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_API = "YYYY-MM-DD";

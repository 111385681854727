/* global google */
import Moment from "moment";
import React from "react";
import { Button, Modal } from "react-blueprint-library";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  getEmploymentBreakHistoryInformation,
  getEmploymentHistoryInformation,
  insertEmploymentBreakHistoryInformation,
  insertEmploymentHistoryInformation,
  updateEmploymentBreakHistoryInformation,
  updateEmploymentHistoryInformation,
} from "../../../api/makeAPIRequest";
import { getUSPSAddress } from "../../../api/uspsAddress";
import "../../../assets/stylesheets/StartApplication/CAS/EmploymentHistoryModal.scss";
import {
  clickFunc,
  createDateObject,
  isValidDate,
  onlyAlphaNumeric,
  onlyNumeric,
  pasteCommaDelimited,
  pasteZipCode,
} from "../../../common/Functions";
import { COUNTRIES } from "../../../constants/countries";
import BackButtonModal from "../Forms/BackButtonModal";
import "../StartApplication.scss";
import USPSValidationModal from "./EmpHistUSPSValidationModal";

class EmploymentHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.nextClick = this.nextClick.bind(this);
    this.addClick = this.addClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.backClick = this.backClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.handleSalesChange = this.handleSalesChange.bind(this);
    this.handleIRBChange = this.handleIRBChange.bind(this);
    this.handleCurrentlyWorkingChange =
      this.handleCurrentlyWorkingChange.bind(this);
    this.handleContactEmployerChange =
      this.handleContactEmployerChange.bind(this);
    this.handleIndividualLifeChange =
      this.handleIndividualLifeChange.bind(this);
    this.handleIndividualAnnuityChange =
      this.handleIndividualAnnuityChange.bind(this);
    this.handleIndividualDisabilityChange =
      this.handleIndividualDisabilityChange.bind(this);
    this.handleLongTermCareChange = this.handleLongTermCareChange.bind(this);
    this.handleFullTimeRoleChange = this.handleFullTimeRoleChange.bind(this);
    this.handleTrainingChange = this.handleTrainingChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocusState = this.onFocusState.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.updateCurrentTab = this.updateCurrentTab.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.getAddressComponents = this.getAddressComponents.bind(this);
    this.clearInputErrors = this.clearInputErrors.bind(this);
    this.handleUseSuggestedAddress = this.handleUseSuggestedAddress.bind(this);
    this.handleUseEnteredAddress = this.handleUseEnteredAddress.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
  }

  async componentDidMount() {
    this.loadData();
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.reviewEditData !== prevProps.reviewEditData ||
      this.props.isOpen !== prevProps.isOpen
    ) {
      this.loadData();
    }
  }

  async loadData() {
    if (this.props.reviewEditData !== "") {
      if (
        this.props.reviewEditData.display_start_date !==
          this.state.display_start_date ||
        this.props.reviewEditData.display_end_date !==
          this.state.display_end_date ||
        this.props.reviewEditData.display_company_name !==
          this.state.display_company_name ||
        this.props.reviewEditData.display_title !== this.state.display_title
      ) {
        var result;

        if (this.props.reviewEditData.companyname === null) {
          await this.setState({
            step_number: 0,
            currentTab: "Employment Break",
            show_add_button: true,
            show_next_button: false,
            is_add_disabled: false,
            is_next_disabled: false,
            review_edit: true,
            id: this.props.reviewEditData.id,
          });

          result = await getEmploymentBreakHistoryInformation(
            this.props.subId,
            this.props.reviewEditData.id
          );

          await this.setState({
            fulltime_student: result.employmentbreakid === 1 ? "Y" : "N",
            homemaker: result.employmentbreakid === 2 ? "Y" : "N",
            unemployment: result.employmentbreakid === 3 ? "Y" : "N",
            military: result.employmentbreakid === 4 ? "Y" : "N",
            travel: result.employmentbreakid === 5 ? "Y" : "N",
            other: result.employmentbreakid === 6 ? "Y" : "N",
            other_text: result.employmentbreakid === 6 ? result.other : "",
            show_other_panel: result.employmentbreakid === 6,
            step_number: 3,
            break_start_date: Moment(result.startdate).format("MM/DD/YYYY"),
            break_end_date: Moment(result.enddate).format("MM/DD/YYYY"),
          });

          var experience_obj = document.getElementById("experience");
          experience_obj.style.display = "none";
        } else {
          await this.setState({
            step_number: 0,
          });
          result = await getEmploymentHistoryInformation(
            this.props.subId,
            this.props.reviewEditData.id
          );

          var step_number;
          var currentTab;
          var show_add_button = false;
          var show_next_button = false;
          step_number = 1;
          currentTab = "Employment Experience";

          if (result.empstate === "PR") {
            this.setState({ showUrbanization: true });
          }

          show_add_button = !result.salesposition;
          show_next_button = result.salesposition;

          let show_training_info = result.trainingsubsidy;

          let training_start_date =
            result.trainingstartdate === ""
              ? ""
              : Moment(result.trainingstartdate).format("MM/DD/YYYY");

          let training_end_date =
            result.trainingenddate === ""
              ? ""
              : Moment(result.trainingenddate).format("MM/DD/YYYY");

          if (result.totalsubsidyrecd !== null) {
            var subsidy = result.totalsubsidyrecd.toString();
            var commaSeparatedNumber = subsidy
              .split(/(?=(?:\d{3})+$)/)
              .join(",");
            await this.setState({ subsidy: commaSeparatedNumber });
          }
          var selectedState = result.empstate === null ? "" : result.empstate;

          let option = "";
          if (result.industryid !== null)
            option = this.props.industries.find(
              (element) => element.i_industryid === result.industryid
            );

          await this.setState({
            id: this.props.reviewEditData.id,
            sales: result.salesposition ? "Y" : "N",
            contact_employer: result.contactemployer ? "Y" : "N",
            may_we_contact_employer: result.contactemployer,
            irb: result.invrelatedbus ? "Y" : "N",
            individual_life: result.solicitindlife ? "Y" : "N",
            individual_annuity: result.solicitindannuity ? "Y" : "N",
            individual_disability: result.solicitinddisability ? "Y" : "N",
            individual_longterm: result.solicitindltc ? "Y" : "N",
            full_time_role: result.fulltime ? "Y" : "N",
            training: result.trainingsubsidy ? "Y" : "N",
            show_state_required: result.empcountry === "US",
            review_edit: true,
            step_number: step_number,
            currentTab: currentTab,
            is_add_disabled: false,
            is_next_disabled: false,
            show_add_button: show_add_button,
            show_next_button: show_next_button,
            show_training_info: show_training_info,
            title: result.jobtitle,
            company_name: result.companyname,
            employment_start_date: Moment(result.startdate).format(
              "MM/DD/YYYY"
            ),
            employment_end_date: Moment(result.enddate).format("MM/DD/YYYY"),
            street_address: result.empaddress1,
            address2: result.empaddress2,
            reason_for_leaving: result.reasonforleaving || "",
            city: result.empcity,
            state: selectedState,
            zip_code: result.empzip,
            country: result.empcountry,
            urbanization: result.urbanization,
            contact_phone: result.empphonenumber,
            training_start_date: training_start_date,
            training_end_date: training_end_date,
            currently_working: result.currentemployer ? "Y" : "N",
            end_date_disabled: result.currentemployer,
            employment_end_date_display: result.currentemployer
              ? ""
              : Moment(result.enddate).format("MM/DD/YYYY"),
            industry_select_options: this.props.industries,
            industry_select_desc: option === "" ? "" : option.i_industryname,
            industry_select_id:
              result.industryid === null ? "" : result.industryid,
          });
          if (result.currentemployer === true) {
          } else {
            await this.setState({
              employment_end_date_display: Moment(result.enddate).format(
                "MM/DD/YYYY"
              ),
            });
          }
          var break_obj = document.getElementById("break");
          break_obj.style.display = "none";
        }
      }
    } else {
      this.setState({
        step_number: 1,
        industry_select_options: this.props.industries,
        industry_select_desc: "",
        industry_select_id: "",
      });
    }
  }
  async updateCurrentTab(event) {
    if (event.target.id === "break") {
      await this.setState({
        currentTab: "Employment Break",
        step_number: 3,
        show_next_button: false,
        show_add_button: true,
      });
      this.validateFields();
    } else {
      await this.setState({
        currentTab: "Employment Experience",
        step_number: 1,
      });
      if (this.state.sales === "Y") {
        await this.setState({ show_next_button: true, show_add_button: false });
      }
      if (this.state.sales === "N") {
        await this.setState({ show_next_button: false, show_add_button: true });
      }
      this.validateFields();
    }
  }
  async validateFields() {
    await this.setState({
      is_add_disabled: true,
      is_next_disabled: true,
    });
    var currDate = Moment();
    var start_date_error = document.getElementById("start_date_error_test");
    var end_date_error = document.getElementById("end_date_error_test");
    var training_start_date_error = document.getElementById(
      "training_start_date_error"
    );
    var training_end_date_error = document.getElementById(
      "training_end_date_error"
    );
    var break_start_date_error = document.getElementById(
      "break_start_date_error"
    );
    var break_end_date_error = document.getElementById("break_end_date_error");

    if (this.state.step_number === 1) {
      var hasError = false;

      if (
        this.state.title.trim(" ").length < 1 ||
        this.state.company_name.trim(" ").length < 1 ||
        this.state.employment_start_date.includes("_") ||
        this.state.employment_start_date === "" ||
        !isValidDate(this.state.employment_start_date) ||
        Moment(this.state.employment_start_date) > currDate
      ) {
        hasError = true;
      }
      if (this.state.currently_working !== "Y") {
        if (
          this.state.employment_end_date.includes("_") ||
          this.state.employment_end_date === "" ||
          !isValidDate(this.state.employment_end_date) ||
          Moment(this.state.employment_end_date) > currDate === true ||
          Moment(this.state.employment_end_date) >
            Moment(this.state.employment_start_date === true)
        ) {
          hasError = true;
        }
      }
      if (this.state.country === "US") {
        if (this.state.state.trim(" ").length === 0) {
          hasError = true;
        }
      }
      if (
        this.state.street_address.trim(" ").length < 1 ||
        this.state.city.trim(" ").length < 1 ||
        this.state.reason_for_leaving.trim(" ").length === 1 ||
        this.state.zip_code.trim(" ").length < 5 ||
        this.state.country.trim(" ").length <= 0 ||
        this.state.sales.trim(" ").length <= 0 ||
        this.state.irb.trim(" ").length <= 0 ||
        this.state.currently_working.trim(" ").length <= 0 ||
        this.state.contact_employer.trim(" ").length <= 0 ||
        start_date_error.style.display === "block" ||
        end_date_error.style.display === "block" ||
        this.state.industry_select_id === ""
      ) {
        hasError = true;
      }
      if (this.state.contact_employer === "Y") {
        let result = this.state.contact_phone.replace(/\s/g, "");
        if (this.state.contact_phone.includes("_") || result.length < 11) {
          hasError = true;
        }
      }
      if (hasError === false) {
        await this.setState({
          is_add_disabled: false,
          is_next_disabled: false,
        });
      }
    } else if (this.state.step_number === 2 && this.state.training === "N") {
      if (
        this.state.individual_life.trim(" ").length > 0 &&
        this.state.individual_annuity.trim(" ").length > 0 &&
        this.state.individual_disability.trim(" ").length > 0 &&
        this.state.individual_longterm.trim(" ").length > 0 &&
        this.state.full_time_role.trim(" ").length > 0 &&
        this.state.training.trim(" ").length > 0
      ) {
        await this.setState({ is_add_disabled: false });
      }
    } else if (
      this.state.step_number === 2 &&
      this.state.training === "Y" &&
      this.props.hasNYRegs === false
    ) {
      await this.setState({
        is_add_disabled: true,
        is_next_disabled: true,
      });
      if (this.state.employment_end_date === "Invalid date") {
        await this.setState({
          employment_end_date: Moment().format("MM/DD/YYYY"),
        });
      }
      if (
        this.state.individual_life.trim(" ").length > 0 &&
        this.state.individual_annuity.trim(" ").length > 0 &&
        this.state.individual_disability.trim(" ").length > 0 &&
        this.state.individual_longterm.trim(" ").length > 0 &&
        this.state.full_time_role.trim(" ").length > 0 &&
        this.state.training.trim(" ").length > 0 &&
        !this.state.training_start_date.includes("_") &&
        this.state.training_start_date !== "" &&
        isValidDate(this.state.training_start_date) &&
        Moment(this.state.training_start_date) <= currDate &&
        !this.state.training_end_date.includes("_") &&
        this.state.training_end_date !== "" &&
        isValidDate(this.state.training_end_date) &&
        Moment(this.state.training_end_date) <= currDate &&
        Moment(this.state.training_end_date) >=
          Moment(this.state.training_start_date) &&
        Moment(this.state.training_start_date) >=
          Moment(this.state.employment_start_date) &&
        Moment(this.state.training_start_date) <=
          Moment(this.state.employment_end_date) &&
        Moment(this.state.training_end_date) >=
          Moment(this.state.employment_start_date) &&
        Moment(this.state.training_end_date) <=
          Moment(this.state.employment_end_date) &&
        this.state.subsidy.length > 0 &&
        training_start_date_error.style.display !== "block" &&
        training_end_date_error.style.display !== "block"
      ) {
        await this.setState({ is_add_disabled: false });
      }
    } else if (
      this.state.step_number === 2 &&
      this.state.training === "Y" &&
      this.props.hasNYRegs === true
    ) {
      await this.setState({
        is_add_disabled: true,
        is_next_disabled: true,
      });
      if (this.state.employment_end_date === "Invalid date") {
        await this.setState({
          employment_end_date: Moment().format("MM/DD/YYYY"),
        });
      }
      if (
        this.state.individual_life.trim(" ").length > 0 &&
        this.state.individual_annuity.trim(" ").length > 0 &&
        this.state.individual_disability.trim(" ").length > 0 &&
        this.state.individual_longterm.trim(" ").length > 0 &&
        this.state.full_time_role.trim(" ").length > 0 &&
        this.state.training.trim(" ").length > 0 &&
        !this.state.training_start_date.includes("_") &&
        this.state.training_start_date !== "" &&
        isValidDate(this.state.training_start_date) &&
        Moment(this.state.training_start_date) <= currDate &&
        !this.state.training_end_date.includes("_") &&
        this.state.training_end_date !== "" &&
        isValidDate(this.state.training_end_date) &&
        Moment(this.state.training_end_date) <= currDate &&
        Moment(this.state.training_end_date) >=
          Moment(this.state.training_start_date) &&
        Moment(this.state.training_start_date) >=
          Moment(this.state.employment_start_date) &&
        Moment(this.state.training_start_date) <=
          Moment(this.state.employment_end_date) &&
        Moment(this.state.training_end_date) >=
          Moment(this.state.employment_start_date) &&
        Moment(this.state.training_end_date) <=
          Moment(this.state.employment_end_date) &&
        training_start_date_error.style.display !== "block" &&
        training_end_date_error.style.display !== "block"
      ) {
        await this.setState({ is_add_disabled: false });
      }
    } else if (this.state.step_number === 3 && this.state.other === "Y") {
      if (
        this.state.other_text.trim(" ").length > 1 &&
        !this.state.break_start_date.includes("_") &&
        this.state.break_start_date !== "" &&
        isValidDate(this.state.break_start_date) &&
        Moment(this.state.break_start_date) <= currDate &&
        !this.state.break_end_date.includes("_") &&
        this.state.break_end_date !== "" &&
        isValidDate(this.state.break_end_date) &&
        Moment(this.state.break_end_date) <= currDate &&
        Moment(this.state.break_end_date) >=
          Moment(this.state.break_start_date) &&
        break_start_date_error.style.display !== "block" &&
        break_end_date_error.style.display !== "block"
      ) {
        await this.setState({ is_add_disabled: false });
      }
    } else if (this.state.step_number === 3 && this.state.other === "N") {
      if (
        (this.state.fulltime_student === "Y" ||
          this.state.homemaker === "Y" ||
          this.state.unemployment === "Y" ||
          this.state.military === "Y" ||
          this.state.travel === "Y") &&
        !this.state.break_start_date.includes("_") &&
        this.state.break_start_date !== "" &&
        Moment(this.state.break_start_date) <= currDate &&
        !this.state.break_end_date.includes("_") &&
        this.state.break_end_date !== "" &&
        isValidDate(this.state.break_start_date) &&
        isValidDate(this.state.break_end_date) &&
        Moment(this.state.break_end_date) <= currDate &&
        Moment(this.state.break_end_date) >=
          Moment(this.state.break_start_date) &&
        break_start_date_error.style.display !== "block" &&
        break_end_date_error.style.display !== "block"
      ) {
        await this.setState({ is_add_disabled: false });
      }
    }
  }
  onFocusState() {
    this.setState({ ctrl_with_focus: "" });

    var inputs = document.querySelectorAll("button, a");
    try {
      for (var i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            this.setState({ ctrl_with_focus: "" });
          } else {
            this.setState({ ctrl_with_focus: event.relatedTarget.id });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  onKeyDown() {
    this.setState({ form_has_changed: true });
  }

  initialState() {
    return {
      step_number: 0,
      is_add_disabled: true,
      is_next_disabled: true,
      show_add_button: true,
      show_next_button: false,
      currentTab: "Employment Experience",
      show_training_info: false,
      title: "",
      company_name: "",
      employment_start_date: "",
      employment_end_date: "",
      employment_end_date_display: "",
      street_address: "",
      address2: "",
      reason_for_leaving: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      contact_phone: "",
      training_start_date: "",
      training_end_date: "",
      subsidy: "",
      sales: "",
      irb: "",
      currently_working: "",
      contact_employer: "",
      individual_life: "",
      individual_annuity: "",
      individual_disability: "",
      individual_longterm: "",
      full_time_role: "",
      training: "",
      formHasError: false,
      ctrl_with_focus: "",
      is_form_saved: false,
      fulltime_student: "",
      homemaker: "",
      unemployment: "",
      military: "",
      travel: "",
      other: "",
      other_text: "",
      break_start_date: "",
      break_end_date: "",
      show_other_panel: false,
      display_company_name: "",
      display_title: "",
      display_start_date: "",
      display_end_date: "",
      is_there_gap: false,
      review_edit: false,
      end_date_disabled: false,
      may_we_contact_employer: false,
      show_state_required: false,
      showModal: false,
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      usps_country: "",
      urbanization: "",
      addressNotFound: false,
      is_validated: true,
      showUrbanization: false,
      form_has_changed: false,
      showBackModal: false,
      industry_select_desc: "",
      industry_select_id: "",
      industry_select_options: [],
      showSaveLoader: false,
    };
  }

  async handleNext(x) {
    await this.setState({ showSaveLoader: true });
    if (
      (this.props.busAddrCheck === false ||
        this.props.busAddrCheck === undefined) &&
      this.state.is_validated === false
    ) {
      var CheckUSPS = false;

      let verify = false;
      if (this.state.country === "US" && this.state.state.length > 0) {
        verify = true;
      }
      if (this.state.country.length > 0) {
        verify = true;
      }
      if (
        this.state.street_address.length > 0 &&
        this.state.city.length > 0 &&
        verify &&
        //this.state.state.length > 0 &&
        this.state.zip_code.length > 0
      ) {
        CheckUSPS = true;
      }
      if (CheckUSPS === true) {
        let street = this.state.street_address;

        if (this.state.address2 !== undefined) {
          const street2 = street + ", " + this.state.address2;
          street = street2;
        } else {
          this.setState({ address2: "" });
        }

        let data = {
          street: street,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip_code,
          country: this.state.country,
          resurbanization: this.state.urbanization,
        };
        const response = await getUSPSAddress(data);
        let verifyLevel = "default";
        if (response.data !== "undefined") {
          verifyLevel = response.data.VerificationFlags.VerifyLevel;
        }
        if (this.state.is_validated === true) {
          this.handleCloseModal(x);
        } else {
          if (verifyLevel === "Verified") {
            this.setState({
              usps_address: response.data.Address.Street1,
              usps_address2: response.data.Address.Street2,
              usps_city: response.data.Address.City,
              usps_state: response.data.Address.State,
              usps_zip_code: response.data.Address.Zip,
              usps_country: "US",
              showModal: true,
              addressNotFound: false,
            });
          } else {
            this.handleCloseModal(x);

            this.setState({
              usps_address: "USPS does not recognize the address you entered.",
              usps_address2: "",
              usps_city: "",
              usps_state: "",
              usps_zip_code: "",
              showModal: true,
              addressNotFound: true,
            });
          }
        }
      } else {
        let isValid = true;
        // street
        let streetInput = document.querySelector("#street_address");

        let streetErrorMessageBox = document.getElementById(
          streetInput.id + "_error"
        );

        if (this.state.street_address.length === 0) {
          streetInput.style.border = "1px solid #e21224";
          streetInput.style.color = "#e21224";
          streetErrorMessageBox.style.display = "block";
          streetInput.classList.add("validation-error");
          isValid = false;
        }

        //city
        let cityInput = document.querySelector("#city");

        let cityErrorMessageBox = document.getElementById(
          cityInput.id + "_error"
        );

        if (this.state.city.length === 0) {
          cityInput.style.border = "1px solid #e21224";
          cityInput.style.color = "#e21224";
          cityErrorMessageBox.style.display = "block";
          isValid = false;
        }

        //state
        if (this.state.country === "US") {
          let stateInput = document.querySelector("#state");

          let stateErrorMessageBox = document.getElementById(
            stateInput.id + "_error"
          );

          if (this.state.state.length === 0) {
            stateInput.style.border = "1px solid #e21224";
            stateInput.style.color = "#e21224";
            stateErrorMessageBox.style.display = "block";
            isValid = false;
          }
        }
        //zip code
        let zipCodeInput = document.querySelector("#zip_code");
        let zipCodeErrorMessageBox = document.getElementById(
          zipCodeInput.id + "_error"
        );

        if (this.state.zip_code.length === 0) {
          zipCodeInput.style.border = "1px solid #e21224";
          zipCodeInput.style.color = "#e21224";
          zipCodeErrorMessageBox.style.display = "block";
          isValid = false;
        }

        isValid && this.state.sales === "Y"
          ? this.nextClick()
          : this.addClick();
      }
    } else {
      this.state.sales === "Y" && this.state.step_number === 1
        ? this.nextClick()
        : this.addClick();
    }
    await this.setState({ showSaveLoader: false });
  }

  async nextClick() {
    await this.setState({
      step_number: 2,
      show_add_button: true,
      show_next_button: false,
      is_add_disabled: true,
      is_next_disabled: true,
      showModal: false,
    });
    if (this.state.review_edit === true) {
      this.setState({ is_add_disabled: false });
    }

    let myRef = document.getElementById("top_element");
    myRef.scrollIntoView();
    this.validateFields();

    if (
      this.state.training_start_date !== "" &&
      this.state.training_start_date !== "Invalid date"
    ) {
      var startdateEvent = new Event("blur");
      Object.defineProperty(startdateEvent, "target", {
        writable: false,
        value: document.getElementById("training_start_date"),
      });
      await this.onBlur(startdateEvent);
    }

    if (
      this.state.training_end_date !== "" &&
      this.state.training_end_date !== "Invalid date"
    ) {
      var enddateEvent = new Event("blur");
      Object.defineProperty(enddateEvent, "target", {
        writable: false,
        value: document.getElementById("training_end_date"),
      });
      await this.onBlur(enddateEvent);
    }

    if (this.state.subsidy !== "") {
      var subsidyEvent = new Event("blur");
      Object.defineProperty(subsidyEvent, "target", {
        writable: false,
        value: document.getElementById("subsidy"),
      });
      await this.onBlur(subsidyEvent);
    }
  }
  async addClick() {
    await this.setState({ is_add_disabled: true });
    var result;
    if (this.state.step_number < 3) {
      //save employment history record
      var currently_working = false;
      if (this.state.currently_working === "Y") {
        currently_working = true;
        await this.setState({ employment_end_date: "" });
      }
      var sales = false;
      if (this.state.sales === "Y") {
        sales = true;
      }
      var contact_employer = false;
      if (this.state.contact_employer === "Y") {
        contact_employer = true;
      }
      var irb = false;
      if (this.state.irb === "Y") {
        irb = true;
      }
      var individual_life = false;
      if (this.state.individual_life === "Y") {
        individual_life = true;
      }
      var individual_annuity = false;
      if (this.state.individual_annuity === "Y") {
        individual_annuity = true;
      }
      var individual_disability = false;
      if (this.state.individual_disability === "Y") {
        individual_disability = true;
      }
      var individual_longterm = false;
      if (this.state.individual_longterm === "Y") {
        individual_longterm = true;
      }
      var full_time_role = false;
      if (this.state.full_time_role === "Y") {
        full_time_role = true;
      }
      var training = false;
      if (this.state.training === "Y") {
        training = true;
      }
      var training_start_date = "";
      if (
        this.state.training_start_date !== "" &&
        this.state.training_start_date !== "Invalid date"
      ) {
        training_start_date = createDateObject(this.state.training_start_date);
      }
      var training_end_date = "";
      if (
        this.state.training_end_date !== "" &&
        this.state.training_end_date !== "Invalid date"
      ) {
        training_end_date = createDateObject(this.state.training_end_date);
      }
      var subsidy = "";
      if (this.state.subsidy !== "" && this.state.subsidy !== null) {
        subsidy = this.state.subsidy.replaceAll(",", "");
      }
      var db_end_date = "";
      if (this.state.employment_end_date !== "") {
        db_end_date = createDateObject(this.state.employment_end_date);
      }
      let data = {
        companyname: this.state.company_name,
        jobtitle: this.state.title,
        startdate: createDateObject(this.state.employment_start_date),
        enddate: db_end_date,
        currentemployer: currently_working,
        salesposition: sales,
        empphonenumber: this.state.contact_phone,
        contactemployer: contact_employer,
        empaddress1: this.state.street_address,
        empaddress2: this.state.address2,
        reasonforleaving: this.state.reason_for_leaving,
        empcity: this.state.city,
        empstate: this.state.state,
        empzip: this.state.zip_code,
        empcountry: this.state.country,
        urbanization: this.state.urbanization,
        invrelatedbus: irb,
        solicitindlife: individual_life,
        solicitindannuity: individual_annuity,
        solicitinddisability: individual_disability,
        solicitindltc: individual_longterm,
        fulltime: full_time_role,
        trainingsubsidy: training,
        trainingstartdate: training_start_date,
        trainingenddate: training_end_date,
        totalsubsidyrecd: subsidy,
        industryid: this.state.industry_select_id,
      };

      if (this.state.review_edit === false) {
        // insert record
        result = await insertEmploymentHistoryInformation(
          this.props.subId,
          data
        );
      } else {
        //update record
        result = await updateEmploymentHistoryInformation(
          this.props.subId,
          this.state.id,
          data
        );
      }

      this.setState({ review_edit: false, step_number: 0 });
      this.props.showAlert();
      this.props.actionCancel({
        candidateid: result.data.payload[0].candidateid,
        companyname: data.companyname,
        description: null,
        enddate: data.enddate === "" ? null : data.enddate,
        id: result.data.payload[0].employmenthistoryid,
        istheregap: data.istheregap,
        jobtitle: data.jobtitle,
        overlappingRange: null,
        startdate: data.startdate,
      });
      //this.resetFields();
    } else if (this.state.step_number === 3) {
      //save employment break record
      var employmentbreakid = 0;
      var other_text = "";
      let jobtitle = "";
      if (this.state.fulltime_student === "Y") {
        employmentbreakid = 1;
        jobtitle = "Full Time Student";
      }
      if (this.state.homemaker === "Y") {
        employmentbreakid = 2;
        jobtitle = "Homemaker";
      }
      if (this.state.unemployment === "Y") {
        employmentbreakid = 3;
        jobtitle = "Unemployment";
      }
      if (this.state.military === "Y") {
        employmentbreakid = 4;
        jobtitle = "Military Service";
      }
      if (this.state.travel === "Y") {
        employmentbreakid = 5;
        jobtitle = "Extended Travel";
      }
      if (this.state.other === "Y") {
        employmentbreakid = 6;
        other_text = this.state.other_text;
        jobtitle = this.state.other_text;
      }

      let data = {
        employmentbreakid: employmentbreakid,
        other: other_text,
        startdate: createDateObject(this.state.break_start_date),
        enddate: createDateObject(this.state.break_end_date),
      };
      if (this.state.review_edit === false) {
        result = await insertEmploymentBreakHistoryInformation(
          this.props.subId,
          data
        );
      } else {
        //update record
        result = await updateEmploymentBreakHistoryInformation(
          this.props.subId,
          this.state.id,
          data
        );
      }
      this.setState({ review_edit: false, step_number: 0 });
      this.props.showAlert();
      this.props.actionCancel({
        candidateid: result.data.payload[0].candidateid,
        companyname: null,
        description: null,
        enddate: data.enddate === "" ? null : data.enddate,
        id: result.data.payload[0].employmentbreakhistoryid,
        istheregap: data.istheregap,
        jobtitle: jobtitle,
        overlappingRange: null,
        startdate: data.startdate,
      });
    }
    await this.resetFields();
  }
  async cancelClick() {
    this.props.actionCancel(null);
    this.resetFields();
  }
  async backClick() {
    await this.setState({
      step_number: 1,
      is_next_disabled: false,
      show_next_button: true,
      show_add_button: false,
    });
    let myRef = document.getElementById("top_element");
    myRef.scrollIntoView();
  }
  async handleChange(event) {
    if (
      event.target.id === "fulltime_student" ||
      event.target.id === "homemaker" ||
      event.target.id === "unemployment" ||
      event.target.id === "military" ||
      event.target.id === "travel" ||
      event.target.id === "other"
    ) {
      await this.setState({
        fulltime_student: "N",
        homemaker: "N",
        unemployment: "N",
        military: "N",
        travel: "N",
        other: "N",
        other_text: "",
      });
    }
    await this.setState({
      [event.target.id]: event.target.value,
    });

    if (event.target.id === "subsidy") {
      var temp = event.target.value.replaceAll(",", "");
      var truncated = temp.substring(0, 9);
      var tempNumber = truncated.replace(/,/gi, "");
      var commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
      await this.setState({ subsidy: commaSeparatedNumber });
    }

    this.setState({ show_other_panel: this.state.other === "Y" });

    if (event.target.id === "employment_end_date_display") {
      await this.setState({
        employment_end_date_display: event.target.value,
        employment_end_date: event.target.value,
      });
      if (this.state.currently_working === "Y") {
        await this.setState({
          employment_end_date: Moment().format("MM/DD/YYYY"),
        });
      }
    }

    if (
      event.target.id === "street_address" ||
      event.target.id === "address2" ||
      event.target.id === "city" ||
      event.target.id === "state" ||
      event.target.id === "zip_code" ||
      event.target.id === "country"
    ) {
      this.setState({ is_validated: false });
    }
    if (this.state.state === "PR") {
      this.setState({ showUrbanization: true });
    } else {
      this.setState({ showUrbanization: false, urbanization: "" });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handlePhoneChange() {
    var phone = document.getElementById("contact_phone");
    await this.setState({
      contact_phone: phone.value,
      is_form_saved: false,
    });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async resetFields() {
    await this.setState({
      is_add_disabled: true,
      is_next_disabled: true,
      show_add_button: true,
      show_next_button: false,
      currentTab: "Employment Experience",
      show_training_info: false,
      step_number: 0,
      title: "",
      company_name: "",
      employment_start_date: "",
      employment_end_date: "",
      employment_end_date_display: "",
      street_address: "",
      address2: "",
      reason_for_leaving: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      contact_phone: "",
      training_start_date: "",
      training_end_date: "",
      subsidy: "",
      sales: "",
      irb: "",
      currently_working: "",
      contact_employer: "",
      individual_life: "",
      individual_annuity: "",
      individual_disability: "",
      individual_longterm: "",
      full_time_role: "",
      training: "",
      formHasError: false,
      ctrl_with_focus: "",
      is_form_saved: false,
      fulltime_student: "",
      homemaker: "",
      unemployment: "",
      military: "",
      travel: "",
      other: "",
      other_text: "",
      break_start_date: "",
      break_end_date: "",
      show_other_panel: false,
      display_company_name: "",
      display_title: "",
      display_start_date: "",
      display_end_date: "",
      is_there_gap: false,
      review_edit: false,
      id: "",
      end_date_disabled: false,
      may_we_contact_employer: false,
      show_state_required: false,
      showModal: false,
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      usps_country: "",
      urbanization: "",
      addressNotFound: false,
      is_validated: true,
      showUrbanization: false,
      industry_select_desc: "",
      industry_select_id: "",
      form_has_changed: false,
      showSaveLoader: false,
    });
  }
  async handleSalesChange(event) {
    if (event.target.value === "Y") {
      await this.setState({ show_next_button: true, show_add_button: false });
    }
    if (event.target.value === "N") {
      await this.setState({ show_next_button: false, show_add_button: true });
    }
    await this.setState({
      individual_life: "",
      individual_annuity: "",
      individual_disability: "",
      individual_longterm: "",
      full_time_role: "",
      training: "",
      training_start_date: "",
      training_end_date: "",
      subsidy: "",
    });

    await this.setState({ sales: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleIRBChange(event) {
    await this.setState({ irb: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleCurrentlyWorkingChange(event) {
    await this.setState({ currently_working: event.target.value });
    if (this.state.currently_working === "Y") {
      await this.setState({ end_date_disabled: true });
      await this.setState({
        employment_end_date: Moment().format("MM/DD/YYYY"),
        employment_end_date_display: "",
      });
      await this.setState({ reason_for_leaving: "" });
      // clear any errors
      var obj_error = document.getElementById("end_date_error_test");
      var obj_end_date = document.getElementById("employment_end_date_display");
      obj_error.style.display = "none";
      obj_end_date.removeAttribute("style");
      obj_end_date.classList.remove("validation-error");
    } else {
      await this.setState({
        end_date_disabled: false,
      });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleContactEmployerChange(event) {
    await this.setState({ contact_employer: event.target.value });
    if (this.state.contact_employer === "Y") {
      this.setState({ may_we_contact_employer: true, contact_phone: "" });
    } else {
      this.setState({ may_we_contact_employer: false, contact_phone: "" });
    }
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleIndividualLifeChange(event) {
    await this.setState({ individual_life: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleIndividualAnnuityChange(event) {
    await this.setState({ individual_annuity: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleIndividualDisabilityChange(event) {
    await this.setState({ individual_disability: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleLongTermCareChange(event) {
    await this.setState({ individual_longterm: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleFullTimeRoleChange(event) {
    await this.setState({ full_time_role: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async handleTrainingChange(event) {
    if (event.target.value === "Y") {
      await this.setState({ show_training_info: true });
    }
    if (event.target.value === "N") {
      await this.setState({
        show_training_info: false,
        training_start_date: "",
        training_end_date: "",
        subsidy: "",
      });
    }
    await this.setState({ training: event.target.value });
    this.setState({ form_has_changed: true });
    this.validateFields();
  }
  async onBlur(event) {
    //await this.setState({ formHasError: false });
    var obj_error;
    if (
      this.state.ctrl_with_focus.includes("option") &&
      this.state.ctrl_with_focus !== event.target.id
    ) {
      //do nothing
    } else if (event.target.id === "title") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "company_name") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.startsWith("employment_start_date")) {
      var hasError;
      obj_error = document.getElementById("start_date_error_test");
      var end_date = document.getElementById("employment_end_date_display");
      var end_date_error = document.getElementById("end_date_error_test");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter a Start Date";
        hasError = true;
      } else {
        var currDate = Moment();
        var dateParts = event.target.value.split("/");
        var month = dateParts[0];
        var day = dateParts[1];
        var year = dateParts[2];
        var dateCalc = Moment(year + "-" + month + "-" + day);
        var yearDiff = currDate.diff(dateCalc, "year");

        if (dateCalc > currDate) {
          obj_error.innerText = "Start date cannot be a future date";
          hasError = true;
        } else if (yearDiff >= 100) {
          obj_error.innerText = "Start date cannot be more than 100 years ago";
          hasError = true;
        } else if (
          this.state.currently_working !== "Y" &&
          isValidDate(end_date.value) &&
          end_date.value.trim(" ").length > 0
        ) {
          if (Moment(event.target.value) > Moment(end_date.value)) {
            obj_error.innerText = "Start date cannot be after end date.";
            hasError = true;
          }
        }
      }
      if (hasError) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");

        if (end_date_error.style.display === "block") {
          // run onblur for end_date
          var enddateEvent = new Event("blur");
          Object.defineProperty(enddateEvent, "target", {
            writable: false,
            value: document.getElementById("employment_end_date"),
          });
          await this.onBlur(enddateEvent);
        }
      }
    } else if (event.target.id.startsWith("employment_end_date")) {
      var hasError2;
      obj_error = document.getElementById("end_date_error_test");
      var start_date = document.getElementById("employment_start_date");
      var start_date_error = document.getElementById("start_date_error_test");
      var newDate = Moment().subtract(1, "days");
      var endDate = Moment(event.target.value);
      var tenYearsAgo = Moment(newDate).subtract(10, "years");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter an End Date";
        hasError2 = true;
      } else if (endDate < tenYearsAgo) {
        obj_error.innerText = "End dates must fall within the last 10 years";
        hasError2 = true;
      } else {
        var currDate2 = Moment();
        var dateParts2 = event.target.value.split("/");
        var month2 = dateParts2[0];
        var day2 = dateParts2[1];
        var year2 = dateParts2[2];
        var dateCalc2 = Moment(year2 + "-" + month2 + "-" + day2);
        var yearDiff2 = currDate2.diff(dateCalc2, "year");

        if (dateCalc2 > currDate2) {
          obj_error.innerText = "End date cannot be a future date";
          hasError2 = true;
        } else if (yearDiff2 >= 100) {
          obj_error.innerText = "End date cannot be more than 100 years ago";
          hasError2 = true;
        } else if (
          isValidDate(start_date.value) &&
          start_date.value.trim(" ").length > 0
        ) {
          if (Moment(start_date.value) > Moment(event.target.value)) {
            obj_error.innerText = "End date cannot be before Start date";
            hasError2 = true;
          }
        }
      }
      if (hasError2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");

        if (start_date_error.style.display === "block") {
          // run onblur for start_date
          var startdateEvent = new Event("blur");
          Object.defineProperty(startdateEvent, "target", {
            writable: false,
            value: document.getElementById("employment_start_date"),
          });
          await this.onBlur(startdateEvent);
        }
      }
    } else if (event.target.id === "street_address") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "city") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "reason_for_leaving") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 1) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "state") {
      var stateCtrl = document.getElementById("state");
      stateCtrl.style = "none";
      obj_error = document.getElementById("state_error");
      if (
        event.target.id.includes("option") &&
        this.state.ctrl_with_focus.includes("state_option")
      ) {
        //do nothing
      } else if (
        // state not necessary if country != US
        (event.target.id === "state" &&
          event.target.innerText.trim(" ").length === 0 &&
          this.state.country === "US") ||
        (this.state.state === "" && this.state.country === "US")
      ) {
        stateCtrl.style.border = "1px solid #e21224";
        stateCtrl.style.color = "#e21224";
        obj_error.style.display = "block";
      } else {
        stateCtrl.style.border = "1px solid #555";
        stateCtrl.style.color = "#555";
        obj_error.style.display = "none";
      }
    } else if (event.target.id === "country") {
      var countryCtrl = document.getElementById("country");
      countryCtrl.style = "none";
      obj_error = document.getElementById("country_error");
      if (
        event.target.id.includes("option") &&
        this.state.ctrl_with_focus === "country_option"
      ) {
        //do nothing
      } else if (
        (event.target.id === "country" &&
          event.target.innerText.trim(" ").length === 0) ||
        this.state.country === ""
      ) {
        countryCtrl.style.border = "1px solid #e21224";
        countryCtrl.style.color = "#e21224";
        obj_error.style.display = "block";
      } else {
        countryCtrl.style.border = "1px solid #555";
        countryCtrl.style.color = "#555";
        obj_error.style.display = "none";
      }
      if (this.state.country === "US") {
        this.setState({ show_state_required: true });
      } else {
        this.setState({ show_state_required: false });
        obj_error = document.getElementById("state_error");
        stateCtrl = document.getElementById("state");
        stateCtrl.style.border = "1px solid #555";
        stateCtrl.style.color = "#555";
        this.setState({ state: "" });
        obj_error.style.display = "none";
        stateCtrl.style.border = "1px solid #555";
      }
    } else if (event.target.id === "zip_code") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 5) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "country") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      }
    } else if (event.target.id === "contact_phone") {
      obj_error = document.getElementById(event.target.id + "_error");
      let result = event.target.value.replace(/\s/g, "");
      if (result.length < 11) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.startsWith("training_start_date")) {
      var hasError3;
      obj_error = document.getElementById("training_start_date_error");
      var training_end_date = document.getElementById("training_end_date");
      var training_end_date_error = document.getElementById(
        "training_end_date_error"
      );

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter a Start Date";
        hasError3 = true;
      } else if (
        Moment(event.target.value) < Moment(this.state.employment_start_date)
      ) {
        obj_error.innerText =
          "Start date cannot be before the Employment start date";
        hasError3 = true;
      } else if (
        Moment(event.target.value) > Moment(this.state.employment_end_date)
      ) {
        if (Moment(event.target.value) > Moment()) {
          obj_error.innerText = "Start date cannot be a future date";
        } else {
          obj_error.innerText =
            "Start date cannot be after the Employment end date";
        }
        //obj_error.innerText =
        //"Start date cannot be after the Employment end date";
        hasError3 = true;
      } else {
        var currDate3 = Moment();
        var dateParts3 = event.target.value.split("/");
        var month3 = dateParts3[0];
        var day3 = dateParts3[1];
        var year3 = dateParts3[2];
        var dateCalc3 = Moment(year3 + "-" + month3 + "-" + day3);
        var yearDiff3 = currDate3.diff(dateCalc3, "year");

        if (dateCalc3 > currDate3) {
          obj_error.innerText = "Start date cannot be a future date";
          hasError3 = true;
        } else if (yearDiff3 >= 100) {
          obj_error.innerText = "Start date cannot be more than 100 years ago";
          hasError3 = true;
        } else if (
          isValidDate(training_end_date.value) &&
          training_end_date.value.trim(" ").length > 0
        ) {
          if (Moment(event.target.value) > Moment(training_end_date.value)) {
            obj_error.innerText = "Start date cannot be after the end date";
            hasError3 = true;
          }
        }
      }
      if (hasError3) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");
        if (training_end_date_error.style.display === "block") {
          // run onblur for training_end_date
          var trainingenddateEvent = new Event("blur");
          Object.defineProperty(trainingenddateEvent, "target", {
            writable: false,
            value: document.getElementById("training_end_date"),
          });
          await this.onBlur(trainingenddateEvent);
        }
      }
    } else if (event.target.id.startsWith("training_end_date")) {
      var hasError4;
      obj_error = document.getElementById("training_end_date_error");
      var training_start_date = document.getElementById("training_start_date");
      var training_start_date_error = document.getElementById(
        "training_start_date_error"
      );
      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter an End Date";
        hasError4 = true;
      } else if (
        Moment(event.target.value) < Moment(this.state.employment_start_date)
      ) {
        obj_error.innerText =
          "End date cannot be before the Employment start date";
        hasError4 = true;
      } else if (
        Moment(event.target.value) > Moment(this.state.employment_end_date)
      ) {
        if (Moment(event.target.value) > Moment()) {
          obj_error.innerText = "End date cannot be a future date";
        } else {
          obj_error.innerText =
            "End date cannot be after the Employment end date";
        }
        hasError4 = true;
      } else {
        var currDate4 = Moment();
        var dateParts4 = event.target.value.split("/");
        var month4 = dateParts4[0];
        var day4 = dateParts4[1];
        var year4 = dateParts4[2];
        var dateCalc4 = Moment(year4 + "-" + month4 + "-" + day4);
        var yearDiff4 = currDate4.diff(dateCalc4, "year");

        if (dateCalc4 > currDate4) {
          obj_error.innerText = "End date cannot be a future date";
          hasError4 = true;
        } else if (yearDiff4 >= 100) {
          obj_error.innerText = "End date cannot be more than 100 years ago";
          hasError4 = true;
        } else if (
          isValidDate(training_start_date.value) &&
          training_start_date.value.trim(" ").length > 0
        ) {
          if (Moment(training_start_date.value) > Moment(event.target.value)) {
            obj_error.innerText = "End date cannot be before the start date";
            hasError4 = true;
          }
        }
      }
      if (hasError4) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");

        if (training_start_date_error.style.display === "block") {
          // run onblur for training_start_date
          var trainingstartdateEvent = new Event("blur");
          Object.defineProperty(trainingstartdateEvent, "target", {
            writable: false,
            value: document.getElementById("training_start_date"),
          });
          await this.onBlur(trainingstartdateEvent);
        }
      }
    } else if (event.target.id === "subsidy") {
      obj_error = document.getElementById(event.target.id + "_error");
      //let obj_input = document.getElementById(event.target.id + "_input");
      let obj_input_$ = document.getElementById(event.target.id + "_input_$");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        //obj_input.style.border = "1px solid #e21224";
        //obj_input.style.color = "#e21224";
        obj_input_$.style.color = "#e21224";
        obj_input_$.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        event.target.style.border = "1px solid #e21224";
        event.target.style.borderLeft = "0px";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        //obj_input.removeAttribute("style");
        obj_input_$.removeAttribute("style");
      }
    } else if (event.target.id === "insurance_sales") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "total_insurance_sales") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.startsWith("break_start_date")) {
      var hasError5;
      obj_error = document.getElementById("break_start_date_error");
      var break_end_date = document.getElementById("break_end_date");
      var break_end_date_error = document.getElementById(
        "break_end_date_error"
      );

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter a Start Date";
        hasError5 = true;
      } else {
        var currDate5 = Moment();
        var dateParts5 = event.target.value.split("/");
        var month5 = dateParts5[0];
        var day5 = dateParts5[1];
        var year5 = dateParts5[2];
        var dateCalc5 = Moment(year5 + "-" + month5 + "-" + day5);
        var yearDiff5 = currDate5.diff(dateCalc5, "year");

        if (dateCalc5 > currDate5) {
          obj_error.innerText = "Start date cannot be a future date";
          hasError5 = true;
        } else if (yearDiff5 >= 100) {
          obj_error.innerText = "Start date cannot be more than 100 years ago";
          hasError5 = true;
        } else if (
          isValidDate(break_end_date.value) &&
          break_end_date.value.trim(" ").length > 0
        ) {
          if (Moment(event.target.value) > Moment(break_end_date.value)) {
            obj_error.innerText = "Start date cannot be after the end date";
            hasError5 = true;
          }
        }
      }
      if (hasError5) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");

        if (break_end_date_error.style.display === "block") {
          // run onblur for break_end_date
          var breakenddateEvent = new Event("blur");
          Object.defineProperty(breakenddateEvent, "target", {
            writable: false,
            value: document.getElementById("break_end_date"),
          });
          await this.onBlur(breakenddateEvent);
        }
      }
    } else if (event.target.id.startsWith("break_end_date")) {
      var hasError6 = false;
      obj_error = document.getElementById("break_end_date_error");
      var break_start_date = document.getElementById("break_start_date");
      var break_start_date_error = document.getElementById(
        "break_start_date_error"
      );
      var newDate2 = Moment().subtract(1, "days");
      var endDate2 = Moment(event.target.value);
      var tenYearsAgo2 = Moment(newDate2).subtract(10, "years");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0 ||
        event.target.value.slice(-4) === "0000" ||
        !isValidDate(event.target.value)
      ) {
        obj_error.innerText = "Please enter an End Date";
        hasError6 = true;
      } else if (endDate2 < tenYearsAgo2) {
        obj_error.innerText = "End dates must fall within the last 10 years";
        hasError6 = true;
      } else {
        var currDate6 = Moment();
        var dateParts6 = event.target.value.split("/");
        var month6 = dateParts6[0];
        var day6 = dateParts6[1];
        var year6 = dateParts6[2];
        var dateCalc6 = Moment(year6 + "-" + month6 + "-" + day6);
        var yearDiff6 = currDate6.diff(dateCalc6, "year");

        if (dateCalc6 > currDate6) {
          obj_error.innerText = "End date cannot be a future date";
          hasError6 = true;
        } else if (yearDiff6 >= 100) {
          obj_error.innerText = "End date cannot be more than 100 years ago";
          hasError6 = true;
        } else if (
          isValidDate(break_start_date.value) &&
          break_start_date.value.trim(" ").length > 0
        ) {
          if (Moment(break_start_date.value) > Moment(event.target.value)) {
            obj_error.innerText = "End date cannot be before Start date";
            hasError6 = true;
          }
        }
      }
      if (hasError6) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");

        if (break_start_date_error.style.display === "block") {
          // run onblur for break_start_date
          var breakstartdateEvent = new Event("blur");
          Object.defineProperty(breakstartdateEvent, "target", {
            writable: false,
            value: document.getElementById("break_start_date"),
          });
          await this.onBlur(breakstartdateEvent);
        }
      }
    } else if (event.target.id === "other_text") {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length < 2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "industry_select") {
      var industryCtrl = document.getElementById("industry_select");
      industryCtrl.style = "none";
      obj_error = document.getElementById("industry_select_error");
      if (
        event.target.id.includes("option") &&
        this.state.ctrl_with_focus.includes("industry_option")
      ) {
        //do nothing
      } else {
        if (this.state.industry_select_id === "") {
          obj_error.style.display = "block";
          industryCtrl.style.border = "1px solid #e21224";
          industryCtrl.color = "#e21224";
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          industryCtrl.removeAttribute("style");
        }
      }
    }
    this.validateFields();
  }

  handlePlaceSelect(addressObject) {
    //this.props.isFormComplete(false);
    //this.props.hasCurrentFormChanged(true);
    this.setState({ is_validated: false });
    var address_components = this.getAddressComponents(
      addressObject.address_components
    );

    let input;
    if (address_components.street_number === "") {
      input = this.state.street_address.split(" ");

      if (address_components.street === "") {
        for (let i = 0; i < input.length; i++)
          input[i] = input[i][0].toUpperCase() + input[i].substr(1);
        this.setState({
          street_address: input.join(" "),
        });
      } else {
        this.setState({
          //street_address: input[0] + " " + address_components.street,
          street_address: address_components.street,
        });
      }
    } else {
      let addr = address_components.street;
      if (addr.toLocaleUpperCase().includes("AVENIDA")) {
        addr = addr.replace("Avenida", "Ave");
      }
      this.setState({
        street_address: address_components.street_number + " " + addr,
      });
    }
    let geocoder = new google.maps.Geocoder();

    if (address_components.city === "" && address_components.zip_code !== "") {
      geocoder
        .geocode({
          address:
            address_components.zip_code + "," + address_components.country,
        })
        .then((response) => {
          var newAddressComponents = response.results[0]["address_components"];
          for (var i = 0; i < newAddressComponents.length; i++) {
            if (newAddressComponents[i].types.includes("locality") === true) {
              this.setState({ city: newAddressComponents[i]["long_name"] });
            }
          }
        });
    } else if (
      address_components.city !== "" &&
      address_components.zip_code === ""
    ) {
      geocoder
        .geocode({
          address:
            "10" +
            address_components.street +
            "," +
            address_components.city +
            "," +
            address_components.state +
            "," +
            address_components.country,
        })
        .then((response) => {
          let newAddressComponents = response.results[0]["address_components"];
          for (var i = 0; i < newAddressComponents.length; i++) {
            if (
              newAddressComponents[i].types.includes("postal_code") === true
            ) {
              this.setState({ zip_code: newAddressComponents[i]["long_name"] });
            }
          }
        });
    }
    this.clearInputErrors();

    this.setState({
      city: address_components.city,
      state: address_components.state,
      zip_code: address_components.zip_code,
      address2: "",
      urbanization: "",
      country:
        address_components.country === "Puerto Rico"
          ? "US"
          : address_components.country.match(/\b(\w)/g).join(""),
    });
    if (this.state.country === "US") {
      this.setState({ show_state_required: true });
    } else {
      this.setState({ show_state_required: false });
    }
    this.validateFields();
  }
  getAddressComponents(address) {
    var USAddressComponents = {
      street_number: ["street_number"],
      street: ["street_address", "route"],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      zip_code: ["postal_code"],
    };

    var address_components = {
      street_number: "",
      street: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      urbanization: "",
    };

    address.forEach((component) => {
      switch (component.long_name) {
        case "Puerto Rico":
        case "United States":
          address_components.country = component.long_name;

          if (address_components.country === "Puerto Rico")
            this.handleShowUrbanization();
          else this.handleHideUrbanization();

          break;
        default:
      }
    });

    address.forEach((component) => {
      for (var addressComponent in USAddressComponents) {
        if (
          USAddressComponents[addressComponent].indexOf(component.types[0]) !==
          -1
        ) {
          if (addressComponent === "state") {
            if (address_components.country === "Puerto Rico") {
              address_components[addressComponent] = "PR";
            } else {
              address_components[addressComponent] = component.short_name;
            }
          } else {
            address_components[addressComponent] = component.long_name;
          }
        }
      }
    });
    return address_components;
  }
  handleShowUrbanization() {
    this.setState({ showUrbanization: true });
  }

  handleHideUrbanization() {
    this.setState({ showUrbanization: false });
  }
  clearInputErrors() {
    let streetInput = document.querySelector("#street_address");
    let address2Input = document.querySelector("#address2");
    let cityInput = document.querySelector("#city");
    let stateInput = document.querySelector("#state");
    let zipCodeInput = document.querySelector("#zip_code");
    let countryInput = document.querySelector("#country");

    let streetErrorMessageBox = document.getElementById(
      streetInput.id + "_error"
    );
    let address2ErrorMessageBox = document.getElementById(
      address2Input.id + "_error"
    );
    let cityErrorMessageBox = document.getElementById(cityInput.id + "_error");
    let stateErrorMessageBox = document.getElementById(
      stateInput.id + "_error"
    );
    let zipCodeErrorMessageBox = document.getElementById(
      zipCodeInput.id + "_error"
    );
    let countryErrorMessageBox = document.getElementById(
      countryInput.id + "_error"
    );

    streetInput.style = "1px solid #6b748e";
    address2Input.style = "1px solid #6b748e";
    cityInput.style = "1px solid #6b748e";
    stateInput.style = "1px solid #6b748e";
    zipCodeInput.style = "1px solid #6b748e";
    countryInput.style = "1px solid #6b748e";

    streetErrorMessageBox.style.display = "none";
    address2ErrorMessageBox.style.display = "none";
    cityErrorMessageBox.style.display = "none";
    stateErrorMessageBox.style.display = "none";
    zipCodeErrorMessageBox.style.display = "none";
    countryErrorMessageBox.style.display = "none";
  }
  async handleUseEnteredAddress() {
    await this.setState({
      is_validated: true,
      useSuggestedAddress: false,
    });
    this.state.sales === "Y" && this.state.step_number === 1
      ? this.nextClick()
      : this.addClick();
  }
  async handleUseSuggestedAddress(event) {
    if (this.state.usps_address.toUpperCase().includes("URB")) {
      await this.setState({
        street_address: this.state.usps_address2,
        address2: "",
        city: this.state.usps_city,
        state: this.state.usps_state,
        zip_code: this.state.usps_zip_code,
        country: this.state.country,
        urbanization: this.state.usps_address,
        is_validated: true,
        useSuggestedAddress: true,
      });
    } else {
      await this.setState({
        street_address: this.state.usps_address,
        address2: this.state.usps_address2,
        city: this.state.usps_city,
        state: this.state.usps_state,
        zip_code: this.state.usps_zip_code,
        country: this.state.country,
        urbanization: "",
        is_validated: true,
        useSuggestedAddress: true,
      });
    }
    if (this.state.usps_state === "PR") this.handleShowUrbanization();
    else this.handleHideUrbanization();
    this.state.sales === "Y" && this.state.step_number === 1
      ? this.nextClick()
      : this.addClick();
  }
  async handleCloseModal(x) {
    if (x === "confirm") {
      await this.setState({ form_has_changed: true });
    }
    if (this.state.form_has_changed && x !== "confirm") {
      this.setState({ showBackModal: true });
    } else {
      this.setState({ showBackModal: false });
      if (x !== "confirm") {
        this.cancelClick();
      }
    }
    if (x === "close") {
      this.setState({ showBackModal: false, showModal: false });
    }
  }

  onFocus = (event) => {
    if (event.target.street_address) {
      event.target.autocomplete = "new-address";
    }
  };
  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ form_has_changed: false });
    this.handleCloseBackModal();
    this.resetFields();
    this.props.actionCancel(null);
  }
  async handleIndustryChange(event) {
    this.setState({
      industry_select_desc: event.target.innerText,
      industry_select_id: event.target.value,
      is_form_saved: false,
      formHasChanged: true,
    });
  }

  render() {
    const { showBackModal, showModal } = this.state;
    const { isOpen } = this.props;
    return (
      <>
        <div>
          <div id="addressModal"></div>

          <div>
            <Modal
              id="empHistModal"
              show={isOpen && !showBackModal && !showModal}
              className="modal-md"
              title=""
            >
              <div id="top_element">
                <div className="modal-body">
                  <div className="container">
                    {this.state.step_number > 0 && (
                      <>
                        <div className="row">
                          <div
                            className="col-md-11"
                            style={{ paddingLeft: "0px" }}
                          >
                            <h2>Add Experience</h2>
                          </div>
                          <div className="col-md-1">
                            <h2 style={{ marginBottom: ".4rem" }}>
                              <button
                                id="emp_hist_close_modal"
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close modal dialog"
                                //onClick={this.cancelClick}
                                onClick={this.handleCloseModal}
                              />
                            </h2>
                          </div>
                        </div>
                        <div
                          className="mm-tabnav"
                          style={{ marginTop: "10px", marginBottom: "0px" }}
                        >
                          <ul
                            className="nav nav-tabs"
                            id="sampleTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <label
                                className={
                                  this.state.currentTab ===
                                  "Employment Experience"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="experience"
                                onClick={this.updateCurrentTab}
                              >
                                Employment Experience
                              </label>
                            </li>
                            <li className="nav-item" role="presentation">
                              <label
                                className={
                                  this.state.currentTab === "Employment Break"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="break"
                                onClick={this.updateCurrentTab}
                              >
                                Employment Break
                              </label>
                            </li>
                          </ul>
                        </div>
                        <hr
                          id="emp_hist_modal_hr"
                          style={{ color: "#C1C9D5", marginTop: "-5px" }}
                        ></hr>
                      </>
                    )}
                  </div>
                  {this.state.step_number > 0 && (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <span className="required-field-text">
                            * = Required Field
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.step_number === 0 && (
                    <div
                      className="row"
                      style={{ marginTop: "15px", height: "350px" }}
                    >
                      <div
                        id="emp_hist_loading"
                        className="mm-dots-loading-xl"
                        style={{ marginLeft: "225px", marginTop: "170px" }}
                      />
                    </div>
                  )}
                  {this.state.step_number === 1 && (
                    <>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-12">
                          <label htmlFor="title" id="emp_hist_title_label">
                            <strong>Title*</strong>
                          </label>
                          <input
                            type="text"
                            id="title"
                            className="form-control"
                            spellCheck="false"
                            maxLength={100}
                            autoComplete="new-password"
                            value={this.state.title}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                          />
                          <div id="title_error" className="invalid-feedback">
                            Please enter a Title
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-12">
                          <label
                            htmlFor="company_name"
                            id="emp_hist_company_name_label"
                          >
                            <strong>Company Name*</strong>
                          </label>
                          <input
                            type="text"
                            id="company_name"
                            className="form-control"
                            spellCheck="false"
                            maxLength={100}
                            value={this.state.company_name}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                            autoComplete="off"
                          />
                          <div
                            id="company_name_error"
                            className="invalid-feedback"
                          >
                            Please enter a Company Name
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-12">
                          <label
                            id="emp_hist_industry"
                            htmlFor="industry_select"
                          >
                            <strong>Industry*</strong>
                          </label>
                          <DropdownButton
                            id="industry_select"
                            type="default"
                            variant="secondary"
                            title={this.state.industry_select_desc}
                            selected={false}
                            autoComplete="new-password"
                            onSelect={(key, event) => {
                              event.target.id = "industry_select";
                              event.target.value = key;
                              this.handleIndustryChange(event);
                            }}
                            onBlur={this.onBlur}
                            onClick={clickFunc}
                            onMouseDown={this.onKeyDown}
                            onFocus={this.onFocus}
                          >
                            {this.state.industry_select_options
                              .sort((a, b) =>
                                a.i_industryname < b.i_industryname ? -1 : 1
                              )
                              .map((option) => (
                                <Dropdown.Item
                                  key={option.i_industryid}
                                  eventKey={option.i_industryid}
                                  id={"industry_option_" + option.i_industryid}
                                >
                                  {option.i_industryname}
                                </Dropdown.Item>
                              ))}
                          </DropdownButton>
                          <div
                            id="industry_select_error"
                            className="invalid-feedback"
                          >
                            Please choose an Industry
                          </div>
                        </div>
                      </div>

                      {/* END */}
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-7">
                          <span id="emp_hist_sales_label">
                            Is this position in Sales?<strong>*</strong>
                          </span>{" "}
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover id="popover-sales">
                                You will need to answer a few questions related
                                to your sales history on a separate section in
                                this application.
                              </Popover>
                            }
                          >
                            <button
                              variant="link"
                              className="btn btn-link"
                              id="sales_button"
                              style={{ paddingTop: "1px" }}
                            >
                              <span className="icon-info"></span>
                            </button>
                          </OverlayTrigger>
                        </div>
                        <div className="col-md-2 custom-control custom-radio">
                          <input
                            type="radio"
                            id="sales_yes"
                            name="sales"
                            className="custom-control-input"
                            checked={this.state.sales === "Y"}
                            value="Y"
                            onChange={this.handleSalesChange}
                          />
                          <label
                            htmlFor="sales_yes"
                            className="custom-control-label"
                            id="label_sales_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="col-md-3 custom-control custom-radio">
                          <input
                            type="radio"
                            id="sales_no"
                            name="sales"
                            className="custom-control-input"
                            checked={this.state.sales === "N"}
                            value="N"
                            onChange={this.handleSalesChange}
                          />
                          <label
                            htmlFor="sales_no"
                            className="custom-control-label"
                            id="label_sales_no"
                          >
                            No
                          </label>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-7">
                          <span id="emp_hist_irb_label">
                            Is this position in Investment Related Business?
                            <strong>*</strong>
                          </span>{" "}
                        </div>
                        <div className="col-md-2 custom-control custom-radio">
                          <input
                            type="radio"
                            id="irb_yes"
                            name="irb"
                            className="custom-control-input"
                            checked={this.state.irb === "Y"}
                            value="Y"
                            onChange={this.handleIRBChange}
                          />
                          <label
                            htmlFor="irb_yes"
                            className="custom-control-label"
                            id="label_irb_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="col-md-3 custom-control custom-radio">
                          <input
                            type="radio"
                            id="irb_no"
                            name="irb"
                            className="custom-control-input"
                            checked={this.state.irb === "N"}
                            value="N"
                            onChange={this.handleIRBChange}
                          />
                          <label
                            htmlFor="irb_no"
                            className="custom-control-label"
                            id="label_irb_no"
                          >
                            No
                          </label>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-7">
                          <span id="emp_hist_currently_working_label">
                            I am currently working in this role
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-2 custom-control custom-radio">
                          <input
                            type="radio"
                            id="currently_working_yes"
                            name="currently_working"
                            className="custom-control-input"
                            checked={this.state.currently_working === "Y"}
                            value="Y"
                            onChange={this.handleCurrentlyWorkingChange}
                          />
                          <label
                            htmlFor="currently_working_yes"
                            className="custom-control-label"
                            id="label_currently_working_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="col-md-3 custom-control custom-radio">
                          <input
                            type="radio"
                            id="currently_working_no"
                            name="currently_working"
                            className="custom-control-input"
                            checked={this.state.currently_working === "N"}
                            value="N"
                            onChange={this.handleCurrentlyWorkingChange}
                          />
                          <label
                            htmlFor="currently_working_no"
                            className="custom-control-label"
                            id="label_currently_working_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      {this.state.currently_working === "N" && (
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col">
                            <label id="reasonforleavingText">
                              <strong> Reason for leaving? (optional) </strong>
                            </label>
                            <input
                              id={"reason_for_leaving"}
                              className="form-control form-control-15"
                              value={this.state.reason_for_leaving}
                              onBlur={this.onBlur}
                              onChange={this.handleChange}
                              onKeyDown={this.onKeyDown}
                              minLength={2}
                              maxLength={500}
                              autoComplete="new-address"
                              //onBlur={this.onBlurAddress2}
                            ></input>
                            <div
                              id={"reason_for_leaving_error"}
                              className="invalid-feedback"
                            >
                              Please add a valid reason for leaving
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6">
                          <label
                            htmlFor="employment_start_date"
                            id="emp_hist_employment_start_date_label"
                          >
                            <strong>Start Date*</strong>
                          </label>
                          <InputMask
                            id="employment_start_date"
                            mask="99/99/9999"
                            maskChar="_"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            autoComplete="new-password"
                            value={this.state.employment_start_date}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                          />
                          <div
                            id="start_date_error_test"
                            className="invalid-feedback"
                          >
                            Please enter a Start Date
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="employment_end_date"
                            id="emp_hist_employment_end_date_label"
                          >
                            <strong>End Date*</strong>
                          </label>
                          <InputMask
                            id="employment_end_date_display"
                            mask="99/99/9999"
                            maskChar="_"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            autoComplete="new-password"
                            value={this.state.employment_end_date_display}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                            disabled={this.state.end_date_disabled}
                          />
                          <div
                            id="end_date_error_test"
                            className="invalid-feedback"
                          >
                            Please enter an End Date
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-12">
                          <label
                            htmlFor="street_address"
                            id="emp_hist_street_address_label"
                          >
                            <strong>Address of Employer*</strong>
                          </label>
                          <Autocomplete
                            apiKey={window.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => {
                              this.handlePlaceSelect(place);
                            }}
                            options={{
                              types: ["geocode", "establishment"],
                              componentRestrictions: {
                                country: [
                                  "us" /*United States*/,
                                  //"as" /*American Samoa*/,
                                  //"fm" /*Federated States of Micronesia*/,
                                  //"gu" /*Guam*/,
                                  //"mh" /*Marshall Islands*/,
                                  //"mp" /*Northern Mariana Islands*/,
                                  "pr" /*Puerto Rico*/,
                                  //"pw" /*Palau*/,
                                  //"vi" /*U.S. Virgin Islands*/,
                                  //"um" /*U.S. Minor Outlying Islands*/,
                                ],
                              },
                            }}
                            id={"street_address"}
                            className="form-control form-control-15"
                            placeholder="Enter an Address"
                            value={this.state.street_address}
                            onChange={this.handleChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onKeyDown={this.onKeyDown}
                            maxLength={320}
                          />

                          <div
                            id="street_address_error"
                            className="invalid-feedback"
                          >
                            Please enter an Address
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col">
                          <label id="address_apartment_code">
                            <strong>
                              {" "}
                              Apartment, Suite, Building, Gate Code{" "}
                            </strong>
                          </label>
                          <input
                            id={"address2"}
                            className="form-control form-control-15"
                            value={this.state.address2}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                            maxLength={320}
                            autoComplete="new-address"
                            //onBlur={this.onBlurAddress2}
                          ></input>
                          <div
                            id={"address2_error"}
                            className="invalid-feedback"
                          >
                            Please enter an Apartment, Suite, Building, or Gate
                            Code.
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-md-12">
                          <label id="emp_hist_city_label">
                            <strong>City*</strong>
                          </label>
                          <input
                            id="city"
                            name="city"
                            className="form-control"
                            value={this.state.city}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                            autoComplete="new-address"
                            onBlur={this.onBlur}
                            maxLength={320}
                          ></input>
                          <div id="city_error" className="invalid-feedback">
                            Please enter a City
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "15px" }}>
                        <div className="col-md-12">
                          <label id="emp_hist_state_label">
                            <strong>
                              State / Province
                              {this.state.show_state_required === true && "*"}
                            </strong>
                          </label>
                          <DropdownButton
                            id="state"
                            type="default"
                            variant="secondary"
                            title={this.state.state}
                            selected={false}
                            autoComplete="new-address"
                            onSelect={(key, event) => {
                              event.target.id = "state";
                              event.target.value = key;
                              this.handleChange(event);
                              this.onKeyDown();
                            }}
                            onBlur={this.onBlur}
                            onClick={clickFunc}
                            onFocus={this.onFocusState}
                          >
                            <Dropdown.Item
                              eventKey={""}
                              id="state_option"
                            ></Dropdown.Item>
                            <Dropdown.Item eventKey={"PR"} id="state_option_PR">
                              PR - Puerto Rico
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"AL"} id="state_option_AL">
                              AL - Alabama
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"AK"} id="state_option_AK">
                              AK - Alaska
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"AZ"} id="state_option_AZ">
                              AZ - Arizona
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"AR"} id="state_option_AR">
                              AR - Arkansas
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"CA"} id="state_option_CA">
                              CA - California
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"CO"} id="state_option_CO">
                              CO - Colorodo
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"CT"} id="state_option_CT">
                              CT - Connecticut
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"DE"} id="state_option_DE">
                              DE - Delaware
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"DC"} id="state_option_DC">
                              DC - District of Columbia
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"FL"} id="state_option_FL">
                              FL - Florida
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"GA"} id="state_option_GA">
                              GA - Georgia
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"HI"} id="state_option_HI">
                              HI - Hawaii
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"ID"} id="state_option_ID">
                              ID - Idaho
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"IL"} id="state_option_IL">
                              IL - Illinois
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"IN"} id="state_option_IN">
                              IN - Indiana
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"IA"} id="state_option_IA">
                              IA - Iowa
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"KS"} id="state_option_KS">
                              KS - Kansas
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"KY"} id="state_option_KY">
                              KY - Kentucky
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"LA"} id="state_option_LA">
                              LA - Louisiana
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"ME"} id="state_option_ME">
                              ME - Maine
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MD"} id="state_option_MD">
                              MD - Maryland
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MA"} id="state_option_MA">
                              MA - Massachusetts
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MI"} id="state_option_MI">
                              MI - Michigan
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MN"} id="state_option_MN">
                              MN - Minnesota
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MS"} id="state_option_MS">
                              MS - Mississippi
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MO"} id="state_option_MO">
                              MO - Missouri
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"MT"} id="state_option_MT">
                              MT - Montana
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NE"} id="state_option_NE">
                              NE - Nebraska
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NV"} id="state_option_NV">
                              NV - Nevada
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NH"} id="state_option_NH">
                              NH - New Hampshire
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NJ"} id="state_option_NJ">
                              NJ - New Jersey
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NM"} id="state_option_NM">
                              NM - New Mexico
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NY"} id="state_option_NY">
                              NY - New York
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"NC"} id="state_option_NC">
                              NC - North Carolina
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"ND"} id="state_option_ND">
                              ND - North Dakota
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"OH"} id="state_option_OH">
                              OH - Ohio
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"OK"} id="state_option_OK">
                              OK - Oklahoma
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"OR"} id="state_option_OR">
                              OR - Oregon
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"PA"} id="state_option_PA">
                              PA - Pennsylvania
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"RI"} id="state_option_RI">
                              RI - Rhode Island
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"SC"} id="state_option_SC">
                              SC - South Carolina
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"SD"} id="state_option_SD">
                              SD - South Dakota
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"TN"} id="state_option_TN">
                              TN - Tennessee
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"TX"} id="state_option_TX">
                              TX - Texas
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"UT"} id="state_option_UT">
                              UT - Utah
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"VT"} id="state_option_VT">
                              VT - Vermont
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"VA"} id="state_option_VA">
                              VA - Virginia
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"WA"} id="state_option_WA">
                              WA - Washington
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"WV"} id="state_option_WV">
                              WV - West Virginia
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"WI"} id="state_option_WI">
                              WI - Wisconsin
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"WY"} id="state_option_WY">
                              WY - Wyoming
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                        <div
                          id="state_error"
                          className="invalid-feedback"
                          style={{ paddingLeft: "17px" }}
                        >
                          Please select a valid State/Province
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-md-12">
                          <label id="emp_hist_zip_code_label">
                            <strong> ZIP Code* </strong>
                          </label>
                          <input
                            id="zip_code"
                            className="form-control"
                            value={this.state.zip_code}
                            onChange={this.handleChange}
                            autoComplete="new-address"
                            onBlur={this.onBlur}
                            maxLength={10}
                            onKeyPress={onlyAlphaNumeric}
                            onPaste={pasteZipCode}
                          ></input>
                          <div id="zip_code_error" className="invalid-feedback">
                            Please enter a Zip Code
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "15px" }}>
                        <div className="col-md-12">
                          <label id="emp_hist_country_label">
                            <strong>Country*</strong>
                          </label>
                          <DropdownButton
                            id="country"
                            type="default"
                            variant="secondary"
                            title={this.state.country}
                            selected={false}
                            autoComplete="new-address"
                            onSelect={(key, event) => {
                              event.target.id = "country";
                              event.target.value = key;
                              this.handleChange(event);
                              this.onKeyDown();
                            }}
                            onBlur={this.onBlur}
                            onClick={clickFunc}
                            onFocus={this.onFocusState}
                          >
                            {COUNTRIES.map((option) => (
                              <Dropdown.Item
                                key={option.code}
                                eventKey={option.code}
                                id="country_option"
                              >
                                {option.code + " - " + option.name}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </div>
                        <div
                          id="country_error"
                          className="invalid-feedback"
                          style={{ paddingLeft: "17px" }}
                        >
                          Please choose a Country
                        </div>
                      </div>
                      {this.state.showUrbanization === true && (
                        <div className="row" style={{ paddingTop: "20px" }}>
                          <div className="col">
                            <label id="address_urbanization">
                              <strong> Urbanization </strong>
                            </label>
                            <input
                              id={"urbanization"}
                              type="text"
                              className="form-control form-control-15"
                              value={this.state.urbanization}
                              onChange={this.handleChange}
                              onKeyDown={this.onKeyDown}
                              onBlur={this.onBlur}
                              autoComplete="new-address"
                              placeholder="Puerto Rico Residents Only"
                              maxLength={100}
                            ></input>
                          </div>
                        </div>
                      )}
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-7">
                          <span id="emp_hist_may_we_contact_label">
                            May we contact this employer?
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-2 custom-control custom-radio">
                          <input
                            type="radio"
                            id="contact_employer_yes"
                            name="contact_employer"
                            className="custom-control-input"
                            checked={this.state.contact_employer === "Y"}
                            value="Y"
                            onChange={this.handleContactEmployerChange}
                          />
                          <label
                            htmlFor="contact_employer_yes"
                            className="custom-control-label"
                            id="label_contact_employer_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="col-md-3 custom-control custom-radio">
                          <input
                            type="radio"
                            id="contact_employer_no"
                            name="contact_employer"
                            className="custom-control-input"
                            checked={this.state.contact_employer === "N"}
                            value="N"
                            onChange={this.handleContactEmployerChange}
                          />
                          <label
                            htmlFor="contact_employer_no"
                            className="custom-control-label"
                            id="label_contact_employer_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      {this.state.may_we_contact_employer && (
                        <div className="row" style={{ marginTop: "10px" }}>
                          <div className="col-md-12">
                            <label
                              htmlFor="contact_phone"
                              id="emp_hist_contact_phone_label"
                            >
                              <strong>Phone number of Contact*</strong>
                            </label>
                            <PhoneInput
                              id="contact_phone"
                              defaultCountry="US"
                              international
                              countrySelectProps={{ unicodeFlags: true }}
                              autoComplete="new-password"
                              value={this.state.contact_phone}
                              onBlur={this.onBlur}
                              onChange={this.handlePhoneChange}
                              onKeyDown={this.onKeyDown}
                              maxLength={17}
                            />
                            <div
                              id="contact_phone_error"
                              className="invalid-feedback"
                            >
                              Please enter Phone number of Contact
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {this.state.step_number === 2 && (
                    <>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-12">
                          <span id="emp_hist_break_intro_text">
                            Did this position involve the direct solicitation of
                            and compensation for:
                          </span>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_ind_life_label">
                            Individual Life Insurance
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="individual_life_yes"
                            name="individual_life"
                            className="custom-control-input"
                            checked={this.state.individual_life === "Y"}
                            value="Y"
                            onChange={this.handleIndividualLifeChange}
                          />
                          <label
                            htmlFor="individual_life_yes"
                            className="custom-control-label"
                            id="label_individual_life_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="individual_life_no"
                            name="individual_life"
                            className="custom-control-input"
                            checked={this.state.individual_life === "N"}
                            value="N"
                            onChange={this.handleIndividualLifeChange}
                          />
                          <label
                            htmlFor="individual_life_no"
                            className="custom-control-label"
                            id="label_individual_life_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_ind_annuity_label">
                            Individual Annuity
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="individual_annuity_yes"
                            name="individual_annuity"
                            className="custom-control-input"
                            checked={this.state.individual_annuity === "Y"}
                            value="Y"
                            onChange={this.handleIndividualAnnuityChange}
                          />
                          <label
                            htmlFor="individual_annuity_yes"
                            className="custom-control-label"
                            id="label_individual_annuity_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="individual_annuity_no"
                            name="individual_annuity"
                            className="custom-control-input"
                            checked={this.state.individual_annuity === "N"}
                            value="N"
                            onChange={this.handleIndividualAnnuityChange}
                          />
                          <label
                            htmlFor="individual_annuity_no"
                            className="custom-control-label"
                            id="label_individual_annuity_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_ind_disability_label">
                            Individual Disability Income
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="individual_disability_yes"
                            name="individual_disability"
                            className="custom-control-input"
                            checked={this.state.individual_disability === "Y"}
                            value="Y"
                            onChange={this.handleIndividualDisabilityChange}
                          />
                          <label
                            htmlFor="individual_disability_yes"
                            className="custom-control-label"
                            id="label_individual_disability_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="individual_disability_no"
                            name="individual_disability"
                            className="custom-control-input"
                            checked={this.state.individual_disability === "N"}
                            value="N"
                            onChange={this.handleIndividualDisabilityChange}
                          />
                          <label
                            htmlFor="individual_disability_no"
                            className="custom-control-label"
                            id="label_individual_disability_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_ind_ltc_label">
                            Individual Long Term Care Insurance
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="individual_longterm_yes"
                            name="individual_longterm"
                            className="custom-control-input"
                            checked={this.state.individual_longterm === "Y"}
                            value="Y"
                            onChange={this.handleLongTermCareChange}
                          />
                          <label
                            htmlFor="individual_longterm_yes"
                            className="custom-control-label"
                            id="label_individual_longterm_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="individual_longterm_no"
                            name="individual_longterm"
                            className="custom-control-input"
                            checked={this.state.individual_longterm === "N"}
                            value="N"
                            onChange={this.handleLongTermCareChange}
                          />
                          <label
                            htmlFor="individual_longterm_no"
                            className="custom-control-label"
                            id="label_individual_longterm_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_full_time_role_label">
                            Was this a full time role?
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="full_time_role_yes"
                            name="full_time_role"
                            className="custom-control-input"
                            checked={this.state.full_time_role === "Y"}
                            value="Y"
                            onChange={this.handleFullTimeRoleChange}
                          />
                          <label
                            htmlFor="full_time_role_yes"
                            className="custom-control-label"
                            id="label_full_time_role_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="full_time_role_no"
                            name="full_time_role"
                            className="custom-control-input"
                            checked={this.state.full_time_role === "N"}
                            value="N"
                            onChange={this.handleFullTimeRoleChange}
                          />
                          <label
                            htmlFor="full_time_role_no"
                            className="custom-control-label"
                            id="label_full_time_role_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_training_or_subsidy_label">
                            Did you receive a training or financing subsidy
                            while employed by this company?
                            <strong>*</strong>
                          </span>
                        </div>
                        <div className="col-md-1 custom-control custom-radio">
                          <input
                            type="radio"
                            id="training_yes"
                            name="training"
                            className="custom-control-input"
                            checked={this.state.training === "Y"}
                            value="Y"
                            onChange={this.handleTrainingChange}
                          />
                          <label
                            htmlFor="training_yes"
                            className="custom-control-label"
                            id="label_training_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          className="col-md-1 custom-control custom-radio"
                          style={{ paddingLeft: "45px" }}
                        >
                          <input
                            type="radio"
                            id="training_no"
                            name="training"
                            className="custom-control-input"
                            checked={this.state.training === "N"}
                            value="N"
                            onChange={this.handleTrainingChange}
                          />
                          <label
                            htmlFor="training_no"
                            className="custom-control-label"
                            id="label_training_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      {this.state.show_training_info && (
                        <>
                          <div className="row" style={{ marginTop: "15px" }}>
                            <div className="col-md-12">
                              <span id="emp_hist_if_yes_training_label">
                                If yes, provide training period:
                                <strong>*</strong>
                              </span>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-6">
                              <label
                                htmlFor="training_start_date"
                                id="emp_hist_training_start_date_label"
                              >
                                <strong>Start Date</strong>
                              </label>
                              <br></br>
                              <InputMask
                                id="training_start_date"
                                mask="99/99/9999"
                                maskChar="_"
                                className="form-control"
                                placeholder="MM/DD/YYYY"
                                value={this.state.training_start_date}
                                onBlur={this.onBlur}
                                onChange={this.handleChange}
                                onKeyDown={this.onKeyDown}
                                autoComplete="off"
                              />
                              <div
                                id="training_start_date_error"
                                className="invalid-feedback"
                              >
                                Please enter a Start Date
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="training_end_date"
                                id="emp_hist_training_end_date_label"
                              >
                                <strong>End Date</strong>
                              </label>
                              <br></br>
                              <InputMask
                                id="training_end_date"
                                mask="99/99/9999"
                                maskChar="_"
                                className="form-control"
                                placeholder="MM/DD/YYYY"
                                value={this.state.training_end_date}
                                onBlur={this.onBlur}
                                onChange={this.handleChange}
                                onKeyDown={this.onKeyDown}
                                autoComplete="off"
                              />
                              <div
                                id="training_end_date_error"
                                className="invalid-feedback"
                              >
                                Please enter an End Date
                              </div>
                            </div>
                          </div>

                          {this.props.hasNYRegs === false && (
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-12">
                                <span id="emp_hist_subsidy_label">
                                  Total subsidy received from this employer:*
                                </span>
                                <div className="input-group" id="subsidy_input">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text input-group-text-pad-right"
                                      id="subsidy_input_$"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    id="subsidy"
                                    className="form-control input-pad-left"
                                    spellCheck="false"
                                    maxLength={11}
                                    value={this.state.subsidy}
                                    onBlur={this.onBlur}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onKeyDown}
                                    onKeyPress={onlyNumeric}
                                    onPaste={pasteCommaDelimited}
                                    autoComplete="off"
                                  />
                                </div>
                                <div
                                  id="subsidy_error"
                                  className="invalid-feedback"
                                >
                                  Please enter Total subsidy received from this
                                  employer
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {/* ADD BUTTON PANEL START */}
                      {this.state.show_add_button && (
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              display: "inline",
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            <button
                              id="emp_hist_modal_back_button"
                              type="button"
                              className="btn btn-link"
                              onClick={this.backClick}
                              //onclick={this.handleCloseModal}
                              style={{
                                textAlign: "left",
                                fontSize: "1.4rem",
                                padding: "4px 0px",
                              }}
                            >
                              Back
                            </button>
                            {this.state.review_edit === false && (
                              <Button
                                label="ADD"
                                id="add_button"
                                onClick={this.addClick}
                                //onClick={this.handleNext}
                                style={{
                                  padding: "5px",
                                  float: "none",
                                  width: "150px",
                                  fontSize: "1.3rem",
                                }}
                                disabled={this.state.is_add_disabled}
                              ></Button>
                            )}
                            {this.state.review_edit === true && (
                              <Button
                                label="SAVE"
                                id="add_button"
                                //onClick={this.addClick}
                                onClick={this.handleNext}
                                style={{
                                  padding: "5px",
                                  float: "none",
                                  width: "150px",
                                  fontSize: "1.3rem",
                                }}
                                disabled={this.state.is_add_disabled}
                              ></Button>
                            )}
                            {this.state.showSaveLoader && (
                              <div
                                className="mm-dots-loading"
                                style={{ float: "right", marginTop: "3px" }}
                              ></div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* ADD BUTTON PANEL END */}
                    </>
                  )}
                  {this.state.step_number === 3 && (
                    <>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-9">
                          <span id="emp_hist_break_text">
                            Please select from the following to explain
                            <strong>*</strong>
                          </span>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="fulltime_student"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.fulltime_student === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_fulltime_student_label"
                            htmlFor="fulltime_student"
                            className="custom-control-label"
                          >
                            <strong>Full Time Student</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="homemaker"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.homemaker === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_homemaker_label"
                            htmlFor="homemaker"
                            className="custom-control-label"
                          >
                            <strong>Homemaker</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="unemployment"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.unemployment === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_unemployment_label"
                            htmlFor="unemployment"
                            className="custom-control-label"
                          >
                            <strong>Unemployment</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="military"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.military === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_military_label"
                            htmlFor="military"
                            className="custom-control-label"
                          >
                            <strong>Military Service</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="travel"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.travel === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_travel_label"
                            htmlFor="travel"
                            className="custom-control-label"
                          >
                            <strong>Extended Travel</strong>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 custom-control custom-radio"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            type="radio"
                            id="other"
                            name="employmentbreak"
                            className="custom-control-input"
                            checked={this.state.other === "Y"}
                            value="Y"
                            onChange={this.handleChange}
                          />
                          <label
                            id="emp_hist_other_label"
                            htmlFor="other"
                            className="custom-control-label"
                          >
                            <strong>Other</strong>
                          </label>
                        </div>
                      </div>
                      {this.state.show_other_panel && (
                        <>
                          <label
                            htmlFor="other_text"
                            id="emp_hist_other_text_label"
                          >
                            <strong>Please provide an explanation*</strong>
                          </label>
                          <div>
                            {" "}
                            <textarea
                              rows="3"
                              id="other_text"
                              className="form-control"
                              maxLength={100}
                              autoComplete="new-password"
                              value={this.state.other_text}
                              onBlur={this.onBlur}
                              onChange={this.handleChange}
                              onKeyDown={this.onKeyDown}
                            ></textarea>
                          </div>
                          <div
                            id="other_text_error"
                            className="invalid-feedback"
                          >
                            Please enter a valid explanation
                          </div>
                        </>
                      )}
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6">
                          <label
                            htmlFor="break_start_date"
                            id="emp_hist_break_start_date_label"
                          >
                            <strong>Start Date*</strong>
                          </label>
                          <InputMask
                            id="break_start_date"
                            mask="99/99/9999"
                            maskChar="_"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            autoComplete="off"
                            value={this.state.break_start_date}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                          />
                          <div
                            id="break_start_date_error"
                            className="invalid-feedback"
                          >
                            Please enter a Start Date
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="break_end_date"
                            id="emp_hist_break_end_date_label"
                          >
                            <strong>End Date*</strong>
                          </label>
                          <InputMask
                            id="break_end_date"
                            mask="99/99/9999"
                            maskChar="_"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            autoComplete="off"
                            value={this.state.break_end_date}
                            onBlur={this.onBlur}
                            onChange={this.handleChange}
                            onKeyDown={this.onKeyDown}
                          />
                          <div
                            id="break_end_date_error"
                            className="invalid-feedback"
                          >
                            Please enter an End Date
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* ADD BUTTON PANEL START */}
                {this.state.show_add_button &&
                  this.state.step_number !== 2 &&
                  this.state.step_number > 0 && (
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          display: "inline",
                          float: "right",
                          textAlign: "right",
                        }}
                      >
                        <button
                          id="emp_hist_cancel_button"
                          type="button"
                          className="btn btn-link"
                          //onClick={this.cancelClick}
                          onClick={this.handleCloseModal}
                          style={{
                            textAlign: "left",
                            fontSize: "1.4rem",
                            padding: "4px 0px",
                          }}
                        >
                          Cancel
                        </button>
                        {this.state.review_edit === false && (
                          <Button
                            label="ADD"
                            id="add_button"
                            //onClick={this.addClick}
                            onClick={this.handleNext}
                            style={{
                              padding: "5px",
                              float: "none",
                              width: "150px",
                              fontSize: "1.3rem",
                            }}
                            disabled={
                              this.state.is_add_disabled ||
                              this.state.showSaveLoader
                            }
                          ></Button>
                        )}
                        {this.state.review_edit === true && (
                          <Button
                            label="SAVE"
                            id="add_button"
                            //onClick={this.addClick}
                            onClick={this.handleNext}
                            style={{
                              padding: "5px",
                              float: "none",
                              width: "150px",
                              fontSize: "1.3rem",
                            }}
                            disabled={
                              this.state.is_add_disabled ||
                              this.state.showSaveLoader
                            }
                          ></Button>
                        )}
                        {this.state.showSaveLoader && (
                          <div
                            className="mm-dots-loading"
                            style={{ float: "right", marginTop: "3px" }}
                          ></div>
                        )}
                      </div>
                    </div>
                  )}
                {/* ADD BUTTON PANEL END */}
                {/* NEXT BUTTON PANEL START */}
                {this.state.show_next_button && this.state.step_number > 0 && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        display: "inline",
                        float: "right",
                        textAlign: "right",
                      }}
                    >
                      <button
                        id="emp_hist_cancel_button"
                        type="button"
                        className="btn btn-link"
                        //onClick={this.cancelClick}
                        onClick={this.handleCloseModal}
                        style={{
                          textAlign: "left",
                          fontSize: "1.4rem",
                          padding: "4px 0px",
                        }}
                      >
                        Cancel
                      </button>
                      <Button
                        label="NEXT"
                        //onClick={this.nextClick}
                        onClick={this.handleNext}
                        id="next_button"
                        style={{
                          padding: "5px",
                          float: "none",
                          width: "150px",
                          fontSize: "1.3rem",
                        }}
                        disabled={
                          this.state.is_next_disabled ||
                          this.state.showSaveLoader
                        }
                      ></Button>
                      {this.state.showSaveLoader && (
                        <div
                          className="mm-dots-loading"
                          style={{ float: "right", marginTop: "3px" }}
                        ></div>
                      )}
                    </div>
                  </div>
                )}
                {/* NEXT BUTTON PANEL END */}
              </div>
            </Modal>
          </div>
        </div>

        <USPSValidationModal
          isOpen={this.state.showModal}
          closeModal={this.handleCloseModal}
          useSuggestedAddress={this.handleUseSuggestedAddress}
          useEnteredAddress={this.handleUseEnteredAddress}
          street_address={this.state.street_address}
          address2={this.state.address2}
          city={this.state.city}
          state={this.state.state}
          zip_code={this.state.zip_code}
          country={this.state.country}
          usps_address={this.state.usps_address}
          usps_address2={this.state.usps_address2}
          usps_city={this.state.usps_city}
          usps_state={this.state.usps_state}
          usps_zip_code={this.state.usps_zip_code}
          usps_country={this.state.usps_country}
          urbanization={this.state.urbanization}
          addressNotFound={this.state.addressNotFound}
          contracttypedesc={this.props.contracttypedesc}
          src_id={this.state.form_id}
          formHasChanged={this.state.form_has_changed}
          exitModal={this.handleBackClick}
        />
        <BackButtonModal
          isOpen={this.state.showBackModal && !this.state.showModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default EmploymentHistoryModal;

import React, { Component, Fragment } from "react";
import EmploymentHistoryDeleteModal from "./EmploymentHistoryDeleteModal";
import EmploymentHistoryDeleteWarnModal from "./EmploymentHistoryDeleteWarnModal";
import Moment from "moment";
import "../../../assets/stylesheets/StartApplication/CAS/EmploymentHistoryTable.scss";
class EmploymentHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobHistory: "",
      show_modal: false,
      show_modal_warn: false,
      delete_index: -1,
      isThereCurrentJob: false,
      show_spinner: false,
      hasPSR: [],
      warn_message: "",
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteHistoryRow = this.deleteHistoryRow.bind(this);
    this.handleReviewEditClick = this.handleReviewEditClick.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.loadData();
    }
  }

  async loadData() {
    let isThereCurrent = false;
    let doesRangeExistAlready = false;
    let overlappingRanges = [];
    let hasPSR = [];

    for (let i = 0; i < this.props.data.length; i++) {
      if (this.props.data[i].enddate === null) {
        isThereCurrent = true;
      }
      if (overlappingRanges.length === 0) {
        if (this.props.data[i].overlappingRange !== null) {
          overlappingRanges.push({
            startdate: this.props.data[i].overlappingRange.startdate,
            enddate: this.props.data[i].overlappingRange.enddate,
            id: this.props.data[i].id,
          });
        }
      } else {
        for (let o = 0; o < overlappingRanges.length; o++) {
          if (this.props.data[i].overlappingRange !== null) {
            if (
              overlappingRanges[o].startdate ===
                this.props.data[i].overlappingRange.startdate &&
              overlappingRanges[o].enddate ===
                this.props.data[i].overlappingRange.enddate
            ) {
              doesRangeExistAlready = true;
            }
          }
        }
        if (
          this.props.data[i].overlappingRange !== null &&
          doesRangeExistAlready === false
        ) {
          overlappingRanges.push({
            startdate: this.props.data[i].overlappingRange.startdate,
            enddate: this.props.data[i].overlappingRange.enddate,
            id: this.props.data[i].id,
          });
        }
      }
    }
    for (let i = 0; i < this.props.psrData.length; i++) {
      for (let j = 0; j < this.props.data.length; j++) {
        if (
          this.props.psrData[i].employmenthistoryid === this.props.data[j].id
        ) {
          if (this.props.psrData[i].psr_id > 0)
            hasPSR.push({ psrID: this.props.psrData[i].employmenthistoryid });
        }
      }
    }
    await this.setState({
      jobHistory: this.props.data,
      isThereCurrentJob: isThereCurrent,
      overlappingRanges: overlappingRanges,
      hasPSR: hasPSR,
    });
  }
  showMenu(element) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
    element.target.parentNode.children[1].className = "dropdown-content show";
  }

  hideMenu(element) {
    element.target.parentNode.children[1].className = "dropdown-content";
  }

  async handleDeleteClick(item, hasPSR, hasNYRegs) {
    await this.setState({ delete_index: item });
    if (hasNYRegs) {
      this.setState({
        warn_message:
          " Deleting this employer will also delete the associated Personal Production Record data, are you sure you want to proceed? ",
      });
    } else {
      this.setState({
        warn_message:
          " Deleting this employer will also delete the associated Personal Sales Record data, are you sure you want to proceed? ",
      });
    }
    if (hasPSR) {
      await this.setState({ show_modal_warn: true });
    } else {
      await this.setState({ show_modal: true });
    }
    //hide popup menu
    let myRef = document.getElementById("top_delete_element");
    myRef.click();
  }
  async closeDeleteModal() {
    await this.setState({ show_modal: false, show_modal_warn: false });
  }
  async deleteHistoryRow() {
    this.showSpinner(true);
    await this.props.deleteData(this.state.delete_index);
    this.showSpinner(false);
    await this.setState({ show_modal: false, show_modal_warn: false });
  }
  async handleReviewEditClick(job) {
    this.props.reviewEditClick(job);
  }
  async showSpinner(value) {
    this.setState({ show_spinner: value });
  }
  render() {
    return (
      <div id="empHistoryTable">
        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col-md-12">
            {this.state.jobHistory.length > 0 && (
              <>
                <ul className="mm-vertical-timeline">
                  {Array.from(this.state.jobHistory).map((job, key) => {
                    let hasPSR = false;
                    for (let i = 0; i < this.state.hasPSR.length; i++) {
                      if (this.state.hasPSR[i].psrID === job.id) {
                        hasPSR = true;
                      }
                    }
                    let hasNYRegs = this.props.hasNYRegs;
                    let todaysDate = Moment();
                    let tenYearsAgo = Moment.utc(todaysDate)
                      .subtract(10, "years")
                      .format("MM/DD/YYYY");
                    let startDate = Moment.utc(job.startdate).add(1, "days");
                    let endDate =
                      job.enddate === null ? Moment() : Moment.utc(job.enddate);

                    let highDate = Moment.utc(todaysDate).subtract(10, "years");
                    for (let j = 0; j < this.state.jobHistory.length; j++) {
                      let currentDate = Moment.utc(
                        this.state.jobHistory[j].enddate
                      );
                      if (currentDate > highDate) {
                        highDate = currentDate;
                      }
                    }

                    let gapTopStartDate = Moment.utc(highDate)
                      .add(1, "days")
                      .format("MM/DD/YYYY");
                    let gapBottomStartDate = Moment.utc(job.startdate)
                      .subtract(1, "days")
                      .format("MM/DD/YYYY");

                    let gapBottomEndDate = Moment.utc(tenYearsAgo)
                      .subtract(1, "days")
                      .format("MM/DD/YYYY");
                    let gapStartDate = "";
                    let gapEndDate = "";
                    if (key > 0) {
                      gapStartDate = Moment.utc(job.enddate)
                        .add(1, "days")
                        .format("MM/DD/YYYY");
                      gapEndDate = Moment.utc(
                        this.state.jobHistory[key - 1].startdate
                      )
                        .subtract(1, "days")
                        .format("MM/DD/YYYY");
                    }
                    let showOverLappingDateAdd = false;

                    if (job.overlappingRange !== null) {
                      gapStartDate = Moment.utc(job.overlappingRange.enddate)
                        .add(1, "days")
                        .format("MM/DD/YYYY");
                      for (
                        let o = 0;
                        o < this.state.overlappingRanges.length;
                        o++
                      ) {
                        showOverLappingDateAdd =
                          this.state.overlappingRanges[o].id === job.id &&
                          Moment.utc(gapEndDate).diff(
                            Moment.utc(gapStartDate),
                            "days"
                          ) > 0;
                      }
                    }

                    let showAddJobRowTop =
                      (key === 0 &&
                        endDate.format("MM/DD/YYYY") !==
                          todaysDate.format("MM/DD/YYYY") &&
                        todaysDate.diff(endDate, "days") > -1 &&
                        todaysDate > endDate &&
                        todaysDate.diff(gapTopStartDate, "days") > -1 &&
                        this.state.isThereCurrentJob === false) ||
                      (job.istheregap && job.overlappingRange === null) ||
                      (job.istheregap &&
                        job.overlappingRange !== null &&
                        showOverLappingDateAdd);

                    let showAddJobRowBottom =
                      key === this.state.jobHistory.length - 1 &&
                      startDate.diff(tenYearsAgo, "days") > 0;
                    //startDate > tenYearsAgo;

                    return (
                      <Fragment key={key}>
                        {/* Render "Add Job" Row on Top*/}
                        {showAddJobRowTop && (
                          <li
                            id={`preAddJob${key}`}
                            key={`preAddJob${key}`}
                            className="complete timeline-li"
                          >
                            <div className="column-border-top-wrapper">
                              <div className="column-border-top-pad"></div>
                              <div className="column-border-top"></div>
                            </div>
                            <div className="custom-table-row">
                              <div className="custom-table-timeline-dot"></div>
                              <div className="custom-table-date-column">
                                {job.istheregap && key !== 0 ? (
                                  <>
                                    {Moment.utc(gapEndDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                    <br></br>-<br></br>
                                    {Moment.utc(gapStartDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    Present
                                    <br></br>-<br></br>
                                    {Moment.utc(gapTopStartDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="custom-table-job-column">
                                <span
                                  id="add_emp_history"
                                  role="presentation"
                                  className="add-experience-btn"
                                  onClick={() => this.props.showAddModal()}
                                >
                                  <span className="icon-add"></span>
                                  <strong>Add Experience</strong>
                                </span>
                              </div>
                            </div>
                          </li>
                        )}
                        {/* Render Job */}
                        <li
                          id={`job${key}`}
                          key={`job${key}`}
                          className="active timeline-li"
                        >
                          <div className="column-border-top-wrapper">
                            <div className="column-border-top-pad"></div>
                            <div className="column-border-top"></div>
                          </div>
                          <div className="custom-table-row">
                            <div className="custom-table-timeline-dot"></div>
                            <div className="custom-table-date-column">
                              <span className="timeline-date-text">
                                {job.enddate === null
                                  ? `Present`
                                  : Moment.utc(job.enddate).format(
                                      "MM/DD/YYYY"
                                    )}
                                <br></br>-<br></br>
                                {Moment.utc(job.startdate).format("MM/DD/YYYY")}
                              </span>
                            </div>
                            <div className="custom-table-job-column">
                              <div className="job-history-title">
                                {job.jobtitle}
                              </div>
                              <div className="job-history-companyname">
                                {job.jobtitle === "Other"
                                  ? job.description
                                  : job.companyname}
                              </div>

                              <div className="job-quick-action-wrapper">
                                <button
                                  id="dropbtn"
                                  className="icon-md icon-quick-actions"
                                  aria-label="Review/Edit Show Menu"
                                  onClick={this.showMenu}
                                />
                                <div
                                  id={"mnuDropDown" + key}
                                  className="dropdown-content"
                                >
                                  <span
                                    id="mnuLinkReview"
                                    onClick={() =>
                                      this.handleReviewEditClick(job)
                                    }
                                    className="menu-item-hover"
                                    role="presentation"
                                  >
                                    Review/Edit
                                  </span>
                                  <span
                                    id="mnuLinkDelete"
                                    onClick={() =>
                                      this.handleDeleteClick(
                                        job,
                                        hasPSR,
                                        hasNYRegs
                                      )
                                    }
                                    style={{ textAlign: "left" }}
                                    className="menu-item-hover"
                                    role="presentation"
                                  >
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        {/* Render "Add Job" Row at end of iteration */}
                        {showAddJobRowBottom && (
                          <li
                            id={`postAddJob${key}`}
                            key={`postAddJob${key}`}
                            className="complete timeline-li"
                          >
                            <div className="column-border-top-wrapper">
                              <div className="column-border-top-pad"></div>
                              <div className="column-border-top"></div>
                            </div>
                            <div className="custom-table-row">
                              <div className="custom-table-timeline-dot"></div>
                              <div className="custom-table-date-column">
                                {Moment.utc(gapBottomStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                <br></br>-<br></br>
                                {Moment.utc(gapBottomEndDate)
                                  .add(1, "days")
                                  .format("MM/DD/YYYY")}
                              </div>
                              <div className="custom-table-job-column">
                                <span
                                  id="add_emp_history"
                                  role="presentation"
                                  className="add-experience-btn"
                                  onClick={() => this.props.showAddModal()}
                                >
                                  <span className="icon-add"></span>
                                  <strong>Add Experience</strong>
                                </span>
                              </div>
                            </div>
                          </li>
                        )}
                      </Fragment>
                    );
                  })}
                </ul>

                <EmploymentHistoryDeleteModal
                  message={" Are you sure you'd like to delete this history?"}
                  isOpen={this.state.show_modal}
                  closeClick={this.closeDeleteModal}
                  deleteClick={this.deleteHistoryRow}
                  show_spinner={this.state.show_spinner}
                  showSpinner={this.showSpinner}
                />
                <EmploymentHistoryDeleteWarnModal
                  message={this.state.warn_message}
                  isOpen={this.state.show_modal_warn}
                  closeClick={this.closeDeleteModal}
                  deleteClick={this.deleteHistoryRow}
                  show_spinner={this.state.show_spinner}
                  showSpinner={this.showSpinner}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EmploymentHistoryTable;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import CheckboxList from "../../../common/CheckboxList";
import {
  DRPProdCheckList,
  DRPReliefSoughtCheckList,
  dateInputMask,
  eventStatusDateType,
} from "../../../constants";
import "./CivJudDetails.scss";

const specialRegActionInitiated = {
  SEC: "SEC",
  OTHER: "Other",
  DEFAULT_VALUE: "Select one",
};

const CivJudDetails = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();

  const [filingDate, setFilingDate] = useState(data?.filingDate || "");
  const [filingDateError, setFilingDateError] = useState(false);
  const [filingDateErrorMsg, setFilingDateErrorMsg] = useState("");
  const [filingDateRadio, setFilingDateRadio] = useState(
    data?.filingDateRadio || ""
  );
  const [filingDateExp, setFilingDateExp] = useState(data?.filingDateExp || "");
  const [filingDateExpError, setFilingDateExpError] = useState(false);

  const [noticeDateErrorMsg, setNoticeErrorMsg] = useState("");
  const [noticeDate, setNotice] = useState(data?.noticeDate || "");
  const [noticeDateError, setNoticeError] = useState(false);
  const [noticeDateRadio, setNoticeRadio] = useState(
    data?.noticeDateRadio || ""
  );
  const [noticeDateExp, setNoticeExp] = useState(data?.noticeDateExp || "");
  const [noticeDateExpError, setNoticeExpError] = useState(false);

  const [civilActionDes, setCivilActionDes] = useState(
    data?.civilActionDes || ""
  );
  const [civilActionDesError, setCivilActionDesError] = useState(false);

  const [emplCivJudAction, setEmplCivJudAction] = useState(
    data?.emplCivJudAction || ""
  );
  const [emplCivJudActionError, setEmplCivJudActionError] = useState(false);

  const [otherReliefSoughtText, setOtherReliefSoughtText] = useState(
    data?.otherReliefSoughtText || ""
  );
  const [selectedReliefSoughtValues, setSelectedReliefSoughtValues] = useState(
    data?.selectedReliefSoughtValues || []
  );
  const [otherReliefSoughtTextError, setOtherReliefSoughtTextError] =
    useState(false);

  const [otherProdText, setOtherProdText] = useState(data?.otherProdText || "");
  const [selectedProdValues, setSelectedProdValues] = useState(
    data?.selectedProdValues || []
  );
  const [otherProdTextError, setOtherProdTextError] = useState(false);

  const [showBackModal, setShowBackModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [isFilDateNextDisabled, setIsFilDateNextDisabled] = useState(false);
  const [isNotDateNextDisabled, setIsNotDateNextDisabled] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isError =
    emplCivJudActionError ||
    civilActionDesError ||
    otherProdTextError ||
    otherReliefSoughtTextError ||
    filingDateError ||
    filingDateExpError ||
    noticeDateError ||
    noticeDateError ||
    noticeDateExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isDateFirstChargedDisabled =
    !filingDateRadio?.length ||
    !filingDate.length ||
    filingDate.includes("_") ||
    filingDate === dateInputMask.EMPTY ||
    (!filingDate.length && !filingDateRadio.length) ||
    !filingDate.length ||
    (filingDate.length &&
      filingDateRadio.length &&
      filingDateRadio === eventStatusDateType.EXPLANATION &&
      !filingDateExp?.length &&
      filingDateExp?.length < 2);

  const isDateNoticeDisabled =
    !noticeDateRadio?.length ||
    !noticeDate?.length ||
    noticeDate.includes("_") ||
    noticeDate === dateInputMask.EMPTY ||
    (!noticeDate?.length && !noticeDateRadio?.length) ||
    !noticeDateRadio?.length ||
    (noticeDate?.length &&
      noticeDateRadio?.length &&
      noticeDateRadio === eventStatusDateType.EXPLANATION &&
      !noticeDateExp?.length) ||
    (noticeDateExp?.length && noticeDateExp?.length < 2);

  const isNextBtnDisabled =
    !!(
      isNotDateNextDisabled ||
      isFilDateNextDisabled ||
      isDateNoticeDisabled ||
      isDateFirstChargedDisabled ||
      selectedProdValues?.filter((x) => typeof x === "string")?.length === 0 ||
      (selectedProdValues?.includes(specialRegActionInitiated.OTHER) &&
        !otherProdText?.length) ||
      (selectedProdValues?.includes(specialRegActionInitiated.OTHER) &&
        otherProdText?.length &&
        otherProdText?.length < 2) ||
      selectedReliefSoughtValues?.filter((x) => typeof x === "string")
        ?.length === 0 ||
      (selectedReliefSoughtValues?.includes(specialRegActionInitiated.OTHER) &&
        !otherReliefSoughtText?.length) ||
      (selectedReliefSoughtValues.includes(specialRegActionInitiated.OTHER) &&
        otherReliefSoughtText?.length &&
        otherReliefSoughtText?.length < 2) ||
      !civilActionDes.length ||
      (civilActionDes?.length && civilActionDes?.length < 2) ||
      !emplCivJudAction?.length ||
      (emplCivJudAction?.length && emplCivJudAction?.length < 2)
    ) ||
    isError ||
    isFocusedState;

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleChangeDateFirstChargedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    if (
      filingDateExp.length &&
      filingDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setFilingDateExp("");
    }

    setFilingDateExpError(false);
    setFilingDateRadio(newValue);
  };

  const filingDateErrorHandler = (value) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsFilDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setFilingDateError(true);
      } else {
        setFilingDateError(false);
      }
    } else {
      setFilingDateError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setFilingDateErrorMsg("Date cannot be a future date");
        setFilingDateError(true);
      } else if (date < past) {
        setFilingDateErrorMsg("Date cannot be more than 100 years ago");
        setFilingDateError(true);
      } else {
        setFilingDateError(false);
      }
    } else {
      setFilingDateErrorMsg("Please enter Filing date of court action");
      setFilingDateError(true);
    }
  };
  const onDateFirstChargedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFilingDate(value);

    if (value?.length === 10 && !value.includes("_") && !filingDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsFilDateNextDisabled(true);
        } else {
          setIsFilDateNextDisabled(false);
        }
      } else {
        setIsFilDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsFilDateNextDisabled(true);
        } else {
          setIsFilDateNextDisabled(false);
        }
      } else {
        setIsFilDateNextDisabled(true);
      }
    }
  };

  const onDateFirstChargedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFilingDateExp(value);
  };

  const onBlurDateFirstChargedExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setFilingDateExpError(true)
      : setFilingDateExpError(false);
  };

  const onDateFirstChargedBlurHandler = ({ target: { value } }) => {
    setFormHasChanged(true);

    filingDateErrorHandler(value);
    setFocusedState(false);
  };

  const handleDateNoticeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setNoticeExpError(false);
    if (
      noticeDateExp.length &&
      noticeDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setNoticeExp("");
    }

    setNoticeRadio(newValue);
  };

  const noticeDateErrorHandler = (value) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsNotDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setNoticeErrorMsg("Date cannot be a future date");
        setNoticeError(true);
      } else if (date < past) {
        setNoticeErrorMsg("Date cannot be more than 100 years ago");
        setNoticeError(true);
      } else {
        setNoticeError(false);
      }
    } else {
      setNoticeErrorMsg("Please enter Date Notice/Process was Served");
      setNoticeError(true);
    }
  };

  const onDateNoticeOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNotice(value);

    if (value?.length === 10 && !value.includes("_") && !noticeDateError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(true);
      }
    }
  };

  const onDateNoticeExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setNoticeExp(value);
  };

  const onDateNoticeBlurHandler = ({ target: { value } }) => {
    setFocusedState(false);
    noticeDateErrorHandler(value);
  };

  const onBlurDateNoticeExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setNoticeExpError(true)
      : setNoticeExpError(false);
  };

  const onCivilActionDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setCivilActionDes(value);
  };
  const onBlurCivilActionDesHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setCivilActionDesError(true)
      : setCivilActionDesError(false);
  };

  const onBlurEmplRegActioHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setEmplCivJudActionError(true)
      : setEmplCivJudActionError(false);
  };

  const onChangeEmplCivJudActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEmplCivJudAction(value);
  };

  const onChangeCheckedProd = ({ target: { value, checked } }) => {
    setFormHasChanged(true);

    let updatedSelectedProdValues;
    if (checked) {
      updatedSelectedProdValues = [...selectedProdValues, value];
    } else {
      updatedSelectedProdValues = selectedProdValues.filter(
        (item) => item !== value
      );
    }

    // Maintain the order based on the original list
    const orderedSelectedProdValues = DRPProdCheckList.filter((item) =>
      updatedSelectedProdValues.includes(item.value)
    ).map((item) => item.value);

    setSelectedProdValues(orderedSelectedProdValues);
  };

  const onChangeCheckedReliefSought = ({ target: { value, checked } }) => {
    setFormHasChanged(true);

    let updatedSelectedReliefSoughtValues;
    if (checked) {
      updatedSelectedReliefSoughtValues = [
        ...selectedReliefSoughtValues,
        value,
      ];
    } else {
      updatedSelectedReliefSoughtValues = selectedReliefSoughtValues.filter(
        (item) => item !== value
      );
    }

    // Maintain the order based on the original list
    const orderedSelectedReliefSoughtValues = DRPReliefSoughtCheckList.filter(
      (item) => updatedSelectedReliefSoughtValues.includes(item.value)
    ).map((item) => item.value);

    setSelectedReliefSoughtValues(orderedSelectedReliefSoughtValues);
  };

  const onOtherProdChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherProdText(value);
  };

  const onBlurProdOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherProdTextError(true)
      : setOtherProdTextError(false);
  };

  const onOtherReliefSoughtChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherReliefSoughtText(value);
  };

  const onBlurReliefSoughtOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherReliefSoughtTextError(true)
      : setOtherReliefSoughtTextError(false);
  };

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      filingDate,
      filingDateRadio,
      filingDateExp,
      selectedProdValues,
      selectedReliefSoughtValues,
      noticeDate,
      noticeDateRadio,
      noticeDateExp,
      emplCivJudAction,
      civilActionDes,
      otherProdText,
      otherReliefSoughtText,
    };

    setFormHasChanged(false);
    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    filingDate,
    filingDateRadio,
    filingDateExp,
    emplCivJudAction,
    selectedProdValues,
    selectedReliefSoughtValues,
    headerText,
    noticeDate,
    civilActionDes,
    otherProdText,
    otherReliefSoughtText,
    noticeDateRadio,
    noticeDateExp,
    groupKey,
  ]);

  return (
    <div className="civ-jud-det-form-wrapper">
      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label id="dateFirstChargedId" htmlFor="dateFirstChargedId">
            <strong id="regFilingDate">Filing date of court action: *</strong>
          </label>
          <InputMask
            id="dateFirstChargedId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              filingDateError ? "form-control is-invalid" : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={filingDate}
            onChange={onDateFirstChargedChangeHandler}
            onBlur={onDateFirstChargedBlurHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="filingDateError" className="invalid-feedback">
            {filingDateErrorMsg?.length
              ? filingDateErrorMsg
              : "Please enter an Explanation"}
          </div>
        </div>
        <div className="row event-status-date-radio-group">
          <div className="custom-control">
            <div className="col-12 copy-md-8 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-dateFirstChargedExactId"
                  type="radio"
                  id="dateFirstChargedExactId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXACT}
                  checked={filingDateRadio === eventStatusDateType.EXACT}
                  onChange={handleChangeDateFirstChargedChecked}
                />
                <label
                  htmlFor="dateFirstChargedExactId"
                  className="custom-control-label"
                  id="fillingExactDate"
                >
                  {eventStatusDateType.EXACT}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-dateFirstChargedExplanationId"
                  type="radio"
                  id="dateFirstChargedExplanationId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXPLANATION}
                  checked={filingDateRadio === eventStatusDateType.EXPLANATION}
                  onChange={handleChangeDateFirstChargedChecked}
                />
                <label
                  htmlFor="dateFirstChargedExplanationId"
                  className="custom-control-label"
                  id="fillingExplanation"
                >
                  {eventStatusDateType.EXPLANATION}
                </label>
              </div>
              {filingDateRadio === eventStatusDateType.EXPLANATION ? (
                <div className="event-status-date-explanation-container">
                  <label
                    htmlFor="dateFirstChargedExplanation"
                    className="event-status-date-exp"
                    id="fillingExplanationLabel"
                  >
                    If not exact, provide explanation *
                  </label>
                  <textarea
                    id="dateFirstChargedExplanation"
                    onChange={onDateFirstChargedExplanationChange}
                    className={
                      filingDateExpError
                        ? "form-control form-control-15 is-invalid"
                        : "form-control form-control-15"
                    }
                    maxLength={500}
                    minLength={2}
                    onBlur={onBlurDateFirstChargedExpHandler}
                    onFocus={() => setFocusedState(true)}
                    rows="3"
                    cols={500}
                    value={filingDateExp}
                  />
                  <div id="filingDateExpError" className="invalid-feedback">
                    Please enter an Explanation
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label id="noticeDateLabelPaiId" htmlFor="noticeDateId">
            <strong id="noticeDateLabelId">
              Date Notice/Process was Served: *
            </strong>
          </label>
          <InputMask
            id="noticeDateId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              noticeDateError ? "form-control is-invalid" : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={noticeDate}
            onChange={onDateNoticeOnChangeHandler}
            onBlur={onDateNoticeBlurHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="noticeDateError" className="invalid-feedback">
            {noticeDateErrorMsg?.length
              ? noticeDateErrorMsg
              : "Please enter a valid Notice Date"}
          </div>
        </div>

        <div className="row event-status-date-radio-group">
          <div className="custom-control">
            <div className="col-12 copy-md-8 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-noticeDateExactId"
                  type="radio"
                  id="noticeDateExactId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXACT}
                  checked={noticeDateRadio === eventStatusDateType.EXACT}
                  onChange={handleDateNoticeChecked}
                />
                <label
                  htmlFor="noticeDateExactId"
                  className="custom-control-label"
                  id="servedExactDate"
                >
                  {eventStatusDateType.EXACT}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-noticeDateExplanationId"
                  type="radio"
                  id="noticeDateExplanationId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXPLANATION}
                  checked={noticeDateRadio === eventStatusDateType.EXPLANATION}
                  onChange={handleDateNoticeChecked}
                />
                <label
                  htmlFor="noticeDateExplanationId"
                  className="custom-control-label"
                  id="servedExplanation"
                >
                  {eventStatusDateType.EXPLANATION}
                </label>
              </div>

              {noticeDateRadio === eventStatusDateType.EXPLANATION ? (
                <div className="event-status-date-explanation-container">
                  <label
                    htmlFor="noticeDateExplanation"
                    className="event-status-date-exp"
                    id="noticeDateExplanationLabelId"
                  >
                    If not exact, provide explanation *
                  </label>
                  <textarea
                    id="noticeDateExplanation"
                    onChange={onDateNoticeExplanationChange}
                    className={
                      noticeDateExpError
                        ? "form-control form-control-15 is-invalid"
                        : "form-control form-control-15"
                    }
                    maxLength={500}
                    minLength={2}
                    onBlur={onBlurDateNoticeExpHandler}
                    onFocus={() => setFocusedState(true)}
                    rows="3"
                    cols={500}
                    value={noticeDateExp}
                  />
                  <div id="noticeDateExpError" className="invalid-feedback">
                    Please enter an Explanation
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="crim-comments-container">
        <label
          htmlFor="civilActionDes"
          className="comments-label"
          id="civilActionDesLabel"
        >
          Describe the allegations related to this civil action: *
        </label>
        <textarea
          id="civilActionDes"
          onChange={onCivilActionDesChangeHandler}
          onBlur={onBlurCivilActionDesHandler}
          onFocus={() => setFocusedState(true)}
          className={
            civilActionDesError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15"
          }
          minLength={2}
          maxLength={500}
          rows="3"
          cols={500}
          value={civilActionDes}
        />
        <div id="civilActionDesError" className="invalid-feedback">
          Please enter the allegations related to this civil action
        </div>
      </div>

      <div className="row empl-firm">
        <div className="col-md-10">
          <label
            className="pos-title-rel"
            htmlFor="emplCivJudAction"
            id="emplCivJudActionLabel"
          >
            Employing Firm when activity occurred which led to civil judicial
            action: *
          </label>
          <input
            type="text"
            id="emplCivJudAction"
            className={
              emplCivJudActionError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={emplCivJudAction}
            onBlur={onBlurEmplRegActioHandler}
            onChange={onChangeEmplCivJudActionHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="emplCivJudActionError" className="invalid-feedback">
            Please enter name of Employing Firm when activity occurred which led
            to the civil judicial action
          </div>
        </div>
      </div>

      <CheckboxList
        selectedItems={selectedProdValues}
        list={DRPProdCheckList}
        header="Product Type(s) (Select all that apply): *"
        onChangeChecked={onChangeCheckedProd}
        onOtherChangeHandler={onOtherProdChangeHandler}
        onBlurOtherHandler={onBlurProdOtherHandler}
        otherTextError={otherProdTextError}
        otherText={otherProdText}
        onFocusHandler={() => setFocusedState(true)}
      />

      <div style={{ marginTop: "24px" }}>
        <CheckboxList
          selectedItems={selectedReliefSoughtValues}
          list={DRPReliefSoughtCheckList}
          header="Relief Sought (Select all that apply): *"
          onChangeChecked={onChangeCheckedReliefSought}
          onOtherChangeHandler={onOtherReliefSoughtChangeHandler}
          onBlurOtherHandler={onBlurReliefSoughtOtherHandler}
          otherTextError={otherReliefSoughtTextError}
          otherText={otherReliefSoughtText}
          onFocusHandler={() => setFocusedState(true)}
        />
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span
            id="civBackId"
            role="presentation"
            onClick={handleShowBackModal}
          >
            <strong id="civBackLabelId">Back</strong>
          </span>
          <Button
            label="Next"
            id="civNextId"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CivJudDetails;

import { PAGE_SECTION } from "../../../../../common/Functions";

function ReviewPageTitle({ pageTitle, sectionTitle }) {
  const showRequired =
    pageTitle !== PAGE_SECTION.RESIDENTIAL_HISTORY &&
    pageTitle !== PAGE_SECTION.PSR &&
    pageTitle !== PAGE_SECTION.CHARACTER_CREDIT_AND_BONDING;
  return (
    <div
      id="reviewPageTitle"
      className={"col-12"}
      style={{ marginBottom: "20px", marginTop: "4%", paddingLeft: "0px" }}
    >
      <div id="sectionTitle" className="eyebrow">
        {sectionTitle}
      </div>
      <h1 id="pageTitle">{pageTitle}</h1>
      {showRequired && <div id="requiredFieldNotice">* = Required Field</div>}
    </div>
  );
}

export default ReviewPageTitle;

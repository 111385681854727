import React from "react";
import AddEmploymentHistoryModal from "./AddEmploymentHistoryModal";
import Moment from "moment";
import EmploymentHistoryTable from "./EmplomentHistoryTable";
import AddedEmpHistToast from "./AddedEmpHistToast";
import DeletedEmpHistToast from "./DeletedEmpHistToast";
import { FORM_ID } from "../../../constants/Forms";
import {
  deleteEmploymentHistoryInformation,
  deleteEmploymentBreakHistoryInformation,
} from "../../../api/makeAPIRequest";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import { UtagTracking } from "../../../utils/utag-tracking";

class EmploymentHistoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.getEmploymentHistory = this.getEmploymentHistory.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.showAddModalFromTable = this.showAddModalFromTable.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.showDeletedAlert = this.showDeletedAlert.bind(this);
    this.closeDeletedAlert = this.closeDeletedAlert.bind(this);
    this.reviewEditClick = this.reviewEditClick.bind(this);
    this.enableNextButton = this.enableNextButton.bind(this);
    this.organizeData = this.organizeData.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
  }

  initialState() {
    return {
      show_modal: false,
      emp_hist_data: [],
      date_list: [],
      show_experience_added_alert: false,
      show_experience_deleted_alert: false,
      is_next_disabled: true,
      reviewEditData: "",
      oktasubid: "",
      candidateId: 0,
      added_saved: "added",
    };
  }
  async componentDidMount() {
    await this.setState({
      oktasubid: this.props.subId,
      candidateId: this.props.candidateId,
    });
    this.getEmploymentHistory();
    UtagTracking.sendPageNameView(
      "employmenthistoryform",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }

  async closeAddModal(data) {
    if (data !== null) {
      let newEmpHistData = [...this.state.emp_hist_data];

      //if index is found, then overwrite spot in arraylist. if not, add new data to arraylist
      const index = newEmpHistData.findIndex((item) => item.id === data.id);
      index !== -1 ? (newEmpHistData[index] = data) : newEmpHistData.push(data);

      await this.showAlert();
      this.props.saveEmpHistInfo(newEmpHistData);
      this.setState({ emp_hist_data: newEmpHistData });
      await this.getEmploymentHistory();
    }

    this.setState({ show_modal: false, reviewEditData: "" });
  }
  async closeAlert() {
    var toast_banner = document.getElementById("emphist_added_alert");
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({
      show_experience_added_alert: false,
      added_saved: "added",
    });
  }
  async closeDeletedAlert() {
    var toast_banner = document.getElementById("hist_delete_alert");
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({ show_experience_deleted_alert: false });
  }
  async deleteData(item) {
    item.companyname === null
      ? await deleteEmploymentBreakHistoryInformation(this.props.subId, item.id)
      : await deleteEmploymentHistoryInformation(this.props.subId, item.id);

    let newEmploymentHistory = this.state.emp_hist_data.filter(
      (addressHistItem) => addressHistItem !== item
    );

    this.props.saveEmpHistInfo(newEmploymentHistory);

    await this.getEmploymentHistory();
    await this.showDeletedAlert();
    window.scrollTo(0, 0);
  }
  enableNextButton() {
    var addHistoryLink = document.getElementById("add_emp_history");

    let isEmpHistoryComplete =
      addHistoryLink === null && this.state.emp_hist_data.length > 0;

    this.setState({ is_next_disabled: !isEmpHistoryComplete });
    this.props.isFormComplete(isEmpHistoryComplete);
  }
  async getEmploymentHistory() {
    const newEmpHist = this.organizeData([...this.props.empHistoryData]);
    await this.setState({ emp_hist_data: newEmpHist });
    window.scrollTo(0, 0);
    this.props.refreshPSR();
    this.enableNextButton();
  }
  organizeData(_employmentHistory) {
    let employmentHistory = _employmentHistory;

    //sort employmentHistory by begindate
    if (employmentHistory.length > 0) {
      employmentHistory = employmentHistory.sort(
        (a, b) => new Date(b.startdate) - new Date(a.startdate)
      );
    }

    //clear overlapping timelines that already exists
    for (let z = 0; z < employmentHistory.length; z++) {
      employmentHistory[z].overlappingRange = null;

      // for employment break/other, use description field
      if (employmentHistory[z].jobtitle === "Other") {
        employmentHistory[z].jobtitle = employmentHistory[z].description;
        employmentHistory[z].description = "";
      }
    }

    for (let i = 0; i < employmentHistory.length; i++) {
      let endi =
        employmentHistory[i].enddate === null
          ? Moment()
          : Moment(employmentHistory[i].enddate);
      if (i > 0) {
        // check for overlapping timelines
        for (let o = 0; o < employmentHistory.length; o++) {
          let endo =
            employmentHistory[o].enddate === null
              ? Moment()
              : Moment(employmentHistory[o].enddate);

          if (i !== o) {
            if (
              Moment(employmentHistory[i].startdate) >=
                Moment(employmentHistory[o].startdate) &&
              endi <= endo
            ) {
              employmentHistory[i].overlappingRange = {
                startdate: employmentHistory[o].startdate,
                enddate: endo.format("YYYY-MM-DD"),
              };
            } else {
              if (
                typeof employmentHistory[i].overlappingRange === "undefined"
              ) {
                employmentHistory[i].overlappingRange = null;
              }
            }
          }
        }

        // check for gaps between each item
        let lastMoveOutDate = Moment(employmentHistory[i].enddate);
        let currentMoveInDate = Moment(employmentHistory[i - 1].startdate);
        employmentHistory[i].istheregap =
          currentMoveInDate.diff(lastMoveOutDate, "days") > 89;
      } else {
        employmentHistory[i].istheregap = false;
        employmentHistory[i].overlappingRange = null;
      }
    }

    return employmentHistory;
  }
  async reviewEditClick(job) {
    await this.setState({
      reviewEditData: job,
      added_saved: "saved",
      show_modal: true,
    });
    //hide popup menu
    let myRef = document.getElementById("top_element");
    myRef.click();
  }
  async showAddModal() {
    await this.setState({
      show_modal: true,
      reviewEditData: "",
    });
  }
  async showAddModalFromTable() {
    await this.setState({
      show_modal: true,
      added_saved: "added",
    });
  }
  async showAlert() {
    var toast_banner = document.getElementById("emphist_added_alert");
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_experience_added_alert: true });
  }
  async showDeletedAlert() {
    var toast_banner = document.getElementById("hist_delete_alert");
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_experience_deleted_alert: true });
  }
  async handleNextClick() {
    this.props.isFormComplete(true);

    if (
      this.props.psrData.length > 0 &&
      this.props.contractType !== CONTRACT_TYPE_DESCRIPTIONS.MMLIS
    ) {
      this.props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD);
    } else if (this.props.psrData.length <= 0) {
      this.props.toggleForm(FORM_ID.CCB_INTRO);
    } else {
      this.props.toggleForm(FORM_ID.CCB_INTRO);
    }
  }

  handleShowBackModal() {
    if (this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) {
      this.props.toggleForm(FORM_ID.BUSINESS_INFO);
    } else {
      this.props.toggleForm(FORM_ID.PROFESSIONAL_DESIGNATION);
    }
  }

  render() {
    return (
      <>
        <AddedEmpHistToast
          showToast={this.state.show_experience_added_alert}
          closeToast={this.closeAlert}
          addedSaved={this.state.added_saved}
        ></AddedEmpHistToast>
        <DeletedEmpHistToast
          showToast={this.state.show_experience_deleted_alert}
          closeDeletedToast={this.closeDeletedAlert}
        ></DeletedEmpHistToast>

        <div className="row">
          <div className="col-md-12">
            <h1>Employment History</h1>
          </div>
        </div>
        <div>
          <div
            className="col-md-12"
            style={{ paddingTop: "15px", paddingLeft: "0px" }}
          >
            <h3 id="emp_hist_header">
              Please provide your employment history for the last 10 years,
              including any time contracted with MassMutual.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "15px" }}>
            <span
              id="emp_hist_intro"
              className="required-field-text"
              style={{ fontSize: "16px" }}
            >
              To account for military service, education, unemployment,
              homemaking, extended travel, or any other employment gaps lasting
              three months or more, please use the “Employment Break” tab on the
              Add Experience screen.
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "40px" }}>
            {/* {this.state.emp_hist_data.length === 0 && ( */}
            <hr style={{ color: "#C1C9D5" }}></hr>
            {/* )} */}
            {/* {this.state.emp_hist_data.length < 1 && ( */}
            <div
              className="col-md-3"
              //onClick={() => this.showAddModalFromTable()}
              style={{ paddingLeft: "0px" }}
            >
              <span
                id="initial_add_emp_history"
                style={{
                  marginRight: "25px",
                  color: "#3174af",
                  cursor: "pointer",
                }}
                onClick={() => this.showAddModalFromTable()}
              >
                <span className="icon-add"></span>
                {"  "}{" "}
                <strong style={{ paddingLeft: "5px" }}>Add Experience</strong>
              </span>
            </div>
            {/* )} */}
            {this.state.emp_hist_data.length > 0 && (
              <div
                style={{
                  padding: "0",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
              >
                <EmploymentHistoryTable
                  data={this.state.emp_hist_data}
                  psrData={this.props.psrData}
                  showAddModal={this.showAddModalFromTable}
                  deleteData={this.deleteData}
                  reviewEditClick={this.reviewEditClick}
                  hasNYRegs={this.props.hasNYRegs}
                  industries={this.props.industries}
                ></EmploymentHistoryTable>
              </div>
            )}

            <NavigationButtons
              onBack={this.handleShowBackModal}
              onNext={this.handleNextClick}
              isLoading={false}
              isDisabled={this.state.is_next_disabled}
              nxtbtnid={"emp_hist_next"}
              bckbtnid={"emp_hist_back"}
            />
          </div>
        </div>
        <AddEmploymentHistoryModal
          isOpen={this.state.show_modal}
          actionCancel={this.closeAddModal}
          subId={this.state.oktasubid}
          candidateId={this.state.candidateId}
          showAlert={this.showAlert}
          showDeletedAlert={this.showDeletedAlert}
          reviewEditData={this.state.reviewEditData}
          hasNYRegs={this.props.hasNYRegs}
          industries={this.props.industries}
        />
      </>
    );
  }
}

export default EmploymentHistoryForm;

import DRPSectionDivider from "../common/DRPSectionDivider";

const ReviewInvestigationDRP = ({
  drp: {
    comments,
    invDetails: {
      drpCheckList,
      fullNameReg,
      invDetRes,
      noticeDate,
      noticeDateExp,
      noticeDateRadio,
      noticeReceived,
      noticeReceivedOther,
    },
    statusDetails: {
      dateClosedResolved,
      dateClosedResolvedExp,
      dateClosedResolvedRadio,
      invPending,
      invResolved,
      invResolvedOther,
    },
  },
}) => {
  return (
    <div id="ReviewInvestigationDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">
          {drpCheckList
            .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
            .join(", ")}
        </div>
      </div>
      <DRPSectionDivider section={"INVESTIGATION DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Notice received from: *</strong>
        </div>
        <div className="col-6">
          {noticeReceived}
          {noticeReceived === "Other" ? `: ${noticeReceivedOther}` : ""}
        </div>
      </div>

      {noticeReceived !== "SEC" && (
        <>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>
                Full name of regulator (if other than SEC) that initiated the
                investigation: *
              </strong>
            </div>
            <div className="col-6">{fullNameReg}</div>
          </div>
        </>
      )}

      <br />
      <div className="row">
        <div className="col-6">
          <strong>Notice Date: *</strong>
        </div>
        <div className="col-6">
          {noticeDate}
          <br />
          {`${noticeDateRadio}${
            noticeDateRadio === "Explanation" ? `: ${noticeDateExp}` : ""
          }`}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>
            Describe briefly the nature of the investigation, if known, or
            details of the resolution: *
          </strong>
        </div>
        <div className="col-6">{invDetRes}</div>
      </div>
      <DRPSectionDivider section={"STATUS DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Is investigation pending?: *</strong>
        </div>
        <div className="col-6">{invPending ? "Yes" : "No"}</div>
      </div>
      {invPending === false && (
        <>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>
                If investigation is not pending, provide the resolution details
                below:
              </strong>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Date Closed/Resolved: *</strong>
            </div>
            <div className="col-6">
              {dateClosedResolved}
              <br />
              {`${dateClosedResolvedRadio}${
                dateClosedResolvedRadio === "Explanation"
                  ? `: ${dateClosedResolvedExp}`
                  : ""
              }`}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>How was investigation resolved? *</strong>
            </div>
            <div className="col-6">
              {invResolved}
              {invResolved === "Other" ? `: ${invResolvedOther}` : ""}
            </div>
          </div>
        </>
      )}
      <DRPSectionDivider section={"COMMENTS"} />
      <div className="row">
        <div className="col-6">
          <strong>
            You may use this field to provide a brief summary of the
            circumstances leading to the investigation as well as the current
            status or final disposition and/or finding(s). (Optional)
          </strong>
        </div>
        <div className="col-6">{comments === "" ? "-" : comments}</div>
      </div>
    </div>
  );
};
export default ReviewInvestigationDRP;

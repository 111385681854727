import { useOktaAuth } from "@okta/okta-react";
import moment from "moment";
import { Button } from "react-blueprint-library";
import { updateContractStatus } from "../../../api/makeAPIRequest";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_STATUS_ID,
  CONTRACT_TYPE_DESCRIPTIONS,
  isBackgroundCheck,
  isPending,
  isSubmitted,
} from "../../../constants/Contract";
import PDFForm from "./PDFForm";
import "../../../assets/stylesheets/HomePage/HomePageActiveApps.scss";

const HomePageActiveApps = ({
  agencynumber,
  applicationStatus,
  contract,
  contracttype,
  contractid,
  mmlis,
  finance,
  lastchangedate,
  showGetStartedFor,
  ...rest
}) => {
  const {
    authState: {
      idToken: {
        claims: { sub, email },
      },
    },
  } = useOktaAuth();

  const getStarted = async () => {
    if (applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT) {
      showGetStartedFor(contractid);
    } else {
      const shouldUpdateStatus =
        applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
        applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION;
      if (shouldUpdateStatus) {
        await updateContractStatus(
          sub,
          contractid,
          email,
          CONTRACT_STATUS_ID.APPLICATION_IN_PROGRESS
        );
      }
      // For some reason, the page changes location before the updateContractStatus Completes.
      // Set a timeout to fix this
      setTimeout(
        () => {
          window.location = `/startapplication?contract=${contractid}`;
        },
        shouldUpdateStatus ? 250 : 0
      );
    }
  };

  const showAddOns = mmlis || finance;
  const addOnsText =
    mmlis && finance
      ? "+ MMLIS, Financing"
      : mmlis && !finance
      ? "+ MMLIS"
      : !mmlis && finance
      ? "+ Financing"
      : null;

  const applicationStatusText =
    applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT
      ? "Invitation sent on "
      : "Application Status as of ";

  const showStartApplicationButton =
    applicationStatus === CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS ||
    applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
    applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION ||
    applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT;

  const startApplicationButtonText =
    applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
    applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION
      ? "Revise Application"
      : applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT
      ? "Begin Application"
      : "Continue Application";
  return (
    <div className="home-page-active-apps row" id={`contract${contractid}`}>
      <div className="col-8">
        <div className="homepageText">
          <div className="eyebrow">
            {applicationStatus === CONTRACT_STATUS_DESC.INVITATION_SENT && (
              <span
                id="new_bubble"
                className="badge badge-pill badge-secondary new-bubble"
              >
                New
              </span>
            )}
            <span id="finetext"> Agency #{agencynumber}</span>
          </div>
          <div className="contract-type-and-addons">
            <h2 className="contract-type">{contracttype}</h2>
            {showAddOns && <span className="addons">{addOnsText}</span>}
          </div>
          {contracttype === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
            <div className="corporation-name">{contract?.corpname}</div>
          )}
          <div id="homepageAppStatusText" className="application-status-as-of">
            <span>
              {applicationStatusText}
              {moment(lastchangedate).utc().format("MMMM DD, YYYY")}
            </span>
          </div>

          {applicationStatus !== CONTRACT_STATUS_DESC.INVITATION_SENT && (
            <div className="application-timeline">
              {isBackgroundCheck(applicationStatus) ||
              isPending(applicationStatus) ||
              isSubmitted(applicationStatus) ? (
                <span className="badge badge-primary">
                  Application Submitted
                </span>
              ) : applicationStatus ===
                  CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
                applicationStatus === CONTRACT_STATUS_DESC.NEEDS_REVISION ? (
                <span className="badge badge-primary-error">
                  Application Returned
                </span>
              ) : applicationStatus ===
                  CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS ||
                applicationStatus ===
                  CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ? (
                <span className="badge badge-primary-current">
                  Application in Progress
                </span>
              ) : (
                <span className="badge badge-primary">
                  Application in Progress
                </span>
              )}
              <hr className="strike-through" />
              {applicationStatus === CONTRACT_STATUS_DESC.AGENCY_REVIEW ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
              applicationStatus === CONTRACT_STATUS_DESC.RETURNED_TO_AGENCY ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPLICATION_SUBMITTED ||
              isBackgroundCheck(applicationStatus) ? (
                <span className="badge badge-primary-current">
                  Agency Review
                </span>
              ) : (
                <span className="badge badge-primary">Agency Review</span>
              )}
              <hr className="strike-through" />
              {applicationStatus === CONTRACT_STATUS_DESC.HOME_OFFICE_REVIEW ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
              applicationStatus ===
                CONTRACT_STATUS_DESC.SUBMITTED_TO_HOME_OFFICE ||
              applicationStatus === CONTRACT_STATUS_DESC.MMLIS_Pending ||
              isPending(applicationStatus) ? (
                <span className="badge badge-primary-current">
                  MassMutual Review
                </span>
              ) : (
                <span className="badge badge-primary">MassMutual Review</span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="col-4">
        {showStartApplicationButton ? (
          <p className="buttons">
            <Button
              onClick={() => getStarted()}
              className="continue-button"
              id={`continue-application-button-${contractid}`}
            >
              {startApplicationButtonText}
              <span className="icon-caret" />
            </Button>
          </p>
        ) : (
          <div id={`contractForms${contractid}`} className="contract-forms">
            <PDFForm
              contractid={contractid}
              mmlis={mmlis}
              applicationStatus={applicationStatus}
              contract={contracttype}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePageActiveApps;

import React, { useEffect } from "react";

function ReviewCorporationBusinessInformation(props) {
  return (
    <div id="reviewBusinessInformation" className="review-form">
      <div className="col-7 row mm-spacing--minor">
        <h3 id="conf_businessinfo_text">Business Information</h3>
      </div>

      <div className="candidate-info">
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biStreetAddressLabel">Street Address*</strong>
          </div>
          <div id="biStreetAdress" className="col-6 bo--text-color">
            {props.busAddress.address1 === null ||
            props.busAddress.address1 === ""
              ? "-"
              : props.busAddress.address1}
          </div>
        </div>
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biAddress2Label">
              Apartment, Suite, Building, Gate Code
            </strong>
          </div>
          <div id="biAddress2" className="col-6 bo--text-color">
            {props.busAddress.address2 === null ||
            props.busAddress.address2 === ""
              ? "-"
              : props.busAddress.address2}
          </div>
        </div>
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biCityLabel">City*</strong>
          </div>
          <div id="biCity" className="col-6 bo--text-color">
            {props.busAddress.city === null || props.busAddress.city === ""
              ? "-"
              : props.busAddress.city}
          </div>
        </div>
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biStateLabel">State/Province*</strong>
          </div>
          <div id="biState" className="col-6 bo--text-color">
            {props.busAddress.state === null || props.busAddress.state === ""
              ? "-"
              : props.busAddress.state}
          </div>
        </div>
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biZipCodeLabel">Zip Code*</strong>
          </div>
          <div id="biZipCode" className="col-6 bo--text-color">
            {props.busAddress.zipcode === null ||
            props.busAddress.zipcode === ""
              ? "-"
              : props.busAddress.zipcode}
          </div>
        </div>
        {props.busAddress.state === "PR" && (
          <div className="row">
            <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
              <strong id="biUrbanizationLabel">Urbanization</strong>
            </div>
            <div id="biUrbanization" className="col-6 bo--text-color">
              {props.busAddress.corpurbanization === null ||
              props.busAddress.corpurbanization === ""
                ? "-"
                : props.busAddress.corpurbanization}
            </div>
          </div>
        )}

        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biPhoneNumberLabel">Business Phone Number*</strong>
          </div>
          <div className="col-6 peacock-blue">
            <strong id="biPhoneNumber">
              {props.busAddress.phonenumber === null ||
              props.busAddress.phonenumber === ""
                ? "-"
                : props.busAddress.phonenumber}
            </strong>
          </div>
        </div>
        <div className="row">
          <div className={props.id === "conf_bussinfo" ? "col-5" : "col-4"}>
            <strong id="biFaxNumberLabel">Business Fax Number</strong>
          </div>
          <div className="col-6 peacock-blue">
            <strong id="biFaxNumber">
              {props.busAddress.faxnumber === null ||
              props.busAddress.faxnumber === ""
                ? "-"
                : props.busAddress.faxnumber}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCorporationBusinessInformation;

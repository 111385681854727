import React, { useState, useEffect } from "react";
import Moment from "moment";

const ConfAppMMLIS2 = ({ contrib, hideReview, contracttypeid }) => {
  const [contribAmount, setContribAmount] = useState("");
  const [result, setResult] = useState({
    contribform: "",
    contribrecipient: "",
    entitledtovote: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const data = contrib;
    const amt = data?.contribamt?.replace(".00", "");
    setResult(data);
    setContribAmount(amt);
  }, [contrib, contracttypeid]);

  return (
    <div id="reviewMMLISInfo" className="review-form">
      <div className="candidate-info">
        <div className="row">
          <div className="col-5">
            <strong id="mmlisDateLabel">Date of Contribution*</strong>
          </div>
          <div id="mmlisDate" className="col-6 bo--text-color">
            {result?.contribdate !== ""
              ? Moment.utc(result.contribdate).format("MM/DD/YYYY")
              : "-"}
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <strong id="mmlisAmountLabel">Amount*</strong>
          </div>
          <div id="mmlisAmount" className="col-6 bo--text-color">
            ${contribAmount !== "" ? contribAmount : "-"}
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <strong id="mmlisFormLabel">
              Form of contribution if other than cash or check
            </strong>
          </div>
          <div id="mmlisForm" className="col-6 bo--text-color">
            {result.contribform !== "" ? result.contribform : "-"}
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <strong id="mmlisRecipientLabel">
              Name of Recipient, Political Party, PAC, or PPC*
            </strong>
          </div>
          <div id="mmlisRecipient" className="col-6 bo--text-color">
            {result.contribrecipient !== "" ? result.contribrecipient : "-"}
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <strong id="mmlisEntitledLabel">
              Were you entitled to vote in the jurisdiction covered by the party
              committee at the time of the contribution?*
            </strong>
          </div>
          <div id="mmlisEntitled" className="col-6 bo--text-color">
            {result.entitledtovote === true ? "Yes" : "No"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfAppMMLIS2;

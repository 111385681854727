function ConfAppCCBInd(props) {
  return (
    <div id="confidential-ccb">
      <div className="row">
        <div className="col-8 mm-spacing--minor">
          <h3 id="conf_ccb_text">Character, Credit, and Bonding</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <h5 id="conf_ccb_q_lawsuit">
            Are you now or have you in the past been directly or indirectly
            involved in a lawsuit since you have been in the business of
            insurance?*
          </h5>
        </div>
        <div
          id="invlawsuit"
          className="col-6 data"
          style={{
            color: "#555",
            overflowWrap: "break-word",
          }}
        >
          {props.ccb_info.invlawsuit === true ? "Yes" : "No"}
        </div>
      </div>

      {props.ccb_info.invlawsuit === true && (
        <div className="row">
          <div className="col-6">
            <h5 id="law_suit">
              Please explain your involvement in a lawsuit relative to insurance
              practice.*
            </h5>
          </div>
          <div id="invlawsuitdesc" className="col-6 data">
            <>{props.ccb_info.invlawsuitdesc}</>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6">
          <h5 id="conf_ccb_q_crime">
            Have you ever been convicted of any crime (felony or misdemeanor)?*
          </h5>
        </div>
        <div id="convictcrime" className="col-6 data">
          {props.ccb_info.convictcrime === true ? "Yes" : "No"}
        </div>
      </div>

      {props.ccb_info.convictcrime === true && (
        <div className="row">
          <div className="col-6">
            <h5 id="law_suit_1">
              Please explain the crime (felony or misdemeanor) of which you have
              been convicted.*
            </h5>
          </div>
          <div id="convictcrimedesc" className="col-6 data">
            {props.ccb_info.convictcrimedesc}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6">
          <h5 id="conf_ccb_q_licsuspend">
            Have you ever had a professional license suspended, revoked,
            cancelled, or had your employment or contract ‘terminated for
            cause’?*
          </h5>
        </div>
        <div id="licsuspend" className="col-6 data">
          {props.ccb_info.licsuspend === true ? "Yes" : "No"}
        </div>
      </div>

      {props.ccb_info.licsuspend === true && (
        <div className="row">
          <div className="col-6">
            <h5 id="law_suit_2">
              Please explain why your professional license was suspended,
              revoked, cancelled, or why your employment or contract was
              'terminated for cause'.*
            </h5>
          </div>
          <div id="licsuspenddesc" className="col-6 data">
            {props.ccb_info.licsuspenddesc}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6">
          <h5 id="conf_ccb_q_failedbusiness">
            Have you ever failed in business, declared bankruptcy, compromised
            with creditors, or had any liens or judgments against you?*
          </h5>
        </div>
        <div id="failedbusiness" className="col-6 data">
          {props.ccb_info.failedbusiness === true ? "Yes" : "No"}
        </div>
      </div>

      {props.ccb_info.failedbusiness === true && (
        <div className="row">
          <div className="col-6">
            <h5 id="law_suit_3">
              Please explain if you have ever failed in business, declared
              bankruptcy, compromised with creditors, or had any liens or
              judgments against you.*
            </h5>
          </div>
          <div id="failedbusinessdesc" className="col-6 data">
            {props.ccb_info.failedbusinessdesc}
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfAppCCBInd;

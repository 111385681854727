import React from "react";

export const one = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-1">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12Z"
        fill="#004DB2"
      />
      <path
        id="1"
        d="M11.3511 9.50685V16.6712C11.3511 16.8493 11.5191 17 11.7176 17H12.6336C12.8321 17 13 16.8493 13 16.6712V7.32877C13 7.15068 12.8321 7 12.6336 7H11.7939C11.5802 7 11.4427 7.13699 11.3969 7.32877C11.1985 8.23288 10.5878 8.78082 9.36641 8.94521C9.15267 8.9726 9 9.12329 9 9.31507V10.2192C9 10.3836 9.18321 10.5068 9.36641 10.5068C10.2366 10.5068 11.0916 10.0959 11.3511 9.50685Z"
        fill="#004DB2"
      />
    </g>
  </svg>
);

export const twoInactive = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-2">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12ZM23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0751 6.42487 23 12.5 23C18.5751 23 23.5 18.0751 23.5 12Z"
        fill="#6B748E"
      />
      <path
        id="2"
        d="M16.1322 17C16.3314 17 16.5 16.8514 16.5 16.6757V15.9189C16.5 15.7432 16.3314 15.5946 16.1322 15.5946H10.1858C10.1858 14.4595 11.5805 13.8108 13.0517 13.0946C14.6762 12.3108 16.3621 11.4595 16.3621 9.81081C16.3621 8.2973 15.0441 7 12.6686 7C10.6303 7 9.37356 7.82432 8.5613 9.10811C8.46935 9.24324 8.54598 9.44595 8.72989 9.51351L9.60345 9.83784C9.80268 9.91892 10.0019 9.85135 10.0939 9.67567C10.5383 8.85135 11.2893 8.28378 12.6226 8.28378C13.9406 8.28378 14.6609 8.90541 14.6609 9.81081C14.6609 10.8514 13.2969 11.5 11.8563 12.2162C10.2318 13.0135 8.5 13.9189 8.5 15.5946V16.6757C8.5 16.8514 8.66858 17 8.86782 17H16.1322Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const threeInactive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-3">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12Z"
        fill="#6B748E"
      />
      <path
        id="3"
        d="M16 14.0933C16 12.9733 15.341 12.24 14.1878 11.84C15.2362 11.4133 15.8053 10.6933 15.8053 9.76C15.8053 8.06667 14.3076 7 12.031 7C10.3536 7 9.24527 7.56 8.37659 8.44C8.25677 8.56 8.28673 8.77333 8.4365 8.86667L9.14043 9.32C9.30518 9.44 9.51486 9.41333 9.64965 9.26667C10.2038 8.66667 10.9826 8.26667 12.0161 8.26667C13.4389 8.26667 14.2177 8.86667 14.2177 9.76C14.2177 10.6 13.4539 11.1867 11.8962 11.1867H10.8478C10.6531 11.1867 10.4884 11.3333 10.4884 11.5067V12.2133C10.4884 12.3867 10.6531 12.5333 10.8478 12.5333H11.9412C13.5138 12.5333 14.3825 13.1333 14.3825 14.0667C14.3825 15.0133 13.5288 15.68 12.0161 15.68C10.8928 15.68 10.0241 15.3333 9.425 14.6267C9.2902 14.48 9.08052 14.4533 8.91577 14.56L8.13695 15.0667C7.98718 15.1733 7.95723 15.3867 8.06207 15.5067C8.9607 16.48 10.2188 17 12.0161 17C14.5322 17 16 15.7467 16 14.0933Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const fourInactive = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-4">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12ZM23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0751 6.42487 23 12.5 23C18.5751 23 23.5 18.0751 23.5 12Z"
        fill="#6B748E"
      />
      <path
        id="4"
        d="M14.6884 17C14.8768 17 15.0362 16.8493 15.0362 16.6712V14.8356H16.1522C16.3406 14.8356 16.5 14.6849 16.5 14.5068V13.8082C16.5 13.6301 16.3406 13.4795 16.1522 13.4795H15.0362V7.32877C15.0362 7.15068 14.8768 7 14.6884 7H13.7174C13.5 7 13.2971 7.10959 13.1667 7.27397L8.7029 13.1918C8.57246 13.3562 8.5 13.589 8.5 13.7945V14.5068C8.5 14.6849 8.65942 14.8356 8.84783 14.8356H13.5V16.6712C13.5 16.8493 13.6594 17 13.8478 17H14.6884ZM13.5 9.12329V13.4795H10.2246L13.5 9.12329Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const fiveInactive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-5">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12Z"
        fill="#6B748E"
      />
      <path
        id="5"
        d="M16 13.4459C16 11.4595 14.4528 9.95946 12.1995 9.95946C11.163 9.95946 10.3369 10.2838 9.79608 10.7297L10.1716 8.37838H14.9635C15.1588 8.37838 15.324 8.22973 15.324 8.05405V7.32432C15.324 7.14865 15.1588 7 14.9635 7H9.21024C8.99994 7 8.8347 7.13513 8.80466 7.32432L8.12869 11.6081C8.09864 11.7973 8.21882 11.9865 8.4141 12.0676L9.25531 12.4054C9.45059 12.4865 9.67591 12.4324 9.79608 12.2703C10.2918 11.6757 10.9527 11.2973 11.9592 11.2973C13.3262 11.2973 14.3476 12.1622 14.3476 13.4324C14.3476 14.7162 13.4914 15.6351 11.9592 15.6351C10.9377 15.6351 10.1115 15.1892 9.67591 14.4324C9.57076 14.2568 9.37548 14.2027 9.1802 14.2838L8.18877 14.7027C8.02354 14.7703 7.94843 14.9865 8.03856 15.1216C8.74457 16.2432 10.0665 17 11.9291 17C14.3626 17 16 15.3919 16 13.4459Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const sixInactive = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-6">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12ZM23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0751 6.42487 23 12.5 23C18.5751 23 23.5 18.0751 23.5 12Z"
        fill="#6B748E"
      />
      <path
        id="6"
        d="M16.5 13.6133C16.5 11.68 14.9148 10.28 12.9296 10.28C11.5222 10.28 10.4704 10.8933 9.98148 11.9867C10.0852 9.56 11.5074 8.38667 13.6259 8.38667C14.0111 8.38667 14.4259 8.45333 14.737 8.54667C14.9593 8.6 15.137 8.52 15.2259 8.33333L15.5667 7.56C15.6407 7.41333 15.537 7.22667 15.3593 7.18667C14.9296 7.08 14.3222 7 13.7444 7C10.6185 7 8.5 8.85333 8.5 12.28C8.5 14.0533 8.95926 14.96 9.62593 15.7333C10.3963 16.5867 11.4333 17 12.6185 17C14.8259 17 16.5 15.5333 16.5 13.6133ZM10.3519 13.6133C10.3519 12.44 11.3296 11.6 12.6037 11.6C13.9519 11.6 14.9148 12.4533 14.9148 13.6133C14.9148 14.7867 13.9074 15.6533 12.6037 15.6533C11.3296 15.6533 10.3519 14.7733 10.3519 13.6133Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const sevenInactive = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/icon-circle-7">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1426 12C21.1426 7.02944 17.1131 3 12.1426 3C7.17202 3 3.14258 7.02944 3.14258 12C3.14258 16.9706 7.17202 21 12.1426 21C17.1131 21 21.1426 16.9706 21.1426 12ZM23.1426 12C23.1426 5.92487 18.2177 1 12.1426 1C6.06745 1 1.14258 5.92487 1.14258 12C1.14258 18.0751 6.06745 23 12.1426 23C18.2177 23 23.1426 18.0751 23.1426 12Z"
        fill="#6B748E"
      />
      <path
        id="7"
        d="M10.8695 17C11.0804 17 11.2914 16.8767 11.3968 16.7123L15.9769 8.57534C16.0823 8.41096 16.1426 8.15068 16.1426 7.9589V7.32877C16.1426 7.15068 15.9769 7 15.781 7H8.50416C8.3083 7 8.14258 7.15068 8.14258 7.32877V8.09589C8.14258 8.27397 8.3083 8.42466 8.50416 8.42466H14.3799L9.64917 16.7123C9.57384 16.8493 9.6793 17 9.84503 17H10.8695Z"
        fill="#6B748E"
      />
    </g>
  </svg>
);

export const timelineCheckmark = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.9869 9.59172C18.3817 9.20553 18.3887 8.5724 18.0025 8.17759C17.6163 7.78279 16.9832 7.7758 16.5884 8.162L10.6674 13.9538L7.64324 11.2403C7.23217 10.8714 6.59994 10.9057 6.2311 11.3167C5.86226 11.7278 5.8965 12.3601 6.30757 12.7289L10.0291 16.0681C10.4214 16.42 11.0195 16.4072 11.3962 16.0386L17.9869 9.59172Z"
      fill="#004DB2"
    />
  </svg>
);

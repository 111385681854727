import React, { Component, Fragment } from "react";
import { Card } from "react-blueprint-library";
import { Accordion, AccordionButton } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

function formatValue(value) {
  if (isNaN(value)) {
    value = 0;
  }
  var rounded = Math.round(value).toFixed(0);
  let nf = Intl.NumberFormat("en-US");
  let result = nf.format(rounded);
  return result;
}
class ReviewPSRCommissions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.props.id === "confidential_psr" ? (
            <>
              <div className="footer-link row">
                <strong className="col-3">
                  <p>
                    {this.props.hasNYRegs === false ? (
                      <>Commissions:</>
                    ) : (
                      <>Premiums:</>
                    )}
                  </p>
                </strong>
                <strong className="col-9">
                  ${formatValue(this.props.data.totalComissions)}
                </strong>
              </div>
              <section className="row">
                <div className="col-md-12 align-self-stretch">
                  <div style={{ width: "100%" }}>
                    <br></br>
                    <table width="100%" cellPadding={8} cellSpacing={8}>
                      <tbody>
                        <tr className="psr-row">
                          <td width="20%" className="psr-cell">
                            <strong></strong>
                          </td>
                          {Array.from(this.props.comData).map((psr, key) => {
                            return (
                              <Fragment key={key}>
                                {key === 0 && (
                                  <td width="13%" className="psr-cell-center">
                                    <strong>
                                      Current Year<br></br>
                                      {this.props.comData[0][0].year}
                                    </strong>
                                  </td>
                                )}
                                {key > 0 && (
                                  <td width="13%" className="psr-cell-center">
                                    <strong>
                                      Year {key}
                                      <br></br>
                                      {this.props.comData[key][0].year}
                                    </strong>
                                  </td>
                                )}
                              </Fragment>
                            );
                          })}
                        </tr>
                        {this.props.has_ind_life === true && (
                          <>
                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                <strong>Individual Life Insurance</strong>
                              </td>
                              <td colSpan={6} className="psr-cell-center"></td>
                            </tr>

                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                {this.props.hasNYRegs === false ? (
                                  <>First Year Commissions</>
                                ) : (
                                  <>First Year Premium</>
                                )}
                              </td>
                              {Array.from(this.props.comData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <td
                                        width="13%"
                                        className="psr-cell-center"
                                      >
                                        $
                                        {formatValue(
                                          this.props.comData[key][0]
                                            .indlifirstyear
                                        )}
                                      </td>
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>
                            {this.props.hasNYRegs === false && (
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Renewal Commissions
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .indlirenewcom
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            )}
                          </>
                        )}
                        {this.props.has_ind_annuity === true && (
                          <>
                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                <strong>Individual Annuity</strong>
                              </td>
                              <td colSpan={6} className="psr-cell-center"></td>
                            </tr>

                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                {this.props.hasNYRegs === false ? (
                                  <>First Year Commissions</>
                                ) : (
                                  <>First Year Premium</>
                                )}
                              </td>
                              {Array.from(this.props.comData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <td
                                        width="13%"
                                        className="psr-cell-center"
                                      >
                                        $
                                        {formatValue(
                                          this.props.comData[key][0]
                                            .indannfirstyear
                                        )}
                                      </td>
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>
                            {this.props.hasNYRegs === false && (
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Renewal Commissions
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .indannrenewcom
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            )}
                          </>
                        )}
                        {this.props.has_ind_disabilty === true && (
                          <>
                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                <strong>Individual Disability Income</strong>
                              </td>
                              <td colSpan={6} className="psr-cell-center"></td>
                            </tr>

                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                {this.props.hasNYRegs === false ? (
                                  <>First Year Commissions</>
                                ) : (
                                  <>First Year Premium</>
                                )}
                              </td>
                              {Array.from(this.props.comData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <td
                                        width="13%"
                                        className="psr-cell-center"
                                      >
                                        $
                                        {formatValue(
                                          this.props.comData[key][0]
                                            .inddisincfirstyear
                                        )}
                                      </td>
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>

                            {this.props.hasNYRegs === false && (
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Renewal Commissions
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .inddisincrenewcom
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            )}
                          </>
                        )}
                        {this.props.has_ind_long_term === true && (
                          <>
                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                <strong>Individual Long Term Care</strong>
                              </td>
                              <td colSpan={6} className="psr-cell-center"></td>
                            </tr>

                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                {this.props.hasNYRegs === false ? (
                                  <>First Year Commissions</>
                                ) : (
                                  <>First Year Premium</>
                                )}
                              </td>
                              {Array.from(this.props.comData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <td
                                        width="13%"
                                        className="psr-cell-center"
                                      >
                                        $
                                        {formatValue(
                                          this.props.comData[key][0]
                                            .indlongtermfirstyear
                                        )}
                                      </td>
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>

                            {this.props.hasNYRegs === false && (
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Renewal Commissions
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .indlongtermrenewcom
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            )}
                          </>
                        )}
                        {this.props.hasNYRegs === false && (
                          <tr className="psr-row">
                            <td colSpan={7} className="psr-cell"></td>
                          </tr>
                        )}
                        {this.props.has_gross_commissions === true &&
                          this.props.hasNYRegs === false && (
                            <>
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Investment Product Gross Dealer Commissions
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .invprodgrossdealercom
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            </>
                          )}
                        {this.props.has_fee_based_sales === true &&
                          this.props.hasNYRegs === false && (
                            <>
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  Fee-based Sales<br></br>Relevant Fees
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          $
                                          {formatValue(
                                            this.props.comData[key][0]
                                              .feebasedsalesrelfees
                                          )}
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            </>
                          )}
                        {this.props.hasNYRegs === false &&
                          (this.props.has_gross_commissions === true ||
                            this.props.has_fee_based_sales === true) && (
                            <tr
                              style={{
                                height: "25px",
                              }}
                            >
                              <td colSpan={7} className="psr-cell"></td>
                            </tr>
                          )}
                        <tr className="psr-row-gray">
                          <td width="20%" className="psr-cell-left-no-border">
                            <strong>Totals</strong>
                          </td>
                          {Array.from(this.props.comData).map((psr, key) => {
                            return (
                              <Fragment key={key}>
                                <td
                                  width="13%"
                                  className="psr-cell-center-no-border"
                                >
                                  <strong>
                                    $
                                    {formatValue(
                                      this.props.comData[key][0].totalPerYear
                                    )}
                                  </strong>
                                </td>
                              </Fragment>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <Card>
              <Accordion>
                <div className="mm-collapse">
                  <AccordionButton
                    id="psr_commissions"
                    className="btn-collapse"
                  >
                    <table width="100%">
                      <tbody>
                        <tr className="psr-first-row">
                          <td width="3%">&nbsp;</td>
                          <td width="22%">
                            <strong className="footer-link psr-accordion">
                              <p>
                                {this.props.hasNYRegs === false ? (
                                  <>Commissions:</>
                                ) : (
                                  <>Premiums:</>
                                )}
                              </p>
                            </strong>
                          </td>
                          <td width="72%">
                            <strong className="footer-link psr-accordion">
                              <p>
                                ${formatValue(this.props.data.totalComissions)}
                              </p>
                            </strong>
                          </td>
                          <td>
                            <span className="indicator psr-chevron"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </AccordionButton>
                  <AccordionBody>
                    <section className="row">
                      <div className="col-md-12 align-self-stretch">
                        <div style={{ width: "100%" }}>
                          <br></br>
                          <table width="100%" cellPadding={8} cellSpacing={8}>
                            <tbody>
                              <tr className="psr-row">
                                <td width="20%" className="psr-cell">
                                  <strong></strong>
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        {key === 0 && (
                                          <td
                                            width="13%"
                                            className="psr-cell-center"
                                          >
                                            <strong>
                                              Current Year<br></br>
                                              {this.props.comData[0][0].year}
                                            </strong>
                                          </td>
                                        )}
                                        {key > 0 && (
                                          <td
                                            width="13%"
                                            className="psr-cell-center"
                                          >
                                            <strong>
                                              Year {key}
                                              <br></br>
                                              {this.props.comData[key][0].year}
                                            </strong>
                                          </td>
                                        )}
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                              {this.props.has_ind_life === true && (
                                <>
                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      <strong>Individual Life Insurance</strong>
                                    </td>
                                    <td
                                      colSpan={6}
                                      className="psr-cell-center"
                                    ></td>
                                  </tr>

                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      {this.props.hasNYRegs === false ? (
                                        <>First Year Commissions</>
                                      ) : (
                                        <>First Year Premium</>
                                      )}
                                    </td>
                                    {Array.from(this.props.comData).map(
                                      (psr, key) => {
                                        return (
                                          <Fragment key={key}>
                                            <td
                                              width="13%"
                                              className="psr-cell-center"
                                            >
                                              $
                                              {formatValue(
                                                this.props.comData[key][0]
                                                  .indlifirstyear
                                              )}
                                            </td>
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </tr>
                                  {this.props.hasNYRegs === false && (
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Renewal Commissions
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .indlirenewcom
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  )}
                                </>
                              )}
                              {this.props.has_ind_annuity === true && (
                                <>
                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      <strong>Individual Annuity</strong>
                                    </td>
                                    <td
                                      colSpan={6}
                                      className="psr-cell-center"
                                    ></td>
                                  </tr>

                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      {this.props.hasNYRegs === false ? (
                                        <>First Year Commissions</>
                                      ) : (
                                        <>First Year Premium</>
                                      )}
                                    </td>
                                    {Array.from(this.props.comData).map(
                                      (psr, key) => {
                                        return (
                                          <Fragment key={key}>
                                            <td
                                              width="13%"
                                              className="psr-cell-center"
                                            >
                                              $
                                              {formatValue(
                                                this.props.comData[key][0]
                                                  .indannfirstyear
                                              )}
                                            </td>
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </tr>
                                  {this.props.hasNYRegs === false && (
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Renewal Commissions
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .indannrenewcom
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  )}
                                </>
                              )}
                              {this.props.has_ind_disabilty === true && (
                                <>
                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      <strong>
                                        Individual Disability Income
                                      </strong>
                                    </td>
                                    <td
                                      colSpan={6}
                                      className="psr-cell-center"
                                    ></td>
                                  </tr>

                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      {this.props.hasNYRegs === false ? (
                                        <>First Year Commissions</>
                                      ) : (
                                        <>First Year Premium</>
                                      )}
                                    </td>
                                    {Array.from(this.props.comData).map(
                                      (psr, key) => {
                                        return (
                                          <Fragment key={key}>
                                            <td
                                              width="13%"
                                              className="psr-cell-center"
                                            >
                                              $
                                              {formatValue(
                                                this.props.comData[key][0]
                                                  .inddisincfirstyear
                                              )}
                                            </td>
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </tr>

                                  {this.props.hasNYRegs === false && (
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Renewal Commissions
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .inddisincrenewcom
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  )}
                                </>
                              )}
                              {this.props.has_ind_long_term === true && (
                                <>
                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      <strong>Individual Long Term Care</strong>
                                    </td>
                                    <td
                                      colSpan={6}
                                      className="psr-cell-center"
                                    ></td>
                                  </tr>

                                  <tr className="psr-row">
                                    <td width="20%" className="psr-cell">
                                      {this.props.hasNYRegs === false ? (
                                        <>First Year Commissions</>
                                      ) : (
                                        <>First Year Premium</>
                                      )}
                                    </td>
                                    {Array.from(this.props.comData).map(
                                      (psr, key) => {
                                        return (
                                          <Fragment key={key}>
                                            <td
                                              width="13%"
                                              className="psr-cell-center"
                                            >
                                              $
                                              {formatValue(
                                                this.props.comData[key][0]
                                                  .indlongtermfirstyear
                                              )}
                                            </td>
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </tr>

                                  {this.props.hasNYRegs === false && (
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Renewal Commissions
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .indlongtermrenewcom
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  )}
                                </>
                              )}
                              {this.props.hasNYRegs === false && (
                                <tr className="psr-row">
                                  <td colSpan={7} className="psr-cell"></td>
                                </tr>
                              )}
                              {this.props.has_gross_commissions === true &&
                                this.props.hasNYRegs === false && (
                                  <>
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Investment Product Gross Dealer
                                        Commissions
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .invprodgrossdealercom
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  </>
                                )}
                              {this.props.has_fee_based_sales === true &&
                                this.props.hasNYRegs === false && (
                                  <>
                                    <tr className="psr-row">
                                      <td width="20%" className="psr-cell">
                                        Fee-based Sales<br></br>Relevant Fees
                                      </td>
                                      {Array.from(this.props.comData).map(
                                        (psr, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <td
                                                width="13%"
                                                className="psr-cell-center"
                                              >
                                                $
                                                {formatValue(
                                                  this.props.comData[key][0]
                                                    .feebasedsalesrelfees
                                                )}
                                              </td>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </tr>
                                  </>
                                )}
                              {this.props.hasNYRegs === false &&
                                (this.props.has_gross_commissions === true ||
                                  this.props.has_fee_based_sales === true) && (
                                  <tr
                                    style={{
                                      height: "25px",
                                    }}
                                  >
                                    <td colSpan={7} className="psr-cell"></td>
                                  </tr>
                                )}
                              <tr className="psr-row-gray">
                                <td
                                  width="20%"
                                  className="psr-cell-left-no-border"
                                >
                                  <strong>Totals</strong>
                                </td>
                                {Array.from(this.props.comData).map(
                                  (psr, key) => {
                                    return (
                                      <Fragment key={key}>
                                        <td
                                          width="13%"
                                          className="psr-cell-center-no-border"
                                        >
                                          <strong>
                                            $
                                            {formatValue(
                                              this.props.comData[key][0]
                                                .totalPerYear
                                            )}
                                          </strong>
                                        </td>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </section>
                  </AccordionBody>
                </div>
              </Accordion>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default ReviewPSRCommissions;

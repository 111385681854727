import ReviewDRPHeader from "./ReviewDRPHeader";
import "./ReviewCCBDRP.scss";
import { CCB_SUB_SECTION_TITLE } from "./CCB";
import ReviewBondDRP from "./ReviewDRPTypes/ReviewBondDRP";
import ReviewJudgmentLienDRP from "./ReviewDRPTypes/ReviewJudgmentLienDRP";
import ReviewTerminationDRP from "./ReviewDRPTypes/ReviewTerminationDRP";
import ReviewCustComplaintDRP from "./ReviewDRPTypes/ReviewCustComplaintDRP";
import ReviewRegulatoryActionDRP from "./ReviewDRPTypes/ReviewRegulatoryActionDRP";
import ReviewInvestigationDRP from "./ReviewDRPTypes/ReviewInvestigationDRP";
import ReviewCriminalDRP from "./ReviewDRPTypes/ReviewCriminalDRP";
import ReviewBankruptcyDRP from "./ReviewDRPTypes/ReviewBankruptcyDRP";
import ReviewCivilJudicialDRP from "./ReviewDRPTypes/ReviewCivilJudicialDRP";

const ReviewCCBDRP = ({ drp, toggleDRPReview, source }) => {
  const { drpType } = drp;

  const handleBack = () => {
    toggleDRPReview(false);
  };

  const renderDRPReviewForm = () => {
    switch (drpType) {
      case CCB_SUB_SECTION_TITLE.bond:
        return <ReviewBondDRP drp={drp}></ReviewBondDRP>;
      case CCB_SUB_SECTION_TITLE.judgmentLien:
        return <ReviewJudgmentLienDRP drp={drp}></ReviewJudgmentLienDRP>;
      case CCB_SUB_SECTION_TITLE.termination:
        return <ReviewTerminationDRP drp={drp}></ReviewTerminationDRP>;
      case CCB_SUB_SECTION_TITLE.customerComplaint:
        return <ReviewCustComplaintDRP drp={drp}></ReviewCustComplaintDRP>;
      case CCB_SUB_SECTION_TITLE.investigation:
        return <ReviewInvestigationDRP drp={drp}></ReviewInvestigationDRP>;
      case CCB_SUB_SECTION_TITLE.individualBankruptcy:
      case CCB_SUB_SECTION_TITLE.organizationalBankruptcy:
        return <ReviewBankruptcyDRP drp={drp}></ReviewBankruptcyDRP>;
      case CCB_SUB_SECTION_TITLE.individual:
      case CCB_SUB_SECTION_TITLE.organization:
        return <ReviewCriminalDRP drp={drp}></ReviewCriminalDRP>;
      case CCB_SUB_SECTION_TITLE.regulatoryActionSRO:
      case CCB_SUB_SECTION_TITLE.regulatoryActionSECorCFTC:
      case CCB_SUB_SECTION_TITLE.regulatoryActionOtherRegulators:
      case CCB_SUB_SECTION_TITLE.formalPendingActionInvestigation:
      case CCB_SUB_SECTION_TITLE.professionalSuspension:
      case CCB_SUB_SECTION_TITLE.regulatoryAction:
        return (
          <ReviewRegulatoryActionDRP drp={drp}></ReviewRegulatoryActionDRP>
        );
      case CCB_SUB_SECTION_TITLE.civilJudicial:
        return <ReviewCivilJudicialDRP drp={drp}></ReviewCivilJudicialDRP>;
      default:
        break;
    }
  };

  return (
    <>
      <ReviewDRPHeader
        handleBack={handleBack}
        section={drpType}
        source={source}
      ></ReviewDRPHeader>
      <br />
      {renderDRPReviewForm()}
    </>
  );
};
export default ReviewCCBDRP;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import Moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { isValidDate } from "../../../../../../common/Functions";
import { logoutActions } from "../../../../../../store/actions";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import DRPCardNoButton from "../../common/DRPCard/DRPCardNoButton";
import CheckboxList from "../../common/DRPCheckboxList";
import DRPSummaryListingCardCheck from "../../common/DRPSummaryListingCard";
import { KEYS } from "../../constants";
import { getDRPSummaryQuestions, getSpecificObjByKeys } from "../../helpers";
import { DRPSummaryTerminationDisclosure } from "../state";

const TerminationDisclosureAddDRPForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  tempDrp,
  updateTempDRP,
  hasCurrentFormChanged,
  currentFormChanged,
}) => {
  const reducer = (state, action) => {
    let filteredState = "";
    switch (action.type) {
      case "loadTempData":
        hasCurrentFormChanged(true);
        return {
          ...state,
          drpList: tempDrp.drpList,
          firmName: tempDrp.firmName,
          termType: tempDrp.termType,
          termDate: tempDrp.termDate,
          termDateRadio: tempDrp.termDateRadio,
          termDateExplanation: tempDrp.termDateExplanation,
          allegations: tempDrp.allegations,
          otherText: tempDrp.otherText,
          productTypes: tempDrp.productTypes,
        };
      case "updateShowBackModal":
        //returns entire state aside from that specific value
        return { ...state, showBackModal: action.value };
      case "updateIsFormComplete":
        return { ...state, isFormComplete: action.value };
      case "onChange":
        return { ...state, [action.id]: action.value };
      case "updateFirmNameError":
        return { ...state, firmNameError: action.value };
      case "updateTermDateError":
        return { ...state, termDateError: action.value };
      case "updateAllegationsError":
        return { ...state, allegationsError: action.value };
      case "updateExplanationError":
        return { ...state, explanationError: action.value };
      case "updateOtherTextError":
        return { ...state, otherTextError: action.value };
      case "addToList":
        if (action.id === "productTypes") {
          return {
            ...state,
            productTypes: [...state.productTypes, action.value],
          };
        } else if (action.id === "drpList") {
          return {
            ...state,
            drpList: [...state.drpList, action.value],
          };
        } else return state;
      case "removeFromList":
        if (action.id === "productTypes") {
          filteredState = {
            ...state,
            productTypes: [...state.productTypes].filter(
              (x) => x !== action.value
            ),
          };
          return filteredState;
        } else if (action.id === "drpList") {
          filteredState = {
            ...state,
            drpList: [...state.drpList].filter((x) => x !== action.value),
          };
          return filteredState;
        } else return state;
      default:
        return state;
    }
  };
  const initialState = {
    showBackModal: false,
    isFormComplete: false,
    firmName: "",
    firmNameError: false,
    termType: "",
    termDate: "",
    termDateError: false,
    termDateRadio: "",
    termDateExplanation: "",
    explanationError: false,
    allegations: "",
    allegationsError: false,
    otherText: "",
    otherTextError: false,
    productTypes: [],
    drpList: [],
    firstCheck: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const logoutDispatch = useDispatch();
  const { data } = useSelector((state) => state.ccb14);
  const [isFocusedState, setFocusedState] = useState(false);

  const terminationDisclosureObj = getSpecificObjByKeys(
    data,
    KEYS.terminationDisclosures
  );

  const termDiscl = getDRPSummaryQuestions(
    DRPSummaryTerminationDisclosure,
    terminationDisclosureObj
  );

  useEffect(() => {
    if (state.otherTextError && state.firstCheck) {
      document.getElementById("othertextboxError").scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      dispatch({
        type: "onChange",
        value: false,
        id: "firstCheck",
      });
    }

    if (
      termDiscl.length &&
      termDiscl.length === 1 &&
      state.drpList.length === 0
    ) {
      let x = termDiscl.map((v) => v.key);
      dispatch({
        type: "addToList",
        value: x[0],
        id: "drpList",
      });
    }

    if (allFilled() && noErrors()) {
      dispatch({ type: "updateIsFormComplete", value: true });
    } else {
      dispatch({ type: "updateIsFormComplete", value: false });
    }

    if (currentFormChanged || tempDrp !== "") {
      logoutDispatch(
        logoutActions.setShowLogoutModal({ showLogoutModal: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.firmName,
    state.termType,
    state.termDate,
    state.termDateRadio,
    state.termDateExplanation,
    state.allegations,
    state.productTypes,
    state.drpList,
    state.firmNameError,
    state.termDateError,
    state.explanationError,
    state.allegationsError,
    state.otherText,
    state.otherTextError,
    state.firstCheck,
    currentFormChanged,
  ]);

  useEffect(() => {
    hasCurrentFormChanged(false);
    logoutDispatch(
      logoutActions.setShowLogoutModal({ showLogoutModal: false })
    );
    window.scrollTo(0, 0);
    if (tempDrp !== "") {
      dispatch({ type: "loadTempData" });
      if (tempDrp.otherText !== "") {
        handleDiv("othertext", "block");
      }
      if (tempDrp.termDateExplanation !== "") {
        handleDiv("dvtext", "block");
      }
    }
  }, []);

  const allFilled = () => {
    if (state.productTypes.includes("Other") && state.otherText.length < 2) {
      return false;
    } else
      return (
        state.firmName.length > 0 &&
        state.termType !== "" &&
        !state.termDate.includes("_") &&
        state.termDate !== "" &&
        (state.termDateRadio === "Exact Date" ||
          (state.termDateRadio === "Explanation" &&
            state.termDateExplanation.trim().length >= 2)) &&
        state.allegations.trim().length >= 2 &&
        state.productTypes.length > 0 &&
        state.drpList.length > 0
      );
  };

  const noErrors = () => {
    if (state.productTypes.includes("Other") && state.otherTextError === true) {
      return false;
    } else
      return (
        state.firmNameError === false &&
        state.termDateError === false &&
        state.allegationsError === false &&
        (state.termDateRadio === "Exact Date" ||
          (state.termDateRadio === "Explanation" &&
            state.explanationError === false))
      );
  };

  const handleChange = (event) => {
    if (event.target.id.includes("drpCheckbox")) {
      if (isChecked(document.getElementById(event.target.id))) {
        dispatch({
          type: "addToList",
          value: event.target.value,
          id: "drpList",
        });
      } else {
        dispatch({
          type: "removeFromList",
          value: event.target.value,
          id: "drpList",
        });
      }
    }

    if (event.target.id.includes("product")) {
      if (isChecked(document.getElementById(event.target.id))) {
        dispatch({
          type: "addToList",
          value: event.target.value,
          id: "productTypes",
        });
      } else {
        dispatch({
          type: "removeFromList",
          value: event.target.value,
          id: "productTypes",
        });
      }
    }

    if (event.target.id === "firmName") {
      dispatch({ type: "onChange", value: event.target.value, id: "firmName" });
    }

    if (event.target.id.includes("termType")) {
      dispatch({ type: "onChange", value: event.target.value, id: "termType" });
    }

    if (event.target.id === "termDate") {
      dispatch({ type: "onChange", value: event.target.value, id: "termDate" });
    }

    if (event.target.id.includes("termDateRadio")) {
      dispatch({
        type: "onChange",
        value: event.target.value,
        id: "termDateRadio",
      });
    }
    if (event.target.id.includes("termDateExplanation")) {
      dispatch({
        type: "onChange",
        value: event.target.value,
        id: "termDateExplanation",
      });
    }
    if (event.target.id === "allegations") {
      dispatch({
        type: "onChange",
        value: event.target.value,
        id: "allegations",
      });
    }
    if (event.target.id === "othertextbox") {
      dispatch({
        type: "onChange",
        value: event.target.value,
        id: "otherText",
      });
    }

    hasCurrentFormChanged(true);
  };

  const onBlur = (event) => {
    if (event.target.id === "firmName") {
      if (event.target.value.trim().length === 0) {
        dispatch({ type: "updateFirmNameError", value: true });
      } else {
        dispatch({ type: "updateFirmNameError", value: false });
      }
    } else if (event.target.id === "termDate") {
      let obj_error = document.getElementById("termDateError");

      let currDate = Moment(); //today
      let dateCalc3 = Moment(event.target.value); //entered date
      let yearDiff3 = currDate.diff(dateCalc3, "year"); //past

      if (
        event.target.value.includes("_") ||
        event.target.value.trim().length < 10 ||
        !isValidDate(event.target.value) ||
        event.target.value.includes("0000")
      ) {
        obj_error.innerText = "Please enter Termination Date";
        dispatch({ type: "updateTermDateError", value: true });
      } else if (yearDiff3 > 100 || event.target.value.includes("/00")) {
        obj_error.innerText = "Date cannot be more than 100 years ago";
        dispatch({ type: "updateTermDateError", value: true });
      } else if (Moment(event.target.value) > currDate) {
        obj_error.innerText = "Date cannot be a future date";
        dispatch({ type: "updateTermDateError", value: true });
      } else {
        dispatch({ type: "updateTermDateError", value: false });
      }
    } else if (event.target.id === "allegations") {
      if (event.target.value.trim().length < 2) {
        dispatch({ type: "updateAllegationsError", value: true });
      } else {
        dispatch({ type: "updateAllegationsError", value: false });
      }
    } else if (event.target.id.includes("termDateExplanation")) {
      if (event.target.value.trim().length < 2) {
        dispatch({ type: "updateExplanationError", value: true });
      } else {
        dispatch({ type: "updateExplanationError", value: false });
      }
    } else if (event.target.id.includes("termDateExplanation")) {
      if (event.target.value.trim().length < 2) {
        dispatch({ type: "updateExplanationError", value: true });
      } else {
        dispatch({ type: "updateExplanationError", value: false });
      }
    } else if (event.target.id === "othertextbox") {
      if (event.target.value.trim().length < 2) {
        dispatch({ type: "updateOtherTextError", value: true });
        dispatch({
          type: "onChange",
          value: true,
          id: "firstCheck",
        });
      } else {
        dispatch({ type: "updateOtherTextError", value: false });
      }
    }
    hasCurrentFormChanged(true);
    setFocusedState(false);
  };

  const handleNext = () => {
    let newTempDRP = {
      allegations: state.allegations,
      drpList: state.drpList,
      firmName: state.firmName,
      firstCheck: state.firstCheck,
      otherText: state.otherText,
      productTypes: state.productTypes,
      termDate: state.termDate,
      termDateExplanation: state.termDateExplanation,
      termDateRadio: state.termDateRadio,
      termType: state.termType,
    };
    if (tempDrp !== "") {
      newTempDRP["terminationComments"] = tempDrp.terminationComments;
    }

    updateTempDRP(newTempDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };

  const handleBackClick = async () => {
    handleCloseBackModal();
    updateCCBForm(backFormId);
    hasCurrentFormChanged(false);
  };

  const handleShowBackModal = () => {
    if (currentFormChanged === true || tempDrp !== "") {
      dispatch({ type: "updateShowBackModal", value: true });
    } else {
      handleBackClick();
    }
  };
  const handleCloseBackModal = () =>
    dispatch({ type: "updateShowBackModal", value: false });
  const isChecked = (value) => value.checked;
  const handleDiv = (id, value) =>
    (document.getElementById(id).style.display = value);

  const handleShowDiv = () => {
    if (isChecked(document.getElementById("termDateRadioExplanation"))) {
      handleDiv("dvtext", "block");
    } else {
      handleDiv("dvtext", "none");
      clearOptionalData("termDateExplanation");
    }
  };

  const handleShowOtherDiv = () => {
    if (isChecked(document.getElementById("productOther"))) {
      handleDiv("othertext", "block");
      document.getElementById("othertext").scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
      handleDiv("othertext", "none");
      clearOptionalData("othertextbox");
    }
  };

  const clearOptionalData = (id) => {
    if (id === "othertextbox") {
      dispatch({
        type: "onChange",
        value: "",
        id: "otherText",
      });
      dispatch({
        type: "onChange",
        value: false,
        id: "otherTextError",
      });
    } else if (id === "termDateExplanation") {
      dispatch({
        type: "onChange",
        value: "",
        id: "termDateExplanation",
      });
      dispatch({
        type: "onChange",
        value: false,
        id: "explanationError",
      });
    }
  };

  return (
    <>
      <div className="termination-disclosures-wrapper">
        <div className="row">
          <div className="col-md-12">
            <span id="termAddDrpText">
              One event may result in more than one affirmative answer to the
              below items. Use only one DRP to report details related to the
              same termination. Use a separate DRP for each termination
              reported.
            </span>
          </div>
        </div>
        <div
          className="term-disclosure-drp-summary"
          style={{ paddingTop: "20px" }}
        >
          {termDiscl.length && termDiscl.length > 1 ? (
            <DRPCardNoButton
              header={"Check the question(s) you are responding to:"}
              isDrp
            >
              {termDiscl.map(({ key, mainQuestion, question }, index) => (
                <DRPSummaryListingCardCheck
                  key={`${index}_${key}`}
                  questionKey={key}
                  mainQuestion={mainQuestion}
                  question={question}
                  index={index}
                  length={termDiscl.length}
                  handleChange={handleChange}
                  isCheckbox
                  drpCheckList={state.drpList}
                />
              ))}
            </DRPCardNoButton>
          ) : null}
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col-md-8">
            <label>
              <strong id="termDiscFirmName">Firm Name: *</strong>
            </label>
            <input
              type="text"
              id="firmName"
              className={
                state.firmNameError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={320}
              //minLength={3}
              value={state.firmName}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={() => setFocusedState(true)}
              autoComplete="off"
            />
            <div id="firmNameError" className="invalid-feedback">
              Please enter Firm Name
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label style={{ paddingTop: "20px" }}>
              <strong id="termDiscTermType">Termination Type: *</strong>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="custom-control">
            <div
              className="col-12 copy-md-8 form-group"
              style={{ paddingTop: "10px" }}
            >
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide"
                  type="radio"
                  id="termTypeDischarged"
                  className="custom-control-input"
                  checked={state.termType === "Discharged"}
                  value="Discharged"
                  onChange={handleChange}
                />
                <label
                  htmlFor="termTypeDischarged"
                  className="custom-control-label"
                  id="label_discharged"
                >
                  <strong id="termDisclDischarged">Discharged</strong>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide"
                  type="radio"
                  id="termTypePermittedToResign"
                  className="custom-control-input"
                  checked={state.termType === "Permitted to Resign"}
                  value="Permitted to Resign"
                  onChange={handleChange}
                />
                <label
                  htmlFor="termTypePermittedToResign"
                  className="custom-control-label"
                  id="label_permittedToResign"
                >
                  <strong id="termDisclPermitResign">
                    Permitted to Resign
                  </strong>
                </label>
              </div>
              <div>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide"
                    type="radio"
                    id="termTypeVoluntary"
                    className="custom-control-input"
                    checked={state.termType === "Voluntary Resignation"}
                    value="Voluntary Resignation"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="termTypeVoluntary"
                    className="custom-control-label"
                    id="label_voluntary"
                  >
                    <strong id="termDisclVoluntaryResign">
                      Voluntary Resignation
                    </strong>
                  </label>
                </div>
              </div>
              <div id="radio_error" className="invalid-feedback">
                Please choose a response.
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label id="termDateText" htmlFor="termDate">
              <strong id="termDisclTermDate">Termination Date: *</strong>
            </label>
            <InputMask
              id="termDate"
              mask="99/99/9999"
              maskChar="_"
              className={
                state.termDateError ? "form-control is-invalid" : "form-control"
              }
              placeholder="MM/DD/YYYY"
              required
              value={state.termDate}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={() => setFocusedState(true)}
              autoComplete="off"
            />
            <div id="termDateError" className="invalid-feedback"></div>
          </div>
        </div>
        <div className="row">
          <div className="custom-control">
            <div
              className="col-12 copy-md-8 form-group"
              style={{ paddingTop: "10px" }}
            >
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-2"
                  type="radio"
                  id="termDateRadioExactDate"
                  className="custom-control-input"
                  value="Exact Date"
                  checked={state.termDateRadio === "Exact Date"}
                  onClick={handleShowDiv}
                  onChange={handleChange}
                />
                <label
                  htmlFor="termDateRadioExactDate"
                  className="custom-control-label"
                  id="label_exactDate"
                >
                  <strong id="termDisclExactDate">Exact Date</strong>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-2"
                  type="radio"
                  id="termDateRadioExplanation"
                  className="custom-control-input"
                  checked={state.termDateRadio === "Explanation"}
                  value="Explanation"
                  onClick={handleShowDiv}
                  onChange={handleChange}
                />
                <label
                  htmlFor="termDateRadioExplanation"
                  className="custom-control-label"
                  id="label_explanation"
                >
                  <strong id="termDisclExplanation">Explanation</strong>
                </label>
              </div>
              <div id="dvtext" style={{ display: "none" }}>
                <textarea
                  id="termDateExplanation"
                  onChange={handleChange}
                  className={
                    state.explanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  onBlur={onBlur}
                  onFocus={() => setFocusedState(true)}
                  value={state.termDateExplanation}
                  rows="3"
                  maxLength={500}
                  minLength={2}
                ></textarea>
                <div id="explanationError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
              <div id="radio_error" className="invalid-feedback">
                Please choose a response.
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label id="allegationsText" htmlFor="allegations">
              <strong id="termDisclAllegations">Allegation(s): *</strong>
            </label>
          </div>
        </div>
        <textarea
          onChange={handleChange}
          id="allegations"
          className={
            state.allegationsError ? "form-control is-invalid" : "form-control"
          }
          rows="3"
          onBlur={onBlur}
          onFocus={() => setFocusedState(true)}
          maxLength={500}
          minLength={2}
          value={state.allegations}
          autoComplete="off"
        ></textarea>
        <div id="allegationsError" className="invalid-feedback">
          Please enter the Allegation(s)
        </div>
        <div className="row">
          <div className="col-md-12">
            <label style={{ paddingTop: "20px" }}>
              <strong id="termDisclProductTypes">
                Product Type(s) (Select all that apply): *
              </strong>
            </label>
          </div>
        </div>
        <CheckboxList
          handleChange={handleChange}
          handleShowOtherDiv={handleShowOtherDiv}
          onBlur={onBlur}
          otherText={state.otherText}
          otherTextError={state.otherTextError}
          productTypes={state.productTypes}
          onFocus={() => setFocusedState(true)}
        />

        <div className="row">
          <div
            className="col-md-12 buttons"
            style={{ textAlign: "right", paddingTop: "30px" }}
          >
            <span
              id="termAddDRPBack"
              className="back-button"
              onClick={handleShowBackModal}
            >
              <strong id="termDisclBack">Back</strong>
            </span>
            <Button
              disabled={!state.isFormComplete || isFocusedState}
              id="termAddDRPNext"
              style={{ marginRight: "10px" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
        <BackButtonModal
          isOpen={state.showBackModal}
          closeBackModal={handleCloseBackModal}
          backClick={handleBackClick}
        />
      </div>
    </>
  );
};
export default TerminationDisclosureAddDRPForm;

import React from "react";
import DRPSectionDivider from "../common/DRPSectionDivider";
import { isNotNullUndefinedOrEmpty } from "../../../../../../../common/Functions";

const ReviewTerminationDRP = ({
  drp: {
    allegations,
    drpList,
    firmName,
    firstCheck,
    otherText,
    productTypes,
    termDate,
    termDateExplanation,
    termDateRadio,
    termType,
    terminationComments,
  },
}) => {
  return (
    <div id="ReviewTerminationDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">
          {drpList
            .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
            .join(", ")}
        </div>
      </div>
      <DRPSectionDivider section={"DETAILS"} />
      <div className="row">
        <div className="col-6">
          <strong>Firm Name: *</strong>
        </div>
        <div className="col-6">{firmName}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Termination Type: *</strong>
        </div>
        <div className="col-6">{termType}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Termination Date: *</strong>
        </div>
        <div className="col-6">
          {termDate}
          <br />
          {`${termDateRadio}${
            termDateRadio === "Explanation" ? `: ${termDateExplanation}` : ""
          }`}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Allegation(s): *</strong>
        </div>
        <div className="col-6">{allegations}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <strong>Product Type(s) (Select all that apply): *</strong>
        </div>
        <div className="col-6">
          {productTypes.map((product, key) => (
            <React.Fragment key={key}>
              <span>{`${product}${
                product === "Other" ? `: ${otherText}` : ""
              }`}</span>
              {key !== productTypes.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <DRPSectionDivider section={"COMMENTS"} />
      <div className="row">
        <div className="col-6">
          <strong>
            You may use this field to provide a brief summary of the
            circumstances leading to the termination. (Optional)
          </strong>
        </div>
        <div className="col-6">
          {isNotNullUndefinedOrEmpty(terminationComments)
            ? terminationComments
            : "-"}
        </div>
      </div>
    </div>
  );
};
export default ReviewTerminationDRP;

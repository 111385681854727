import { CCB_SUB_SECTION_TITLE } from "../../CCB";
import DRPSectionDivider from "../common/DRPSectionDivider";

const ReviewCriminalDRP = ({
  drp: {
    comments,
    courtDetails,
    drpType,
    eventDispDetails,
    eventStatus,
    orgDetails,
  },
}) => {
  let drpCheckListString;
  switch (drpType) {
    case CCB_SUB_SECTION_TITLE.individual:
      drpCheckListString = courtDetails?.drpCheckList
        .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
        .join(", ");
      break;
    case CCB_SUB_SECTION_TITLE.organization:
      drpCheckListString = orgDetails?.drpCheckList
        .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
        .join(", ");
      break;
    default:
      break;
  }
  const drpCheckListArray = drpCheckListString
    .split(",")
    .map((item) => item.trim());
  const sortedChecklist = drpCheckListArray.sort((a, b) => {
    const aParts = a.match(/(\d+)(\w)?\((\d+)\)\((\w+)\)/);
    const bParts = b.match(/(\d+)(\w)?\((\d+)\)\((\w+)\)/);
    if (aParts && bParts) {
      //Sort by the first number
      if (aParts[1] !== bParts[1]) {
        return aParts[1] - bParts[1];
      }
      //If there are letters after the first number, sorting them
      if (aParts[2] !== bParts[2]) {
        return aParts[2].localeCompare(bParts[2]);
      }
      //Sort by the number inside the first parentheses
      if (aParts[3] !== bParts[3]) {
        return aParts[3] - bParts[3];
      }

      //Sort by the letter inside the second parentheses
      return aParts[4].localeCompare(bParts[4]);
    }
    return 0; //If regex matching fails, don't change the order
  });
  const sortedChecklistString = sortedChecklist
    .sort((a, b) => a.localeCompare(b, "en", { numeric: true }))
    ?.join(", ");
  return (
    <div id="ReviewCriminalDRP">
      <div className="row">
        <div className="col-6">
          <strong>Check the question(s) you are responding to:</strong>
        </div>
        <div className="col-6">{sortedChecklistString?.toString()}</div>
      </div>
      {drpType === CCB_SUB_SECTION_TITLE.organization && (
        <div id="organizationDetails">
          <DRPSectionDivider section={"ORGANIZATION Details"} />
          <div className="row">
            <div className="col-6">
              <strong>
                If charge(s) were brought against an organization over which you
                exercise(d) control:
              </strong>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Organization Name*</strong>
            </div>
            <div className="col-6">{orgDetails?.orgName}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Investment related business? *</strong>
            </div>
            <div className="col-6">
              {orgDetails?.invRelBusiness ? "Yes" : "No"}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <strong>Position, Title, or Relationship *</strong>
            </div>
            <div className="col-6">{orgDetails?.posTitleRel}</div>
          </div>
        </div>
      )}
      <div id="courtDetails">
        <DRPSectionDivider section={"Court DETAILS"} />
        <div className="row">
          <div className="col-6">
            <strong>Court action brought in: *</strong>
          </div>
          <div className="col-6">
            {courtDetails?.courtAction}
            {courtDetails?.courtAction === "Other"
              ? `: ${courtDetails?.courtActionOther}`
              : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Name of court: *</strong>
          </div>
          <div className="col-6">{courtDetails?.courtName}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>
              Location of court (City or County and State or Country): *
            </strong>
          </div>
          <div className="col-6">{courtDetails?.courtLocation}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Docket/Case #: *</strong>
          </div>
          <div className="col-6">{courtDetails?.docketOrCase}</div>
        </div>
      </div>
      <div id="eventStatus">
        <DRPSectionDivider section={"Event Status"} />
        <div className="row">
          <div className="col-6">
            <strong>Current Status of the Event: *</strong>
          </div>
          <div className="col-6">{eventStatus?.status}</div>
        </div>
        <br />
        {eventStatus?.status !== "Pending" && (
          <div className="row">
            <div className="col-6">
              <strong>
                Event Status Date (complete unless status is pending) *
              </strong>
            </div>
            <div className="col-6">
              <>
                {eventStatus?.eventStatusDate}
                <br />
                {eventStatus?.eventStatusDateRadio}
                {eventStatus?.eventStatusDateRadio === "Explanation"
                  ? `: ${eventStatus?.eventStatusDateExp}`
                  : ""}
              </>
            </div>
          </div>
        )}
      </div>
      <div id="eventDispositionDetails">
        <DRPSectionDivider section={"EVENT & DISPOSITION DETAILS"} />
        {eventDispDetails?.newChargeDetailsList.map((charge, key) => {
          return (
            <>
              <div id={`chargeDetail${key}`}>
                <div className="row">
                  <div className="col-6">
                    <strong>Charge Details *</strong>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Formal Charge/Description *</strong>
                  </div>
                  <div className="col-6">{charge?.formalChargeDescription}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Number of Counts *</strong>
                  </div>
                  <div className="col-6">{charge?.nrOfCounts}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Felony or Misdemeanor ?*</strong>
                  </div>
                  <div className="col-6">{charge?.felonyOrMisdemeanor}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <strong>Plea for each charge *</strong>
                  </div>
                  <div className="col-6">{charge?.pleaEachCharge}</div>
                </div>
                <br />
                <div className="row mm-spacing--minor">
                  <div className="col-6">
                    <strong>Disposition of charge *</strong>
                  </div>
                  <div className="col-6">
                    {charge?.selectedDispOfCharge}
                    {charge?.selectedDispOfCharge === "Other" && (
                      <>{`: ${charge?.otherExp}`}</>
                    )}
                    {charge?.selectedDispOfCharge ===
                      "Deferred Adjudication" && (
                      <>{`: ${charge?.deferedExp}`}</>
                    )}
                  </div>
                </div>
                {charge?.selectedDispOfCharge === "Amended" && (
                  <>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Date of Amended Charge *</strong>
                      </div>
                      <div className="col-6">{charge?.dateAmendedCharge}</div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          If original charge was amended, specify new charge: *
                        </strong>
                      </div>
                      <div className="col-6">
                        {charge?.amendedChargeDescription}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Number of Counts (for amended charge) *</strong>
                      </div>
                      <div className="col-6">{charge?.nrOfAmendedCounts}</div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Specify if amended charge is a Felony or Misdemeanor?
                          *
                        </strong>
                      </div>
                      <div className="col-6">
                        {charge?.felonyOrMisdemeanorAmended}
                        {charge?.felonyOrMisdemeanorAmended === "Other" && (
                          <>{`: ${charge?.felonyOrMisdemeanorAmendedOther}`}</>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Plea for each amended charge *</strong>
                      </div>
                      <div className="col-6">
                        {charge?.pleaEachAmendedCharge}
                      </div>
                    </div>
                    <br />
                    <div className="row mm-spacing--minor">
                      <div className="col-6">
                        <strong>Disposition of amended charge *</strong>
                      </div>
                      <div className="col-6">
                        {charge?.selectedDispOfAmendedCharge}
                        {charge?.selectedDispOfAmendedCharge === "Other" && (
                          <>{`: ${charge?.otherAmendedExp}`}</>
                        )}
                        {charge?.selectedDispOfAmendedCharge ===
                          "Deferred Adjudication" && (
                          <>{`: ${charge?.otherAmendedExp}`}</>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {charge?.selectedDispOfCharge === "Reduced" && (
                  <>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Date of Reduced Charge *</strong>
                      </div>
                      <div className="col-6">{charge?.dateReducedCharge}</div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          If original charge was reduced, specify new charge: *
                        </strong>
                      </div>
                      <div className="col-6">
                        {charge?.reducedChargeDescription}
                      </div>
                    </div>
                    <br />

                    <div className="row">
                      <div className="col-6">
                        <strong>Number of Counts (for reduced charge) *</strong>
                      </div>
                      <div className="col-6">{charge?.nrOfReducedCounts}</div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Specify if reduced charge is a Felony or Misdemeanor?
                          *
                        </strong>
                      </div>
                      <div className="col-6">
                        {charge?.felonyOrMisdemeanorReduced}
                        {charge?.felonyOrMisdemeanorReduced === "Other" && (
                          <>{`: ${charge?.felonyOrMisdemeanorReducedOther}`}</>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <strong>Plea for each reduced charge *</strong>
                      </div>
                      <div className="col-6">
                        {charge?.pleaEachReducedCharge}
                      </div>
                    </div>
                    <br />
                    <div className="row mm-spacing--minor">
                      <div className="col-6">
                        <strong>Disposition of reduced charge *</strong>
                      </div>
                      <div className="col-6">
                        {charge?.selectedDispOfReducedCharge}
                        {charge?.selectedDispOfReducedCharge === "Other" && (
                          <>{`: ${charge?.otherReducedExp}`}</>
                        )}
                        {charge?.selectedDispOfReducedCharge ===
                          "Deferred Adjudication" && (
                          <>{`: ${charge?.otherReducedExp}`}</>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}

        <br />
        <div className="row">
          <div className="col-6">
            <strong>Date First Charged: *</strong>
          </div>
          <div className="col-6">
            {eventDispDetails?.dateFirstCharged}
            <br />
            {eventDispDetails?.dateFirstChargedRadio}
            {eventDispDetails?.dateFirstChargedRadio === "Explanation"
              ? `: ${eventDispDetails?.dateFirstChargedExp}`
              : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Date of Disposition: *</strong>
          </div>
          <div className="col-6">
            {eventDispDetails?.dateOfDisp}
            <br />
            {eventDispDetails?.dateOfDispRadio}
            {eventDispDetails?.dateOfDispRadio === "Explanation"
              ? `: ${eventDispDetails?.dateOfDispExp}`
              : ""}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Duration (If Suspension, Probation, Etc)</strong>
          </div>
          <div className="col-6">{}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Start Date of Penalty: *</strong>
          </div>
          <div className="col-6">{eventDispDetails?.startDatePenalty}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>End Date of Penalty: *</strong>
          </div>
          <div className="col-6">{eventDispDetails?.endDatePenalty}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <strong>Were you charged a monetary penalty/fine? *</strong>
          </div>
          <div className="col-6">
            {eventDispDetails?.monetaryPenalty ? "Yes" : "No"}
          </div>
        </div>
        {eventDispDetails?.monetaryPenalty && (
          <>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Amount Paid: *</strong>
              </div>
              <div className="col-6">${eventDispDetails?.amountPaid}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <strong>Date monetary/penalty fine paid: *</strong>
              </div>
              <div className="col-6">
                {" "}
                {eventDispDetails?.dateMonetaryPenaltyPaid}
                <br />
                {eventDispDetails?.dateMonetaryPenaltyPaidRadio}
                {eventDispDetails?.dateMonetaryPenaltyPaidRadio ===
                "Explanation"
                  ? `: ${eventDispDetails?.dateMonetaryPenaltyPaidExp}`
                  : ""}
              </div>
            </div>
          </>
        )}
      </div>
      <div id="comments">
        <DRPSectionDivider section={"COMMENTS"} />
        <div className="row">
          <div className="col-6">
            <strong>
              You may use this field to provide a brief summary of the
              circumstances leading to the investigation as well as the current
              status or final disposition and/or finding(s). (Optional)
            </strong>
          </div>
          <div className="col-6">{comments || "-"}</div>
        </div>
      </div>
    </div>
  );
};
export default ReviewCriminalDRP;

import { CCB_SUB_SECTION_TITLE } from "../CCB";

const ReviewDRPHeader = ({
  handleBack = () => {
    console.log("default back");
  },
  section = "",
  source,
}) => {
  const renderHeader = (header) => {
    if (
      header === "Individual Criminal" ||
      header === "Organization Criminal"
    ) {
      if (header.includes("Individual")) {
        return "Individual Criminal";
      }
      if (header.includes("Organization")) {
        return "Organization Criminal";
      }
    }
    if (
      header.includes("Regulatory Action") ||
      header.includes("Professional Suspension") ||
      header.includes("Formal Pending Action")
    ) {
      return "Regulatory Action";
    }
    // if (header.includes("Organization") || header.includes("Personal")) {
    //   if (
    //     header !== "Personal Bankruptcy" &&
    //     header !== "Organizational Bankruptcy"
    //   ) {
    //     return "Criminal";
    //   }
    // }
    if (
      header === "Personal Bankruptcy" ||
      header === "Organizational Bankruptcy"
    ) {
      if (header === "Organizational Bankruptcy") {
        return "Organization Bankruptcy";
      }
      return header;
    }
    return header;
  };

  const renderSubSectionTitle = (subSection) => {
    switch (subSection) {
      case CCB_SUB_SECTION_TITLE.individual:
      case CCB_SUB_SECTION_TITLE.organization:
        return `Criminal Disclosure - ${subSection}`;
      case CCB_SUB_SECTION_TITLE.regulatoryActionSRO:
      case CCB_SUB_SECTION_TITLE.regulatoryActionSECorCFTC:
      case CCB_SUB_SECTION_TITLE.regulatoryActionOtherRegulators:
      case CCB_SUB_SECTION_TITLE.professionalSuspension:
      case CCB_SUB_SECTION_TITLE.formalPendingActionInvestigation:
      case CCB_SUB_SECTION_TITLE.investigation:
        return `Regulatory Action Disclosure - ${subSection}`;
      case CCB_SUB_SECTION_TITLE.bond:
      case CCB_SUB_SECTION_TITLE.individualBankruptcy:
      case CCB_SUB_SECTION_TITLE.organizationalBankruptcy:
      case CCB_SUB_SECTION_TITLE.judgmentLien:
        return `Financial Disclosure - ${subSection}`;
      default:
        return `${subSection} Disclosure`;
    }
  };

  return (
    <div className="drp-layout-wrapper">
      {source === "profile" && (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="drp-layout-return">
                <span role="presentation" onClick={handleBack}>
                  <span className="icon-xs icon-caret-spin-left"></span>
                  <strong id="drp-return-disclosure">
                    Return To Disclosure
                  </strong>
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">CHARACTER, CREDIT, AND BONDING</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>
                <h1 id="drp-header">Disclosure Reporting Page (DRP)</h1>
              </label>
            </div>
          </div>
        </>
      )}
      {source === "confidential" && <hr></hr>}
      <div className="row">
        <div className="col-md-12 section-details-container">
          <div className="drp-layout-section">
            <strong id="drp-section">
              {source === "confidential" ? (
                <>{`${renderHeader(section)} DRP`}</>
              ) : (
                <>{`${renderSubSectionTitle(section)}`}</>
              )}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDRPHeader;

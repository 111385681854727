/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  clickFunc,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";

const COUNTS_ERR_MSG = "Please enter a valid number";

const initialValues = {
  selectedDispOfCharge: null,
  felonyOrMisdemeanor: null,
  pleaEachCharge: null,
  pleaEachAmendedCharge: null,
  pleaEachReducedCharge: null,
  nrOfCounts: null,
  formalChargeDescription: null,
  selectedDispOfAmendedCharge: null,
  selectedDispOfReducedCharge: null,
  nrOfAmendedCounts: null,
  nrOfReducedCounts: null,
  otherAmendedExp: null,
  otherReducedExp: null,
  dateAmendedCharge: null,
  dateReducedCharge: null,
  amendedChargeDescription: null,
  reducedChargeDescription: null,
  courtActionAmended: null,
  courtActionAmendedOther: null,
  courtActionReduced: null,
  courtActionReducedOther: null,
};

const specificDispOfCharge = {
  AMENDED: "Amended",
  REDUCED: "Reduced",
  DEFERED: "Deferred Adjudication",
  OTHER: "Other",
};

const dispOfChargeArray = [
  { key: 1, name: "Acquitted" },
  { key: 2, name: specificDispOfCharge.AMENDED },
  { key: 3, name: "Convicted" },
  { key: 4, name: specificDispOfCharge.DEFERED },
  { key: 5, name: "Dismissed" },
  { key: 6, name: "Found not guilty" },
  { key: 7, name: "Pled guilty" },
  { key: 8, name: "Pled not guilty" },
  { key: 9, name: "Pre-trial Intervention" },
  { key: 10, name: specificDispOfCharge.REDUCED },
  { key: 11, name: specificDispOfCharge.OTHER },
];

const selectedRefDispCharge = Object.values(specificDispOfCharge);

const isAdditionalForm = (selectedDispOfCharge) =>
  selectedRefDispCharge.includes(selectedDispOfCharge);

const felonyOrMisdemeanorType = {
  FELONY: "Felony",
  MISDEAMEANOR: "Misdemeanor",
  OTHER: "Other",
};

const formalChargeStyle = { height: "80px", minHeight: "80px" };

const AddNewChargeContent = ({
  data,
  getSubmitDataValues,
  setShowAddNewChargeModal,
  setIsModalUpdates,
  dataIndex,
  headerText,
}) => {
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [selectedDispOfCharge, setSelectedDispOfCharge] = useState(
    data?.selectedDispOfCharge || ""
  );
  const [dispOfAmendedChargeError, setDispOfAmendedChargeError] =
    useState(false);
  const [dispOfReducedChargeError, setDispOfReducedChargeError] =
    useState(false);
  const [dispOfChargeError, setDispOfChargeError] = useState(false);

  const [selectedDispOfAmendedCharge, setSelectedDispOfAmendedCharge] =
    useState(data?.selectedDispOfAmendedCharge || "");

  const [selectedDispOfReducedCharge, setSelectedDispOfReducedCharge] =
    useState(data?.selectedDispOfReducedCharge || "");

  const [felonyOrMisdemeanor, setFelonyOrMisdemeanor] = useState(
    data?.felonyOrMisdemeanor || ""
  );

  const [dateAmendedFocus, setDateAmendedFocus] = useState(false);
  const [dateReducedFocus, setDateReducedFocus] = useState(false);

  const [pleaEachCharge, setPleaEachCharge] = useState(
    data?.pleaEachCharge || ""
  );
  const [pleaEachChargeError, setPleaEachChargeError] = useState(false);

  const [pleaEachAmendedCharge, setPleaEachAmendedCharge] = useState(
    data?.pleaEachAmendedCharge || ""
  );
  const [pleaEachAmendedChargeError, setPleaEachAmendedChargeError] =
    useState(false);

  const [pleaEachReducedCharge, setPleaEachReducedCharge] = useState(
    data?.pleaEachReducedCharge || ""
  );
  const [pleaEachReducedChargeError, setPleaEachReducedChargeError] =
    useState(false);

  const [nrOfCounts, setNrOfCounts] = useState(data?.nrOfCounts || "");
  const [nrOfCountsError, setNrOfCountsError] = useState(false);
  const [nrOfCountsErrorMsg, setNrOfCountsErrorMsg] = useState("");

  const [formalChargeDescription, setFormalChargeDescription] = useState(
    data?.formalChargeDescription || ""
  );
  const [formalChargeDescriptionError, setFormalChargeDescriptionError] =
    useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const [nrOfAmendedCounts, setNrOfAmendedCounts] = useState(
    data?.nrOfAmendedCounts || ""
  );
  const [nrOfAmendedCountsError, setNrOfAmendedCountsError] = useState(false);
  const [nrOfAmendedCountsErrorMsg, setNrOfAmendedCountsErrorMsg] =
    useState("");

  const [nrOfReducedCounts, setNrOfReducedCounts] = useState(
    data?.nrOfReducedCounts || ""
  );
  const [nrOfReducedCountsError, setNrOfReducedCountsError] = useState(false);
  const [nrOfReducedCountsErrorMsg, setNrOfReducedCountsErrorMsg] =
    useState("");

  const [deferedExp, setDeferedExp] = useState(data?.deferedExp || "");
  const [deferedExpError, setDeferedExpError] = useState(false);

  const [otherExp, setOtherExp] = useState(data?.otherExp || "");
  const [otherExpError, setOtherExpError] = useState(false);

  const [otherAmendedExp, setOtherAmendedExp] = useState(
    data?.otherAmendedExp || ""
  );
  const [otherAmendedExpError, setOtherAmendedExpError] = useState(false);

  const [otherReducedExp, setOtherReducedExp] = useState(
    data?.otherReducedExp || ""
  );
  const [otherReducedExpError, setOtherReducedExpError] = useState(false);

  const [dateAmendedCharge, setDateAmendedCharge] = useState(
    data?.dateAmendedCharge || ""
  );
  const [dateAmendedChargeError, setDateAmendedChargeError] = useState(false);

  const [dateReducedCharge, setDateReducedCharge] = useState(
    data?.dateReducedCharge || ""
  );
  const [dateReducedChargeError, setDateReducedChargeError] = useState(false);

  const [amendedChargeDescription, setAmendedChargeDescription] = useState(
    data?.amendedChargeDescription || ""
  );
  const [amendedChargeDescriptionError, setAmendedChargeDescriptionError] =
    useState(false);

  const [reducedChargeDescription, setReducedChargeDescription] = useState(
    data?.reducedChargeDescription || ""
  );
  const [reducedChargeDescriptionError, setReducedChargeDescriptionError] =
    useState(false);

  const [felonyOrMisdemeanorAmended, setFelonyOrMisdemeanorAmended] = useState(
    data?.felonyOrMisdemeanorAmended || ""
  );
  const [felonyOrMisdemeanorAmendedOther, setFelonyOrMisdemeanorAmendedOther] =
    useState(data?.felonyOrMisdemeanorAmendedOther || "");
  const [felonyOrMisdemeanorAmendedError, setFelonyOrMisdemeanorAmendedError] =
    useState(false);

  const [felonyOrMisdemeanorReduced, setFelonyOrMisdemeanorReduced] = useState(
    data?.felonyOrMisdemeanorReduced || ""
  );
  const [felonyOrMisdemeanorReducedOther, setFelonyOrMisdemeanorReducedOther] =
    useState(data?.felonyOrMisdemeanorReducedOther || "");
  const [felonyOrMisdemeanorReducedError, setFelonyOrMisdemeanorReducedError] =
    useState(false);

  const [dateAmendedChargeErrorMsg, setDateAmendedChargeErrorMsg] =
    useState("");
  const [dateReducedChargeErrorMsg, setDateReducedChargeErrorMsg] =
    useState("");
  const [isFocusedState, setFocusedState] = useState(false);

  const onOtherExplanationChange = ({ target: { value } }) => {
    setOtherExp(value);
  };

  const onBlurOtherExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherExpError(true)
      : setOtherExpError(false);
  };

  const onOtherAmendedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherAmendedExp(value);
  };

  const onBlurOtherAmendedExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherAmendedExpError(true)
      : setOtherAmendedExpError(false);
  };

  const onOtherReducedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherReducedExp(value);
  };

  const onBlurOtherReducedExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherReducedExpError(true)
      : setOtherReducedExpError(false);
  };

  const onDeferedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDeferedExp(value);
  };

  const onBlurDeferedExpHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 500
      ? setDeferedExpError(true)
      : setDeferedExpError(false);
  };

  const onChargeDescriptionChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFormalChargeDescription(value);
  };
  const onBlurChargeDescriptionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setFormalChargeDescriptionError(true)
      : setFormalChargeDescriptionError(false);
  };

  const onBlurNrOfCountsHandler = ({ target: { value } }) => {
    setFocusedState(false);
    setFormHasChanged(true);
    setNrOfCountsError(value?.length === 0);

    setNrOfCountsErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+value === 0 ||
          +commaSeparatedNumber < 1 ||
          +commaSeparatedNumber > 100)) ||
      Number.isNaN(commaSeparatedNumber) ||
      commaSeparatedNumber.includes("-")
    ) {
      setNrOfCountsErrorMsg(COUNTS_ERR_MSG);

      return setNrOfCountsError(true);
    }

    return value.length === 0
      ? setNrOfCountsError(true)
      : setNrOfCountsError(false);
  };

  const onBlurNrOfAmendedCountsHandler = ({ target: { value } }) => {
    setFocusedState(false);
    setFormHasChanged(true);
    setNrOfAmendedCountsError(value.length === 0);

    setNrOfAmendedCountsErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 ||
          +commaSeparatedNumber < 1 ||
          +commaSeparatedNumber > 100)) ||
      Number.isNaN(commaSeparatedNumber) ||
      commaSeparatedNumber.includes("-")
    ) {
      setNrOfAmendedCountsErrorMsg(COUNTS_ERR_MSG);

      return setNrOfAmendedCountsError(true);
    }

    return value.length === 0
      ? setNrOfAmendedCountsError(true)
      : setNrOfAmendedCountsError(false);
  };

  const onBlurNrOfReducedCountsHandler = ({ target: { value } }) => {
    setFocusedState(false);
    setFormHasChanged(true);
    setNrOfReducedCountsError(value?.length === 0);

    setNrOfReducedCountsErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 ||
          +commaSeparatedNumber < 1 ||
          +commaSeparatedNumber > 100)) ||
      Number.isNaN(commaSeparatedNumber) ||
      commaSeparatedNumber.includes("-")
    ) {
      setNrOfReducedCountsErrorMsg(COUNTS_ERR_MSG);

      return setNrOfReducedCountsError(true);
    }

    return value.length === 0
      ? setNrOfReducedCountsError(true)
      : setNrOfReducedCountsError(false);
  };

  const handleChangeChecked = (e) => {
    setFormHasChanged(true);
    const { value } = e.target;
    let newValue = value;

    setFelonyOrMisdemeanor(newValue);
  };

  const handleChangeAmendedChecked = (e) => {
    const { value } = e.target;
    setFormHasChanged(true);
    let newValue = value;

    setFelonyOrMisdemeanorAmendedError(false);
    if (value !== felonyOrMisdemeanorType.OTHER) {
      setFelonyOrMisdemeanorAmendedOther("");
    }

    setFelonyOrMisdemeanorAmended(newValue);
  };

  const handleChangeReducedChecked = (e) => {
    const { value } = e.target;
    setFormHasChanged(true);
    let newValue = value;

    setFelonyOrMisdemeanorReducedError(false);
    if (value !== felonyOrMisdemeanorType.OTHER) {
      setFelonyOrMisdemeanorReducedOther("");
    }

    setFelonyOrMisdemeanorReduced(newValue);
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
    setShowAddNewChargeModal(true);
  };

  const isError =
    pleaEachChargeError ||
    pleaEachAmendedChargeError ||
    pleaEachReducedChargeError ||
    nrOfCountsError ||
    nrOfAmendedCountsError ||
    nrOfReducedCountsError ||
    deferedExpError ||
    otherExpError ||
    otherAmendedExpError ||
    otherReducedExpError ||
    dateAmendedChargeError ||
    dateReducedChargeError ||
    amendedChargeDescriptionError ||
    reducedChargeDescriptionError ||
    felonyOrMisdemeanorAmendedError ||
    dispOfAmendedChargeError ||
    dispOfReducedChargeError ||
    dispOfChargeError ||
    formalChargeDescriptionError ||
    felonyOrMisdemeanorReducedError;

  const isUpdates =
    (data?.length === 0 && formalChargeDescription?.length) ||
    formHasChanged ||
    isError;

  useEffect(() => {
    if (isUpdates || isError) {
      setIsModalUpdates(true);
    } else {
      setIsModalUpdates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdates, isError]);

  const isNextDisabledFirstForm =
    !formalChargeDescription?.length ||
    (formalChargeDescription.length && formalChargeDescription.length < 2) ||
    !nrOfCounts?.length ||
    !felonyOrMisdemeanor?.length ||
    !pleaEachCharge?.length ||
    (pleaEachCharge.length && pleaEachCharge.length < 2) ||
    !selectedDispOfCharge?.length;

  const isNextDisabledSecondFormAmended =
    selectedDispOfCharge === specificDispOfCharge.AMENDED &&
    (!nrOfAmendedCounts?.length ||
      !dateAmendedCharge?.length ||
      !amendedChargeDescription?.length ||
      (amendedChargeDescription.length &&
        amendedChargeDescription.length < 2) ||
      !felonyOrMisdemeanorAmended?.length ||
      !pleaEachAmendedCharge.length ||
      (pleaEachAmendedCharge.length && pleaEachAmendedCharge.length < 2) ||
      !selectedDispOfAmendedCharge?.length ||
      ((selectedDispOfAmendedCharge === specificDispOfCharge.OTHER ||
        selectedDispOfAmendedCharge === specificDispOfCharge.DEFERED) &&
        !otherAmendedExp?.length) ||
      (otherAmendedExp?.length && otherAmendedExp?.length < 2) ||
      (felonyOrMisdemeanorAmended === felonyOrMisdemeanorType.OTHER &&
        !felonyOrMisdemeanorAmendedOther?.length) ||
      (felonyOrMisdemeanorAmendedOther?.length &&
        felonyOrMisdemeanorAmendedOther?.length < 2) ||
      (felonyOrMisdemeanorAmendedOther?.length &&
        felonyOrMisdemeanorAmendedOther?.length < 2));

  const isNextDisabledSecondFormReduced =
    selectedDispOfCharge === specificDispOfCharge.REDUCED &&
    (!nrOfReducedCounts?.length ||
      !dateReducedCharge.length ||
      !reducedChargeDescription?.length ||
      (reducedChargeDescription.length &&
        reducedChargeDescription.length < 2) ||
      !felonyOrMisdemeanorReduced?.length ||
      !pleaEachReducedCharge?.length ||
      (pleaEachReducedCharge.length && pleaEachReducedCharge.length < 2) ||
      !selectedDispOfReducedCharge?.length ||
      ((selectedDispOfReducedCharge === specificDispOfCharge.OTHER ||
        selectedDispOfReducedCharge === specificDispOfCharge.DEFERED) &&
        !otherReducedExp?.length) ||
      (otherReducedExp?.length && otherReducedExp?.length < 2) ||
      (felonyOrMisdemeanorReduced === felonyOrMisdemeanorType.OTHER &&
        !felonyOrMisdemeanorReducedOther?.length) ||
      (felonyOrMisdemeanorReducedOther.length &&
        felonyOrMisdemeanorReducedOther.length < 2));

  const isNextDisabledSecondFormDefered =
    selectedDispOfCharge === specificDispOfCharge.DEFERED &&
    (!deferedExp.length || (deferedExp.length && deferedExp.length < 2));

  const isNextDisabledSecondFormOther =
    selectedDispOfCharge === specificDispOfCharge.OTHER &&
    (!otherExp.length || (otherExp.length && otherExp.length < 2));

  const isNextDisabled =
    isNextDisabledFirstForm ||
    (isAdditionalForm(selectedDispOfCharge) &&
      (isNextDisabledSecondFormAmended ||
        isNextDisabledSecondFormReduced ||
        isNextDisabledSecondFormDefered ||
        isNextDisabledSecondFormOther)) ||
    isError ||
    isFocusedState;

  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
      setFormHasChanged(isUpdates);
    } else {
      getSubmitDataValues(
        {
          ...initialValues,
        },
        headerText
      );

      setShowAddNewChargeModal(false);
    }
  };

  const handleBackClick = () => {
    getSubmitDataValues({ ...initialValues }, headerText);
    handleCloseBackModal();
    setShowAddNewChargeModal(false);
  };

  const handleNrOfCountsOnChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    setNrOfCounts(commaSeparatedNumber);
  };

  const handleNrOfAmendedCountsOnChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    setNrOfAmendedCounts(commaSeparatedNumber);
  };

  const handleNrOfReducedCountsOnChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    setNrOfReducedCounts(commaSeparatedNumber);
  };

  const onChangePleaEachChargeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setPleaEachCharge(value);
  };
  const onBlurPleaEachChargeHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setPleaEachChargeError(true)
      : setPleaEachChargeError(false);
  };

  const onChangePleaEachAmendedChargeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setPleaEachAmendedCharge(value);
  };
  const onBlurPleaEachAmendedChargeHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setPleaEachAmendedChargeError(true)
      : setPleaEachAmendedChargeError(false);
  };

  const onChangePleaEachReducedChargeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setPleaEachReducedCharge(value);
  };
  const onBlurPleaEachReducedChargeHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setPleaEachReducedChargeError(true)
      : setPleaEachReducedChargeError(false);
  };

  const onBlurDispOfAmendedChargeHandler = () => {
    setFocusedState(false);
    return !selectedDispOfAmendedCharge.length
      ? setDispOfAmendedChargeError(true)
      : setDispOfAmendedChargeError(false);
  };
  const onBlurDispOfReducedChargeHandler = () => {
    setFocusedState(false);
    return !selectedDispOfReducedCharge.length
      ? setDispOfReducedChargeError(true)
      : setDispOfReducedChargeError(false);
  };
  const onBlurDispOfChargeHandler = () => {
    setFocusedState(false);
    return !selectedDispOfCharge.length
      ? setDispOfChargeError(true)
      : setDispOfChargeError(false);
  };

  const dropdownOnChangeHandler = (key) => {
    setNrOfAmendedCounts("");
    setNrOfReducedCounts("");
    setDeferedExp("");
    setOtherExp("");
    setOtherAmendedExp("");
    setOtherReducedExp("");
    setDateAmendedCharge("");
    setDateReducedCharge("");
    setAmendedChargeDescription("");
    setReducedChargeDescription("");
    setFelonyOrMisdemeanorAmended("");
    setFelonyOrMisdemeanorAmendedOther("");
    setFelonyOrMisdemeanorReduced("");
    setPleaEachAmendedCharge("");
    setPleaEachReducedCharge("");
    setSelectedDispOfReducedCharge("");
    setSelectedDispOfAmendedCharge("");
    setFelonyOrMisdemeanorReducedOther("");

    setNrOfAmendedCountsErrorMsg("");
    setNrOfReducedCountsErrorMsg("");

    setDispOfAmendedChargeError(false);
    setDispOfReducedChargeError(false);
    setPleaEachReducedChargeError(false);
    setPleaEachAmendedChargeError(false);
    setNrOfAmendedCountsError(false);
    setNrOfReducedCountsError(false);
    setDeferedExpError(false);
    setOtherExpError(false);
    setOtherAmendedExpError(false);
    setOtherReducedExpError(false);
    setDateAmendedChargeError(false);
    setDateReducedChargeError(false);
    setAmendedChargeDescriptionError(false);
    setReducedChargeDescriptionError(false);
    setFelonyOrMisdemeanorAmendedError(false);
    setFelonyOrMisdemeanorReducedError(false);

    setFormHasChanged(true);

    setSelectedDispOfCharge(key);
  };

  const dropdownOnChangeAmendedHandler = (key) => {
    setFormHasChanged(true);
    setOtherAmendedExp("");
    setOtherAmendedExpError(false);
    setDeferedExpError(false);
    setSelectedDispOfAmendedCharge(key);
  };

  const dropdownOnChangeReducedHandler = (key) => {
    setFormHasChanged(true);
    setOtherReducedExp("");
    setOtherReducedExpError(false);
    setDeferedExpError(false);
    setSelectedDispOfReducedCharge(key);
  };

  const onDateAmendedChargeChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateAmendedCharge(value);
  };

  const onDateAmendedChargeBlurHandler = ({ target: { value } }) => {
    setFocusedState(false);
    setFormHasChanged(true);
    setDateAmendedFocus(false);
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateAmendedChargeError(true);
      } else {
        setDateAmendedChargeError(false);
      }
    } else {
      setDateAmendedChargeError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateAmendedChargeErrorMsg("Date cannot be a future date");
        setDateAmendedChargeError(true);
      } else if (date < past) {
        setDateAmendedChargeErrorMsg("Date cannot be more than 100 years ago");
        setDateAmendedChargeError(true);
      } else {
        setDateAmendedChargeError(false);
      }
    } else {
      setDateAmendedChargeErrorMsg("Please enter a valid date");
      setDateAmendedChargeError(true);
    }
  };

  const onDateReducedChargeChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateReducedCharge(value);
  };

  const onDateReducedChargeBlurHandler = ({ target: { value } }) => {
    setFocusedState(false);
    setFormHasChanged(true);
    setDateReducedFocus(false);
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateReducedChargeError(true);
      } else {
        setDateReducedChargeError(false);
      }
    } else {
      setDateReducedChargeError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateReducedChargeErrorMsg("Date cannot be a future date");
        setDateReducedChargeError(true);
      } else if (date < past) {
        setDateReducedChargeErrorMsg("Date cannot be more than 100 years ago");
        setDateReducedChargeError(true);
      } else {
        setDateReducedChargeError(false);
      }
    } else {
      setDateReducedChargeErrorMsg("Please enter a valid date");
      setDateReducedChargeError(true);
    }
  };

  const onAmendedChargeDescriptionChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setAmendedChargeDescription(value);
  };
  const onBlurAmendedChargeDescriptionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setAmendedChargeDescriptionError(true)
      : setAmendedChargeDescriptionError(false);
  };

  const onReducedChargeDescriptionChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setReducedChargeDescription(value);
  };
  const onBlurReducedChargeDescriptionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setReducedChargeDescriptionError(true)
      : setReducedChargeDescriptionError(false);
  };

  const onChangeCourtActionAmendedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFelonyOrMisdemeanorAmendedOther(value);
  };
  const onBlurCourtActionAmendedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setFelonyOrMisdemeanorAmendedError(true)
      : setFelonyOrMisdemeanorAmendedError(false);
  };

  const onChangeCourtActionReducedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFelonyOrMisdemeanorReducedOther(value);
  };
  const onBlurCourtActionReducedHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setFelonyOrMisdemeanorReducedError(true)
      : setFelonyOrMisdemeanorReducedError(false);
  };

  const renderDispOfChargeOptions = () => {
    let content = null;

    switch (selectedDispOfCharge) {
      case specificDispOfCharge.AMENDED:
        content = (
          <div className="amended-container">
            <div className="col-md-12 event-status-date-wrapper amended-date">
              <label
                id="dateAmendedChargedLabelId"
                htmlFor="dateAmendedChargedId"
              >
                <strong>Date of {selectedDispOfCharge} Charge *</strong>
              </label>
              <InputMask
                id="dateAmendedChargedId"
                mask="99/99/9999"
                maskChar="_"
                className={`${
                  dateAmendedChargeError ||
                  (dateAmendedFocus && dateAmendedChargeError)
                    ? "form-control is-invalid"
                    : "form-control"
                } event-status-date-input`}
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                required
                onFocus={() => {
                  setFocusedState(true);
                  setDateAmendedFocus(true);
                }}
                value={dateAmendedCharge}
                onChange={onDateAmendedChargeChangeHandler}
                onBlur={onDateAmendedChargeBlurHandler}
              />
              <div id="dateAmendedChargeError" className="invalid-feedback">
                {dateAmendedChargeErrorMsg?.length
                  ? dateAmendedChargeErrorMsg
                  : `Please enter a valid Date ${selectedDispOfCharge} Charge`}
              </div>
            </div>

            <div className="formal-charge-container">
              <label
                htmlFor="amendedDescChargeId"
                className="formal-charge-label"
                id="amendedDescChargeLabelId"
              >
                If original charge was {selectedDispOfCharge.toLowerCase()},
                specify new charge: *
              </label>
              <textarea
                id="amendedDescChargeId"
                style={{ ...formalChargeStyle }}
                onChange={onAmendedChargeDescriptionChangeHandler}
                className={
                  amendedChargeDescriptionError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15 formal-charge-id"
                }
                minLength={0}
                maxLength={100}
                rows="3"
                cols={500}
                onBlur={onBlurAmendedChargeDescriptionHandler}
                onFocus={() => setFocusedState(true)}
                value={amendedChargeDescription}
              />
              <div
                id="amendedChargeDescriptionError"
                className="invalid-feedback"
              >
                Please enter a new charge
              </div>
            </div>

            <div className="nr-of-counts-container">
              <label
                htmlFor="nrOfAmendedCountsId"
                id="nrOfAmendedCountsLabelId"
                className="nr-of-counts"
              >
                Number of Counts (for {selectedDispOfCharge.toLowerCase()}{" "}
                charge) *
              </label>
              <div
                className="input-group nr-of-counts-group"
                id="ind_longterm_first_year_input"
              >
                <input
                  type="text"
                  id="nrOfAmendedCountsId"
                  className={
                    nrOfAmendedCountsError
                      ? "form-control form-control-15 input-pad-left is-invalid"
                      : "form-control form-control-15 input-pad-left formal-charge-id"
                  }
                  style={{ borderRightStyle: "inset" }}
                  spellCheck="false"
                  maxLength={3}
                  placeholder="Enter number"
                  autoComplete="off"
                  onBlur={onBlurNrOfAmendedCountsHandler}
                  value={`${nrOfAmendedCounts}`.replaceAll("-", "").trim()}
                  onChange={handleNrOfAmendedCountsOnChange}
                  onKeyPress={onlyNumeric}
                  onPaste={pasteCommaDelimited}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="nrOfAmendedCountsError" className="invalid-feedback">
                  {nrOfAmendedCountsErrorMsg?.length
                    ? nrOfAmendedCountsErrorMsg
                    : "Please enter Number of Counts"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label className="court-action" id="felOrMisdLabelId">
                  Specify if {selectedDispOfCharge.toLowerCase()} charge is a
                  Felony or Misdemeanor? *
                </label>
              </div>
            </div>
            <div className="row">
              <div className="drp-radio-input-wrapper">
                <div className="col-12 copy-md-4 form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="felonyCourtAmended"
                      type="radio"
                      id="felony-court-amended"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.FELONY}
                      checked={
                        felonyOrMisdemeanorAmended ===
                        felonyOrMisdemeanorType.FELONY
                      }
                      onChange={handleChangeAmendedChecked}
                    />
                    <label
                      htmlFor="felony-court-amended"
                      className="custom-control-label"
                      id="felony-court-amended-label-id"
                    >
                      {felonyOrMisdemeanorType.FELONY}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="misdCourtAmended"
                      type="radio"
                      id="misdCourtAmendedId"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.MISDEAMEANOR}
                      checked={
                        felonyOrMisdemeanorAmended ===
                        felonyOrMisdemeanorType.MISDEAMEANOR
                      }
                      onChange={handleChangeAmendedChecked}
                    />
                    <label
                      htmlFor="misdCourtAmendedId"
                      className="custom-control-label"
                      id="misdCourtAmendedLabelId"
                    >
                      {felonyOrMisdemeanorType.MISDEAMEANOR}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="otherAmendedRadio"
                      type="radio"
                      id="otherAmendedRadioId"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.OTHER}
                      checked={
                        felonyOrMisdemeanorAmended ===
                        felonyOrMisdemeanorType.OTHER
                      }
                      onChange={handleChangeAmendedChecked}
                    />
                    <label
                      htmlFor="otherAmendedRadioId"
                      className="custom-control-label"
                      id="otherAmendedRadioLabelId"
                    >
                      {felonyOrMisdemeanorType.OTHER}
                    </label>
                  </div>

                  {felonyOrMisdemeanorAmended ===
                  felonyOrMisdemeanorType.OTHER ? (
                    <div className="court-action-other">
                      <input
                        type="text"
                        id="otherText"
                        className={
                          felonyOrMisdemeanorAmendedError
                            ? "form-control form-control-15 is-invalid"
                            : "form-control form-control-15"
                        }
                        maxLength={100}
                        minLength={2}
                        autoComplete="off"
                        value={felonyOrMisdemeanorAmendedOther}
                        onChange={onChangeCourtActionAmendedHandler}
                        onBlur={onBlurCourtActionAmendedHandler}
                        onFocus={() => setFocusedState(true)}
                      />
                      <div
                        id="felonyOrMisdemeanorAmended"
                        className="invalid-feedback"
                      >
                        Please describe the Other charge
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label
                  className="plea-each-charge"
                  htmlFor="pleaEachAmendedCharge"
                  id="pleaEachAmendedChargeLabelId"
                >
                  Plea for each {selectedDispOfCharge.toLowerCase()} charge *
                </label>
                <input
                  type="text"
                  id="pleaEachAmendedCharge"
                  className={
                    pleaEachAmendedChargeError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={100}
                  minLength={2}
                  autoComplete="off"
                  value={pleaEachAmendedCharge}
                  onChange={onChangePleaEachAmendedChargeHandler}
                  onBlur={onBlurPleaEachAmendedChargeHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="pleaEachAmendedChargeError"
                  className="invalid-feedback"
                >
                  Please enter a Plea
                </div>
              </div>
            </div>
            <div className="row disp-of-charge-container">
              <div className="col-md-12">
                <label
                  className="disp-of-charge-label"
                  htmlFor="dispOfAmendedChargeId"
                  id="dispOfAmendedChargeIdLabelId"
                >
                  Disposition of {selectedDispOfCharge.toLowerCase()} charge *
                </label>
                <DropdownButton
                  id="dispOfAmendedChargeId"
                  type="default"
                  variant="secondary"
                  title={
                    selectedDispOfAmendedCharge
                      ? selectedDispOfAmendedCharge
                      : "Choose one"
                  }
                  className={
                    dispOfAmendedChargeError
                      ? "is-invalid disp-of-charge"
                      : !selectedDispOfAmendedCharge
                      ? "disp-of-charge"
                      : ""
                  }
                  selected={false}
                  autoComplete="off"
                  onSelect={dropdownOnChangeAmendedHandler}
                  onClick={clickFunc}
                  onBlur={onBlurDispOfAmendedChargeHandler}
                >
                  {dispOfChargeArray.map(({ name, key }) => (
                    <Dropdown.Item
                      key={`${name}_${key}`}
                      eventKey={name}
                      id={name}
                    >
                      {name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <div id="dispOfAmendedChargeError" className="invalid-feedback">
                  Please select Disposition
                </div>
              </div>
            </div>

            {selectedDispOfAmendedCharge === specificDispOfCharge.OTHER ||
            selectedDispOfAmendedCharge === specificDispOfCharge.DEFERED ? (
              <div className="event-status-date-explanation-container-am-re">
                <label
                  htmlFor="otherAmendedExp"
                  className="event-status-date-exp"
                  id="otherAmendedExpLabelId"
                >
                  Explanation *
                </label>
                <textarea
                  id="otherAmendedExp"
                  onChange={onOtherAmendedExplanationChange}
                  className={
                    otherAmendedExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurOtherAmendedExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={otherAmendedExp}
                />
                <div id="otherAmendedExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        );
        break;
      case specificDispOfCharge.REDUCED:
        content = (
          <div className="reduced-container">
            <div className="col-md-12 event-status-date-wrapper reduced-date">
              <label id="dateReducedChargedId" htmlFor="dateReducedChargedId">
                <strong>Date of {selectedDispOfCharge} Charge *</strong>
              </label>
              <InputMask
                id="dateReducedChargedId"
                mask="99/99/9999"
                maskChar="_"
                className={`${
                  dateReducedChargeError ||
                  (dateReducedFocus && dateReducedChargeError)
                    ? "form-control is-invalid"
                    : "form-control"
                } event-status-date-input`}
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                required
                onFocus={() => {
                  setFocusedState(true);
                  setDateReducedFocus(true);
                }}
                value={dateReducedCharge}
                onChange={onDateReducedChargeChangeHandler}
                onBlur={onDateReducedChargeBlurHandler}
              />
              <div id="dateReducedChargeError" className="invalid-feedback">
                {dateReducedChargeErrorMsg?.length
                  ? dateReducedChargeErrorMsg
                  : `Please enter a valid Date ${selectedDispOfCharge} Charge`}
              </div>
            </div>
            <div className="formal-charge-container">
              <label
                htmlFor="reducedChargeId"
                className="formal-charge-label"
                id="reducedChargeLabelId"
              >
                If original charge was {selectedDispOfCharge.toLowerCase()},
                specify new charge: *
              </label>
              <textarea
                id="reducedChargeId"
                style={{ ...formalChargeStyle }}
                onChange={onReducedChargeDescriptionChangeHandler}
                className={
                  reducedChargeDescriptionError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15 formal-charge-id"
                }
                minLength={0}
                maxLength={100}
                rows="3"
                cols={500}
                onBlur={onBlurReducedChargeDescriptionHandler}
                onFocus={() => setFocusedState(true)}
                value={reducedChargeDescription}
              />
              <div
                id="reducedChargeDescriptionError"
                className="invalid-feedback"
              >
                Please enter a new charge
              </div>
            </div>
            <div className="nr-of-counts-container">
              <label
                htmlFor="nrOfReducedCountsId"
                className="nr-of-counts"
                id="nrOfReducedCountsLabelId"
              >
                Number of Counts (for {selectedDispOfCharge.toLowerCase()}{" "}
                charge) *
              </label>
              <div
                className="input-group nr-of-counts-group"
                id="ind_longterm_first_year_input"
              >
                <input
                  type="text"
                  id="nrOfAmendedCountsId"
                  className={
                    nrOfReducedCountsError
                      ? "form-control form-control-15 input-pad-left is-invalid"
                      : "form-control form-control-15 input-pad-left formal-charge-id"
                  }
                  style={{ borderRightStyle: "inset" }}
                  spellCheck="false"
                  maxLength={3}
                  placeholder="Enter number"
                  autoComplete="off"
                  onBlur={onBlurNrOfReducedCountsHandler}
                  value={`${nrOfReducedCounts}`.replaceAll("-", "").trim()}
                  onChange={handleNrOfReducedCountsOnChange}
                  onKeyPress={onlyNumeric}
                  onPaste={pasteCommaDelimited}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="nrOfReducedCountsError" className="invalid-feedback">
                  {nrOfReducedCountsErrorMsg?.length
                    ? nrOfReducedCountsErrorMsg
                    : "Please enter Number of Counts"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label className="court-action" id="felOrMisdChargeLabelId">
                  Specify if {selectedDispOfCharge.toLowerCase()} charge is a
                  Felony or Misdemeanor? *
                </label>
              </div>
            </div>
            <div className="row">
              <div className="drp-radio-input-wrapper">
                <div className="col-12 copy-md-4 form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="felonyCourtReduced"
                      type="radio"
                      id="felony-court-reduced"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.FELONY}
                      checked={
                        felonyOrMisdemeanorReduced ===
                        felonyOrMisdemeanorType.FELONY
                      }
                      onChange={handleChangeReducedChecked}
                    />
                    <label
                      htmlFor="felony-court-reduced"
                      className="custom-control-label"
                      id="felony-court-reduced-label-id"
                    >
                      {felonyOrMisdemeanorType.FELONY}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="misdCourtReduced"
                      type="radio"
                      id="misdCourtReducedId"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.MISDEAMEANOR}
                      checked={
                        felonyOrMisdemeanorReduced ===
                        felonyOrMisdemeanorType.MISDEAMEANOR
                      }
                      onChange={handleChangeReducedChecked}
                    />
                    <label
                      htmlFor="misdCourtReducedId"
                      className="custom-control-label"
                      id="misdCourtReducedLabelId"
                    >
                      {felonyOrMisdemeanorType.MISDEAMEANOR}
                    </label>
                  </div>

                  <div className="custom-control custom-radio">
                    <input
                      name="otherReducedRadio"
                      type="radio"
                      id="otherReducedRadioId"
                      className="custom-control-input"
                      value={felonyOrMisdemeanorType.OTHER}
                      checked={
                        felonyOrMisdemeanorReduced ===
                        felonyOrMisdemeanorType.OTHER
                      }
                      onChange={handleChangeReducedChecked}
                    />
                    <label
                      htmlFor="otherReducedRadioId"
                      className="custom-control-label"
                      id="otherReducedRadioLabelId"
                    >
                      {felonyOrMisdemeanorType.OTHER}
                    </label>
                  </div>

                  {felonyOrMisdemeanorReduced ===
                  felonyOrMisdemeanorType.OTHER ? (
                    <div className="court-action-other">
                      <input
                        type="text"
                        id="otherText"
                        className={
                          felonyOrMisdemeanorReducedError
                            ? "form-control form-control-15 is-invalid"
                            : "form-control form-control-15"
                        }
                        maxLength={100}
                        minLength={2}
                        autoComplete="off"
                        value={felonyOrMisdemeanorReducedOther}
                        onChange={onChangeCourtActionReducedHandler}
                        onBlur={onBlurCourtActionReducedHandler}
                        onFocus={() => setFocusedState(true)}
                      />
                      <div
                        id="courtActionReducedError"
                        className="invalid-feedback"
                      >
                        Please describe the Other charge
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label
                  className="plea-each-charge"
                  htmlFor="pleaEachReducedCharge"
                  id="pleaEachReducedChargeLabelId"
                >
                  Plea for each {selectedDispOfCharge.toLowerCase()} charge *
                </label>
                <input
                  type="text"
                  id="pleaEachReducedCharge"
                  className={
                    pleaEachReducedChargeError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={100}
                  minLength={2}
                  autoComplete="off"
                  value={pleaEachReducedCharge}
                  onChange={onChangePleaEachReducedChargeHandler}
                  onBlur={onBlurPleaEachReducedChargeHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="pleaEachReducedChargeError"
                  className="invalid-feedback"
                >
                  Please enter a Plea
                </div>
              </div>
            </div>
            <div className="row disp-of-charge-container">
              <div className="col-md-12">
                <label
                  className="disp-of-charge-label"
                  htmlFor="dispOfReducedChargeId"
                  id="dispOfReducedChargeLabelId"
                >
                  Disposition of {selectedDispOfCharge.toLowerCase()} charge *
                </label>
                <DropdownButton
                  id="dispOfReducedChargeId"
                  type="default"
                  variant="secondary"
                  title={
                    selectedDispOfReducedCharge
                      ? selectedDispOfReducedCharge
                      : "Choose one"
                  }
                  className={
                    dispOfReducedChargeError
                      ? "is-invalid disp-of-charge"
                      : !selectedDispOfReducedCharge
                      ? "disp-of-charge"
                      : ""
                  }
                  selected={false}
                  autoComplete="off"
                  onSelect={dropdownOnChangeReducedHandler}
                  onClick={clickFunc}
                  onBlur={onBlurDispOfReducedChargeHandler}
                >
                  {dispOfChargeArray.map(({ name, key }) => (
                    <Dropdown.Item
                      key={`${name}_${key}`}
                      eventKey={name}
                      id={name}
                    >
                      {name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <div id="dispOfReducedChargeError" className="invalid-feedback">
                  Please select Disposition
                </div>
              </div>
            </div>

            {selectedDispOfReducedCharge === specificDispOfCharge.OTHER ||
            selectedDispOfReducedCharge === specificDispOfCharge.DEFERED ? (
              <div className="event-status-date-explanation-container-am-re">
                <label
                  htmlFor="otherReducedExp"
                  className="event-status-date-exp"
                  id="otherReducedExpLabelId"
                >
                  Explanation *
                </label>
                <textarea
                  id="otherReducedExp"
                  onChange={onOtherReducedExplanationChange}
                  className={
                    otherReducedExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurOtherReducedExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={otherReducedExp}
                />
                <div id="otherReducedExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        );
        break;
      case specificDispOfCharge.DEFERED:
        content = (
          <div className="event-status-date-explanation-container">
            <label
              htmlFor="deferedExp"
              id="deferedExpLabelId"
              className="event-status-date-exp"
            >
              Explanation *
            </label>
            <textarea
              id="deferedExp"
              onChange={onDeferedExplanationChange}
              className={
                deferedExpError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              onBlur={onBlurDeferedExpHandler}
              rows="3"
              cols={500}
              value={deferedExp}
            />
            <div id="deferedExpError" className="invalid-feedback">
              Please enter an Explanation
            </div>
          </div>
        );
        break;
      case specificDispOfCharge.OTHER:
        content = (
          <div className="event-status-date-explanation-container">
            <label
              htmlFor="otherExp"
              id="otherExpLabelId"
              className="event-status-date-exp"
            >
              Explanation *
            </label>
            <textarea
              id="otherExp"
              onChange={onOtherExplanationChange}
              className={
                otherExpError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              onBlur={onBlurOtherExpHandler}
              onFocus={() => setFocusedState(true)}
              rows="3"
              cols={500}
              value={otherExp}
            />
            <div id="otherExpError" className="invalid-feedback">
              Please enter an Explanation
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return content;
  };

  const handleAddCharge = useCallback(() => {
    const submitData = {
      selectedDispOfCharge,
      felonyOrMisdemeanor,
      pleaEachCharge,
      pleaEachAmendedCharge,
      pleaEachReducedCharge,
      nrOfCounts,
      formalChargeDescription,
      selectedDispOfAmendedCharge,
      selectedDispOfReducedCharge,
      nrOfAmendedCounts,
      nrOfReducedCounts,
      otherAmendedExp,
      otherReducedExp,
      dateAmendedCharge,
      dateReducedCharge,
      deferedExp,
      otherExp,
      amendedChargeDescription,
      reducedChargeDescription,
      felonyOrMisdemeanorAmended,
      felonyOrMisdemeanorAmendedOther,
      felonyOrMisdemeanorReduced,
      felonyOrMisdemeanorReducedOther,
    };

    getSubmitDataValues({ ...submitData }, dataIndex);

    setFormHasChanged(false);

    setShowAddNewChargeModal(false);
  }, [
    getSubmitDataValues,
    selectedDispOfCharge,
    felonyOrMisdemeanor,
    pleaEachCharge,
    pleaEachAmendedCharge,
    pleaEachReducedCharge,
    otherExp,
    deferedExp,
    nrOfCounts,
    formalChargeDescription,
    selectedDispOfAmendedCharge,
    selectedDispOfReducedCharge,
    nrOfAmendedCounts,
    nrOfReducedCounts,
    otherAmendedExp,
    otherReducedExp,
    dateAmendedCharge,
    dateReducedCharge,
    amendedChargeDescription,
    reducedChargeDescription,
    felonyOrMisdemeanorAmended,
    felonyOrMisdemeanorAmendedOther,
    felonyOrMisdemeanorReduced,
    felonyOrMisdemeanorReducedOther,
    setShowAddNewChargeModal,
    dataIndex,
  ]);

  const isExpl =
    selectedDispOfCharge === specificDispOfCharge.OTHER ||
    selectedDispOfCharge === specificDispOfCharge.DEFERED;

  return (
    <div className="add-new-charge-children-wrapper">
      <div className="formal-charge-container">
        <label
          htmlFor="formalChargeId"
          id="formalChargeLabelId"
          className="formal-charge-label"
        >
          Formal Charge/Description *
        </label>
        <textarea
          id="formalChargeId"
          style={{ ...formalChargeStyle }}
          onChange={onChargeDescriptionChangeHandler}
          className={
            formalChargeDescriptionError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15 formal-charge-id"
          }
          minLength={0}
          maxLength={100}
          rows="3"
          cols={500}
          onBlur={onBlurChargeDescriptionHandler}
          onFocus={() => setFocusedState(true)}
          value={formalChargeDescription}
        />
        <div id="formalChargeDescriptionError" className="invalid-feedback">
          Please enter Formal Charge/Description
        </div>
      </div>

      <div className="nr-of-counts-container">
        <label
          htmlFor="nrOfCountsId"
          id="nrOfCountsLabelId"
          className="nr-of-counts"
        >
          Number of Counts *
        </label>
        <div
          className="input-group nr-of-counts-group"
          id="ind_longterm_first_year_input"
        >
          <input
            type="text"
            id="nrOfCountsId"
            className={
              nrOfCountsError
                ? "form-control form-control-15 input-pad-left is-invalid"
                : "form-control form-control-15 input-pad-left formal-charge-id"
            }
            style={{ borderRightStyle: "inset" }}
            spellCheck="false"
            maxLength={3}
            placeholder="Enter number"
            autoComplete="off"
            onBlur={onBlurNrOfCountsHandler}
            value={`${nrOfCounts}`.replaceAll("-", "").trim()}
            onChange={handleNrOfCountsOnChange}
            onKeyPress={onlyNumeric}
            onPaste={pasteCommaDelimited}
            onFocus={() => setFocusedState(true)}
          />

          <div id="nrOfCountsError" className="invalid-feedback">
            {nrOfCountsErrorMsg.length
              ? nrOfCountsErrorMsg
              : "Please enter Number of Counts"}
          </div>
        </div>
      </div>
      <div className="event-container">
        <div className="row">
          <div className="col-md-12">
            <label id="felOrMisdEvLabelId" className="event-status">
              Felony or Misdemeanor? *
            </label>
          </div>
        </div>

        <div className="row">
          <div className="drp-radio-input-wrapper">
            <div className="col-12 copy-md-4 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="felony"
                  type="radio"
                  id="felonyId"
                  className="custom-control-input"
                  value={felonyOrMisdemeanorType.FELONY}
                  checked={
                    felonyOrMisdemeanor === felonyOrMisdemeanorType.FELONY
                  }
                  onChange={handleChangeChecked}
                />
                <label
                  htmlFor="felonyId"
                  id="felonyLabelId"
                  className="custom-control-label"
                >
                  {felonyOrMisdemeanorType.FELONY}
                </label>
              </div>

              <div className="custom-control custom-radio">
                <input
                  name="misdemeanor"
                  type="radio"
                  id="misdemeanorId"
                  className="custom-control-input"
                  value={felonyOrMisdemeanorType.MISDEAMEANOR}
                  checked={
                    felonyOrMisdemeanor === felonyOrMisdemeanorType.MISDEAMEANOR
                  }
                  onChange={handleChangeChecked}
                />
                <label
                  htmlFor="misdemeanorId"
                  id="misdemeanorLabelId"
                  className="custom-control-label"
                >
                  {felonyOrMisdemeanorType.MISDEAMEANOR}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label
              className="plea-each-charge"
              htmlFor="pleaEachCharge"
              id="pleaEachChargeLabelId"
            >
              Plea for each charge *
            </label>
            <input
              type="text"
              id="pleaEachCharge"
              className={
                pleaEachChargeError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={100}
              minLength={2}
              autoComplete="off"
              value={pleaEachCharge}
              onChange={onChangePleaEachChargeHandler}
              onBlur={onBlurPleaEachChargeHandler}
              onFocus={() => setFocusedState(true)}
            />
            <div id="pleaEachChargeError" className="invalid-feedback">
              Please enter a Plea
            </div>
          </div>
        </div>

        <div className="row disp-of-charge-container">
          <div className="col-md-12">
            <label
              className="disp-of-charge-label"
              htmlFor="dispOfChargeId"
              id="dispOfChargeLabelId"
            >
              Disposition of charge *
            </label>
            <DropdownButton
              id="dispOfChargeId"
              type="default"
              variant="secondary"
              title={selectedDispOfCharge ? selectedDispOfCharge : "Choose one"}
              className={
                dispOfChargeError
                  ? "is-invalid disp-of-charge"
                  : !selectedDispOfCharge
                  ? "disp-of-charge"
                  : ""
              }
              selected={false}
              autoComplete="off"
              onSelect={dropdownOnChangeHandler}
              onClick={clickFunc}
              onBlur={onBlurDispOfChargeHandler}
            >
              {dispOfChargeArray.map(({ name, key }) => (
                <Dropdown.Item key={`${name}_${key}`} eventKey={name} id={name}>
                  {name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <div id="dispOfChargeError" className="invalid-feedback">
              Please choose Disposition of charge
            </div>
          </div>
        </div>

        {isAdditionalForm(selectedDispOfCharge) ? (
          <>
            <div className={`sentence-hr ${isExpl ? "expl" : ""}`}>
              {!isExpl && (
                <>
                  <hr />
                  <div className="eyebrow-text-wrapper">
                    <strong className="eyebrow">
                      {selectedDispOfCharge} Charge
                    </strong>
                  </div>
                  <hr />
                </>
              )}
            </div>

            {renderDispOfChargeOptions()}
          </>
        ) : null}
      </div>

      <div className="row drp-add-new-charge-btns">
        <div className="col-md-12 buttons">
          <span
            onClick={handleShowBackModal}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
            role="presentation"
          >
            <strong>Cancel</strong>
          </span>

          <Button
            className="addChargeButton"
            label={dataIndex !== null ? "Save" : "Add Charge"}
            onClick={handleAddCharge}
            disabled={isNextDisabled}
          />

          {false && <span className="mm-dots-loading"></span>}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default AddNewChargeContent;

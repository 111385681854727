import { withOktaAuth } from "@okta/okta-react";
import React, { Component } from "react";
import Loader from "../../SharedComponents/Loader";
import ConfidentialAppHeader from "./ConfAppSections/ConfidentialAppHeader";
import "../../../assets/stylesheets/ConfidentialApplication/ConfidentialApplication.scss";
import Footer from "../../SharedComponents/Footer";
import {
  getBrokerDealerInfo,
  getBusinessInfo,
  getCandidateCorp,
  getCandidateEducation,
  getCandidateMMLIS,
  getCCBInfo,
  getCombinedEmploymentHistoryInformation,
  getContractInfo,
  getCorporation,
  getCorporationCCB,
  getCorporationDD,
  getCorporationEO,
  getDashboard,
  getDirectDepositInfo,
  getDRPs,
  getEmpHistoryInformation,
  getErrorsAndOmissions,
  getESigScreenshot,
  getFinancingDetails,
  getInternEducation,
  getMMLISGeneralContributions,
  getMMLISManagedContributions,
  getMMLISObaCria,
  getPersonalInfoOpportunities,
  getPersonalInformation,
  getProfessionalDesignations,
  getPSRChartData,
  getResidentialAddress,
} from "../../../api/makeAPIRequest";
import ContractTypeDescription from "./ConfAppSections/ContractTypeDescription";
import {
  bin2String,
  isBrokerContract,
  isCareerAndMMLISContract,
  isCareerContract,
  isCorporateAndFinanceContract,
  isFinanceContract,
  isFinanceORBrokerORMMLISORCorpORMOC,
  isNotNullUndefinedOrEmpty,
} from "../../../common/Functions";
import ConfAppContractDetails from "./ConfAppSections/ConfAppContractDetails";
import ReviewPersonalInfo from "./ConfAppSections/ConfPersonalInformation";
import ReviewResidentialHistory from "./ConfAppSections/ConfResidentialHistory";
import { getResidentialHistory } from "../../../api/residentialHistory";
import ConfAppResAddress from "./ConfAppSections/ConfAppResAddress";
import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../constants/Contract";
import ConfEducation from "./ConfAppSections/ConfEducation";
import ConfAppBusInfo from "./ConfAppSections/ConfAppBusInfo";
import ReviewEmploymentHistory from "./ConfAppSections/ReviewEmploymentHistory";
import ReviewPSR from "./ConfAppSections/ReviewPSR";
import ConfAppCCB from "./ConfAppSections/ConfAppCCB";
import { getCCB14 } from "../../../api/CCB/ccb14";
import ConfDRPData from "./ConfAppSections/ConfDRPData";
import ConfAppDirectDeposit from "./ConfAppSections/ConfAppDirectDeposit";
import ReviewMMLIS from "./ConfAppSections/ReviewMMLIS/ReviewMMLIS";
import ConfAppESignature from "./ConfAppSections/ConfAppESignature";
import FinancingDetailsForm from "./ConfAppSections/FinancingDetailsForm";
import ConfAppBrokerDealer from "./ConfAppSections/ConfAppBrokerDealer";
import ConfAppCCBInd from "./ConfAppSections/ConfAppCCBInd";
import ConfAppEOC from "./ConfAppSections/ConfAppEOC";
import BrokerCorporationSection from "./ConfAppSections/ReviewCorp/BrokerCorporationSection";

class ConfidentialApplication extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.printPDF = this.printPDF.bind(this);
    this.state = {
      subId: this.props.authState.idToken.claims.sub,
      dashboard_info: "",
      personal_info: "",
      res_address: "",
      bus_address: "",
      broker_dealer_info: "",
      ccb14Data: "",
      ccb_info: "",
      eoc_info: "",
      dd_info: "",
      same_bank_info: "",
      contract_details: "",
      user_id: "",
      contract_id: "",
      secured_data: false,
      imageData: "",
      resHistoryData: "",
      educationData: "",
      empHistoryData: "",
      empHistoryBreakData: "",
      empHistoryCombinedData: "",
      psrChart: "",
      brokerDealerInfo: "",
      ddInfo: "",
      ccbDRPData: "",
      corp_data: "",
      mmlisData: "",
      mmlisManageData: "",
      mmlisGeneralData: "",
      mmlisObaCriaData: "",
      mmlisExamRes: "",
      financing_details: "",
      corporationData: {},
      corporationCCBData: {},
      corporationEOData: {},
      corporationDDData: {},
      professionalDesignations: "",
    };
  }

  async componentDidMount() {
    try {
      this.loadData();
    } catch (er) {
      console.error("Error in componentDidMount:", er);
    }
  }

  async hideLoader() {
    this.setState({ secured_data: true });
  }

  async loadData() {
    const queryParams = new URLSearchParams(window.location.search);
    const subId = queryParams.get("candidate");
    const contractid = queryParams.get("contract");
    if (!subId || !contractid) {
      window.close();
      return;
    }
    try {
      const [
        dashboardInfo,
        personalInfo,
        opps,
        fastCashInfo,
        contractDetails,
        financingDetailsData,
        eSig,
        psrChart,
        brokerDealerInfo,
        directDeposit,
        professionalDesignations,
      ] = await Promise.all([
        getDashboard(subId, contractid),
        getPersonalInformation(subId),
        getPersonalInfoOpportunities(),
        getDirectDepositInfo(subId),
        getContractInfo(subId, contractid),
        getFinancingDetails(contractid),
        getESigScreenshot(contractid),
        getPSRChartData(subId),
        getBrokerDealerInfo(subId),
        getBusinessInfo(subId),
        getProfessionalDesignations(subId),
      ]);

      let opportunity = opps.find(
        (item) => item.opportunityexpid === personalInfo.opportunityid
      );

      personalInfo.opportunitydesc = opportunity
        ? opportunity.opportunityexpdesc
        : "";

      let sameBankInfo =
        fastCashInfo.brokroutingnumber === fastCashInfo.mmroutingnumber &&
        fastCashInfo.brokaccountnumber === fastCashInfo.mmaccountnumber &&
        fastCashInfo.brokaccounttype === fastCashInfo.mmaccounttype;
      const { contracttypedesc, mmlis } = contractDetails;
      if (
        contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY &&
        contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP &&
        eSig.status === 200
      ) {
        let convertedImgData = await bin2String(eSig.data[0].screenimage.data);
        this.setState({ imageData: convertedImgData });
      }

      await this.setState({
        dashboard_info: dashboardInfo,
        personal_info: personalInfo,
        bus_address: !isFinanceContract(contracttypedesc)
          ? await getBusinessInfo(subId)
          : null,
        dd_info: fastCashInfo,
        same_bank_info: sameBankInfo,
        contract_details: contractDetails || "",
        professionalDesignations: professionalDesignations || "",
        financing_details: financingDetailsData || null,
      });

      if (
        isCareerAndMMLISContract(contracttypedesc) &&
        !isCorporateAndFinanceContract(contracttypedesc)
      ) {
        const [resHis, empHistory, empHistoryBreaks, education, ccb, drpData] =
          await Promise.all([
            getResidentialHistory(subId),
            getEmpHistoryInformation(subId),
            getCombinedEmploymentHistoryInformation(subId),
            contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
              ? getInternEducation(subId)
              : getCandidateEducation(subId),
            getCCB14({ oktaSubId: subId }),
            getDRPs(subId),
          ]);
        this.setState({
          resHistoryData: resHis,
          empHistoryData: empHistory,
          empHistoryBreakData: empHistoryBreaks,
          educationData: education,
          ccb14Data: ccb,
          ccbDRPData: drpData,
        });

        this.buildEmpHistData();
      } else if (
        isCorporateAndFinanceContract(contracttypedesc) ||
        !isCareerContract(contracttypedesc)
      ) {
        const [resAddr, ccb, eoc] = await Promise.all([
          getResidentialAddress(subId),
          getCCBInfo(subId),
          getErrorsAndOmissions(subId),
        ]);
        if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP) {
          const corpdata = {
            candidateid: contractDetails.candidateid,
            contractid: contractDetails.contractid,
          };
          const corpresults = await getCandidateCorp(subId, corpdata);
          const corpid = corpresults.corpid || 0;

          const corpData = await Promise.all([
            getCorporation(subId, { corpid }),
            getCorporationCCB(subId, { corpid }),
            getCorporationEO(subId, { corpid }),
            getCorporationDD(subId, { corpid }),
          ]);

          this.setState({
            corporationData: corpData[0],
            corporationCCBData: corpData[1],
            corporationEOData: corpData[2],
            corporationDDData: corpData[3],
          });
        }
        this.setState({
          res_address: resAddr,
          ccb14Data: ccb,
          eoc_info: eoc,
        });
        if (
          contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP ||
          contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER
        ) {
          this.setState({
            ccb_info: ccb,
          });
        }
      }
      if (contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS || mmlis) {
        const [
          mmlisRes,
          mmlisManageRes,
          mmlisGeneralRes,
          mmlisObaCriaRes,
          // mmlisExamRes,
        ] = await Promise.all([
          getCandidateMMLIS(subId),
          getMMLISManagedContributions(subId),
          getMMLISGeneralContributions(subId),
          getMMLISObaCria(subId),
          // getMMLIS(subId, contractid),
        ]);

        this.setState({
          mmlisData: mmlisRes,
          mmlisManageData: mmlisManageRes,
          mmlisGeneralData: mmlisGeneralRes,
          mmlisObaCriaData: mmlisObaCriaRes,
          // mmlisExamRes: mmlisExamRes,
        });
      }

      this.setState({
        psrChart,
        broker_dealer_info: brokerDealerInfo,
        ddInfo: directDeposit,
      });

      this.hideLoader();
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }

  buildEmpHistData = () => {
    var empHistCombined = [];
    if (this.state.empHistoryBreakData.length > 0) {
      for (let i = 0; i < this.state.empHistoryBreakData.length; i++) {
        if (this.state.empHistoryBreakData[i].companyname !== null) {
          for (let j = 0; j < this.state.empHistoryData.length; j++) {
            if (
              this.state.empHistoryBreakData[i].id ===
              this.state.empHistoryData[j].employmenthistoryid
            ) {
              empHistCombined.push(this.state.empHistoryData[j]);
              break;
            }
          }
        } else {
          empHistCombined.push(this.state.empHistoryBreakData[i]);
        }
      }
    }

    this.setState({ empHistoryCombinedData: empHistCombined });
  };

  async printPDF() {
    window.print();
  }

  render() {
    const {
      financing_details,
      dashboard_info,
      subId,
      contract_details,
      corporationData,
    } = this.state;
    const hasFinancingAddon = dashboard_info?.financingonly;
    const { mmlis, contracttypeid, nyregs, cmid, contracttypedesc } =
      contract_details;
    const { agencynumber, mmid, showcontractdetails, contractstatusdesc } =
      dashboard_info;
    const { firstname, lastname } = this.state.personal_info;
    // const { corporationData, corporationCCBData } = this.state;
    const { corpmmid, corpcmid } = corporationData;
    const showContractDetails =
      !isFinanceContract(contracttypedesc) &&
      this.state.contract_details !== "" &&
      (contractstatusdesc === CONTRACT_STATUS_DESC.HOME_OFFICE_REVIEW ||
        contractstatusdesc === CONTRACT_STATUS_DESC.SUBMITTED_TO_HOME_OFFICE ||
        contractstatusdesc === CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED ||
        contractstatusdesc === CONTRACT_STATUS_DESC.ACKNOWLEDGED_DECLINED ||
        contractstatusdesc === CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
        contractstatusdesc === CONTRACT_STATUS_DESC.DECLINED_BY_HOME_OFFICE ||
        contractstatusdesc === CONTRACT_STATUS_DESC.MMLISPending ||
        ((contractstatusdesc === CONTRACT_STATUS_DESC.APPLICATION_IN_PROGRESS ||
          contractstatusdesc === CONTRACT_STATUS_DESC.APPLICATION_SUBMITTED ||
          contractstatusdesc === CONTRACT_STATUS_DESC.AGENCY_REVIEW ||
          contractstatusdesc === CONTRACT_STATUS_DESC.RETURNED_TO_CANDIDATE ||
          contractstatusdesc === CONTRACT_STATUS_DESC.RETURNED_TO_AGENCY ||
          contractstatusdesc ===
            CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN ||
          contractstatusdesc ===
            CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY) &&
          showcontractdetails));

    const showDivider =
      isFinanceContract(contracttypedesc) ||
      showContractDetails ||
      contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
      hasFinancingAddon;
    return (
      <div id="confidential-application">
        <Loader customClass={this.state.secured_data ? "hidden" : ""} />
        <ConfidentialAppHeader printFunction={this.printPDF} />
        {this.state.secured_data ? (
          <>
            <div className="container">
              <div>
                <div className="row">
                  <div className="col-12 bo--text-color">
                    <h1 id="confidential_name">
                      {firstname}&nbsp;
                      {lastname}
                    </h1>
                    <ContractTypeDescription
                      contracttypedesc={contracttypedesc}
                      mmlis={mmlis}
                      hasFinancingAddon={hasFinancingAddon}
                      agencynumber={agencynumber}
                      corpmmid={corpmmid}
                      corpcmid={corpcmid}
                      mmid={mmid}
                      cmid={cmid}
                    />
                    <div id="confidential_form" className="confidential-form">
                      {showContractDetails && (
                        <ConfAppContractDetails
                          contract_details={contract_details}
                          dashboard_info={this.state.dashboard_info}
                          hasFinancingAddon={hasFinancingAddon}
                          contracttypedesc={contracttypedesc}
                        ></ConfAppContractDetails>
                      )}
                      {hasFinancingAddon && (
                        <>
                          <div
                            className="mm-section mm-spacing--minor"
                            style={{ paddingTop: 20 }}
                          >
                            FINANCING PLAN
                          </div>
                          <FinancingDetailsForm
                            readOnly
                            dataView
                            financingDetails={financing_details}
                            id="confidential"
                            contract_details={contract_details}
                          />
                        </>
                      )}
                      {contracttypedesc ===
                        CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                        <BrokerCorporationSection
                          contracttypedesc={contracttypedesc}
                          corporationData={this.state.corporationData}
                          corporationCCBData={this.state.corporationCCBData}
                          corporationEOData={this.state.corporationEOData}
                          corporationDDData={this.state.corporationDDData}
                          brokerDealerInfo={this.state.broker_dealer_info}
                          subId={subId}
                          contracttypeid={contracttypeid}
                        />
                      )}
                      {showDivider ? (
                        <hr />
                      ) : (
                        <div className="mm-spacing--minor"></div>
                      )}
                      {contracttypedesc ===
                        CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                        <div className="col-7 row">
                          <h2
                            id="conf_broker_corporation"
                            style={{ color: "#000000" }}
                          >
                            Member of Corporation
                          </h2>
                        </div>
                      )}

                      <ReviewPersonalInfo
                        personalInfo={this.state.personal_info}
                        contracttype={contracttypedesc}
                        id="confidential_pinfo"
                      />
                      {!isFinanceContract(contracttypedesc) && <hr></hr>}
                      {isCareerAndMMLISContract(contracttypedesc) ? (
                        <ReviewResidentialHistory
                          resHistory={this.state.resHistoryData}
                          id="confidential"
                          contracttypeid={contracttypeid}
                        />
                      ) : (
                        contracttypedesc !==
                          CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY && (
                          <ConfAppResAddress
                            res_address={this.state.res_address}
                            contracttypeid={contracttypeid}
                          ></ConfAppResAddress>
                        )
                      )}
                      {contracttypedesc !==
                        CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY &&
                        contracttypedesc !==
                          CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
                          <>
                            <hr></hr>
                            <ConfAppBusInfo
                              id="conf_bussinfo"
                              busAddress={this.state.bus_address}
                              contracttype={contracttypedesc}
                            ></ConfAppBusInfo>
                          </>
                        )}
                      {isCareerContract(contracttypedesc) && (
                        <>
                          <hr></hr>
                          <ConfEducation
                            educationData={this.state.educationData[0]}
                            form_id="confidential_education"
                            contracttypeid={contracttypeid}
                            contracttype={contracttypedesc}
                            professionalDesignations={
                              this.state.professionalDesignations
                            }
                          />
                        </>
                      )}
                      {isCareerAndMMLISContract(contracttypedesc) && (
                        <>
                          <hr></hr>
                          <ReviewEmploymentHistory
                            empHistory={this.state.empHistoryCombinedData}
                            subId={subId}
                            id="confidential_emphist"
                            hasNYRegs={nyregs}
                            contracttypeid={contracttypeid}
                          />
                        </>
                      )}
                      {this.state.psrChart.payload?.length > 0 &&
                        !isFinanceORBrokerORMMLISORCorpORMOC(
                          contracttypedesc
                        ) && (
                          <>
                            <hr></hr>
                            <ReviewPSR
                              chartInfo={this.state.psrChart}
                              hasNYRegs={nyregs}
                              brokerDealerInfo={this.state.broker_dealer_info}
                              subId={subId}
                              id="confidential_psr"
                              heading={
                                nyregs
                                  ? "Personal Production Record"
                                  : "Personal Sales Record"
                              }
                            />
                          </>
                        )}
                      {!isCareerAndMMLISContract(contracttypedesc) &&
                        !isFinanceContract(contracttypedesc) && (
                          <>
                            <hr></hr>
                            <ConfAppBrokerDealer
                              broker_dealer_info={this.state.broker_dealer_info}
                            ></ConfAppBrokerDealer>
                          </>
                        )}
                      {isCareerAndMMLISContract(contracttypedesc) ? (
                        <>
                          <hr></hr>
                          <ConfAppCCB
                            ccb_info={this.state.ccb14Data}
                          ></ConfAppCCB>

                          <ConfDRPData ccbDRPData={this.state.ccbDRPData} />
                        </>
                      ) : (
                        contracttypedesc !==
                          CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY && (
                          <>
                            <hr></hr>
                            <ConfAppCCBInd ccb_info={this.state.ccb_info} />
                          </>
                        )
                      )}
                      {!isCareerAndMMLISContract(contracttypedesc) &&
                        !isFinanceContract(contracttypedesc) && (
                          <>
                            <hr></hr>
                            <ConfAppEOC
                              eoc_info={this.state.eoc_info}
                            ></ConfAppEOC>
                          </>
                        )}
                      {(isCareerContract(contracttypedesc) ||
                        isBrokerContract(contracttypedesc)) && (
                        <>
                          <hr></hr>
                          <ConfAppDirectDeposit
                            dd_info={this.state.dd_info}
                            same_bank_info={this.state.same_bank_info}
                          ></ConfAppDirectDeposit>
                        </>
                      )}
                      {(mmlis ||
                        contracttypedesc ===
                          CONTRACT_TYPE_DESCRIPTIONS.MMLIS) && (
                        <>
                          <hr></hr>
                          <ReviewMMLIS
                            contracttype={contracttypedesc}
                            subId={subId}
                            id="conf_info"
                            contracttypeid={contracttypeid}
                            mmlisData={this.state.mmlisData}
                            mmlisManageData={this.state.mmlisManageData}
                            mmlisGeneralData={this.state.mmlisGeneralData}
                            mmlisObaCriaData={this.state.mmlisObaCriaData}
                          />
                        </>
                      )}
                      {isFinanceContract(contracttypedesc) && (
                        <>
                          <div
                            className="mm-section mm-spacing--minor"
                            style={{ paddingTop: 20 }}
                          >
                            FINANCING PLAN
                          </div>

                          <FinancingDetailsForm
                            readOnly
                            dataView
                            financingDetails={financing_details}
                            id="confidential"
                            contract_details={this.state.contract_details}
                          />
                        </>
                      )}
                      {contracttypedesc !==
                        CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY &&
                        contracttypedesc !==
                          CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                          <>
                            <hr></hr>
                            {isNotNullUndefinedOrEmpty(
                              this.state.imageData
                            ) && (
                              <ConfAppESignature
                                imageData={this.state.imageData}
                              ></ConfAppESignature>
                            )}
                          </>
                        )}
                      <div id="footer-wrapper">
                        <Footer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            <div id="conf_load1" className="mm-skeleton--short">
              <span className="sr-only" aria-busy="true" aria-live="polite">
                Content is loading
              </span>
            </div>
            <div id="conf_load2" className="mm-skeleton--tall">
              <span className="sr-only" aria-busy="true" aria-live="polite">
                Content is loading
              </span>
            </div>
            <div id="conf_load3" className="mm-skeleton--tall">
              <span className="sr-only" aria-busy="true" aria-live="polite">
                Content is loading
              </span>
            </div>
            {this.props?.id !== "integration" && <Footer></Footer>}
          </div>
        )}
      </div>
    );
  }
}

export default withOktaAuth(ConfidentialApplication);

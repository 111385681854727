import { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import { CONTRACT_STATUS_DESC } from "../../../constants/Contract";
import ApplicationSentToCandidate from "./ApplicationSentToCandidate";
import HomePageActiveApps from "./HomePageActiveApps";
import HomePagePriorApps from "./HomePagePriorApps";

const RECORDS_PER_PAGE = 5;

function ActiveAndPriorApplications({ contractList, archiveList }) {
  const [currentPage, setCurrentPage] = useState(1);

  const defaultGetStartedContract = useMemo(() => {
    return contractList.length === 1 &&
      archiveList.length === 0 &&
      contractList[0].contractstatusdesc ===
        CONTRACT_STATUS_DESC.INVITATION_SENT
      ? contractList[0]
      : null;
  }, [contractList, archiveList]);

  const [getStartedContract, setGetStartedContract] = useState(null);

  useEffect(() => {
    if (defaultGetStartedContract) {
      setGetStartedContract(defaultGetStartedContract);
    }
  }, [defaultGetStartedContract]);

  const handlePaginationChange = (id) => {
    if (id === "nextPage") {
      setCurrentPage(currentPage + 1);
    } else if (id === "lastPage") {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(id);
    }
  };

  const indexOfLastRecord = currentPage * RECORDS_PER_PAGE;
  const indexOfFirstRecord = indexOfLastRecord - RECORDS_PER_PAGE;
  const currentRecords = archiveList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(archiveList.length / RECORDS_PER_PAGE);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const showGetStartedFor = (contractId) => {
    setGetStartedContract(
      contractList.find((contract) => contract.contractid === contractId)
    );
  };

  if (getStartedContract) {
    return (
      <>
        <ApplicationSentToCandidate
          contract={getStartedContract.contracttypedesc}
          agency={getStartedContract.agencyname}
          contractid={getStartedContract.contractid}
          agencynumber={getStartedContract.agencynumber}
          nyregs={getStartedContract.nyregs}
          phonenumber={getStartedContract.phonenumber}
          mmlis={getStartedContract.mmlis}
          financingonly={getStartedContract.financingonly}
          multiContracts={contractList.length}
          archiveContract={archiveList.length}
        />

        <p className="need-help-box">
          Need help? Contact your Agency Licensing Coordinator or Agency
          Supervisory Officer at Agency #{getStartedContract.agencynumber} at{" "}
          {getStartedContract.phonenumber}
        </p>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <div>
          <h4>
            Active Applications ({contractList.length})
            <div className="tooltipContainer">
              <span className="icon-info"></span>
              <span className="tooltiptext">
                This section contains applications submitted through this system
                that are still currently in progress.
              </span>
            </div>
          </h4>

          <hr></hr>

          {contractList.map((x, idx) => (
            <HomePageActiveApps
              key={`${idx}_${x.contractid}`}
              agencynumber={x.agencynumber}
              applicationStatus={x.contractstatusdesc}
              contract={x}
              contracttype={x.contracttypedesc}
              contractid={x.contractid}
              mmlis={x.mmlis}
              finance={x.financingonly}
              lastchangedate={x.lastchangedate}
              showGetStartedFor={showGetStartedFor}
              multiContracts={contractList.length}
            />
          ))}
        </div>
      </div>
      {archiveList.length > 0 && (
        <div className="container" style={{ marginTop: "100px" }}>
          <div>
            <h4>
              Prior Applications ({archiveList.length})
              <div className="tooltipContainer">
                <span className="icon-info"></span>
                <span className="tooltiptext">
                  This section contains applications submitted through this
                  system that might not be active contracts or agreements.{" "}
                  <br></br> If you don’t see the application that you are
                  looking for, reach out to the agency that you submitted it
                  with.
                </span>
              </div>
            </h4>

            <hr></hr>

            <>
              {currentRecords.map((x, idx) => (
                <HomePagePriorApps
                  key={`${idx}_${x.contractid}`}
                  agencynumber={x.agencynumber}
                  agency={x.agencyname}
                  applicationStatus={x.contractstatusdesc}
                  contract={x}
                  contracttype={x.contracttypedesc}
                  contracteffectivedate={x.contracteffectivedate}
                  contractid={x.contractid}
                  prevSub={x.prevsub}
                  firstname={x.firstname}
                  lastname={x.lastname}
                  nyregs={x.nyregs}
                  phonenumber={x.phonenumber}
                  mmlis={x.mmlis}
                  finance={x.financingonly}
                  cmid={x.cmid}
                  lastchangedate={x.lastchangedate}
                  mmlisregistrationdate={x.mmlisregistrationdate}
                  oktasubid={x.oktasubid}
                />
              ))}
              {archiveList.length > 5 && (
                <Pagination
                  handleChange={handlePaginationChange}
                  nPages={nPages}
                  currentPage={currentPage}
                  pageNumbers={pageNumbers}
                />
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}

export default ActiveAndPriorApplications;

import { KEYS, SECTION } from "../../constants";
import {
  convertGroupKey,
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import { headerText } from "../constants";
import {
  DRPSummaryFormalPendingActionInvestigation,
  DRPSummaryProfessionalSuspension,
  DRPSummaryRegulatoryActionOtherRegulators,
  DRPSummaryRegulatoryActionSECorCFTC,
  DRPSummaryRegulatoryActionSRO,
} from "../state";

export const filterByUnchecked = (section, uncheckedList, data) => {
  let sectionKey = null;

  switch (section) {
    case SECTION.regulatoryActionSECorCFTC:
    case SECTION.regulatoryActionOtherRegulators:
    case SECTION.regulatoryActionSRO:
    case SECTION.professionalSuspension:
      sectionKey = convertGroupKey(headerText.REG);
      break;
    case SECTION.formalPendingActionInvestigation:
      break;

    default:
      break;
  }

  let results = [];

  if (
    section === SECTION.formalPendingActionInvestigation &&
    sectionKey === null &&
    uncheckedList?.length
  ) {
    uncheckedList?.forEach((uncheckedKey) => {
      if (
        data[convertGroupKey(headerText.REG_FORM_INV)]?.hasOwnProperty(
          uncheckedKey
        )
      ) {
        const entries =
          data[convertGroupKey(headerText.REG_FORM_INV)][uncheckedKey];

        entries.forEach((entry) => {
          if (entry?.invDetails?.drpCheckList.includes(uncheckedKey)) {
            const types = [entry?.invDetails?.noticeReceived] || null;
            const date = entry?.invDetails?.noticeDate || null;
            const status = entry?.statusDetails?.invPending
              ? "Pending"
              : entry?.statusDetails?.invResolved;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              isInv: true,
              status,
              date,
              dataIndex: entry.dataIndex,
            });
          }
        });
      }

      if (data[convertGroupKey(headerText.REG)]?.hasOwnProperty(uncheckedKey)) {
        const entries = data[convertGroupKey(headerText.REG)][uncheckedKey];

        entries.forEach((entry) => {
          if (entry?.actDetails?.drpCheckList.includes(uncheckedKey)) {
            const types =
              [entry?.actDetails?.selectedRegActionInitiated] || null;
            const date = entry?.actDetails?.dateInitiated || null;
            const status = entry?.statusDetails?.status;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              status,
              date,
              dataIndex: entry.dataIndex,
            });
          }
        });
      }
    });

    return results;
  }

  const sectionData = data[sectionKey];

  uncheckedList?.forEach((uncheckedKey) => {
    if (sectionData?.hasOwnProperty(uncheckedKey)) {
      const entries = sectionData[uncheckedKey];

      if (
        [
          SECTION.regulatoryActionSECorCFTC,
          SECTION.regulatoryActionOtherRegulators,
          SECTION.regulatoryActionSRO,
          SECTION.professionalSuspension,
        ].includes(section) ||
        sectionKey === convertGroupKey(headerText.REG_FORM_ACT)
      ) {
        entries.forEach((entry) => {
          if (entry?.actDetails?.drpCheckList.includes(uncheckedKey)) {
            const types =
              [entry?.actDetails?.selectedRegActionInitiated] || null;
            const date = entry?.actDetails?.dateInitiated || null;

            const status = entry?.statusDetails?.status;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              status,
              date,
              dataIndex: entry.dataIndex,
            });
          }
        });
      } else {
        entries.forEach((entry) => {
          if (entry?.invDetails?.drpCheckList.includes(uncheckedKey)) {
            const types = [entry?.invDetails?.noticeReceived] || null;
            const date = entry?.invDetails?.noticeDate || null;
            const status = entry?.statusDetails?.status;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              status,
              date,
              dataIndex: entry.dataIndex,
            });
          }
        });
      }
    }
  });

  return results;
};

export const isDrpSummaryPage = (data) => {
  const regulatoryActionSECorCFTCObj = getSpecificObjByKeys(
    data,
    KEYS.regulatoryActionSECorCFTC
  );
  const regulatoryActionOtherRegulatorsObj = getSpecificObjByKeys(
    data,
    KEYS.regulatoryActionOtherRegulators
  );
  const regulatoryActionSROObj = getSpecificObjByKeys(
    data,
    KEYS.regulatoryActionSRO
  );
  const professionalSuspensionObj = getSpecificObjByKeys(
    data,
    KEYS.professionalSuspension
  );
  const formalPendingActionInvestigationObj = getSpecificObjByKeys(
    data,
    KEYS.formalPendingActionInvestigation
  );

  const secCFTC = getDRPSummaryQuestions(
    DRPSummaryRegulatoryActionSECorCFTC,
    regulatoryActionSECorCFTCObj
  );
  const actReg = getDRPSummaryQuestions(
    DRPSummaryRegulatoryActionOtherRegulators,
    regulatoryActionOtherRegulatorsObj
  );
  const actSRO = getDRPSummaryQuestions(
    DRPSummaryRegulatoryActionSRO,
    regulatoryActionSROObj
  );
  const proSusp = getDRPSummaryQuestions(
    DRPSummaryProfessionalSuspension,
    professionalSuspensionObj
  );
  const regFormActInv = getDRPSummaryQuestions(
    DRPSummaryFormalPendingActionInvestigation,
    formalPendingActionInvestigationObj
  );

  const uncheckedSecCFTC = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryRegulatoryActionSECorCFTC,
    regulatoryActionSECorCFTCObj
  );

  const uncheckedActReg = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryRegulatoryActionOtherRegulators,
    regulatoryActionOtherRegulatorsObj
  );

  const uncheckedActSRO = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryRegulatoryActionSRO,
    regulatoryActionSROObj
  );

  const uncheckedProSusp = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryProfessionalSuspension,
    professionalSuspensionObj
  );

  const uncheckedRegFormActInv = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryFormalPendingActionInvestigation,
    formalPendingActionInvestigationObj
  );

  return {
    secCFTC,
    actReg,
    actSRO,
    proSusp,
    regFormActInv,
    uncheckedSecCFTC,
    uncheckedActReg,
    uncheckedActSRO,
    uncheckedProSusp,
    uncheckedRegFormActInv,
  };
};

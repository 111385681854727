import { Component } from "react";
import Moment from "moment";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import ReviewPageTitle from "./ReviewPageTitle";
import { SECTION_TITLE } from "../../../../../common/Functions";

class ReviewMMLISPayToPlay2 extends Component {
  constructor(props) {
    super(props);
    this.hideReview = this.hideReview.bind(this);
    this.state = {
      contribDate: "",
      contribAmount: "",
      result: "",
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    var result = JSON.parse(this.props.contrib);
    this.setState({ result: result });

    this.setState({
      contribDate: Moment.utc(result.contribdate).format("MM/DD/YYYY"),
    });

    var commaNumber = result.contribamt.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    commaNumber = commaNumber.replace(".00", "");

    this.setState({ contribAmount: commaNumber });
    UtagTracking.sendPageNameView(
      "reviewmmlispaytoplay2info",
      this.props.contracttypeid
    );
  }

  async hideReview() {
    this.props.hideReview();
  }

  render() {
    return (
      <div id="reviewMMLISInfo" className="review-form">
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-link" onClick={() => this.hideReview()}>
              <strong className="peacock-blue return-link">
                &lt; Return To MMLIS
              </strong>
            </button>
          </div>
        </div>

        <ReviewPageTitle
          pageTitle="Pay to Play Compliance Disclosure form"
          sectionTitle={SECTION_TITLE}
        ></ReviewPageTitle>

        <div className="candidate-info">
          <div className="row">
            <div className="col-5">
              <strong id="mmlisDateLabel">Date of Contribution*</strong>
            </div>
            <div id="mmlisDate" className="col-6 bo--text-color">
              {this.state.contribDate !== "" ? this.state.contribDate : "-"}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <strong id="mmlisAmountLabel">Amount*</strong>
            </div>
            <div id="mmlisAmount" className="col-6 bo--text-color">
              $
              {this.state.contribAmount !== "" ? this.state.contribAmount : "-"}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <strong id="mmlisFormLabel">
                Form of contribution if other than cash or check
              </strong>
            </div>
            <div id="mmlisForm" className="col-6 bo--text-color">
              {this.state.result.contribform !== ""
                ? this.state.result.contribform
                : "-"}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <strong id="mmlisRecipientLabel">
                Name of Recipient, Political Party, PAC, or PPC*
              </strong>
            </div>
            <div id="mmlisRecipient" className="col-6 bo--text-color">
              {this.state.result.contribrecipient !== ""
                ? this.state.result.contribrecipient
                : "-"}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <strong id="mmlisEntitledLabel">
                Were you entitled to vote in the jurisdiction covered by the
                party committee at the time of the contribution?*
              </strong>
            </div>
            <div id="mmlisEntitled" className="col-6 bo--text-color">
              {this.state.result.entitledtovote === true ? "Yes" : "No"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewMMLISPayToPlay2;
